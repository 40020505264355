import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { BonusRouter } from './bonus/BonusRouter';
import { EmployeeRouter } from './employees/EmployeeRouter';
import { ExitsRouter } from './exits/ExitsRouter';
import { IncidentRouter } from './incidents/IncidentRouter';
import { subRoutes } from '../../utils/constants/adminRoutes';

export const EmployeeFolderRouter = () => (
  <Routes>
    <Route
      path={`${subRoutes.EMPLOYEE_FOLDER.EMPLOYEES}/*`}
      element={<EmployeeRouter />}
    />
    <Route
      path={`${subRoutes.EMPLOYEE_FOLDER.BONUS}/*`}
      element={<BonusRouter />}
    />
    <Route
      path={`${subRoutes.EMPLOYEE_FOLDER.INCIDENTS}/*`}
      element={<IncidentRouter />}
    />
    <Route
      path={`${subRoutes.EMPLOYEE_FOLDER.EXITS}/*`}
      element={<ExitsRouter />}
    />
  </Routes>
);
