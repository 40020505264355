import React, { useState } from 'react';
import { Switch, Select, DatePicker, TimePicker, Input, Upload } from 'antd';
import moment from 'moment';
import { CameraOutlined } from '@ant-design/icons/lib';
import { useTranslation } from 'react-i18next';
import { useErrorMessage } from '../../../utils/ErrorMessage';

const { Dragger } = Upload;
const { TextArea } = Input;
const { Option } = Select;

export const fileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (e) => reject(e);
  });

const useFields = (
  allEstablishement,
  setAllEstablishment,
  draft,
  setDraft,
  premises
) => {
  const { message } = useErrorMessage();
  const [fileList, setFileList] = useState([]);
  const [base64, setBase64] = useState('');
  const { t } = useTranslation();

  // This function convert the PDF to base64 format

  const draggerProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: async (file) => {
      const fileExtension = file.name.split('.').pop();
      if (
        fileExtension === 'png' ||
        fileExtension === 'PNG' ||
        fileExtension === 'jpg' ||
        fileExtension === 'JPG'
      ) {
        setFileList([...fileList, file]);
        const base = await fileToBase64(file);
        setBase64(base);
        return false;
      }
      message('Not a PNG or JPG file.');
      return true;
    },
    fileList
  };

  const format = 'HH:mm';

  const disabledDate = (current) =>
    current && current < moment().startOf('day');

  const formFields = [
    {
      name: ['switch_premises'],
      input: (
        <Switch
          checked={allEstablishement}
          onChange={() => setAllEstablishment(!allEstablishement)}
        />
      )
    },
    {
      name: ['premises'],
      hidden: allEstablishement,
      rules: [{ required: !allEstablishement }],
      input: (
        <Select
          showSearch
          mode="multiple"
          allowClear
          style={{ width: '100%' }}
          placeholder={t('memos.form.premises')}
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
        >
          {(premises || [])
            .filter((premise) => premise.show)
            .map((premise) => (
              <Option label={premise.label} key={premise._id}>
                {premise.label}
              </Option>
            ))}
        </Select>
      )
    },
    {
      name: ['date'],
      rules: [{ required: true }],
      input: (
        <DatePicker style={{ width: '100%' }} disabledDate={disabledDate} />
      )
    },
    {
      name: ['scheduledTime'],
      rules: [{ required: true }],
      input: (
        <TimePicker style={{ width: '100%' }} minuteStep={30} format={format} />
      )
    },
    {
      name: ['draft'],
      input: <Switch checked={draft} onChange={() => setDraft(!draft)} />
    },
    {
      name: ['object'],
      rules: [{ required: true }],
      input: <Input placeholder="Veuillez définir l'objet" />
    },
    {
      name: ['content'],
      rules: [{ required: true }],
      input: <TextArea />
    },
    {
      name: ['photo'],
      input: (
        <Dragger {...draggerProps}>
          <p className="ant-upload-drag-icon">
            <CameraOutlined style={{ color: 'var(--textColor)' }} />
          </p>
          <p className="ant-upload-text">{t('files.create.action')}</p>
        </Dragger>
      )
    }
  ];

  return { formFields, base64 };
};

export default useFields;
