import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ListTemplateDocs from './ListTemplateDocs';
import { DocEditor } from './DocEditor/DocEditor';
import { Exception } from '../../components';
import { ShowTemplate } from './ShowTemplate';

export const TemplateDocRouter = () => (
  <Routes>
    <Route path="/create" element={<DocEditor purpose="create" />} />
    <Route path="/edit/:id" element={<DocEditor purpose="edit" />} />
    <Route path="/show/:id" element={<ShowTemplate />} />
    {/* ---------------------------------------------------------- */}
    <Route index element={<ListTemplateDocs />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
