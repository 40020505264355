import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Drawer, Form, Spin } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/ErrorMessage';
import useFields from './fields';
import { useGenerateFormItem } from '../../../../utils/GenerateFormItem';
import { useApplicationsContext } from '../../../../contexts/ApplicationsContext';
import { setParam } from './utils/setParam';

const EditDrawer = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState(null);
  const { message } = useErrorMessage();
  const generateFormItem = useGenerateFormItem();
  const {
    visible,
    setVisible,
    application,
    dataRefresh,
    setDataRefresh,
    setCurrentApplication
  } = useApplicationsContext();
  const { fields, isFieldsLoading, isCandidateExist } = useFields({
    visible,
    setFile,
    application
  });

  const onClose = () => {
    form.resetFields();
    setCurrentApplication(null);
    setVisible(false);
    setDataRefresh(!dataRefresh);
  };

  const onFinishForm = async (values) => {
    try {
      await dispatchAPI(
        application ? 'PATCH' : 'POST',
        setParam({ values, file, application, isCandidateExist })
      );
      onClose();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const fetchApplication = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/applications/${application._id}?populate=candidate`
      });
      form.setFieldsValue(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsLoading(false);
  }, [application, isFieldsLoading]);

  useEffect(() => {
    if (application && !isFieldsLoading)
      (async () => {
        await fetchApplication();
      })();
  }, [fetchApplication]);

  return (
    <Drawer
      destroyOnClose
      title={t(`applications.form.title.${application ? 'edit' : 'create'}`)}
      width={480}
      onClose={onClose}
      visible={visible}
      getContainer={false}
    >
      <Spin spinning={isLoading}>
        <Form
          wrapperCol={{ span: 24 }}
          labelCol={{ span: 24 }}
          form={form}
          onFinish={onFinishForm}
        >
          {fields.map((field) => generateFormItem('applications', field))}
          <Form.Item style={{ marginTop: 16 }}>
            <Button
              style={{ margin: '0 10px' }}
              type="link"
              danger
              onClick={onClose}
            >
              {`${t('buttons.cancel')} `}
              <CloseOutlined />
            </Button>
            <Button type="add" htmlType="submit">
              {`${t('buttons.save')} `}
              <CheckOutlined />
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Drawer>
  );
};

export default EditDrawer;
