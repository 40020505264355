import React, { useState, useEffect } from 'react';
import { Tabs, Button } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import ListPremises from './ListPremises';
import { ContentCustom, PageHeaderCustom } from '../../../components';
import { routes, subRoutes } from '../../../utils/constants/adminRoutes';
import { permissionVerification } from '../../../utils/permissionVerification';

const ListDocuments = () => {
  const { t } = useTranslation();
  const { dispatchAPI, userPermissions } = useAuthContext();
  const { message } = useErrorMessage();
  const [premises, setPremises] = useState([]);
  const [type, setType] = useState('welcome_booklet');
  const navigate = useNavigate();
  const [permit, setPermit] = useState(false);

  useEffect(() => {
    const permission = permissionVerification(
      userPermissions,
      '/intern-communication/documents/modification'
    );
    setPermit(permission);
  }, [userPermissions]);

  useEffect(() => {
    navigate(
      `${routes.INTERN_COMMUNICATION}${subRoutes.INTERN_COMMUNICATION.DOCUMENTS}?type=${type}`
    );
  }, [type]);

  const items = [
    {
      key: 'welcome_booklet',
      label: `Livret d'accueil`,
      children: premises.map((premise, index) => (
        <ListPremises premise={premise} index={index} />
      ))
    },
    {
      key: 'presentation',
      label: `Présentations`,
      children: premises.map((premise, index) => (
        <ListPremises premise={premise} index={index} />
      ))
    },
    {
      key: 'other',
      label: `Autres`,
      children: premises.map((premise, index) => (
        <ListPremises premise={premise} index={index} />
      ))
    }
  ];

  const getPremises = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/premise' });
      setPremises(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getPremises();
    })();
  }, []);

  const onChange = (key) => {
    setType(key);
  };

  return (
    <>
      <PageHeaderCustom title={t('dashboard.title')} />
      <ContentCustom>
        <Tabs
          defaultActiveKey="welcome_booklet"
          items={items}
          onChange={onChange}
          tabBarExtraContent={
            <Link to="create">
              <Button type="add" disabled={!permit}>
                {t('buttons.create')}
                &nbsp;
                <PlusOutlined />
              </Button>
            </Link>
          }
        />
      </ContentCustom>
    </>
  );
};

export default ListDocuments;
