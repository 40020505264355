/* eslint-disable require-await */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Button, Form, Input, Popconfirm, Switch, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { CloseOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  selectType,
  checkType,
  record,
  index,
  children,
  setColorPicker,
  colorPicker,
  ...restProps
}) => {
  let inputNode;
  switch (inputType) {
    case 'text':
      inputNode = !checkType ? (
        <Input />
      ) : (
        <Switch defaultChecked={record.show} />
      );
      break;
    case 'textarea':
      inputNode = <Input.TextArea rows={6} />;
      break;
    case 'select':
      inputNode = <Input.TextArea rows={6} />;
      break;
    default:
      break;
  }
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0
          }}
          rules={[
            {
              required: true,
              message: `veuillez saisir ${title}!`
            }
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const InterviewFrameTable = ({ interviewframeCols, editInterviewFrameCol }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const [colorPicker, setColorPicker] = useState('');
  const [switchLoading, setSwitchLoading] = useState(false);

  const isEditing = (record) => record._id === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      title: record.title,
      label: record.label,
      show: record.show,
      ...record
    });
    setEditingKey(record._id);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const saveSwitch = async (record) => {
    try {
      editInterviewFrameCol(record._id, record);
      setEditingKey('');
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Error with Switch change: ', e);
    }
  };

  const save = async (record) => {
    try {
      const row = await form.validateFields();
      editInterviewFrameCol(record._id, row);
      setEditingKey('');
    } catch (errInfo) {
      // eslint-disable-next-line no-console
      console.log('Validate Failed:', errInfo);
    }
  };

  const columns = [
    {
      title: t('settings.interviewframeTable.title'),
      dataIndex: 'title',
      width: '30%',
      editable: true
    },
    {
      title: t('settings.interviewframeTable.label'),
      dataIndex: 'label',
      width: '50%',
      editable: true
    },
    {
      title: t('settings.interviewframeTable.show'),
      dataIndex: 'show',
      width: '15%',
      editable: true,
      render: (value, record) => (
        <Switch
          loading={switchLoading}
          checked={value}
          onClick={async () => {
            setSwitchLoading(true);
            setTimeout(async () => {
              // eslint-disable-next-line no-param-reassign
              record.show = !value;
              await saveSwitch(record);
              setSwitchLoading(false);
            }, 500);
          }}
        />
      )
    },
    {
      title: t('settings.interviewframeTable.action'),
      dataIndex: 'operation',
      width: '20%',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <div>
            <Button
              // eslint-disable-next-line no-script-url
              href="javascript:;"
              onClick={() => save(record)}
            >
              {t('buttons.save')}
              <SaveOutlined />
            </Button>
            <Popconfirm
              title={t('settings.messages.confirm-deletion')}
              onConfirm={cancel}
            >
              <Button>
                {t('buttons.cancel')}
                <CloseOutlined />
              </Button>
            </Popconfirm>
          </div>
        ) : (
          <Button
            onClick={() => edit(record)}
            disabled={editingKey !== ''}
            type="primary"
          >
            {t('buttons.edit')}
            <EditOutlined />
          </Button>
        );
      }
    }
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: ['color', 'label'].includes(col.dataIndex)
          ? 'textarea'
          : 'text',
        selectType: col.dataIndex === 'color' ? 'color' : 'icon',
        checkType: col.dataIndex === 'show',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        colorPicker,
        setColorPicker
      })
    };
  });
  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell
          }
        }}
        bordered
        dataSource={interviewframeCols}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={false}
      />
    </Form>
  );
};

export default InterviewFrameTable;
