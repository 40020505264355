import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import useFields from './fields';
import { CreateUpdateOfferContainer } from './CreateUpdateOfferContainer';

const CreateUpdateOffer = ({ purpose }) => {
  const [isLogo, setIsLogo] = useState(false);
  const { fields, isFieldsLoading, premise, position } = useFields({
    setIsLogo,
    isLogo
  });

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data,
        end_of_publication_date:
          data.end_of_publication_date && moment(data.end_of_publication_date),
        expected_start_date:
          data.expected_start_date && moment(data.expected_start_date),
        date_of_birth: data.date_of_birth && moment(data.date_of_birth)
      })
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        logo_usage: isLogo,
        work_time: data.work_time.number,
        work_time_period: data.work_time.time_period
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        logo_usage: isLogo,
        work_time: data.work_time.number,
        work_time_period: data.work_time.time_period
      })
    }
  };

  return (
    <CreateUpdateOfferContainer
      fields={fields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="offers"
      resource="offers"
      config={config}
      premise={premise}
      position={position}
    />
  );
};

CreateUpdateOffer.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateOffer;
