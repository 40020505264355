import React, { useContext } from 'react';
import { Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import { useApplicationsContext } from '../../contexts/ApplicationsContext';

interface SwitchModesProps {
  modes: Array<string>;
  mode: string;
  setMode?: (mode: string) => void;
}

export const SwitchModes: React.FC<SwitchModesProps> = ({
  modes,
  mode,
  setMode
}) => {
  const { t } = useTranslation();
  const { setDataRefresh, dataRefresh } = useApplicationsContext();

  const handleChange = (e: any) => {
    setMode?.(e.target.value);
    setDataRefresh();
  };

  return (
    <Radio.Group value={mode} onChange={(e) => handleChange(e)}>
      {modes.map((modeItem) => (
        <Radio.Button value={modeItem} key={modeItem}>
          {t(`calendar.modes.${modeItem}`)}
        </Radio.Button>
      ))}
    </Radio.Group>
  );
};
