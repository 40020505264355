import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { setColumns } from './column';

const ListGeneratedDocuments = ({ data, document }) => {
  const documents = data?.files?.filter((file) => file.type === document);

  return (
    <Table
      dataSource={documents}
      columns={setColumns({ data })}
      pagination={false}
    />
  );
};

ListGeneratedDocuments.propTypes = {
  data: PropTypes.shape(),
  document: PropTypes.string.isRequired
};
ListGeneratedDocuments.defaultProps = {
  data: null
};

export default ListGeneratedDocuments;
