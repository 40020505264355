import React from 'react';
import moment from 'moment';
import {
  Button,
  message as antdMessage,
  Modal,
  Form,
  DatePicker,
  Row,
  Typography,
  Select
} from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

const { Option } = Select;

const EndDateModal = ({
  id,
  setRefresh,
  refresh,
  dispatchAPI,
  message,
  t,
  setIsModalOpen,
  isModalOpen,
  motifsEnums
}) => {
  const [form] = Form.useForm();

  const onClose = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const addEndDateEmployee = async (value) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/employees/${id}`,
        body: { end_date: moment(value.end_date), motif: value.exit_reason }
      });
      setRefresh(!refresh);
      antdMessage.success(t('employees.show.end_date_modal.success'));
      onClose();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  return (
    <Modal
      title={t('employees.show.end_date_modal.title')}
      open={isModalOpen}
      onOk={() => setIsModalOpen(false)}
      onCancel={() => setIsModalOpen(false)}
      footer={null}
    >
      <Form
        wrapperCol={{ span: 24 }}
        labelCol={{ span: 24 }}
        form={form}
        onFinish={addEndDateEmployee}
      >
        <Form.Item
          name="exit_reason"
          label={<Typography>{t('exits.form.motif')}</Typography>}
        >
          <Select>
            {motifsEnums.map((motif) => (
              <Option key={motif} value={motif}>
                {motif}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="end_date"
          label={
            <Typography>{t('employees.show.end_date_modal.label')}</Typography>
          }
        >
          <DatePicker showTime format="DD-MM-YYYY" />
        </Form.Item>
        <Form.Item>
          <Row justify="end" gutter={[8, 16]}>
            <Button type="link" danger onClick={onClose}>
              {`${t('buttons.cancel')} `}
              <CloseOutlined />
            </Button>
            <Button type="add" htmlType="submit">
              {`${t('buttons.save')} `}
              <CheckOutlined />
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EndDateModal;

EndDateModal.propTypes = {
  id: PropTypes.string.isRequired,
  setRefresh: PropTypes.func.isRequired,
  refresh: PropTypes.bool.isRequired,
  dispatchAPI: PropTypes.func.isRequired,
  message: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  motifsEnums: PropTypes.arrayOf(PropTypes.string).isRequired
};
