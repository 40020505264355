import React, { useState, useEffect } from 'react';
import { DatePicker, Select, Switch, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/ErrorMessage';
import { validatePhoneNumber } from '../../../../utils/phoneNumber';
import countryCodes from '../../../../utils/countryCodes';

const { Option } = Select;

const personnalInformations = () => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();

  const [
    isPersonnalInformationsFieldsLoading,
    setIsPersonnalInformationsFieldsLoading
  ] = useState(true);

  const [nationalitiesEnums, setNationalitiesEnums] = useState([]);
  const [disabled_worker, setDisabled_worker] = useState(false);
  const [nationalities, setNationalities] = useState('');

  const getNationalitiesEnums = async () => {
    setIsPersonnalInformationsFieldsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/entries/enums/nationalities'
      });
      setNationalitiesEnums(data);
      setIsPersonnalInformationsFieldsLoading(false);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getNationalitiesEnums();
    })();
  }, []);

  const personnalInformationsFields = [
    {
      name: ['last_name'],
      rules: [{ required: true }]
    },
    {
      name: ['first_name'],
      rules: [{ required: true }]
    },
    {
      name: ['date_of_birth'],
      rules: [{ required: true }],
      input: <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
    },
    {
      name: ['place_of_birth'],
      rules: [{ required: true }],
      input: <Input style={{ width: '100%' }} />
    },
    {
      name: ['nationality'],
      rules: [{ required: true }],
      input: (
        <Select onChange={(value) => setNationalities(value)}>
          {(nationalitiesEnums || []).map((nationality) => (
            <Option key={nationality}>{nationality}</Option>
          ))}
        </Select>
      )
    },
    {
      name: ['expiration_residence_permit'],
      hidden: nationalities === 'Française' || nationalities === '',
      input: <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
    },
    {
      name: ['socialSecurityNumber'],
      label: 'social_security_number',
      rules: [
        {
          len: 15,
          message: t('errors.form.SOCIAL_SECURITY_NUMBER'),
          required: true
        }
      ]
    },
    {
      name: ['disabled_worker'],
      label: 'disabled_worker',
      input: (
        <Switch
          checked={disabled_worker}
          onChange={() => setDisabled_worker(!disabled_worker)}
        />
      )
    },
    {
      label: 'address.number',
      name: ['address', 'number']
    },
    {
      label: 'address.street',
      name: ['address', 'street']
    },
    {
      label: 'address.additional',
      name: ['address', 'additional']
    },
    {
      label: 'address.postcode',
      name: ['address', 'postal_code']
    },
    {
      label: 'address.city',
      name: ['address', 'city']
    },
    {
      name: ['email'],
      rules: [{ required: true }, { type: 'email' }]
    },
    {
      name: ['phone_number'],
      label: 'phone_number.number',
      rules: [{ required: true }],
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: '25%' }}>
              {countryCodes.map((country) => (
                <Option key={country.code} value={country.code}>
                  ({country.code}) {country.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            noStyle
            name={['phone_number', 'number']}
            label="phone_number.number"
            rules={[{ validator: validatePhoneNumber }]}
          >
            <Input style={{ width: '75%' }} />
          </Form.Item>
        </Input.Group>
      )
    }
  ];

  return {
    personnalInformationsFields,
    isPersonnalInformationsFieldsLoading,
    nationalities
  };
};

export default personnalInformations;
