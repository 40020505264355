/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { Button, Input, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAuthContext } from '../../../contexts/AuthContext';
import PremiseTable from './premiseTable';

const initialColumns = [
  {
    title: 'Label',
    dataIndex: 'label',
    key: 'label',
    render: (text) => <Input InitialValue={text} />
  }
];

const PremiseSettings = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [premiseCols, setPremiseCols] = useState(initialColumns);

  const getPremiseCols = async () => {
    try {
      const data = await dispatchAPI('GET', { url: '/premise' });
      setPremiseCols(data.data);
    } catch (e) {
      message.error(e.message);
    }
  };

  const editPremiseCol = async (_id, row) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/premise/${_id}`,
        body: row
      });
    } catch (e) {
      message.error(e.message);
    }
    getPremiseCols();
  };
  useEffect(() => {
    getPremiseCols();
  }, []);

  return (
    <>
      <PremiseTable premiseCols={premiseCols} editPremiseCol={editPremiseCol} />
      <Link
        to={{
          pathname: '/premise/create'
        }}
      >
        <Button>{t('settings.premiseTable.addpremisecol')}</Button>
      </Link>
    </>
  );
};

export default PremiseSettings;
