export const preventWidthExpand = (editor) => {
  // ADD RULES TO PREVENT WIDTH EXPAND
  editor.on('component:add', (component) => {
    if (component.get('name') === 'text') {
      component.addStyle({
        'word-wrap': 'break-word',
        'white-space': 'normal',
        'overflow-wrap': 'break-word',
        'max-width': '100%',
        'max-height': '100%'
      });
    }
  });
};
