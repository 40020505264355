/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useCallback } from 'react';
import { Tree, notification } from 'antd';
import PropTypes from 'prop-types';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/ErrorMessage';
import ProfileRow from './ProfileRow';

const generateTree = (data, parcoursList, deleteProfileTrees) =>
  data.map((item, index) => {
    let children = [];
    if (item.children.length > 0) {
      children = item.children.map((child, childIndex) => ({
        title: (
          <ProfileRow
            id={child._id}
            showAction={false}
            editAction={false}
            deleteAction={false}
            deleteProfile={deleteProfileTrees}
          />
        ),
        key: `0-${index}-${childIndex}`
      }));
    }

    return {
      title: (
        <ProfileRow
          title={item.title}
          id={item._id}
          deleteProfile={deleteProfileTrees}
        />
      ),
      key: `0-${index}`,
      children
    };
  });

const ProfileTree = ({ isArchived }) => {
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [parcoursTrees, setParcoursTrees] = useState([]);
  const [profileTrees, setProfileTrees] = useState([]);
  const [expandedKeys] = useState(['0-0', '0-0-0', '0-0-0-0']);

  const deleteProfileTrees = async (id) => {
    try {
      await dispatchAPI('DELETE', { url: `/profiletree/${id}` });
      notification.success({
        message: 'La formation a bien été supprimée',
        key: 'network_feedback'
      });
      const newtrainingTrees = profileTrees.filter((item) => item._id !== id);
      setProfileTrees(newtrainingTrees);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  const gData1 = generateTree(profileTrees, parcoursTrees, deleteProfileTrees);
  const [gData, setGData] = useState(
    generateTree(profileTrees, parcoursTrees, deleteProfileTrees) || []
  );
  // eslint-disable-next-line no-unused-vars
  const onDragEnter = (info) => {
    // console.log(info);
    // setExpandedKeys(info.expandedKeys)
  };

  const onDrop = (info) => {
    // console.log(info);
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split('-');
    const dropPosition =
      info.dropPosition - Number(dropPos[dropPos.length - 1]);
    // eslint-disable-next-line consistent-return
    const loop = (data, key, callback) => {
      for (let i = 0; i < data.length; i += 1) {
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }
        if (data[i].children) {
          loop(data[i].children, key, callback);
        }
      }
    };
    const data = [...gData];

    let dragObj;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });

    if (!info.dropToGap) {
      loop(data, dropKey, (item) => {
        item.children = item.children || [];
        item.children.unshift(dragObj);
      });
    } else if (
      (info.node.props.children || []).length > 0 &&
      // Has children
      info.node.props.expanded &&
      // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      loop(data, dropKey, (item) => {
        item.children = item.children || [];
        item.children.unshift(dragObj);
      });
    } else {
      let ar = [];
      let i;

      loop(data, dropKey, (_item, index, arr) => {
        ar = arr;
        i = index;
      });

      if (dropPosition === -1) ar.splice(i, 0, dragObj);
      else ar.splice(i + 1, 0, dragObj);
    }
    setGData(data);
  };

  const getParcoursTrees = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/trainingtrees' });
      setParcoursTrees(data || []);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getProfileTrees = async ({ isArchiv }) => {
    const extraQuery = isArchiv ? '?is_archived=true' : '?is_archived=false';
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/profiletree/${extraQuery}`
      });
      setProfileTrees(data || []);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async ({ isArchiv }) => {
    await getParcoursTrees();
    await getProfileTrees({ isArchiv });
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions({ isArchiv: isArchived });
    })();
  }, [getSelectOptions, isArchived]);

  return (
    <Tree
      className="draggable-tree"
      defaultExpandedKeys={expandedKeys}
      draggable
      blockNode
      onDragEnter={onDragEnter}
      onDrop={onDrop}
      treeData={gData1}
      selectable={false}
    />
  );
};
export default ProfileTree;

ProfileTree.propTypes = {
  isArchived: PropTypes.bool.isRequired
};
