import React, { useCallback, useEffect, useState } from 'react';
import { Upload, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { CameraOutlined } from '@ant-design/icons/lib';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';

const { Dragger } = Upload;

const useFields = () => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [setEnums] = useState({});
  const [fileList, setFileList] = useState([]);
  const [base64, setBase64] = useState('');

  // This function convert the PDF to base64 format
  const fileToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (e) => reject(e);
    });

  const draggerProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: async (file) => {
      const fileExtension = file.name.split('.').pop();
      if (
        fileExtension === 'png' ||
        fileExtension === 'PNG' ||
        fileExtension === 'jpg' ||
        fileExtension === 'JPG'
      ) {
        setFileList([...fileList, file]);
        const base = await fileToBase64(file);
        setBase64(base);
        return false;
      }
      message('Not a PNG or JPG file');
      return true;
    },
    fileList
  };

  const addonAfter = (
    <Select defaultValue="€" style={{ width: 50 }}>
      <Select.Option value="€">€</Select.Option>
      <Select.Option value="%">%</Select.Option>
    </Select>
  );

  const fields = [
    {
      name: ['logo'],
      input: (
        <Dragger {...draggerProps}>
          <p className="ant-upload-drag-icon">
            <CameraOutlined style={{ color: 'var(--textColor)' }} />
          </p>
          <p className="ant-upload-text">{t('files.create.action')}</p>
        </Dragger>
      )
    },
    {
      name: ['label'],
      rules: [{ required: true }]
    },
    {
      name: ['siren'],
      rules: [{ required: true }, { len: 9 }],
      input: <Input type="number" />
    },
    {
      name: ['premise_type'],
      rules: [{ required: false }]
    },
    {
      label: 'address.number',
      name: ['address', 'number']
    },
    {
      label: 'address.street',
      name: ['address', 'street']
    },
    {
      label: 'address.additional',
      name: ['address', 'additional']
    },
    {
      label: 'address.postcode',
      name: ['address', 'postal_code']
    },
    {
      label: 'address.city',
      name: ['address', 'city']
    },
    {
      name: ['margin_objective'],
      rules: [{ required: true }],
      input: (
        <Input addonAfter={addonAfter} style={{ width: '33%' }} type="number" />
      )
    }
  ];

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/users/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    fields,
    isFieldsLoading,
    base64
  };
};

export default useFields;
