import React from 'react';
import { DatePicker, TimePicker, Select, Input } from 'antd';

const { TextArea } = Input;

const useFields = ({ users, employees, interviewtitles, frames, premises }) => {
  const fields = [
    {
      name: ['header'],
      required: true,
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={interviewtitles}
        />
      )
    },
    {
      name: ['object'],
      rules: [{ required: true }]
    },
    {
      name: ['rh_employee'],
      rules: [{ required: true }],
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={users}
        />
      )
    },
    {
      name: ['interlocutors'],
      rules: [{ required: true }],
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={employees}
        />
      )
    },
    {
      name: ['day'],
      rules: [{ required: true }],
      input: <DatePicker style={{ width: '100%' }} />
    },
    {
      name: ['time_start'],
      required: true,
      input: <TimePicker style={{ width: '100%' }} format="HH:mm" type="time" />
    },
    {
      name: ['time_end'],
      required: true,
      input: <TimePicker style={{ width: '100%' }} format="HH:mm" type="time" />
    },
    {
      name: ['premise'],
      rules: [{ required: true }],
      input: <Select options={premises} />
    },
    {
      name: ['frame'],
      input: <Select options={frames} />
    },
    {
      name: ['comment'],
      input: <TextArea style={{ width: '100%' }} />
    }
  ];

  return {
    fields
  };
};

export default useFields;
