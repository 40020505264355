import direction from '../../assets/images/images_dashboard/img-frame.png';
import paye from '../../assets/images/images_dashboard/payfitblue 1.svg';
import planning from '../../assets/images/images_dashboard/logo1.svg';
import planner from '../../assets/images/images_dashboard/planning 1.png';
import slack from '../../assets/images/images_dashboard/Slack_Logo.png';
import turbo from '../../assets/images/images_dashboard/logo_turbo_transparent 1.png';
import isuite from '../../assets/images/images_dashboard/logo-isuite-expert-1000x_-90-scale-ffffff-wCopyright 1.png';
import cashpad from '../../assets/images/images_dashboard/Cashpad 1.svg';
import clyo from '../../assets/images/images_dashboard/clyo-logo-grey 1.png';
import laddition from '../../assets/images/images_dashboard/laddition_logo_footer 1.svg';
import edf from '../../assets/images/images_dashboard/Électricité_de_France_logo 1.png';
import pickingbeer from '../../assets/images/images_dashboard/pickingbeer.png';
import webshop from '../../assets/images/images_dashboard/webshop-logo-edited 1.png';
import untappd from '../../assets/images/images_dashboard/untappd-catw 1.png';
import sunday from '../../assets/images/images_dashboard/sunday_app 1.png';
import guestonline from '../../assets/images/images_dashboard/guestonline 1.png';
import ubereats from '../../assets/images/images_dashboard/uber-eats-logo 1.png';
import easybeer from '../../assets/images/images_dashboard/easybeer.svg';
import moovago from '../../assets/images/images_dashboard/MOOVAGO_logobaseline-rvb 1.png';
import shipping from '../../assets/images/images_dashboard/logo_shipping_dark 1.png';
import cctv from '../../assets/images/images_dashboard/cctv 1.png';
import alarm from '../../assets/images/images_dashboard/alarm-system 1.png';
import areas from '../../assets/images/images_dashboard/logo_areas_assurances_vectoriel 1.svg';
import accident from '../../assets/images/images_dashboard/question 1.png';
import comite from '../../assets/images/images_dashboard/communication 1.png';
import exploitation from '../../assets/images/images_dashboard/img-frame (1).png';
import logistique from '../../assets/images/images_dashboard/img-frame (2).png';
import commercial from '../../assets/images/images_dashboard/img-frame (3).png';
import communication from '../../assets/images/images_dashboard/img-frame (4).png';
import juridique from '../../assets/images/images_dashboard/img-frame (5).png';
import rh from '../../assets/images/images_dashboard/img-frame (6).png';
import notes from '../../assets/images/images_dashboard/quote 1.png';
import achats from '../../assets/images/images_dashboard/recipient 1.png';
import evenements from '../../assets/images/images_dashboard/logo 2.png';
import drive from '../../assets/images/images_dashboard/Google_Drive-Logo.wine 1.svg';
import we_recruit from '../../assets/images/images_dashboard/we_recruit.png';
import prevaly from '../../assets/images/images_dashboard/prevaly.png';
import collaboratif from '../../assets/images/images_dashboard/collaboratif.png';
import payzen from '../../assets/images/images_dashboard/payzen.png';
import adm_paiement from '../../assets/images/images_dashboard/adm_paiement.png';
import declaration from '../../assets/images/images_dashboard/declaration.png';
import credit from '../../assets/images/images_dashboard/credit.png';
import douane from '../../assets/images/images_dashboard/douane.png';
import abonnement from '../../assets/images/images_dashboard/abonnement.png';
import Canal from '../../assets/images/images_dashboard/Canal.png';
import free from '../../assets/images/images_dashboard/free.svg';
import Spotify from '../../assets/images/images_dashboard/Spotify.png';
import Tshoko from '../../assets/images/images_dashboard/tshoko.svg';
import securite from '../../assets/images/images_dashboard/securite.png';
import encaissement from '../../assets/images/images_dashboard/encaissement.png';
import site from '../../assets/images/images_dashboard/site.png';
import pomona from '../../assets/images/images_dashboard/pomona.png';
import sysco from '../../assets/images/images_dashboard/sysco.png';
import patis from '../../assets/images/images_dashboard/patis.png';
import cuisine from '../../assets/images/images_dashboard/cuisine.png';
import hygiene from '../../assets/images/images_dashboard/hygiene.png';
import technique from '../../assets/images/images_dashboard/technique.png';
import commerce from '../../assets/images/images_dashboard/commerce.png';
import brevo from '../../assets/images/images_dashboard/brevo.png';
import agora from '../../assets/images/images_dashboard/agora.svg';
import adobe from '../../assets/images/images_dashboard/adobe.png';
import ecologie from '../../assets/images/images_dashboard/ecologie.png';

export const widgets = [
  {
    image: rh,
    title: 'Ressources humaines',
    perm: '/ressources',
    type: 'modal'
  },
  {
    image: paye,
    title: 'Fiches de paye',
    parent: 'Ressources humaines',
    perm: '/RH/payfit',
    link: 'https://payfit.com/fr/'
  },
  {
    image: planning,
    title: 'Planning',
    parent: 'Ressources humaines',
    perm: '/RH/combo',
    link: 'https://combohr.com/fr/'
  },
  {
    image: areas,
    title: 'Mutuelle',
    parent: 'Ressources humaines',
    perm: '/RH/areas_mutuelle',
    link: 'https://www.areas.fr/'
  },
  {
    image: we_recruit,
    title: 'We Recruit',
    parent: 'Ressources humaines',
    perm: '/RH/we_recruit',
    link: 'https://www.werecruit.io/'
  },
  {
    image: evenements,
    title: 'Groupe VBR - RH',
    parent: 'Ressources humaines',
    perm: '/RH',
    link: '/homedashboard'
  },
  {
    image: comite,
    title: "Comité d'entreprise",
    parent: 'Ressources humaines',
    perm: '/RH/enterprise_comitee',
    link: 'https://www.linkedin.com/company/groupe-vbr/?originalSubdomain=fr'
  },
  {
    image: prevaly,
    title: 'Prevaly',
    parent: 'Ressources humaines',
    perm: '/RH/prevalys',
    link: 'https://www.prevaly.fr/'
  },
  {
    image: collaboratif,
    title: 'Outils Collaboratifs',
    perm: '/collab_tools',
    type: 'modal'
  },
  {
    image: planner,
    title: 'Planner',
    parent: 'Outils Collaboratifs',
    perm: '/collab_tools/outlook_planner',
    link: 'https://www.microsoft.com/fr-fr/microsoft-365/business/task-management-software'
  },
  {
    image: slack,
    title: 'Slack',
    parent: 'Outils Collaboratifs',
    perm: '/collab_tools/slack',
    link: 'https://slack.com/intl/fr-fr/'
  },
  {
    image: notes,
    title: 'Notes internes',
    parent: 'Outils Collaboratifs',
    perm: '/collab_tools/notes_notifs',
    link: 'https://www.linkedin.com/company/groupe-vbr/?originalSubdomain=fr'
  },
  {
    image: direction,
    title: 'Finances & Direction',
    perm: '/finance',
    type: 'modal'
  },
  {
    image: turbo,
    title: 'Turbo',
    parent: 'Finances & Direction',
    perm: '/finance/turbo',
    link: 'https://www.linkedin.com/company/groupe-vbr/?originalSubdomain=fr'
  },
  {
    image: isuite,
    title: 'iSuite Expert',
    parent: 'Finances & Direction',
    perm: '/finance/isuite_expert',
    link: 'https://isuite-expert.com/'
  },
  {
    image: payzen,
    title: 'Payzen',
    parent: 'Finances & Direction',
    perm: '/finance/payzen',
    link: 'https://www.payzen.eu/'
  },
  {
    image: adm_paiement,
    title: 'ADM Paiement',
    parent: 'Finances & Direction',
    perm: '/finance/adm_paiement',
    link: 'https://www.admvalue.com/'
  },
  {
    image: evenements,
    title: 'Groupe VBR - Direction et finance',
    parent: 'Finances & Direction',
    perm: '/finance/GroupeVBRFinance',
    link: '/homedashboard'
  },
  {
    image: declaration,
    title: 'Déclarations',
    perm: '/declarations',
    type: 'modal'
  },
  {
    image: credit,
    title: 'Tickets restaurant',
    parent: 'Déclarations',
    perm: '/declarations/restaurants_titles',
    link: 'https://www.edenred.fr/'
  },
  {
    image: douane,
    title: 'Douanes',
    parent: 'Déclarations',
    perm: '/declarations/douane',
    link: 'https://www.douane.gouv.fr/'
  },
  {
    image: abonnement,
    title: 'Abonnements',
    perm: '/subscriptions',
    type: 'modal'
  },
  {
    image: Canal,
    title: 'Canal +',
    parent: 'Abonnements',
    perm: '/subscriptions/Canal',
    link: 'https://www.canalplus.com/'
  },
  {
    image: free,
    title: 'Free',
    parent: 'Abonnements',
    perm: '/subscriptions/Free',
    link: 'https://www.free.fr/'
  },
  {
    image: Spotify,
    title: 'Spotify',
    parent: 'Abonnements',
    perm: '/subscriptions/Spotify',
    link: 'https://www.spotify.com/fr/'
  },
  {
    image: Tshoko,
    title: 'Tshoko',
    parent: 'Abonnements',
    perm: '/subscriptions/Tchoko',
    link: 'https://www.tshoko.com/'
  },
  {
    image: easybeer,
    title: 'EasyBeer',
    type: 'card',
    perm: '/easyBeer',
    link: 'https://app.easybeer.fr/'
  },
  {
    image: securite,
    title: 'Sécurité',
    perm: '/security',
    type: 'modal'
  },
  {
    image: cctv,
    title: 'Caméras',
    parent: 'Sécurité',
    perm: '/security/cameras',
    link: 'https://www.linkedin.com/company/groupe-vbr/?originalSubdomain=fr'
  },
  {
    image: alarm,
    title: 'Alarmes',
    parent: 'Sécurité',
    perm: '/security/alarms',
    link: 'https://www.linkedin.com/company/groupe-vbr/?originalSubdomain=fr'
  },
  {
    image: untappd,
    title: 'Untappd',
    type: 'card',
    perm: '/untappd',
    link: 'https://untappd.com/'
  },
  {
    image: guestonline,
    title: 'Guestonline',
    type: 'card',
    perm: '/guestonline',
    link: 'https://guestonline.io/'
  },
  {
    image: encaissement,
    title: 'Encaissement',
    perm: '/collection',
    type: 'modal'
  },
  {
    image: ubereats,
    title: 'Uber eats',
    parent: 'Encaissement',
    perm: '/collection/uber_eats',
    link: 'https://www.ubereats.com/fr'
  },
  {
    image: sunday,
    title: 'Sunday',
    parent: 'Encaissement',
    perm: '/collection/sunday',
    link: 'https://sundayapp.com/fr/'
  },
  {
    image: cashpad,
    title: 'Cashpad',
    parent: 'Encaissement',
    perm: '/collection/cashpad',
    link: 'https://www.cashpad.io/'
  },
  {
    image: laddition,
    title: "L'Addition",
    parent: 'Encaissement',
    perm: '/collection/laddition',
    link: 'https://www.laddition.com/fr'
  },
  {
    image: clyo,
    title: 'Clyo',
    parent: 'Encaissement',
    perm: '/collection/clyo',
    link: 'https://www.clyosystems.com/'
  },
  {
    image: moovago,
    title: 'Moovago',
    type: 'card',
    perm: '/moovago',
    link: 'https://moovago.com/'
  },
  {
    image: site,
    title: 'Site de Commande',
    perm: '/command_site',
    type: 'modal'
  },
  {
    image: pomona,
    title: 'Pomona',
    parent: 'Site de Commande',
    perm: '/command_site/pomona',
    link: 'https://www.pomona.fr/'
  },
  {
    image: sysco,
    title: 'Sysco',
    parent: 'Site de Commande',
    perm: '/command_site/sysco',
    link: 'https://www.sysco.fr/'
  },
  {
    image: patis,
    title: 'Patis Service',
    parent: 'Site de Commande',
    perm: '/command_site/patis',
    link: 'https://www.patis-service.com/'
  },
  {
    image: pickingbeer,
    title: 'Picking Beers',
    parent: 'Site de Commande',
    perm: '/command_site/pickingbeers',
    link: 'https://pickingbeers.com/'
  },
  {
    image: cuisine,
    title: 'Cuisine',
    perm: '/cuisine',
    type: 'modal'
  },
  {
    image: hygiene,
    title: 'Epack hygiène',
    parent: 'Cuisine',
    prem: 'cuisine/epack_hygiene',
    link: 'https://www.epack-hygiene.com/'
  },
  {
    image: technique,
    title: 'Fiches Techniques',
    parent: 'Cuisine',
    perm: '/cuisine/technical_forms',
    link: 'https://www.linkedin.com/company/groupe-vbr/?originalSubdomain=fr'
  },
  {
    image: commerce,
    title: 'E-Commerce',
    perm: '/Ecommerce',
    type: 'modal'
  },
  {
    image: pickingbeer,
    title: 'Picking Beers',
    parent: 'E-Commerce',
    perm: '/Ecommerce/pickingbeers',
    link: 'https://pickingbeers.com/'
  },
  {
    image: webshop,
    title: 'Webshop',
    parent: 'E-Commerce',
    perm: '/Ecommerce/webshop',
    link: 'https://www.labierotheque.fr/'
  },
  {
    image: brevo,
    title: 'Brevo',
    parent: 'E-Commerce',
    perm: '/Ecommerce/brevo',
    link: 'https://www.brevo.com/fr/'
  },
  {
    image: communication,
    title: 'Outils de Communication',
    perm: '/communication_tools',
    type: 'modal'
  },
  {
    image: agora,
    title: 'Agorapulse',
    parent: 'Outils de Communication',
    perm: '/communication_tools/agorapulse',
    link: 'https://www.agorapulse.com/fr'
  },
  {
    image: brevo,
    title: 'Brevo',
    parent: 'Outils de Communication',
    perm: '/communication_tools/brevo',
    link: 'https://www.brevo.com/fr/'
  },
  {
    image: adobe,
    title: 'Suite Adobe',
    parent: 'Outils de Communication',
    perm: '/communication_tools/adobe',
    link: 'https://www.adobe.com/fr/'
  },
  {
    image: logistique,
    title: 'Logistique',
    perm: '/logistique',
    type: 'modal'
  },
  {
    image: shipping,
    title: 'Shippingbo',
    parent: 'Logistique',
    perm: '/logistique/shippingbo',
    link: 'https://www.shippingbo.com/'
  },
  {
    image: ecologie,
    title: 'Ecologie',
    perm: '/ecology',
    type: 'modal'
  },
  {
    image: edf,
    title: 'EDF',
    parent: 'Ecologie',
    perm: '/ecology/edf',
    link: 'https://www.edf.fr/'
  },
  {
    image: hygiene,
    title: 'Epack hygiène',
    parent: 'Ecologie',
    perm: '/ecology/epack_hygiene',
    link: 'https://www.epack-hygiene.com/'
  },
  {
    image: hygiene,
    title: 'Epack hygiène',
    parent: 'Cuisine',
    perm: '/cuisine/epack_hygiene',
    link: 'https://www.epack-hygiene.com/'
  },
  {
    image: accident,
    title: 'Formations',
    type: 'card',
    perm: '/formations',
    link: '/trainings/trainings-path'
  },
  {
    image: accident,
    title: 'Signaler un accident',
    link: 'https://www.linkedin.com/company/groupe-vbr/?originalSubdomain=fr'
  },
  {
    image: exploitation,
    title: 'Exploitation',
    link: 'https://www.linkedin.com/company/groupe-vbr/?originalSubdomain=fr'
  },
  {
    image: commercial,
    title: 'Commercial',
    link: 'https://www.linkedin.com/company/groupe-vbr/?originalSubdomain=fr'
  },
  {
    image: juridique,
    title: 'Juridique',
    link: 'https://www.linkedin.com/company/groupe-vbr/?originalSubdomain=fr'
  },
  {
    image: achats,
    title: 'Achats',
    link: 'https://www.linkedin.com/company/groupe-vbr/?originalSubdomain=fr'
  },
  {
    image: evenements,
    title: 'Evènements',
    link: 'https://www.linkedin.com/company/groupe-vbr/?originalSubdomain=fr'
  },
  {
    image: drive,
    title: 'Drive',
    link: 'https://www.google.com/intl/fr/drive/'
  }
];
