import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ListIncidents from './ListIncidents';
import ShowIncident from './showIncident/ShowIncident';
import CreateUpdateIncident from './CreateUpdateIncident';

export const IncidentRouter = () => (
  <Routes>
    <Route path="/show/:id" element={<ShowIncident />} />
    <Route path="/edit/:id" element={<CreateUpdateIncident purpose="edit" />} />
    <Route path="/create" element={<CreateUpdateIncident purpose="create" />} />
    <Route index element={<ListIncidents />} />
  </Routes>
);
