export const setParam = ({ values, file, application, isCandidateExist }) => {
  let result;
  if (file) {
    const fileBody = application
      ? {
          $push: {
            files: { _id: file._id }
          }
        }
      : { files: [{ _id: file._id }] };

    result = {
      url: `/applications${
        application
          ? `/${application._id}?isCandidate=${isCandidateExist}`
          : `?isCandidate=${isCandidateExist}`
      }`,
      body: {
        ...values,
        curriculum: {
          _id: file._id,
          filename: file.metadata.originalName
        },
        ...fileBody
      }
    };
  } else {
    result = {
      url: `/applications${
        application
          ? `/${application._id}?isCandidate=${isCandidateExist}`
          : `?isCandidate=${isCandidateExist}`
      }`,
      body: { ...values }
    };
  }
  return result;
};
