export const getLastTrainingDate = ({
  trainingTreeData,
  trainingprogress,
  setLastTrainingDate
}) => {
  const allEndDates = trainingTreeData.map((obj) =>
    obj.children.map((child) =>
      child.employees
        .filter(
          (emp) =>
            emp.employee === trainingprogress.employee._id && emp.end_date
        )
        .map((emp) => emp.end_date)
    )
  );

  const allDates = [].concat(...allEndDates);
  const latestDate = allDates
    .filter((date) => date !== '')
    .sort((a, b) => new Date(b) - new Date(a))[0];

  setLastTrainingDate(latestDate);
};
