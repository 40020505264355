import React, { useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row, Col, Progress, Typography } from 'antd';
import { EyeOutlined, RocketOutlined } from '@ant-design/icons';
import ParcourTreeCard from '../../../trainings/TrainingProgress/ParcourTreeCard';

const ShowTrainingPath = ({ trainingprogress, setRefresh, refresh }) => {
  const { t } = useTranslation();
  const [lastTrainingDate, setLastTrainingDate] = useState([]);

  return (
    <Row gutter={[16, 16]}>
      <Col span={22}>
        <Row align="middle">
          <RocketOutlined style={{ marginRight: '10px' }} />
          <Typography>{t('employees.show.general_profile')}</Typography>
        </Row>
      </Col>
      <Col span={2}>
        <Link
          to={{
            pathname: `/trainings/follow-up/show/${trainingprogress?._id}`
          }}
        >
          <EyeOutlined style={{ fontSize: 18, color: 'black' }} />
        </Link>
      </Col>
      <Col span={24}>
        <Progress percent={trainingprogress?.progress} style={{ width: 400 }} />
      </Col>
      {lastTrainingDate.length ? (
        <Col span={24}>
          <Row justify="end">
            <Typography>
              {t('employees.show.latest_training') +
                moment(lastTrainingDate[0]).format('DD/MM/YYYY')}
            </Typography>
          </Row>
        </Col>
      ) : null}
      <Col span={24}>
        <ParcourTreeCard
          trainingprogress={trainingprogress}
          setRefresh={setRefresh}
          refresh={refresh}
          setLastTrainingDate={setLastTrainingDate}
          lastTrainingDate={lastTrainingDate}
        />
      </Col>
    </Row>
  );
};

ShowTrainingPath.propTypes = {
  trainingprogress: PropTypes.shape({
    _id: PropTypes.string,
    progress: PropTypes.string
  }),
  setRefresh: PropTypes.func,
  refresh: PropTypes.bool
};

ShowTrainingPath.defaultProps = {
  setRefresh: null,
  refresh: false,
  trainingprogress: []
};

export default ShowTrainingPath;
