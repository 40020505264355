import { useErrorMessage } from './ErrorMessage';
import { useAuthContext } from '../contexts/AuthContext';

export const useGenerateReport = () => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const generateDoc = async (modelName, id, templateId) => {
    try {
      const { data, headers } = await dispatchAPI('GET', {
        url: `/files/generate/${modelName}/${id}/${templateId}`,
        responseType: 'blob'
      });
      const blob = new Blob([data], {
        type: 'application/pdf'
      });
      const fileName = headers['content-disposition'].substring(
        headers['content-disposition'].indexOf('=') + 1,
        headers['content-disposition']?.length
      );
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${fileName}`;
      a.click();
      return true;
    } catch (e) {
      if (e.response) message(e.response.status);
      return false;
    }
  };
  return { generateDoc };
};
