const listContent = (data, t) => {
  const { title } = data;

  return [
    {
      label: t('trainings.trainingtrees.form.name'),
      span: 3,
      content: title || '-'
    }
  ];
};

export default listContent;
