import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Row, Typography } from 'antd';
import RHCard from './RHCard';

const { Text } = Typography;

const TodayInterviews = ({ interviews }) => {
  const { t } = useTranslation();

  const interviewCards = () =>
    interviews.map((interview) => (
      <RHCard
        title={interview.title}
        time={interview.time}
        name={interview.name}
      />
    ));

  return (
    <Row gutter={[8, 8]}>
      {interviews.length === 0 ? (
        <Text
          italic
          style={{ width: '100%', textAlign: 'center', marginTop: 10 }}
        >
          {t('dashboard.no-meetings')}
        </Text>
      ) : (
        interviewCards()
      )}
    </Row>
  );
};

export default TodayInterviews;

TodayInterviews.propTypes = {
  interviews: PropTypes.arrayOf({
    title: PropTypes.string,
    time: PropTypes.string,
    name: PropTypes.string
  }).isRequired
};
