import React from 'react';
import { Tree } from 'antd';
import PropTypes from 'prop-types';
import { GenerateTitle } from './GenerateTitleGroupTree';

export const TreeGroupOrga = ({
  users,
  groups,
  setPurpose,
  setOpenModal,
  getGroup,
  deleteGroup
}) => {
  const filterData = (group) => {
    const groupID = group._id;

    const usersFromGroup = users.filter((user) =>
      user?.permissions?.includes(groupID)
    );

    const data = usersFromGroup.map((user) => ({
      title: `${user?.last_name} ${user?.first_name}`,
      key: user._id
    }));
    return data;
  };

  const handleEdit = (group) => {
    setPurpose('edit');
    setOpenModal(true);
    getGroup(group._id);
  };

  const CreateTreeNode = () => {
    if (!Array.isArray(groups)) {
      return null;
    }

    const data = groups.map((group) => ({
      title: (
        <GenerateTitle
          group={group}
          filterData={filterData}
          handleEdit={handleEdit}
          deleteGroup={deleteGroup}
        />
      ),
      key: group._id,
      children: filterData(group)
    }));
    return data;
  };

  return <Tree treeData={CreateTreeNode()} className="organization-tree" />;
};

TreeGroupOrga.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      last_name: PropTypes.string,
      first_name: PropTypes.string,
      permissions: PropTypes.arrayOf(PropTypes.string)
    })
  ),
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string
    })
  ),
  setPurpose: PropTypes.func.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  getGroup: PropTypes.func.isRequired,
  deleteGroup: PropTypes.func.isRequired
};

TreeGroupOrga.defaultProps = {
  users: [],
  groups: []
};
