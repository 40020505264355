import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDrop } from 'react-dnd';
import CardsOffer from './CardsOffer';
import { useApplicationsContext } from '../../../../contexts/ApplicationsContext';
import { kanbanIcon } from '../../../settings/applications/utilsKanban';
import { useHandleResize } from '../../../../utils/HandleResize';

const Col = styled.div`
  position: relative;
  min-width: 250px;
  padding: 0 8px 0 6px;
  margin-left: 2px;
  overflow-y: auto;
  overflow-x: hidden;
  border-right: 1px solid rgba(255, 255, 255, 0.5);
`;

const Title = styled.div`
  position: absolute;
  display: flex;
  justify-content: left;
  align-items: center;
  top: 8px;
  background-color: var(--textColor);
  color: #fff;
  box-shadow: 0px 9px 16px -6px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  width: calc(100% - 14px);
  text-align: left;
  height: 38px;
  #icon {
    padding: 0 16px;
  }
  #label {
    text-transform: uppercase;
    letter-spacing: 0.1em;
    max-width: calc(100% - 54px);
    text-overflow: ellipsis;
  }
`;

const CardContainer = styled.div`
  position: absolute;
  top: 58px;
  width: calc(100% - 14px);
  display: flex;
  flex-direction: column;
`;

const KanbanColumn = ({
  filterOffer,
  filterPremise,
  column: { _id, label, icon }
}) => {
  const { updateApplication } = useApplicationsContext();
  const { height } = useHandleResize();
  // eslint-disable-next-line no-unused-vars
  const [, drop] = useDrop({
    accept: 'card',
    drop: async (item) => {
      await updateApplication(item.id, { status: _id });
    },
    collect: (monitor) => ({
      isOver: monitor.isOver()
    })
  });

  return (
    <div>
      <Col
        ref={drop}
        style={{ height: height > 700 ? 'calc(100vh - 300px)' : 600 }}
      >
        <Title>
          <div id="icon">
            {kanbanIcon.filter((ic) => ic.label === icon)[0]?.value}
          </div>
          <div id="label">{label}</div>
        </Title>
        <CardContainer>
          <CardsOffer
            filterOffer={filterOffer}
            filterPremise={filterPremise}
            id={_id}
          />
        </CardContainer>
      </Col>
    </div>
  );
};

KanbanColumn.propTypes = {
  column: PropTypes.shape({
    _id: PropTypes.string,
    color: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.string
  }).isRequired,
  filterOffer: PropTypes.arrayOf(PropTypes.string),
  filterPremise: PropTypes.arrayOf(PropTypes.string)
};

KanbanColumn.defaultProps = {
  filterOffer: [],
  filterPremise: []
};

export default KanbanColumn;
