/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { useDrag } from 'react-dnd';
import { Link, useLocation } from 'react-router-dom';
import { Badge, Popconfirm, Divider, Tag } from 'antd';
import {
  EyeOutlined,
  DeleteOutlined,
  WarningOutlined,
  EditOutlined,
  PaperClipOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useApplicationsContext } from '../../../../contexts/ApplicationsContext';
import { permissionVerification } from '../../../../utils/permissionVerification';
import { useAuthContext } from '../../../../contexts/AuthContext';

// TODO : Refaire le drag&drop

const Card = styled.div`
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(3px);
  margin-top: 0px;
  margin-bottom: 10px;
  height: 150px;
  width: 100%;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 9px 16px -6px rgba(0, 0, 0, 0.25);
  padding: 12px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.3s;

  &:hover {
    background-color: rgba(255, 255, 255, 1);
  }

  a,
  .anticon {
    color: var(--textColor60);
  }

  .anticon:not(.anticon-paper-clip) {
    transition: all 0.3s;
    &:hover {
      font-size: 24px;
    }
  }

  .ant-badge {
    margin-left: 8px;
    .ant-badge-status-dot {
      width: 8px;
      height: 8px;
    }
  }
`;

const EditInfo = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 20px;
`;

const ApplicationCard = ({ application }) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { _id, created_at } = application;
  const { userPermissions } = useAuthContext();
  const [permit, setPermit] = useState(false);

  useEffect(() => {
    const permission = permissionVerification(
      userPermissions,
      '/recruitments/in-progress/modification'
    );
    setPermit(permission);
  }, [userPermissions]);

  const { fetchData, delApplication, setVisible, setCurrentApplication } =
    useApplicationsContext();

  const [{ isDragging }, drag] = useDrag({
    item: {
      id: _id
    },
    type: 'card',
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  const [visibility, setVisibility] = React.useState('visible');
  const styles = {
    visibility: `${visibility}`
  };

  return (
    <Card
      ref={drag}
      style={{ opacity: isDragging ? '0.5' : '1' }}
      onMouseEnter={() => setVisibility('visible')}
      onMouseLeave={() => setVisibility('hidden')}
      isWarning={application.candidate?.warning}
    >
      <div>
        <Tag>{application.offer.name}</Tag>
      </div>
      <div>
        <span style={{ fontWeight: 600 }}>
          {application &&
            `${application.candidate?.last_name} ${application.candidate?.first_name}`}
          {application.candidate?.warning && (
            <Badge color="var(--errorColor)" />
          )}
        </span>
        <br />
        <span style={{ fontSize: 13, color: 'var(--textColor85)' }}>
          {t('applications.list.creationDate')} :{' '}
          {application && `${moment(created_at).format('DD/MM/YYYY')}`}
        </span>
      </div>
      <div>
        <span style={{ marginRight: 10 }}>
          {application && application?.curriculum && (
            <>
              <PaperClipOutlined />
              {t('applications.form.curriculum')}
            </>
          )}
        </span>
      </div>
      <EditInfo style={styles}>
        <Link
          to={{
            pathname: `${pathname}/show/${_id}`
          }}
        >
          <EyeOutlined />
        </Link>
        <Divider type="vertical" />
        {permit && (
          <EditOutlined
            onClick={() => {
              setCurrentApplication(application);
              setVisible(true);
            }}
          />
        )}
        {permit && <Divider type="vertical" />}
        <Popconfirm
          title={t(`applications.list.popconfirm.title`)}
          okText={t(`applications.list.popconfirm.okText`)}
          cancelText={t(`applications.list.popconfirm.cancelText`)}
          onConfirm={() => {
            delApplication(_id);
            fetchData();
          }}
          icon={<WarningOutlined />}
        >
          {permit && <DeleteOutlined />}
        </Popconfirm>
      </EditInfo>
    </Card>
  );
};

ApplicationCard.propTypes = {
  application: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    priority: PropTypes.string
  }).isRequired
};

export default ApplicationCard;
