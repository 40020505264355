import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button, message, Input, message as antdMessage } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useAuthContext } from '../../../../../../contexts/AuthContext';
import SetEmailTemplatesOptions from './SetEmailTemplatesOptions';
import SetEmailRecipientsOptions from './SetEmailRecipientsOptions';
import { setExitBody } from './utils/setExitBody';
import { setSendMailAction } from './utils/setSendMailAction';
import { setEmailMessage } from './utils/setEmailMessage';

const ModalSendMails = ({
  initialSubject,
  sendMailAction,
  emailModel,
  setIsModalOpen,
  recipientsList,
  employee,
  exit,
  isAttachment,
  type,
  activeStep,
  absenceReasonType,
  setRefresh,
  refresh,
  setIsLoading,
  isLoading
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [selectedIds, setSelectedIds] = useState([]);
  const [editorState, setEditorState] = useState('');
  const [mailSubject, setMailSubject] = useState(initialSubject);
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [email, setEmail] = useState({});

  const getEmails = async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/emails?type=${emailModel}`
      });
      setEmailTemplates(data);
      setIsLoading(false);
    } catch (e) {
      if (e.response) message(e.response);
    }
  };

  useEffect(() => {
    (async () => {
      await getEmails();
    })();
  }, [emailModel, absenceReasonType]);

  const setEmployeeForEmailModal = () => {
    const list = [];
    if (recipientsList) recipientsList.map((user) => list.push(user._id));
    setSelectedIds(list);
  };

  useEffect(() => {
    setEmployeeForEmailModal();
  }, [recipientsList]);

  const setSubjectForEmailModal = () => {
    if (email !== undefined && Object.keys(email).length && email.data) {
      setMailSubject(email.subject);
      setEditorState(email.data);
    }
  };

  useEffect(() => {
    setSubjectForEmailModal();
  }, [email]);

  const sendEmail = async () => {
    try {
      const content = editorState;
      const urlMail = '/email_sender';

      await dispatchAPI('POST', {
        url: urlMail,
        body: {
          exitId: exit?._id,
          employee,
          content,
          subject: mailSubject,
          isAttachment,
          type
        }
      });

      setExitBody({
        activeStep,
        absenceReasonType,
        dispatchAPI,
        exit
      });

      setSelectedIds([]);
      antdMessage.success(
        setEmailMessage({ activeStep, absenceReasonType, t })
      );
      setIsModalOpen(false);
    } catch (e) {
      if (e.response) message(e.response);
    }
    setSendMailAction({
      activeStep,
      exit,
      sendMailAction,
      setRefresh,
      refresh
    });
    setIsModalOpen(false);
    setIsLoading(true);
  };
  return (
    <>
      <Row style={{ width: 800 }}>
        <Col span={4}>
          <span>{t('email.modal.recipient')}</span>
        </Col>
        <Col span={12}>
          <SetEmailRecipientsOptions
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
            recipientsList={recipientsList}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 5 }}>
        <Col span={4}>{t('email.modal.model')}</Col>
        <Col span={12}>
          {!isLoading && (
            <SetEmailTemplatesOptions
              emailModel={emailModel}
              setEmail={setEmail}
              emailTemplates={emailTemplates}
              t={t}
            />
          )}
        </Col>
      </Row>

      <Row style={{ marginTop: 5 }}>
        <Col span={4}>
          <span>{t('email.modal.object')}</span>
        </Col>
        <Col span={12}>
          <Input
            value={mailSubject}
            onChange={(e) => setMailSubject(e.target.value)}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 25, width: '100%', height: 150 }}>
        <ReactQuill
          style={{ width: '100%', height: '100%' }}
          value={editorState}
          onChange={(value) => setEditorState(value)}
        />
      </Row>
      <Row style={{ marginTop: 60 }}>
        <Col offset={20} span={4}>
          <Button type="primary" onClick={sendEmail}>
            {t('email.modal.save')}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default ModalSendMails;

ModalSendMails.propTypes = {
  emailModel: PropTypes.string.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  initialSubject: PropTypes.string.isRequired,
  sendMailAction: PropTypes.func.isRequired,
  recipientsList: PropTypes.arrayOf({}).isRequired,
  employee: PropTypes.arrayOf({}).isRequired,
  exit: PropTypes.arrayOf({}).isRequired,
  isAttachment: PropTypes.bool,
  type: PropTypes.string,
  activeStep: PropTypes.func,
  absenceReasonType: PropTypes.string,
  setRefresh: PropTypes.func,
  refresh: PropTypes.bool,
  setIsLoading: PropTypes.func,
  isLoading: PropTypes.bool
};

ModalSendMails.defaultProps = {
  isAttachment: false,
  type: null,
  activeStep: null,
  absenceReasonType: '',
  setRefresh: null,
  refresh: false,
  setIsLoading: null,
  isLoading: true
};
