import React, { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row, Select, Switch } from 'antd';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import KanbanColumn from './KanbanColumn';
import EditDrawer from './EditDrawer';
import { useApplicationsContext } from '../../../../contexts/ApplicationsContext';
import { permissionVerification } from '../../../../utils/permissionVerification';
import { useAuthContext } from '../../../../contexts/AuthContext';

const { Search } = Input;
const { Option } = Select;

const KanbanBoard = () => {
  const { t } = useTranslation();
  const {
    application,
    applications,
    searchResource,
    searchValue,
    visible,
    setVisible,
    fetchKanbanColumns,
    kanbanCols,
    fetchData,
    setIsArchived,
    isArchived,
    setCurrentApplication
  } = useApplicationsContext();
  const [filterOffer, setFilterOffer] = useState([]);
  const [filterPremise, setFilterPremise] = useState([]);
  const [showValidated, setShowValidated] = useState(true);
  const sortedKanbanCols = kanbanCols.sort((a, b) => b.index - a.index);
  const { userPermissions } = useAuthContext();
  const [permit, setPermit] = useState(false);

  useEffect(() => {
    const permission = permissionVerification(
      userPermissions,
      '/recruitments/in-progress/modification'
    );
    setPermit(permission);
  }, [userPermissions]);

  const onClose = () => {
    setVisible(false);
  };

  const uniqueNames = new Set(applications.map((obj) => obj.offer.name));
  const offers = [...uniqueNames];

  const uniquePremiseNames = new Set(
    applications.map((obj) => obj.offer.premise.label)
  );
  const premises = [...uniquePremiseNames];

  useEffect(() => {
    (async () => {
      await fetchData();
      await fetchKanbanColumns();
    })();
  }, [fetchData]);

  useEffect(() => {
    setCurrentApplication(null);
  }, []);

  const toggleShowValidated = () => {
    setShowValidated(!showValidated);
  };

  return (
    <>
      <Row
        justify="space-between"
        align="middle"
        gutter={[16, 16]}
        style={{ marginBottom: 16 }}
      >
        <Col>
          <Row gutter={[8, 8]}>
            <Col>
              <Search
                allowClear
                placeholder={t('placeholder.search')}
                defaultValue={searchValue}
                onSearch={(value) => searchResource(value)}
                style={{ width: 240 }}
              />
            </Col>
            <Col>
              <Select
                mode="multiple"
                placeholder={t('placeholder.offer')}
                style={{ width: 240 }}
                onChange={(value) => setFilterOffer(value)}
              >
                {offers.map((offerName) => (
                  <Option value={offerName} key={offerName}>
                    {offerName}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col>
              <Select
                mode="multiple"
                placeholder={t('placeholder.premise')}
                style={{ width: 240 }}
                onChange={(value) => setFilterPremise(value)}
              >
                {premises.map((premiseLabel) => (
                  <Option value={premiseLabel} key={premiseLabel}>
                    {premiseLabel}
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row gutter={[8, 8]}>
            <Row align="middle" className="list-switch-wrapper">
              <Switch
                checked={isArchived}
                onChange={() => setIsArchived(!isArchived)}
              />
              <p>{t('applications.archived')}</p>
            </Row>
            <Row align="middle" className="list-switch-wrapper">
              <Switch checked={showValidated} onChange={toggleShowValidated} />
              <p>{t('applications.validated')}</p>
            </Row>
            <Col>
              <Link
                to={{
                  pathname: '/recruitments/candidates'
                }}
              >
                <Button type="primary">{t('candidates.title')}</Button>
              </Link>
            </Col>
            <Col>
              <Button
                type="add"
                onClick={() => setVisible(true)}
                disabled={!permit}
              >
                {t('buttons.create')}
                <PlusOutlined />
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <DndProvider backend={HTML5Backend}>
        <div className="applications-kanban-board">
          {sortedKanbanCols
            .filter((col) => col.show)
            .filter((l) => (!isArchived ? l.label !== 'Archivée' : l))
            .filter((col) => showValidated || col.label !== 'Validée')
            .map((col, index) => (
              <KanbanColumn
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                filterOffer={filterOffer}
                filterPremise={filterPremise}
                column={col}
              />
            ))}
        </div>
      </DndProvider>

      <EditDrawer
        visible={visible}
        applicationId={application}
        close={onClose}
      />
    </>
  );
};
export default KanbanBoard;
