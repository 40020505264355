import React, {
  useState,
  useEffect,
  createContext,
  useCallback,
  useContext
} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuthContext } from './AuthContext';
import { useErrorMessage } from '../utils/ErrorMessage';

const ApplicationsContext = createContext({
  dataRefresh: false,
  setDataRefresh: () => {}
});

export const ApplicationsContextProvider = ({ children }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { message: errorMessage } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [visible, setVisible] = useState(false);
  const [applications, setApplications] = useState([]);
  const [application, setCurrentApplication] = useState(null);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [searchValue, setSearchValue] = useState(params.get('k'));
  const [kanbanCols, setKanbanCols] = useState([]);
  const [dataRefresh, setDataRefresh] = useState(false);
  const [users, setUsers] = useState([]);
  const [isArchived, setIsArchived] = useState(false);

  const fetchApplicationById = async (id) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/applications/${id}?populate=candidate,offer,offer.premise,status,files`
      });
      setCurrentApplication(data);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  const searchResource = (value) => {
    if (value) {
      navigate(`${pathname}?k=${value}`);
    } else {
      navigate(pathname);
    }
  };

  useEffect(() => {
    setSearchValue(params.get('k'));
  }, [params]);

  const fetchData = useCallback(async () => {
    const searchUrl = searchValue ? `/search/${searchValue}` : null;
    const extraQuery = isArchived ? '?is_archived=true' : '?is_archived=false';

    try {
      const { data } = await dispatchAPI('GET', {
        url: `/applications${
          searchUrl || ''
        }${extraQuery}&populate=candidate,curriculum,offer,offer.premise,status`
      });

      const listSorted = data.sort((a, b) => {
        const statusOrder = kanbanCols;
        const aStatusIndex = statusOrder.indexOf(a.status);
        const bStatusIndex = statusOrder.indexOf(b.status);
        return aStatusIndex - bStatusIndex;
      });
      setApplications(listSorted);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  }, [searchValue, visible, isArchived]);

  const delApplication = async (id) => {
    try {
      await dispatchAPI('DELETE', { url: `applications/${id}` });
      await fetchData();
      setDataRefresh(!dataRefresh);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  const updateApplication = async (id, body) => {
    try {
      await dispatchAPI('PATCH', { url: `/applications/${id}`, body });
      await fetchData();
      setDataRefresh(!dataRefresh);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  const fetchKanbanColumns = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/kanbans' });
      setKanbanCols(data);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  const getUsers = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/users' });
      setUsers(data);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  const fetch = useCallback(async () => {
    await fetchKanbanColumns();
    await getUsers();
  }, []);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <ApplicationsContext.Provider
      value={{
        visible,
        setVisible,
        applications,
        setApplications,
        application,
        setCurrentApplication,
        searchValue,
        setSearchValue,
        kanbanCols,
        setKanbanCols,
        dataRefresh,
        setDataRefresh,
        users,
        fetchData,
        delApplication,
        updateApplication,
        searchResource,
        fetchKanbanColumns,
        fetchApplicationById,
        setIsArchived,
        isArchived
      }}
    >
      {children}
    </ApplicationsContext.Provider>
  );
};

export const useApplicationsContext = () => {
  const context = useContext(ApplicationsContext);
  if (!context)
    throw new Error('Context must be used within a context provider');
  return context;
};
