import React, { useState, useEffect } from 'react';
import { Tag, Row, Switch, Typography, Select, Col } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import { ListResource } from '../../../components';
import { entriesStatus } from '../../../utils/constants/tagColors';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import { useColumns } from './column';
import { useFormatter } from '../../../utils/formatter';
import { permissionVerification } from '../../../utils/permissionVerification';

const { Option } = Select;

export const statusTag = (status) => {
  const arrayOfTag = [
    { status: 'ON_HOLD', render: t('entries.tags.ON_HOLD') },
    {
      status: 'ONBOARDING_CLOSE',
      render: t('entries.tags.ONBOARDING_CLOSE')
    },
    { status: 'TO_CHECK', render: t('entries.tags.TO_CHECK') },
    { status: 'RH_ACTION', render: t('entries.tags.RH_ACTION') }
  ];

  for (let i = 0; i < arrayOfTag.length; i += 1) {
    if (status === arrayOfTag[i].status) {
      return (
        <Tag
          style={{ backgroundColor: entriesStatus[status] }}
          className="white-text-tag"
        >
          {arrayOfTag[i].render}
        </Tag>
      );
    }
  }
  return null;
};

const ListEntries = () => {
  const { dispatchAPI, userPermissions } = useAuthContext();
  const { message } = useErrorMessage();
  const { formattedData } = useFormatter();
  const [extraQuery, setExtraQuery] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const params = new URLSearchParams(location.search);
  const activated = params.get('active');
  const [active, setActive] = useState(activated === 'true');
  const [refresh, setRefresh] = useState(false);
  const [premises, setPremises] = useState([]);
  const [positions, setPositions] = useState([]);
  const [premiseId, setPremiseId] = useState(null);
  const [positionId, setPositionId] = useState(null);
  const [permit, setPermit] = useState(false);

  useEffect(() => {
    const permission = permissionVerification(
      userPermissions,
      '/recruitments/entries/modification'
    );
    setPermit(permission);
  }, [userPermissions]);

  const onChange = () => {
    setActive(!active);
    navigate(`${pathname}?active=${!active}`);
  };

  const deleteResource = async (id, employeeId) => {
    try {
      await dispatchAPI('DELETE', { url: `/entries/${id}` });
      await dispatchAPI('DELETE', { url: `/employees/${employeeId}` });
      setRefresh(!refresh);
    } catch (e) {
      message(e);
    }
  };

  const getPremises = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/premise'
      });
      setPremises(data);
    } catch (e) {
      message(e);
    }
  };

  const getPositions = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/positions'
      });
      setPositions(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getPremises();
      await getPositions();
    })();
  }, []);

  const headers = [
    {
      label: 'employee',
      extra_label: 'last_name',
      key: 'employee.last_name'
    },
    {
      label: 'employee',
      extra_label: 'first_name',
      key: 'employee.first_name'
    },
    {
      label: 'position',
      key: 'employee.position.label',
      header_key: 'position'
    },
    {
      label: 'premise',
      key: 'employee.premise.label',
      header_key: 'premise'
    },
    {
      label: 'start_date',
      key: 'start_date'
    }
  ];

  const setQuery = () => {
    let query = '';

    query = active ? 'active=false' : 'active=true';

    if (premiseId) {
      query += `&premise=${premiseId}`;
    }

    if (positionId) {
      query += `&offer=${positionId}`;
    }

    setExtraQuery(query);
  };

  useEffect(() => {
    setQuery();
  }, [active, premiseId, positionId]);

  const columns = useColumns({ entriesStatus, pathname, deleteResource });

  return (
    <ListResource
      resourceName="entries"
      columns={columns}
      headers={headers}
      formatter={formattedData}
      withCreateButton={permit}
      populate="employee.position,premise"
      resourceModelName="Entry"
      withoutImportButton
      forceRefresh={refresh}
      customActionColumn
      extraButtons={
        <Row gutter={[8, 8]} align="middle">
          <Col>
            <Select
              placeholder={t('entries.premise_place_holder')}
              showSearch
              allowClear
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              style={{ width: 240 }}
              onChange={(value) => {
                setPremiseId(value);
              }}
            >
              {premises.map((premise) => (
                <Option value={premise._id} key={premise._id}>
                  {premise.label}
                </Option>
              ))}
            </Select>
          </Col>
          <Col>
            <Select
              placeholder={t('entries.position_place_holder')}
              showSearch
              allowClear
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              style={{ width: 240 }}
              onChange={(value) => {
                setPositionId(value);
              }}
            >
              {positions.map((offer) => (
                <Option value={offer._id} key={offer._id}>
                  {offer.label}
                </Option>
              ))}
            </Select>
          </Col>
          <Col>
            <Row align="middle" className="list-switch-wrapper">
              <Switch checked={active} onChange={onChange} />
              <Typography>{t('entries.show.close_entries')}</Typography>
            </Row>
          </Col>
        </Row>
      }
      extraQuery={extraQuery}
    />
  );
};

export default ListEntries;
