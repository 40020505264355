import moment from 'moment';

const resignationInfo = (data) => {
  const { date_of_resignation_letter, notice_period } = data;

  const resignationFields = [
    {
      label: 'exits.form.date_of_resignation_letter',
      span: 1,
      content:
        (date_of_resignation_letter &&
          moment(date_of_resignation_letter).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      label: 'exits.form.notice_period',
      span: 2,
      content: notice_period || '-'
    }
  ];

  return { resignationFields };
};

export default resignationInfo;
