/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { Button, Input, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import EquipmentTable from './equipmentTable';

const initialColumns = [
  {
    title: 'Label',
    dataIndex: 'label',
    key: 'label',
    render: (text) => <Input InitialValue={text} />
  }
];

const EquipmentSettings = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [equipmentCols, setEquipmentCols] = useState(initialColumns);
  const newEquipmentCol = {
    label: 'Nouvelle ligne',
    show: true
  };

  const getEquipmentCols = async () => {
    try {
      const data = await dispatchAPI('GET', { url: '/equipment' });
      setEquipmentCols(data.data);
    } catch (e) {
      message.error(e.message);
    }
  };

  const addEquipmentCol = async () => {
    try {
      await dispatchAPI('POST', { url: '/equipment', body: newEquipmentCol });
      getEquipmentCols();
    } catch (e) {
      message.error(e.message);
    }
  };

  const editEquipmentCol = async (_id, row) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/equipment/${_id}`,
        body: row
      });
    } catch (e) {
      message.error(e.message);
    }
    getEquipmentCols();
  };
  useEffect(() => {
    getEquipmentCols();
  }, []);

  return (
    <>
      <EquipmentTable
        equipmentCols={equipmentCols}
        editEquipmentCol={editEquipmentCol}
      />
      <Button
        type="dashed"
        style={{ width: '100%', height: 50 }}
        onClick={addEquipmentCol}
      >
        {t('settings.equipmentTable.addequipmentcol')}
      </Button>
    </>
  );
};

export default EquipmentSettings;
