export const setSendMailAction = ({
  activeStep,
  exit,
  sendMailAction,
  setRefresh,
  refresh
}) => {
  switch (activeStep) {
    case 'ABSENCE_REASON':
      return exit.is_justification_send || exit.is_formal_notice_send
        ? sendMailAction()
        : setRefresh(!refresh);
    default:
      return sendMailAction();
  }
};
