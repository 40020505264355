export const setExitBody = async ({
  activeStep,
  absenceReasonType,
  dispatchAPI,
  exit
}) => {
  let exitBody;
  switch (activeStep) {
    case 'ABSENCE_REASON':
      if (absenceReasonType === 'justification') {
        exitBody = { is_justification_send: true };
      }
      if (absenceReasonType === 'formal_notice') {
        exitBody = { is_formal_notice_send: true };
      }
      await dispatchAPI('PATCH', {
        url: `exits/${exit._id}`,
        body: exitBody
      });
      break;
    default:
      return null;
  }
  return true;
};
