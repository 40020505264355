import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import useFields from './fields';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainer/CreateUpdateContainer';

const CreateUpdateUser = ({ purpose }) => {
  const { fields, isFieldsLoading } = useFields();

  const combineDateAndHour = ({ date, hour }) => {
    const newHour = new Date(hour);

    const finalDate = moment(date)
      .set({
        hour: newHour.getHours(),
        minute: newHour.getMinutes(),
        second: 0,
        millisecond: 0
      })
      .toDate();

    return finalDate;
  };

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data,
        date: data.date && moment(data.date)
      })
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        date: combineDateAndHour({ date: data.date, hour: data.hour }),
        type: 'INTERN'
      })
    }
  };

  return (
    <CreateUpdateContainer
      fields={fields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      config={config}
      baseUrl="incidents"
      resource="incidents"
    />
  );
};

CreateUpdateUser.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateUser;
