import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Col,
  Form,
  Input,
  Layout,
  Row,
  Typography,
  message
} from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { LockOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { outOfNavRoutes } from '../../utils/constants/adminRoutes';
import { usePwdPattern } from '../../utils/pwdPattern';
import BackgroundPhoto from '../../assets/images/timothy-dykes-Lq1rOaigDoY-unsplash.jpg';
import Logo from '../../assets/images/logo.png';
import { useHandleResize } from '../../utils/HandleResize';

const { Title } = Typography;

/**
 * @component
 * Component for creating a password via email.
 * @param {object} props - Component props.
 * @param {Function} props.switchForm - Callback to switch form.
 * @returns {JSX.Element} The JSX element representing the ForgotPwdForm component.
 */
const PostPwdForm = () => {
  const { t } = useTranslation();
  const { urlToken } = useParams();
  const { dispatchAPI } = useAuthContext();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const { width } = useHandleResize();

  const postPwd = async (body) => {
    setLoading(true);
    try {
      await dispatchAPI('PATCH', {
        url: `users/post-pwd/${urlToken}`,
        body
      });
      message.success(t('login.post-pwd'));
      return navigate(outOfNavRoutes.LOGIN);
    } catch (e) {
      if (e) message.error(t('login.postPwdEmailError'));
      return setLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    await postPwd(values);
  };

  return (
    <Layout
      style={{
        minHeight: '100vh',
        maxHeight: '100vh',
        display: 'inherit',
        overflow: 'hidden'
      }}
    >
      <Row>
        <Col
          xs={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
          style={{ backgroundColor: 'var(--componentBackground)' }}
        >
          <Row
            justify={width < 993 ? 'center' : 'start'}
            align="middle"
            style={{
              height: '100vh',
              maxHeight: '100vh',
              backgroundImage: `url(${BackgroundPhoto})`,
              overflow: 'hidden',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover'
            }}
          >
            <Col
              xxl={7}
              xl={9}
              lg={12}
              sm={18}
              xs={24}
              style={{
                height: '100vh',
                maxHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                textAlign: 'center',
                padding: width < 993 ? '20px 32px' : '40px 40px 0 40px',
                background: 'rgba(9, 9, 9, 0.6)',
                marginLeft: width < 993 ? 0 : 130,
                overflowY: 'auto',
                overflowX: 'hidden',
                backdropFilter: width > 992 && 'blur(4px)'
              }}
            >
              <Row align="middle" justify="center">
                <img
                  style={{
                    width: 90
                  }}
                  alt="Logo"
                  src={Logo}
                />
                <span className="logo-vbr logo-login">Groupe VBR</span>
              </Row>
              <Col>
                <Title
                  style={{
                    textAlign: 'center',
                    marginBottom: 40,
                    fontSize: 36,
                    color: 'var(--clientColor)',
                    fontFamily: 'Cambria, Georgia, serif',
                    fontWeight: 400
                  }}
                >
                  {t(`login.title.post-pwd`)}
                </Title>
                <Form onFinish={handleSubmit}>
                  <Form.Item
                    name="password"
                    rules={[
                      { required: true, message: t('login.pwdMissing') },
                      ...usePwdPattern()
                    ]}
                    hasFeedback
                  >
                    <Input.Password
                      prefix={
                        <LockOutlined
                          style={{ color: 'var(--textColorSecondary)' }}
                        />
                      }
                      placeholder={t('login.password')}
                    />
                  </Form.Item>
                  <Form.Item
                    dependencies={['password']}
                    hasFeedback
                    name="confirm"
                    rules={[
                      {
                        required: true,
                        message: t('login.pwdMissing')
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(t('login.pwdNotMatching'));
                        }
                      })
                    ]}
                  >
                    <Input.Password
                      prefix={
                        <LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                      }
                      placeholder={t('login.confirmPassword')}
                    />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ width: '100%' }}
                      loading={isLoading}
                    >
                      {t('login.createPwd')}
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="link"
                      block
                      style={{ width: '100%' }}
                      onClick={() => navigate(`${outOfNavRoutes.LOGIN}`)}
                    >
                      {t('buttons.back')}
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  );
};

export default PostPwdForm;
