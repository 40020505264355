import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Menu } from 'antd';
import { NavLink, useLocation } from 'react-router-dom';
import navMenuLogos from '../../utils/constants/navMenuLogos';
import { useHandleResize } from '../../utils/HandleResize';
import {
  routes,
  subRoutes,
  pathSearches
} from '../../utils/constants/adminRoutes';
import { useAuthContext } from '../../contexts/AuthContext';
import { permissionVerification } from '../../utils/permissionVerification';
import { useErrorMessage } from '../../utils/ErrorMessage';

const { SubMenu } = Menu;

export const AdminNavMenu = ({ setCollapsed }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { width } = useHandleResize();
  const { message } = useErrorMessage();
  const path = location?.pathname || '/';
  const activeKeys = path
    .replace('/', '')
    .split('/')
    .map((p) => `/${p}`);
  const { user, dispatchAPI } = useAuthContext();

  const [userPermissions, setUserPermissions] = useState([]);

  const getUserPermissions = async (userId) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/users/${userId}?populate=permissions,permissions.permission`
      });
      setUserPermissions(data?.permissions?.permission);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    if (user) {
      getUserPermissions(user?._id);
    }
  }, [user]);

  return (
    <Menu
      style={{
        padding: '24px 0',
        background: 'var(--primaryColor)'
      }}
      selectedKeys={activeKeys}
      defaultOpenKeys={activeKeys}
      mode="inline"
      onSelect={width < 992 ? () => setCollapsed(true) : () => {}}
    >
      {Object.entries(routes).map(([menuItem, p]) => {
        if (permissionVerification(userPermissions, p)) {
          if (subRoutes[menuItem]) {
            return (
              <SubMenu
                key={p}
                icon={navMenuLogos[menuItem]}
                title={t(`basiclayout.menu.${menuItem.toLowerCase()}`)}
              >
                {Object.entries(subRoutes[menuItem]).map(
                  ([subMenuItem, subPath]) => {
                    if (permissionVerification(userPermissions, p + subPath)) {
                      return (
                        <Menu.Item key={subPath} disabled={subPath === ''}>
                          <NavLink
                            to={`${p}${subPath}${
                              pathSearches[subMenuItem]
                                ? pathSearches[subMenuItem]
                                : ''
                            }`}
                          >
                            <span>
                              {t(
                                `basiclayout.submenu.${subMenuItem.toLowerCase()}`
                              )}
                            </span>
                          </NavLink>
                        </Menu.Item>
                      );
                    }
                    return null; // Return null for subroutes without permission
                  }
                )}
              </SubMenu>
            );
          }
          // Check permissions for main routes
          return (
            <Menu.Item key={p} disabled={p === ''}>
              <NavLink
                to={`${p}${
                  pathSearches[menuItem] ? pathSearches[menuItem] : ''
                }`}
              >
                {navMenuLogos[menuItem]}
                <span>{t(`basiclayout.menu.${menuItem.toLowerCase()}`)}</span>
              </NavLink>
            </Menu.Item>
          );
        }
        return null; // Return null for routes without permission
      })}
    </Menu>
  );
};

AdminNavMenu.propTypes = {
  setCollapsed: PropTypes.func.isRequired
};
