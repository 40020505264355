import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ListFollowUps from './ListTrainingProgress';
import CreateUpdateTrainingProgress from './CreateUpdateTrainingProgress';
import ShowTrainingProgress from './ShowTrainingProgress';

export const TrainingProgressRouter = () => (
  <Routes>
    <Route
      path="/create"
      element={<CreateUpdateTrainingProgress purpose="create" />}
    />
    <Route
      path="/edit/:id"
      element={<CreateUpdateTrainingProgress purpose="edit" />}
    />
    <Route path="/show/:id" element={<ShowTrainingProgress />} />
    <Route index element={<ListFollowUps />} />
  </Routes>
);
