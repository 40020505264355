import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Avatar, Button, Layout, message, Menu, Row } from 'antd';
import {
  LogoutOutlined,
  SettingOutlined,
  UserOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import HeaderDropdown from '../../HeaderDropdown/HeaderDropdown';
import LogoBVR from '../../../assets/images/logo 2.png';

const { Header } = Layout;

const StyledHeader = styled.div`
  height: 56px;
  line-height: 56px;
  padding: 0 12px 0 24px;
  z-index: 8;
  position: fixed;
  display: flex;
  justify-content: space-between;
  width: 100vw;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(4px);
`;

const StyledContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

const StyledDropdownContainer = styled.div`
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  transition: all 0.3s;

  :hover {
    background: var(--secondaryHover);
  }

  .ant-btn-link {
    height: fit-content;
  }
`;

const StyledFullNameSpan = styled.span`
  color: var(--textColor);
  font-weight: bold;
`;

const StyledRoleP = styled.p`
  font-size: 10px;
  color: var(--textColor);
  margin-bottom: 0;
`;

const HeaderLayoutDashboard = ({ themeLogo }) => {
  const { user, dispatchAPI } = useAuthContext();
  const { t } = useTranslation();

  const onProfileMenuClick = ({ key }) => {
    if (key === 'logout') {
      dispatchAPI('LOGOUT');
      message.success(t('login.logoutSuccess'));
    }
  };

  const profileMenu = (
    <Menu onClick={onProfileMenuClick}>
      <Menu.Item key="profile">
        <NavLink to="/profile">
          <UserOutlined
            style={{
              fontSize: 16,
              marginRight: 4
            }}
          />
          {` ${t('header.menu.user.profile')}`}
        </NavLink>
      </Menu.Item>
      <Menu.Item key="settings">
        <NavLink to="/settings">
          <SettingOutlined
            style={{
              fontSize: 16,
              marginRight: 4
            }}
          />
          {` ${t('header.menu.user.settings')}`}
        </NavLink>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="logout">
        <LogoutOutlined
          style={{
            fontSize: 16,
            marginRight: 4
          }}
        />
        {` ${t('header.menu.user.logout')}`}
      </Menu.Item>
    </Menu>
  );

  return (
    <StyledHeader as={Header}>
      {themeLogo && (
        <Row align="middle">
          <img
            style={{
              maxHeight: 40,
              marginRight: 8
            }}
            src={LogoBVR}
            alt="Company logo"
          />
          <h1
            style={{
              fontFamily: 'Cambria',
              fontSize: 20,
              fontStyle: 'normal',
              fontWeight: 400,
              marginBottom: 0
            }}
          >
            Groupe <strong>VBR</strong>
          </h1>
        </Row>
      )}
      <StyledContainer>
        <HeaderDropdown overlayMenu={profileMenu}>
          <StyledDropdownContainer>
            <Avatar
              size="medium"
              icon={user?.photo ? '' : <UserOutlined />}
              src={user?.photo ? user.photo : ''}
            >
              {`${user?.first_name[0]}${user?.last_name[0]}`}
            </Avatar>
            <Button type="link">
              <StyledFullNameSpan>
                {`${user?.first_name} ${user?.last_name}`}
              </StyledFullNameSpan>
              <StyledRoleP>
                {t(`users.tags.${user?.role.split(':')[1]}`)}
              </StyledRoleP>
            </Button>
          </StyledDropdownContainer>
        </HeaderDropdown>
      </StyledContainer>
    </StyledHeader>
  );
};

HeaderLayoutDashboard.propTypes = {
  themeLogo: PropTypes.string
};

HeaderLayoutDashboard.defaultProps = {
  themeLogo: null
};

export default HeaderLayoutDashboard;
