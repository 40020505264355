import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import personnalInformations from './personnalInformationsFields';
import concernedPosition from './concernedPositionFields';
import checkingDocuments from './documentsFields';
import { useErrorMessage } from '../../../../utils/ErrorMessage';
import { useAuthContext } from '../../../../contexts/AuthContext';

const { Option } = Select;

const useFields = () => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [profileTrees, setProfileTrees] = useState([]);

  const { personnalInformationsFields, isPersonnalInformationsFieldsLoading } =
    personnalInformations();
  const { concernedPositionFields, isConcernedPostFieldsLoading, position } =
    concernedPosition();
  const { documentsFields } = checkingDocuments();

  let loadingFields;
  if (isPersonnalInformationsFieldsLoading || isConcernedPostFieldsLoading) {
    loadingFields = true;
  } else {
    loadingFields = false;
  }

  const getCompetenceProfile = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/profiletree'
      });
      setProfileTrees(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getCompetenceProfile();
    })();
  }, []);

  const formFields = [
    personnalInformationsFields,
    concernedPositionFields,
    [
      {
        label: 'profile',
        name: ['profile'],
        input: (
          <Select>
            {(profileTrees || []).map((item) => (
              <Option key={item._id} value={item._id}>
                {item.title}
              </Option>
            ))}
          </Select>
        )
      }
    ],
    documentsFields
  ];

  return {
    formFields,
    loadingFields,
    position
  };
};

export default useFields;
