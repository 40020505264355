import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { getFormattedPhoneNumber } from '../../../utils/phoneNumber';

const employeePrincipalInfo = (data) => {
  const {
    first_name,
    last_name,
    date_of_birth,
    nationality,
    socialSecurityNumber,
    address,
    email,
    phone_number
  } = data;

  return [
    {
      label: 'employees.form.last_name',
      span: 1,
      content: last_name || '-'
    },
    {
      label: 'employees.form.first_name',
      span: 2,
      content: first_name || '-'
    },
    {
      label: 'employees.form.date_of_birth',
      span: 1,
      content: moment(date_of_birth).format('DD/MM/YYYY') || '-'
    },
    {
      label: 'employees.form.nationality',
      span: 1,
      content: nationality || '-'
    },
    {
      label: 'employees.form.social_security_number',
      span: 1,
      content: socialSecurityNumber || '-'
    },
    {
      label: 'employees.form.address_full',
      span: 3,
      content:
        (
          <>
            {address?.number} {address?.street} {address?.additional}
            {address?.postal_code} {address?.city}
          </>
        ) || '-'
    },
    {
      label: 'employees.form.email',
      span: 2,
      content: email ? <a href={`mailto:${email}`}>{email}</a> : '-'
    },
    {
      label: 'employees.form.phone_number.number',
      span: 1,
      content:
        phone_number && phone_number.number
          ? getFormattedPhoneNumber(
              phone_number.country_code,
              phone_number.number
            )
          : '-'
    }
  ];
};

const employeeContentInfo = (data) => {
  const { t } = useTranslation();
  const {
    position,
    category,
    level,
    grade,
    contract_type,
    working_time,
    start_date,
    end_date,
    premise,
    gross_monthly_salary,
    gross_monthly_salary_with_charges
  } = data;
  return [
    {
      label: 'employees.form.post_name',
      span: 2,
      content: position?.label || '-'
    },
    {
      label: 'employees.form.category',
      span: 1,
      content: t(`enums.categories.${category}`) || '-'
    },
    {
      label: 'employees.form.grade',
      span: 1,
      content: grade || '-'
    },
    {
      label: 'employees.form.level',
      span: 1,
      content: level || '-'
    },
    {
      label: 'employees.form.contract_type',
      span: 1,
      content: t(`enums.contractTypes.${contract_type}`) || '-'
    },
    {
      label: 'employees.form.working_time',
      span: 1,
      content: working_time ? `${working_time}H` : '-'
    },
    {
      label: 'employees.form.start_date',
      span: 1,
      content: start_date ? moment(start_date).format('DD/MM/YYYY') : '-'
    },
    {
      label: 'employees.form.end_date',
      span: 1,
      content: end_date ? moment(end_date).format('DD/MM/YYYY') : '-'
    },
    {
      label: 'employees.form.premise',
      span: 1,
      content: premise?.label || '-'
    },
    {
      label: 'employees.form.gross_monthly_salary',
      span: 1,
      content: gross_monthly_salary ? `${gross_monthly_salary} € / mois` : '-'
    },
    {
      label: 'employees.form.gross_monthly_salary_with_charges',
      span: 2,
      content: gross_monthly_salary_with_charges
        ? `${gross_monthly_salary_with_charges} € / mois`
        : '-'
    }
  ];
};

export { employeePrincipalInfo, employeeContentInfo };
