import moment from 'moment';

const conventionalInfo = (data) => {
  const { conventional_rupture_date, end_of_withdrawal_periods } = data;

  const conventionalFields = [
    {
      label: 'exits.form.conventional_rupture_date',
      span: 1,
      content:
        (conventional_rupture_date &&
          moment(conventional_rupture_date).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      label: 'exits.form.end_of_withdrawal_periods',
      span: 2,
      content:
        (end_of_withdrawal_periods &&
          moment(end_of_withdrawal_periods).format('DD/MM/YYYY')) ||
        '-'
    }
  ];

  return { conventionalFields };
};

export default conventionalInfo;
