import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Button, Popconfirm, Skeleton, Row, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined,
  DownloadOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import listContent from './listContent';
import ListApplications from './ListApplications';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../../components';
import { routes, subRoutes } from '../../../utils/constants/adminRoutes';
import { useDownloadDocument } from '../../../utils/downloadDoc';
import { permissionVerification } from '../../../utils/permissionVerification';

const ShowCandidate = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { dispatchAPI, userPermissions } = useAuthContext();
  const { downloadDocument } = useDownloadDocument();
  const [isLoading, setIsLoading] = useState(true);
  const [candidates, setCandidates] = useState([]);
  const [permit, setPermit] = useState(false);

  useEffect(() => {
    const permission = permissionVerification(
      userPermissions,
      '/recruitments/candidates/modification'
    );
    setPermit(permission);
  }, [userPermissions]);

  const getCandidate = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/candidates/${id}?populate=potential_positions,premise,curriculum`
      });
      setCandidates(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getCandidate();
    })();
  }, [getCandidate]);

  const deleteCandidate = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/candidates/${id}` });
      navigate(-1);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  return (
    <>
      <PageHeaderCustom
        title={t('candidates.show.title')}
        extra={
          <>
            <Link
              to={{
                pathname: `${routes.RECRUITMENTS}${subRoutes.RECRUITMENTS.CANDIDATES}/edit/${id}`
              }}
            >
              {permit && (
                <Button type="primary">
                  {`${t('buttons.edit')} `}
                  <EditOutlined />
                </Button>
              )}
            </Link>
            <Button
              type="primary"
              icon={<DownloadOutlined />}
              onClick={() =>
                downloadDocument({
                  _id: candidates.curriculum._id,
                  metadata: candidates.curriculum.metadata,
                  contentType: candidates.curriculum.contentType
                })
              }
            >
              {t('buttons.download_curriculum')}
            </Button>
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={deleteCandidate}
              icon={<WarningOutlined />}
            >
              {permit && (
                <Button type="link" danger>
                  {`${t('buttons.delete')} `}
                  <DeleteOutlined />
                </Button>
              )}
            </Popconfirm>
          </>
        }
      />
      <ContentCustom>
        <Card
          title={t('candidates.show.information')}
          bordered={false}
          style={{ width: '100%' }}
          className="form-card"
        >
          <Row>
            <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
              <DescriptionList
                data={listContent(candidates || {}, t)}
                translate
              />
            </Skeleton>
          </Row>
        </Card>
        <Card
          title={t('applications.history')}
          bordered={false}
          style={{ width: '100%' }}
          className="form-card"
        >
          <Row>
            <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
              <ListApplications candidates={candidates} />
            </Skeleton>
          </Row>
        </Card>
      </ContentCustom>
    </>
  );
};

export default ShowCandidate;
