import React, { useEffect, useState } from 'react';
import { Button, Col, Popconfirm, Row } from 'antd';
import PropTypes from 'prop-types';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { permissionVerification } from '../../../utils/permissionVerification';

export const GenerateTitle = ({
  group,
  filterData,
  handleEdit,
  deleteGroup
}) => {
  const { t } = useTranslation();
  const dataUsers = filterData(group);
  const { userPermissions } = useAuthContext();
  const [permit, setPermit] = useState(false);

  useEffect(() => {
    const permission = permissionVerification(
      userPermissions,
      '/organisation/modification'
    );
    setPermit(permission);
  }, [userPermissions]);

  return (
    <Row align="middle" justify="space-between">
      <Col>{group.label}</Col>
      <Col>
        <Button
          type="link"
          onClick={() => {
            handleEdit(group);
          }}
          disabled={!permit}
        >
          <EditOutlined />
        </Button>
        {dataUsers.length === 0 && (
          <Popconfirm
            title={t('datatable.column.action.delete.title')}
            okText={t('datatable.column.action.delete.ok')}
            okButtonProps={{ type: 'danger' }}
            cancelText={t('datatable.column.action.delete.cancel')}
            onConfirm={() => deleteGroup(group._id)}
            icon={<WarningOutlined />}
          >
            {permit && (
              <DeleteOutlined style={{ fontSize: 15 }} type="delete" />
            )}
          </Popconfirm>
        )}
      </Col>
    </Row>
  );
};

GenerateTitle.propTypes = {
  group: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  }).isRequired,
  filterData: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  deleteGroup: PropTypes.func.isRequired
};
