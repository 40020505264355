import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import useFields from './fields';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainer/CreateUpdateContainer';

const CreateUpdateBonus = ({ purpose }) => {
  const { fields, isFieldsLoading } = useFields();

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data,
        date: data.date && moment(data.date)
      })
    }
  };

  return (
    <CreateUpdateContainer
      fields={fields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="bonus"
      resource="bonus"
      config={config}
    />
  );
};

CreateUpdateBonus.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateBonus;
