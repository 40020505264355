export const setNextStep = ({ activeStep, exit, nextStep }) => {
  switch (activeStep) {
    case 'RESTITUTION_VALIDATED':
      return exit?.is_material && exit?.is_check_list_validated
        ? nextStep()
        : null;
    case 'ACKNOWLEDGMENT_OF_RECEIPT':
      return exit?.is_resignation_letter &&
        exit?.is_acknowledgment_of_receipt_generated
        ? nextStep()
        : null;
    default:
      return nextStep();
  }
};
