import React, { useState } from 'react';
import { Input, Upload } from 'antd';
import { useTranslation } from 'react-i18next';
import { UploadOutlined } from '@ant-design/icons/lib';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import { useAuthContext } from '../../../contexts/AuthContext';

const { Dragger } = Upload;

const useFields = () => {
  const { token } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const [file, setFile] = useState([]);

  const draggerProps = {
    name: 'file',
    action: `${process.env.REACT_APP_API_URL}/files?template=true`,
    headers: { Authorization: `Bearer ${token}` },
    onChange(info) {
      const { status, response } = info.file;

      setFile(response);

      if (status === 'error') {
        message(`${info.file.name} file upload failed.`);
      }
    }
  };

  const fields = [
    {
      name: ['label'],
      input: <Input style={{ width: '100%' }} />
    },
    {
      name: ['file'],
      input: (
        <Dragger {...draggerProps}>
          <p className="ant-upload-drag-icon">
            <UploadOutlined style={{ color: 'var(--textColor)' }} />
          </p>
          <p className="ant-upload-text">{t('files.create.action')}</p>
        </Dragger>
      )
    }
  ];

  return {
    fields,
    file
  };
};

export default useFields;
