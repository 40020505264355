import React, { useState } from 'react';
import {
  Collapse,
  Typography,
  Row,
  Col,
  Skeleton,
  Upload,
  Button,
  Divider,
  message as antdMessage
} from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ListDocuments from './ListDocuments';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/ErrorMessage';
import { useApplicationsContext } from '../../../../contexts/ApplicationsContext';

const { Panel } = Collapse;
const { Title } = Typography;
const { Dragger } = Upload;

const Documents = ({ application, isLoading }) => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [files, setFiles] = useState(null);
  const { dataRefresh, setDataRefresh } = useApplicationsContext();

  const props = {
    multiple: false,
    fileList: files,
    beforeUpload: (newFile) => {
      setFiles([newFile]);
      return false;
    },
    onRemove: (file) => setFiles(files.filter((f) => f.uid !== file.uid))
  };

  const handlePostDocuments = async () => {
    try {
      const formData = new FormData();
      files.forEach((doc) =>
        formData.append('files', doc.originFileObj || doc)
      );
      await dispatchAPI('POST', {
        url: `/applications/upload/${application._id}`,
        body: formData
      });
      antdMessage.success(` document(s) téléchargé(s)`);
      setDataRefresh(!dataRefresh);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  return (
    <>
      <Divider orientation="left" plain>
        <Title level={3}>Documents</Title>
      </Divider>
      <Collapse style={{ width: '100%' }} defaultActiveKey={['0']}>
        <Panel key="1" header={t('applications.form.documents.title')}>
          <Row style={{ width: '100%' }}>
            <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
              <Row gutter={[24, 24]} align="middle" style={{ width: '100%' }}>
                <Col xs={24} lg={8}>
                  <div>
                    <Dragger {...props}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">Glisser / Déposer</p>
                    </Dragger>
                    {files && (
                      <Button onClick={handlePostDocuments}>Enregistrer</Button>
                    )}
                  </div>
                </Col>
                <Col xs={24} lg={16}>
                  <ListDocuments data={application} />
                </Col>
              </Row>
            </Skeleton>
          </Row>
        </Panel>
      </Collapse>
    </>
  );
};

export default Documents;

Documents.propTypes = {
  application: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isLoading: PropTypes.bool.isRequired
};
Documents.defaultProps = { application: {} };
