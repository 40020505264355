import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Col, Switch } from 'antd';
import { CalendarOutlined, TableOutlined } from '@ant-design/icons';
import { CalendarView } from './CalendarView';
import { ListView } from './TableView';

const ListInterviews = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const params = new URLSearchParams(location.search);
  const activated = params.get('agenda');
  const [agenda, setAgenda] = useState(activated === 'true');
  const inter = params.get('interviews');
  const [myInterviewsOnly, setMyInterviewsOnly] = useState(inter === 'true');
  const { t } = useTranslation();

  const customActionLine = () => {
    const onChangeAgenda = () => {
      setAgenda(!agenda);
      navigate(`${pathname}?agenda=${!agenda}`);
    };

    const onChangeInterview = () => {
      setMyInterviewsOnly(!myInterviewsOnly);
      navigate(`${pathname}?interviews=${!myInterviewsOnly}`);
    };

    return (
      <Row className="interviews-header" justify="space-between" align="middle">
        <Col xs={24} md={12}>
          <TableOutlined /> {t('interviews.switch.table')}
          <Switch
            onChange={onChangeAgenda}
            style={{ margin: 10 }}
            checked={agenda}
          />
          <CalendarOutlined /> {t('interviews.switch.calendar')}
        </Col>

        <Col xs={24} md={12}>
          <Row align="middle" justify="end">
            {t('interviews.switch.my-interviews')}
            <Switch
              checked={!myInterviewsOnly}
              onChange={onChangeInterview}
              style={{ margin: 10 }}
            />
            {t('interviews.switch.all-interviews')}
          </Row>
        </Col>
      </Row>
    );
  };

  return (
    <div>
      {agenda === true ? (
        <CalendarView
          myInterviewsOnly={myInterviewsOnly}
          customActionLine={customActionLine}
        />
      ) : (
        <ListView
          myInterviewsOnly={myInterviewsOnly}
          customActionLine={customActionLine}
        />
      )}
    </div>
  );
};

export default ListInterviews;
