import React from 'react';
import { Route, Routes } from 'react-router-dom';
import CreateUpdateMemo from './CreateUpdateMemo';
import ShowMemo from './ShowMemo';
import ListMemos from './ListMemos';

export const MemoRouter = () => (
  <Routes>
    <Route path="/create" element={<CreateUpdateMemo purpose="create" />} />
    <Route path="/edit/:id" element={<CreateUpdateMemo purpose="edit" />} />
    <Route path="/show/:id" element={<ShowMemo />} />
    <Route index element={<ListMemos />} />
  </Routes>
);
