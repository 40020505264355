/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import {
  BellOutlined,
  CheckOutlined,
  HourglassOutlined,
  QuestionOutlined,
  RetweetOutlined,
  RollbackOutlined
} from '@ant-design/icons';
import { Button, Input, message, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import KanbanTable from './kanbanTable';

const { Option } = Select;

const initialColumns = [
  {
    title: 'KANBAN',
    dataIndex: 'columns',
    key: 'columns'
  },
  {
    title: 'Label',
    dataIndex: 'label',
    key: 'label',
    render: (text) => <Input InitialValue={text} />
  },
  {
    title: 'Icône',
    dataIndex: 'icon',
    key: 'icon',
    render: (icon) => (
      <Select InitialValue={icon}>
        <Option value="bell">
          <BellOutlined />
        </Option>
        <Option value="hourglass">
          <HourglassOutlined />
        </Option>
        <Option value="Check">
          <CheckOutlined />
        </Option>
        <Option value="question">
          <QuestionOutlined />
        </Option>
        <Option value="review">
          <RetweetOutlined />
        </Option>
        <Option value="rollback">
          <RollbackOutlined />
        </Option>
      </Select>
    )
  }
];

const KanbanSettings = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();

  const [applications, setApplications] = useState([]);
  const [kanbanCols, setKanbanCols] = useState(initialColumns);

  const newKanbanCol = {
    label: 'Nouvelle colonne',
    icon: null,
    show: true,
    color: '#31b3fb'
  };

  const fetchApplications = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: `/applications` });
      setApplications(data);
    } catch (e) {
      message.error(e.message);
    }
  };

  const getKanbanCols = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/kanbans' });
      setKanbanCols(data);
    } catch (e) {
      message.error(e.message);
    }
  };

  const addKanbanCol = async () => {
    try {
      await dispatchAPI('POST', { url: '/kanbans', body: newKanbanCol });
      getKanbanCols();
    } catch (e) {
      message.error(e.message);
    }
  };

  const editKanbanCol = async (_id, row) => {
    const col = kanbanCols.find((col1) => col1._id === _id);
    const useCol = applications.filter(
      (application) => application.status === col.label
    );
    if (useCol.length && row.label !== col.label) {
      return message.error(t('settings.kanbanTable.error-delete-column'));
    }
    try {
      await dispatchAPI('PATCH', {
        url: `/kanbans/${_id}`,
        body: row
      });
    } catch (e) {
      message.error(e.message);
    }
    getKanbanCols();
  };

  useEffect(() => {
    getKanbanCols();
    fetchApplications();
  }, []);

  return (
    <>
      <KanbanTable kanbanCols={kanbanCols} editKanbanCol={editKanbanCol} />
      <Button
        type="dashed"
        style={{ width: '100%', height: 50, marginTop: 10 }}
        onClick={addKanbanCol}
      >
        {t('settings.kanbanTable.addkanbancol')}
      </Button>
    </>
  );
};

export default KanbanSettings;
