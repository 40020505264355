import breakSignedContent from './fields/breakSignedContent';
import restitutionValidContent from './fields/restitutionValidContent';
import accessDeletedContent from './fields/accessDeletedContent';
import acknowledgmentContent from './fields/acknowledgmentContent';
import breakupNotificationContent from './fields/breakupNotificationContent';

const useFields = ({ activeStep, setFile, restitutionType, employee }) => {
  const { breakSignedFields } = breakSignedContent({ setFile });
  const { accessDeletedFields } = accessDeletedContent({});
  const { materialRestitutionValidFields, checkListRestitutionValidFields } =
    restitutionValidContent({ employee });
  const { resignationLetterFields } = acknowledgmentContent({ setFile });
  const { employeeBreakupNotificationFields } = breakupNotificationContent({
    setFile
  });

  const setRestitutionFields = () => {
    switch (restitutionType) {
      case 'material':
        return materialRestitutionValidFields;
      case 'check_list':
        return checkListRestitutionValidFields;
      default:
        return null;
    }
  };

  const setFields = () => {
    switch (activeStep) {
      case 'SIGNED_BREAK':
        return breakSignedFields;
      case 'ACKNOWLEDGMENT_OF_RECEIPT':
        return resignationLetterFields;
      case 'RESTITUTION_VALIDATED':
        return setRestitutionFields();
      case 'ACCESS_DELETED':
        return accessDeletedFields;
      case 'EMPLOYEE_BREAKUP_NOTIFICATION':
        return employeeBreakupNotificationFields;
      default:
        return null;
    }
  };

  const fieldsArray = setFields();
  const fieldsToAdd = Array.isArray(fieldsArray) ? fieldsArray : [];

  return { fieldsToAdd };
};

export default useFields;
