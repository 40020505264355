import React, { useState } from 'react';
import { Button, Row, Spin, message as antdMessage, Col } from 'antd';
import { MailOutlined, FileDoneOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/ErrorMessage';
import { TemplateDocumentButton } from '../../../../components/TemplateDocumentButton/TemplateDocumentButton';

const renderAdditionalButtons = ({
  setType,
  setIsModalVisible,
  nextStep,
  isGenerate,
  activeStep,
  setHistoryExchange,
  employeeId,
  t
}) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [isDocLoading] = useState(false);

  const sendPromiseEmail = async () => {
    try {
      await dispatchAPI('PATCH', {
        url: `/employees/${employeeId}`,
        body: {
          welcome_mail_sent: true
        }
      });
      antdMessage.success(t('entries.show.message.promise_sent'));
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const sendWelcomeEmail = async () => {
    try {
      await dispatchAPI('PATCH', {
        url: `/employees/${employeeId}`,
        body: {
          welcome_mail_sent: true
        }
      });
      antdMessage.success(t('entries.show.message.promise_sent'));
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const validateDocs = () => {
    nextStep();
    antdMessage.success(t('entries.show.message.docs_validated'));
  };

  const sendInvitationEmail = () => {
    setType('invitation');
    setIsModalVisible(true);
  };

  switch (activeStep) {
    case 'FILE':
      return (
        <Col>
          <Button
            type="primary"
            danger
            onClick={() => {
              nextStep();
              setHistoryExchange('employee_sheet');
            }}
          >
            {t('entries.show.button.file')}
          </Button>
        </Col>
      );
    case 'PROMISE':
      return (
        <Col>
          <Row gutter={[16, 16]}>
            <Col>
              {!isGenerate && (
                <Spin spinning={isDocLoading}>
                  <TemplateDocumentButton
                    collection="Entry"
                    use_case="PROMISE_OF_EMPLOYMENT"
                    button_text={t('entries.show.button.generate_promise')}
                    onClick={() => {
                      nextStep();
                    }}
                  />
                </Spin>
              )}
            </Col>
            <Col>
              {isGenerate && (
                <Button
                  type="primary"
                  onClick={() => {
                    nextStep();
                    sendPromiseEmail();
                  }}
                >
                  <MailOutlined />
                  {t('entries.show.button.send_promise')}
                </Button>
              )}
            </Col>
          </Row>
        </Col>
      );
    case 'WELCOME_EMAIL':
      return (
        <Col>
          <Button
            type="primary"
            onClick={() => {
              nextStep();
              sendWelcomeEmail();
            }}
          >
            {t('entries.show.button.welcome')}
          </Button>
        </Col>
      );
    case 'DOCS_VALIDATED':
      return (
        <Col>
          <Button
            type="primary"
            onClick={() => {
              nextStep();
              validateDocs();
              setHistoryExchange('docs_validated');
            }}
          >
            <FileDoneOutlined />
            {t('entries.show.button.docs_validated')}
          </Button>
        </Col>
      );
    case 'CONTRACT':
      return (
        <Col>
          <Spin spinning={isDocLoading}>
            <TemplateDocumentButton
              collection="Entry"
              use_case="CONTRACT_TYPE"
              button_text={t('entries.button.generate_contract')}
              onClick={() => {
                setHistoryExchange('generate_employment_contract');
              }}
            />
          </Spin>
        </Col>
      );
    case 'INVITATION_EMAIL':
      return (
        <Col>
          <Button type="primary" onClick={sendInvitationEmail}>
            {t('entries.show.button.invitation')}
          </Button>
        </Col>
      );
    default:
      return null;
  }
};

export default renderAdditionalButtons;
