export const transformTreeData = (
  t,
  data,
  setDefaultChecked,
  parentKey = ''
) => {
  if (!data) return [];

  const skipKeys = ['_id', '__v', 'created_at', 'updated_at'];
  const result = [];
  Object.keys(data).forEach((key) => {
    if (skipKeys.includes(key)) {
      return;
    }
    const value = data[key];
    const currentNodeKey = parentKey ? `${parentKey}-${key}` : key;
    const currentNode = {
      title: t(`organisation.permission.name.${key}`),
      key: currentNodeKey,
      value: typeof value === 'object' ? null : value,
      children: []
    };
    if (typeof value === 'object' && !Array.isArray(value)) {
      currentNode.children = transformTreeData(
        t,
        value,
        setDefaultChecked,
        currentNode.key
      );
    } else {
      currentNode.isLeaf = true;
      if (value === true) {
        setDefaultChecked((prevCheckedKeys) => [
          ...prevCheckedKeys,
          currentNodeKey
        ]);
      }
    }
    result.push(currentNode);
  });
  return result;
};
