import React from 'react';
import { Button, Col } from 'antd';
import { MailOutlined, UploadOutlined } from '@ant-design/icons';

const ConventionalHeader = ({
  activeStep,
  nextStep,
  setIsModalOpen,
  setRestitutionType,
  exit,
  updateStatus,
  t
}) => {
  const renderAdditionalButtons = () => {
    switch (activeStep) {
      case 'OUTPUT_SHEET':
        return (
          <Col>
            <Button
              type="primary"
              danger
              onClick={() => {
                updateStatus();
                nextStep();
              }}
            >
              {t(`exits.header.button.${activeStep}`)}
            </Button>
          </Col>
        );
      case 'SIGNED_BREAK':
        return (
          <Col>
            <Button
              type="primary"
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              <UploadOutlined />
              {t(`exits.header.button.${activeStep}`)}
            </Button>
          </Col>
        );

      case 'RESTITUTION_REQUEST':
        return (
          <Col>
            <Button
              type="primary"
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              <MailOutlined />
              {t(`exits.header.button.${activeStep}`)}
            </Button>
          </Col>
        );
      case 'RESTITUTION_VALIDATED':
        return (
          <>
            <Col>
              {exit && !exit?.is_material ? (
                <Button
                  type="primary"
                  onClick={() => {
                    setIsModalOpen(true);
                    setRestitutionType('material');
                  }}
                >
                  {t(`exits.header.button.${activeStep}.material`)}
                </Button>
              ) : null}
            </Col>
            <Col>
              {exit && !exit?.is_check_list_validated ? (
                <Button
                  type="primary"
                  onClick={() => {
                    setIsModalOpen(true);
                    setRestitutionType('check_list');
                  }}
                >
                  {t(`exits.header.button.${activeStep}.check_list`)}
                </Button>
              ) : null}
            </Col>
          </>
        );
      case 'ACCESS_DELETED':
        return (
          <Col>
            <Button
              type="primary"
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              {t(`exits.header.button.${activeStep}`)}
            </Button>
          </Col>
        );
      default:
        return null;
    }
  };

  return renderAdditionalButtons();
};

export default ConventionalHeader;
