import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ListInterview from './ListInterviews/ListInterviews';
import CreateUpdateInterview from './CreateUpdateInterview';
import ShowInterview from './ShowInterview';

export const InterviewRouter = () => (
  <Routes>
    <Route
      path="/create"
      element={<CreateUpdateInterview purpose="create" />}
    />
    <Route
      path="/edit/:id"
      element={<CreateUpdateInterview purpose="edit" />}
    />
    <Route path="/show/:id" element={<ShowInterview />} />
    <Route index element={<ListInterview />} />
  </Routes>
);
