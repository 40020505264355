import React from 'react';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { offerStatus } from '../../../utils/constants/tagColors';

const offerPrincipalInfo = (data, t) => {
  const {
    position,
    premise,
    end_of_publication_date,
    name,
    premise_adress,
    expected_start_date,
    status,
    logo_usage
  } = data;

  return [
    {
      label: 'offers.form.position',
      span: 3,
      content: (position && position.label) || '-'
    },
    {
      label: 'offers.form.premise',
      span: 1,
      content: (premise && premise.label) || '-'
    },
    {
      label: 'offers.form.end_of_publication_date',
      span: 2,
      content:
        (end_of_publication_date &&
          moment(end_of_publication_date).format('ll')) ||
        '-'
    },
    {
      label: 'offers.form.name',
      span: 1,
      content: name || '-'
    },
    {
      label: 'offers.form.premise_adress',
      span: 1,
      content: premise_adress || '-'
    },
    {
      label: 'offers.form.expected_start_date',
      span: 2,
      content:
        (expected_start_date && moment(expected_start_date).format('ll')) || '-'
    },
    {
      label: 'offers.form.status',
      span: 2,
      content:
        (status && (
          <Tag color={offerStatus[status]}>{t(`offers.tags.${status}`)}</Tag>
        )) ||
        '-'
    },
    {
      label: 'offers.form.logo_usage',
      span: 1,
      content: (logo_usage && logo_usage ? 'OUI' : 'NON') || '-'
    }
  ];
};

const offerContentInfo = (data) => {
  const { t } = useTranslation();
  const {
    description,
    missions,
    required_profile,
    required_experience,
    advantages,
    contract_type,
    work_time,
    salary,
    salary_period
  } = data;

  return [
    {
      label: 'offers.form.description',
      span: 1,
      content: description || '-'
    },
    {
      label: 'offers.form.missions',
      span: 2,
      content: missions || '-'
    },
    {
      label: 'offers.form.required_profile',
      span: 2,
      content: required_profile || '-'
    },
    {
      label: 'offers.form.required_experience',
      span: 1,
      content: required_experience || '-'
    },
    {
      label: 'offers.form.advantages',
      span: 1,
      content: advantages || '-'
    },
    {
      label: 'offers.form.contract_type',
      span: 1,
      content: contract_type || '-'
    },
    {
      label: 'offers.form.work_time',
      span: 1,
      content: (work_time && `${work_time} h`) || '-'
    },
    {
      label: 'offers.form.salary',
      span: 1,
      content: (salary && `${salary} €`) || '-'
    },
    {
      label: 'offers.form.salary_period',
      span: 1,
      content: t(`offers.form.${salary_period}`) || '-'
    }
  ];
};

export { offerPrincipalInfo, offerContentInfo };
