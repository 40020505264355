export const pickTagColor = (type) => {
  let color;
  switch (type) {
    case 'String':
      color = '#199D90';
      break;
    case 'Number':
      color = '#DB5461';
      break;
    case 'Date':
      color = '#6153CC';
      break;
    case 'Boolean':
      color = '#F18F01';
      break;
    default:
      color = '#000501';
      break;
  }
  return color;
};
