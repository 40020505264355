import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Typography, Card, Input, Button, notification } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/ErrorMessage';
import { ContentCustom, PageHeaderCustom } from '../../../../components';
import ListProfiles from './ListProfiles';

const CreateUpdateProfile = ({ purpose }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [profileTree, setProfileTree] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const getProfileTree = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/profiletrees/${id}`
      });
      setProfileTree(data || []);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    if (purpose === 'edit') await getProfileTree();
  }, []);

  const setTitle = (value) => {
    const newProfileTree = JSON.parse(JSON.stringify(profileTree));
    newProfileTree.title = value;
    setProfileTree(newProfileTree);
  };

  const setChildren = (value) => {
    const newProfileTree = JSON.parse(JSON.stringify(profileTree));
    newProfileTree.children = value;
    setProfileTree(newProfileTree);
  };

  const saveProfileTree = async () => {
    setIsSubmitting(true);
    try {
      if (purpose === 'edit') {
        await dispatchAPI('PATCH', {
          url: `/profiletrees/${id}`,
          body: profileTree
        });

        notification.success({
          message: 'Le profil a bien été mis à jour',
          key: 'network_feedback'
        });
      } else {
        await dispatchAPI('POST', {
          url: `/profiletrees`,
          body: profileTree
        });

        notification.success({
          message: 'Le profil a bien été créé',
          key: 'network_feedback'
        });
      }
      navigate(-1);
      setIsSubmitting(false);
    } catch (e) {
      setIsSubmitting(false);
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return (
    <>
      <PageHeaderCustom
        title={t(`trainings.profiletrees.form.title.${purpose}`)}
      />
      <ContentCustom>
        <Row>
          <Col span={24}>
            <Typography.Text>
              {t('trainings.profiletrees.form.name')}
            </Typography.Text>
          </Col>
          <Col span={24}>
            <Input
              value={profileTree.title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Card
              title={t('trainings.profiletrees.form.trainings')}
              bordered={false}
              className="form-card training-form-card"
            >
              <ListProfiles
                list={profileTree.children || []}
                setList={setChildren}
              />
            </Card>
          </Col>
        </Row>
        <Row justify="end" style={{ gap: 8 }}>
          <Button type="link" danger onClick={() => navigate(-1)}>
            {`${t('buttons.cancel')} `}
            <CloseOutlined />
          </Button>
          <Button type="add" onClick={saveProfileTree} loading={isSubmitting}>
            {`${t('buttons.save')} `}
            <CheckOutlined />
          </Button>
        </Row>
      </ContentCustom>
    </>
  );
};

CreateUpdateProfile.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateProfile;
