import React from 'react';
import { HomeOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Row } from 'antd';
import { ContentCustom, PageHeaderCustom } from '../../components';
import MenuCard from './MenuCard';

export const Home = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageHeaderCustom title={t('home.title')} />
      <ContentCustom>
        <Row gutter={[24, 24]}>
          <MenuCard
            icon={<HomeOutlined />}
            title="Brasserie"
            route="/"
            enabled={false}
          />
          <MenuCard
            icon={<HomeOutlined />}
            title="Restauration"
            route="/"
            enabled={false}
          />
          <MenuCard
            icon={<HomeOutlined />}
            title="Logistique"
            route="/"
            enabled={false}
          />
          <MenuCard
            icon={<HomeOutlined />}
            title="Exploitation"
            route="/"
            enabled={false}
          />
          <MenuCard
            icon={<HomeOutlined />}
            title="Commercial"
            route="/"
            enabled={false}
          />
          <MenuCard
            icon={<HomeOutlined />}
            title="Communication"
            route="/"
            enabled={false}
          />
          <MenuCard
            icon={<HomeOutlined />}
            title="Direction & Finances"
            route="/"
            enabled={false}
          />
          <MenuCard
            icon={<HomeOutlined />}
            title="Juridique"
            route="/"
            enabled={false}
          />
          <MenuCard
            icon={<HomeOutlined />}
            title="Ressources Humaines"
            route="/"
            enabled={false}
          />
        </Row>
      </ContentCustom>
    </>
  );
};
