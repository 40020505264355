import React from 'react';
import { Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';

const checkingDocuments = () => {
  const { t } = useTranslation();

  const documentsFields = [
    {
      name: ['documents', 'id'],
      noLabel: true,
      input: <Checkbox>{t('users.form.documents.id')}</Checkbox>,
      valuePropName: 'checked'
    },
    {
      name: ['documents', 'social_security_attestation'],
      noLabel: true,
      input: (
        <Checkbox>
          {t('users.form.documents.social_security_attestation')}
        </Checkbox>
      ),
      valuePropName: 'checked'
    },
    {
      name: ['documents', 'bank_details'],
      noLabel: true,
      input: <Checkbox>{t('users.form.documents.bank_details')}</Checkbox>,
      valuePropName: 'checked'
    },
    {
      name: ['documents', 'driver_license_B'],
      noLabel: true,
      input: <Checkbox>{t('users.form.documents.driver_license_B')}</Checkbox>,
      valuePropName: 'checked'
    },
    {
      name: ['documents', 'driver_license_C'],
      noLabel: true,
      input: <Checkbox>{t('users.form.documents.driver_license_C')}</Checkbox>,
      valuePropName: 'checked'
    },
    {
      name: ['documents', 'third_party_payment_card'],
      noLabel: true,
      input: (
        <Checkbox>
          {t('users.form.documents.third_party_payment_card')}
        </Checkbox>
      ),
      valuePropName: 'checked'
    },
    {
      name: ['documents', 'identity_picture'],
      noLabel: true,
      input: <Checkbox>{t('users.form.documents.identity_picture')}</Checkbox>,
      valuePropName: 'checked'
    },
    {
      name: ['documents', 'residence_permit'],
      noLabel: true,
      input: <Checkbox>{t('users.form.documents.residence_permit')}</Checkbox>,
      valuePropName: 'checked'
    },
    {
      name: ['documents', 'disabled_worker_document'],
      noLabel: true,
      input: (
        <Checkbox>
          {t('users.form.documents.disabled_worker_document')}
        </Checkbox>
      ),
      valuePropName: 'checked'
    }
  ];
  return {
    documentsFields
  };
};

export default checkingDocuments;
