import React from 'react';
import { Descriptions, Tag, Card, Typography } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getFormattedPhoneNumber } from '../../../../utils/phoneNumber';

const { Item } = Descriptions;
const { Title } = Typography;

const DetailsApplication = ({ application }) => {
  const { t } = useTranslation();
  return (
    <Card key="0" bordered={false} className="application-details-card">
      <Title level={3}>{t('applications.form.information')}</Title>
      <Descriptions column={2}>
        <Item span={2} label={t('applications.form.candidate.first_name')}>
          {application?.candidate?.first_name || '-'}
        </Item>
        <Item span={2} label={t('applications.form.candidate.last_name')}>
          {application?.candidate?.last_name || '-'}
        </Item>
        <Item span={2} label={t('applications.form.offer')}>
          <Tag>{application?.offer?.name || '-'}</Tag>
        </Item>
        <Item span={2} label={t('applications.form.premise')}>
          {application?.offer?.premise?.label || '-'}
        </Item>
        <Item span={2} label={t('applications.form.source_candidacy')}>
          {application?.source_candidacy
            ? t(`applications.form.${application?.source_candidacy}`)
            : '-'}
        </Item>
        <Item span={2} label={t('applications.form.candidate.email')}>
          {application?.candidate?.email ? (
            <a href={`mailto:${application.candidate.email}`}>
              {application.candidate.email}
            </a>
          ) : (
            '-'
          )}
        </Item>
        <Item span={2} label={t('applications.form.phone_number')}>
          {application?.candidate?.phone_number
            ? getFormattedPhoneNumber(
                application.candidate.phone_number.country_code,
                application.candidate.phone_number.number
              )
            : '-'}
        </Item>
        <Item span={2} label={t('applications.form.additionnal_information')}>
          {application?.additionnal_information || '-'}
        </Item>
      </Descriptions>
    </Card>
  );
};
DetailsApplication.propTypes = {
  application: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};
DetailsApplication.defaultProps = { application: {} };

export default DetailsApplication;
