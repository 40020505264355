import React from 'react';
import ResignationHeader from '../ResignationHeader';
import ConventionalRuptureHeader from '../ConventionalRuptureHeader';
import BreakOfTrialHeader from '../BreakOfTrialHeader/BreakOfTrialHeader';
import DismissalHeader from '../DismissalHeader/DismissalHeader';

const setHeader = ({
  exitType,
  activeStep,
  nextStep,
  setRestitutionType,
  restitutionType,
  exit,
  setIsModalOpen,
  setAcknowledgmentOfReceiptType,
  generateFile,
  isDocLoading,
  setAbsenceReasonType,
  updateStatus,
  t
}) => {
  switch (exitType) {
    case 'resignation':
      return (
        <ResignationHeader
          activeStep={activeStep}
          nextStep={nextStep}
          setIsModalOpen={setIsModalOpen}
          setRestitutionType={setRestitutionType}
          restitutionType={restitutionType}
          exit={exit}
          setAcknowledgmentOfReceiptType={setAcknowledgmentOfReceiptType}
          generateFile={generateFile}
          isDocLoading={isDocLoading}
          t={t}
          updateStatus={updateStatus}
        />
      );
    case 'conventional_rupture':
      return (
        <ConventionalRuptureHeader
          activeStep={activeStep}
          nextStep={nextStep}
          setIsModalOpen={setIsModalOpen}
          setRestitutionType={setRestitutionType}
          restitutionType={restitutionType}
          exit={exit}
          t={t}
          updateStatus={updateStatus}
        />
      );
    case 'break_of_trial_period':
      return (
        <BreakOfTrialHeader
          activeStep={activeStep}
          nextStep={nextStep}
          setIsModalOpen={setIsModalOpen}
          setRestitutionType={setRestitutionType}
          restitutionType={restitutionType}
          exit={exit}
          generateFile={generateFile}
          isDocLoading={isDocLoading}
          t={t}
          updateStatus={updateStatus}
        />
      );
    case 'dismissal':
      return (
        <DismissalHeader
          activeStep={activeStep}
          nextStep={nextStep}
          setIsModalOpen={setIsModalOpen}
          setRestitutionType={setRestitutionType}
          restitutionType={restitutionType}
          exit={exit}
          generateFile={generateFile}
          isDocLoading={isDocLoading}
          setAbsenceReasonType={setAbsenceReasonType}
          t={t}
          updateStatus={updateStatus}
        />
      );
    default:
      return null;
  }
};

export default setHeader;
