import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Modal, Form } from 'antd';
import { setModals } from './utils/setModals';
import { setTitle } from './utils/setTitle';

const Modals = ({
  isModalOpen,
  setIsModalOpen,
  setRefresh,
  refresh,
  activeStep,
  exit,
  nextStep,
  setRestitutionType,
  restitutionType,
  employee,
  setAcknowledgmentOfReceiptType,
  acknowledgmentOfReceiptType,
  absenceReasonType,
  setAbsenceReasonType,
  emailModel
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(true);

  const onClose = () => {
    form.resetFields();
    setIsModalOpen(false);
    setRestitutionType('');
    setAcknowledgmentOfReceiptType('');
    setAbsenceReasonType('');
    setIsLoading(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setRestitutionType('');
    setAcknowledgmentOfReceiptType('');
    setAbsenceReasonType('');
    setIsLoading(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setRestitutionType('');
    setAcknowledgmentOfReceiptType('');
    setAbsenceReasonType('');
    setIsLoading(true);
  };

  return (
    <Modal
      title={setTitle({
        activeStep,
        restitutionType,
        acknowledgmentOfReceiptType,
        absenceReasonType,
        t
      })}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      width="fit-content"
    >
      {setModals({
        activeStep,
        isModalOpen,
        setIsModalOpen,
        employee,
        nextStep,
        exit,
        absenceReasonType,
        setRefresh,
        refresh,
        setRestitutionType,
        restitutionType,
        setAcknowledgmentOfReceiptType,
        acknowledgmentOfReceiptType,
        emailModel,
        setIsLoading,
        isLoading,
        onClose
      })}
    </Modal>
  );
};

export default Modals;

Modals.propTypes = {
  isModalOpen: PropTypes.bool,
  setIsModalOpen: PropTypes.func,
  setRefresh: PropTypes.func,
  refresh: PropTypes.bool,
  activeStep: PropTypes.string.isRequired,
  nextStep: PropTypes.func,
  setRestitutionType: PropTypes.func,
  restitutionType: PropTypes.string,
  setAbsenceReasonType: PropTypes.func,
  absenceReasonType: PropTypes.string,
  setAcknowledgmentOfReceiptType: PropTypes.func,
  acknowledgmentOfReceiptType: PropTypes.string,
  exit: PropTypes.shape({
    motif: PropTypes.string,
    _id: PropTypes.string
  }),
  employee: PropTypes.shape({
    activeStep: PropTypes.string,
    _id: PropTypes.string
  }),
  emailModel: PropTypes.string
};

Modals.defaultProps = {
  isModalOpen: false,
  setIsModalOpen: null,
  setRefresh: null,
  refresh: false,
  nextStep: null,
  setRestitutionType: null,
  restitutionType: '',
  setAcknowledgmentOfReceiptType: null,
  acknowledgmentOfReceiptType: '',
  setAbsenceReasonType: null,
  absenceReasonType: '',
  employee: {},
  exit: {},
  emailModel: ''
};
