import React, { useState } from 'react';
import { Button, Col, Row, Tag } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { UserOutlined } from '@ant-design/icons';
import { routes, subRoutes } from '../../utils/constants/adminRoutes';

export const weekCellRender = (eventItem: {
  _id: string;
  date: Date;
  startTime?: string;
  endTime?: string;
  type: string;
  candidate: Array<{
    first_name: string;
    last_name: string;
  }>;
}) => {
  console.log(eventItem);
  return (
    <Link
      to={{
        pathname: `${routes.RECRUITMENTS}${subRoutes.RECRUITMENTS.INTERVIEWS}/show/${eventItem._id}`
      }}
      className="interview-event-calendar"
    >
      <Row>
        <Col>{moment(eventItem.startTime).format('HH:mm')}</Col>
        <Col>{`${eventItem.candidate[0]?.first_name} ${eventItem.candidate[0]?.last_name}`}</Col>
      </Row>
    </Link>
  );
};

export const monthCellRender = (value: moment.Moment, events: Array<any>) => {
  const date = value;
  const [cellClass, setCellClass] = useState<string>();

  const interviewsToDisplay = (events || []).filter(
    (event) =>
      moment(date).day() !== 0 &&
      moment(date).format('YYYY-MM-DD') >=
        moment(event.date).format('YYYY-MM-DD') &&
      moment(date).format('YYYY-MM-DD') <=
        moment(event.date).format('YYYY-MM-DD') &&
      moment(date).isoWeekday() !== 7 &&
      moment(date).isoWeekday() !== 6
  );
  console.log(interviewsToDisplay);

  const dateCellRenderOptions = () => {
    if (interviewsToDisplay.length >= 3 && cellClass !== 'custom-cell') {
      return (
        <Col style={{ minWidth: '100%' }}>
          <Row style={{ minWidth: '100%', marginBottom: '1px' }}>
            <li
              key={interviewsToDisplay[0]._id}
              style={{ width: '100%', display: 'block' }}
            >
              <Link
                to={{
                  pathname: `${routes.RECRUITMENTS}${subRoutes.RECRUITMENTS.INTERVIEWS}/show/${interviewsToDisplay[0]?._id}`
                }}
              >
                <Tag>
                  <Row
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Col>
                      {moment(interviewsToDisplay[0]?.date).format('LT')}
                    </Col>
                    <Col>{`${interviewsToDisplay[0]?.candidate[0]?.first_name} ${interviewsToDisplay[0]?.candidate[0]?.last_name}`}</Col>
                  </Row>
                </Tag>
              </Link>
            </li>
          </Row>
          <Row>
            <Col span={19}>
              <li
                key={interviewsToDisplay[1]._id}
                style={{ width: '100%', display: 'block' }}
              >
                <Link
                  to={{
                    pathname: `${routes.RECRUITMENTS}${subRoutes.RECRUITMENTS.INTERVIEWS}/show/${interviewsToDisplay[1]._id}`
                  }}
                >
                  <Tag>
                    <Row
                      style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Col>{`${interviewsToDisplay[0]?.candidate[0]?.first_name} ${interviewsToDisplay[1]?.candidate[0]?.last_name}`}</Col>
                    </Row>
                  </Tag>
                </Link>
              </li>
            </Col>
            <Col span={4}>
              <Button
                className="light-btn"
                onClick={() => setCellClass('custom-cell')}
              >
                ...
              </Button>
            </Col>
          </Row>
        </Col>
      );
    }
    return interviewsToDisplay.map((interview) => (
      <li key={interview._id}>
        <Link
          to={{
            pathname: `${routes.RECRUITMENTS}${subRoutes.RECRUITMENTS.INTERVIEWS}/show/${interview._id}`
          }}
        >
          <Tag>
            <Row
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between'
              }}
            >
              <Col>{moment(interview?.date).format('LT')}</Col>
              <Col>{`${interview?.candidate[0]?.first_name} ${interview?.candidate[0]?.last_name}`}</Col>
            </Row>
          </Tag>
        </Link>
      </li>
    ));
  };

  return (
    <div
      className={cellClass || 'custom-calendar-cell'}
      key={moment(date).format('DD-MM-YYYY')}
    >
      {interviewsToDisplay.length > 0 && (
        <Row className="consultations-counter">
          <UserOutlined />
          {interviewsToDisplay.length}
        </Row>
      )}
      {dateCellRenderOptions()}
    </div>
  );
};

export const annualCellRender = (value: moment.Moment, events: Array<any>) => {
  const month = moment(value).month();
  return (
    <div key={moment(value).format('DD-MM-YYY')}>
      {(events || [])
        .filter(
          (event) =>
            month >= moment(event.date).month() &&
            month <= moment(event.date).month()
        )
        .sort((a, b) => moment(b.date).day() - moment(a.date).day())
        .map((event) => (
          <li key={event._id}>
            <Link
              to={{
                pathname: `${routes.RECRUITMENTS}/in-progress/show/${event._id}`
              }}
            >
              {moment(event.date).format('DD-MM-YYYY')}
            </Link>
          </li>
        ))}
    </div>
  );
};
