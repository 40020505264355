import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button, Modal, Form } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import useFields from './modalFields';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/ErrorMessage';
import { useGenerateFormItem } from '../../../../utils/GenerateFormItem';

const IncidentModal = ({
  isModalOpen,
  setIsModalOpen,
  date,
  id,
  setRefreshData,
  refreshData
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [employees, setEmployees] = useState([]);
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const generateFormItem = useGenerateFormItem();
  const { fields } = useFields({ employees });

  const getEmployees = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/employees' });

      const options = [];
      data.map((employee) =>
        options.push({
          value: employee._id,
          label: `${employee.first_name} ${employee.last_name}`
        })
      );
      setEmployees(options);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getEmployees();
    })();
  }, []);

  const onClose = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onFinishForm = async (values) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/incidents/${id}`,
        body: values
      });
      setRefreshData(!refreshData);
      onClose();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  return (
    <Modal
      title={t('incidents.show.title') + date}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
    >
      <Form
        wrapperCol={{ span: 24 }}
        labelCol={{ span: 24 }}
        form={form}
        onFinish={onFinishForm}
      >
        {fields.map((field) => generateFormItem('incidents', field))}
        <Form.Item style={{ marginTop: 16 }}>
          <Button
            style={{ margin: '0 10px' }}
            type="link"
            danger
            onClick={onClose}
          >
            {`${t('buttons.cancel')} `}
            <CloseOutlined />
          </Button>
          <Button type="add" htmlType="submit">
            {`${t('buttons.save')} `}
            <CheckOutlined />
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default IncidentModal;

IncidentModal.propTypes = {
  isModalOpen: PropTypes.bool,
  setIsModalOpen: PropTypes.func,
  date: PropTypes.string,
  id: PropTypes.string,
  setRefreshData: PropTypes.func,
  refreshData: PropTypes.bool
};

IncidentModal.defaultProps = {
  isModalOpen: false,
  setIsModalOpen: null,
  date: null,
  id: null,
  setRefreshData: null,
  refreshData: false
};
