import React, { useCallback, useEffect, useState } from 'react';
import { DatePicker, Input, Select, TimePicker } from 'antd';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';

const { Option } = Select;
const { TextArea } = Input;

const useFields = () => {
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [employees, setEmployees] = useState([]);
  const [users, setUsers] = useState([]);
  const [candidates, setCandidates] = useState([]);
  const [interviewtitles, setInterviewtitles] = useState([]);
  const [premises, setPremises] = useState([]);
  const [frames, setFrames] = useState([]);

  const fields = [
    {
      name: ['header'],
      required: true,
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          loading={isFieldsLoading}
        >
          {(interviewtitles || [])
            .filter((title) => title.show)
            .map((title) => (
              <Option label={title.label} key={title._id} value={title._id}>
                {title.label}
              </Option>
            ))}
        </Select>
      )
    },
    {
      name: ['rh_employee'],
      required: true,
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          loading={isFieldsLoading}
        >
          {(users || []).map((user) => (
            <Option
              label={user.first_name + user.last_name}
              key={user._id}
              value={user._id}
            >
              {`${user.first_name} ${user.last_name}`}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['interlocutors'],
      required: true,
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          loading={isFieldsLoading}
          multiple
        >
          {(employees || []).map((user) => (
            <Option
              label={user.first_name + user.last_name}
              key={user._id}
              value={user._id}
            >
              {`${user.first_name} ${user.last_name}`}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['candidate'],
      required: true,
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          loading={isFieldsLoading}
        >
          {(candidates || []).map((candidate) => (
            <Option
              label={candidate.first_name + candidate.last_name}
              key={candidate._id}
              value={candidate._id}
            >
              {`${candidate.first_name} ${candidate.last_name}`}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['date'],
      input: <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
    },
    {
      name: ['time_start'],
      input: <TimePicker style={{ width: '100%' }} format="HH:mm" type="time" />
    },
    {
      name: ['time_end'],
      input: <TimePicker style={{ width: '100%' }} format="HH:mm" type="time" />
    },
    {
      name: ['premise'],
      required: true,
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          loading={isFieldsLoading}
        >
          {(premises || [])
            .filter((premise) => premise.show)
            .map((premise) => (
              <Option
                label={premise.label}
                key={premise._id}
                value={premise._id}
              >
                {premise.label}
              </Option>
            ))}
        </Select>
      )
    },
    {
      name: ['frame'],
      required: true,
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          loading={isFieldsLoading}
        >
          {(frames || [])
            .filter((frame) => frame.show)
            .map((frame) => (
              <Option label={frame.title} key={frame._id} value={frame._id}>
                {frame.title}
              </Option>
            ))}
        </Select>
      )
    },
    {
      name: ['comment'],
      input: <TextArea />
    }
  ];

  const getEmployees = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/employees' });
      setEmployees(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getUsers = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/users' });
      setUsers(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getInterviewtitles = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/interviewtitles' });
      setInterviewtitles(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getPremises = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/premise' });
      setPremises(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getFrames = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/interviewframes' });
      setFrames(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getCandidates = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/candidates' });
      setCandidates(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEmployees();
    await getInterviewtitles();
    await getPremises();
    await getFrames();
    await getCandidates();
    await getUsers();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    fields,
    isFieldsLoading
  };
};

export default useFields;
