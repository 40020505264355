import React, { useState, useEffect } from 'react';
import { DatePicker, Select, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useErrorMessage } from '../../../../utils/ErrorMessage';
import { useAuthContext } from '../../../../contexts/AuthContext';

const { Option } = Select;

const concernedPosition = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const [positionInformationsEnums, setPositionInformationsEnums] = useState(
    []
  );
  const [premises, setPremises] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [positions, setPositions] = useState([]);
  const [position, setPosition] = useState([]);
  const [contractType, setContractType] = useState('');
  const [
    isConcernedPositionFieldsLoading,
    setIsConcernedPositionFieldsLoading
  ] = useState(true);

  const getPositionInformationsEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'employees/enums/position-informations'
      });
      setPositionInformationsEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getPremises = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/premise'
      });
      setPremises(data);
    } catch (e) {
      if (e.response) message(e.response.data);
    }
  };

  const getEmployees = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/employees'
      });
      setEmployees(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getPosition = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/positions'
      });
      setPositions(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const handlePositionChange = (value) => {
    const positionSelectedIndex = positions.findIndex((e) => e._id === value);
    setPosition(positions[positionSelectedIndex]);
  };

  useEffect(() => {
    (async () => {
      setIsConcernedPositionFieldsLoading(true);
      await getPositionInformationsEnums();
      await getPremises();
      await getEmployees();
      await getPosition();
      setIsConcernedPositionFieldsLoading(false);
    })();
  }, []);

  const concernedPositionFields = [
    {
      label: 'position',
      name: ['position'],
      rules: [{ required: true }],
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          onChange={handlePositionChange}
        >
          {(positions || [])
            .filter((p) => p.show)
            .map((p) => (
              <Option label={p.label} key={p._id}>
                {p.label}
              </Option>
            ))}
        </Select>
      )
    },
    {
      label: 'category',
      name: ['category'],
      rules: [{ required: true }],
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
        >
          {(positionInformationsEnums.categories || []).map((category) => (
            <Option label={t(`enums.categories.${category}`)} key={category}>
              {t(`enums.categories.${category}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'level',
      name: ['level'],
      rules: [{ required: true }],
      input: (
        <Select>
          {(positionInformationsEnums.levels || []).map((level) => (
            <Option key={level}>{level}</Option>
          ))}
        </Select>
      )
    },
    {
      label: 'grade',
      name: ['grade'],
      rules: [{ required: true }],
      input: (
        <Select>
          {(positionInformationsEnums.grades || []).map((grade) => (
            <Option key={grade}>{grade}</Option>
          ))}
        </Select>
      )
    },

    {
      label: 'contract_type',
      name: ['contract_type'],
      rules: [{ required: true }],
      input: (
        <Select onChange={(value) => setContractType(value)}>
          {(positionInformationsEnums.contractTypes || []).map(
            (contract_type) => (
              <Option key={contract_type}>
                {t(`enums.contractTypes.${contract_type}`)}
              </Option>
            )
          )}
        </Select>
      )
    },
    {
      label: 'working_time',
      name: ['working_time'],
      rules: [{ required: true }],
      input: <Input type="number" style={{ width: '100%' }} addonAfter="h" />
    },
    {
      label: 'start_date',
      name: ['start_date'],
      rules: [{ required: true }],
      input: <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
    },
    {
      label: 'end_date',
      name: ['end_date'],
      rules:
        contractType === 'fixed-term-contract' ? [{ required: true }] : null,
      input: <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
    },
    {
      label: 'premise',
      name: ['premise'],
      rules: [{ required: true }],
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
        >
          {(premises || [])
            .filter((premise) => premise.show)
            .map((premise) => (
              <Option label={premise.label} key={premise._id}>
                {premise.label}
              </Option>
            ))}
        </Select>
      )
    },
    {
      label: 'gross_monthly_salary',
      name: ['gross_monthly_salary'],
      rules: [{ required: true }],
      input: <Input type="number" style={{ width: '100%' }} addonAfter="€" />
    },
    {
      label: 'registration_number',
      name: ['registration_number'],
      rules: [{ required: true }]
    },
    {
      label: 'gross_monthly_salary_with_charges',
      name: ['gross_monthly_salary_with_charges'],
      rules: [{ required: true }],
      input: <Input type="number" style={{ width: '100%' }} addonAfter="€" />
    },
    {
      label: 'recommended_by',
      name: ['recommended_by'],
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
        >
          {(employees || []).map((employee) => (
            <Option
              label={employee.first_name + employee.last_name}
              value={employee._id}
              key={employee._id}
            >
              {employee.last_name} {employee.first_name}
            </Option>
          ))}
        </Select>
      )
    }
  ];
  return {
    concernedPositionFields,
    isConcernedPositionFieldsLoading,
    position
  };
};

export default concernedPosition;
