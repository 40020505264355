import React, { useEffect, useState } from 'react';
import { Button, Tooltip, Popconfirm, message as antdMessage } from 'antd';
import {
  EditOutlined,
  FileDoneOutlined,
  FileAddOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/ErrorMessage';
import { useApplicationsContext } from '../../../../contexts/ApplicationsContext';
import { permissionVerification } from '../../../../utils/permissionVerification';

const EditApplication = () => {
  const { dispatchAPI, userPermissions } = useAuthContext();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { message: errorMessage } = useErrorMessage();
  const { application, setVisible, fetchApplicationById } =
    useApplicationsContext();
  const [permit, setPermit] = useState(false);

  useEffect(() => {
    const permission = permissionVerification(
      userPermissions,
      '/recruitments/in-progress/modification'
    );
    setPermit(permission);
  }, [userPermissions]);

  const createEntrie = async () => {
    const body = {
      ...application.candidate,
      premise: application.offer.premise,
      position: application.offer.position
    };
    try {
      const { data } = await dispatchAPI('POST', {
        url: '/entries',
        body
      });

      if (data) {
        navigate(`../../entries/edit/${data._id}`);
      }
    } catch (e) {
      errorMessage(e);
    }
  };

  const archiveCandidate = async () => {
    try {
      await dispatchAPI('PATCH', {
        url: `/candidates/${application.candidate._id}`,
        body: { is_archived: true }
      });
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  const validateApplication = async () => {
    try {
      await dispatchAPI('GET', {
        url: `/applications/${application._id}/validate`
      });
      fetchApplicationById(application._id);
      antdMessage.success(t('applications.show.is_validate_application'));
      createEntrie();
      archiveCandidate();
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  const toggleCandidateToInterestingProfiles = async (interest) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/candidates/${application.candidate._id}/`,
        body: {
          interesting: interest
        }
      });
      fetchApplicationById(application._id);
      antdMessage.success(t('applications.show.interesting_profile.save'));
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  return (
    <>
      <Button
        type="primary"
        icon={<FileAddOutlined />}
        style={{ marginRight: 10 }}
        onClick={() =>
          toggleCandidateToInterestingProfiles(
            !application?.candidate?.interesting
          )
        }
        disabled={!permit}
      >
        {application?.candidate?.interesting
          ? t('applications.show.interesting_profile.remove')
          : t('applications.show.interesting_profile.add')}
      </Button>
      {application?.status?._id !== '631334dc4f80f5d3ad9566c3' ? (
        <Tooltip placement="left" title={t('applications.show.tooltip')}>
          <Popconfirm
            placement="top"
            title={t('applications.show.pop_confirm.title')}
            onConfirm={validateApplication}
            okText={t('applications.show.pop_confirm.ok')}
            cancelText={t('applications.show.pop_confirm.cancel')}
          >
            {permit && (
              <Button
                type="primary"
                icon={<FileDoneOutlined />}
                style={{ marginRight: 10 }}
              >
                {t('applications.show.validate_application')}
              </Button>
            )}
          </Popconfirm>
        </Tooltip>
      ) : null}
      {permit && (
        <Button
          type="primary"
          onClick={() => {
            setVisible(true);
          }}
        >
          <EditOutlined />
        </Button>
      )}
    </>
  );
};

export default EditApplication;
