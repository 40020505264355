import { createGlobalStyle } from 'styled-components';
import '../SCSS/user/index.scss';

export const UserTheme = createGlobalStyle`
  body {
    --clientColor: #fff;
    --primaryColor: #090909;
    --primaryColor01: rgba(9, 9, 9, 0.1);
    --primaryColor005: rgba(9, 9, 9, 0.05);
    --addColor: #5FCC29;
    --itemHoverColor: var(--primaryColor);
    --componentBackground: #fff;
    --secondaryHover: #f0f0f0;
    --subMenuBackground: var(--componentBackground);
    --menuDropdownBackground: var(--componentBackground);
    --pickerDropdown: var(--componentBackground);
    --primaryHover:  #BC9D31;
    --secondaryColor: #BC9D31;
    --disabledColor: #bfbfbf;
    --bodyBackground: #FCFCFC;
    --textColor: var(--primaryColor);
    --textColorSecondary: var(--clientColor);
    --textColor85: rgba(9, 9, 9, 0.85);
    --textColor60: rgba(9, 9, 9, 0.6);
    --itemActiveBackground: rgba(188, 157, 49, 0.1);
    --itemHoverBackground: #f0f0f0;
    --borderColor: #d9d9d9;
    --contrastBackground: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #090909;
    --opacitySVG: 1;
    --errorColor: #DA282A;
    --mediumGray: #7d7d7d;
    --primaryColorA04
    --primaryColorA07
    --primaryContrast
    --tabsGray
    --textColorInvert
    --textColorHover
    --textColorA015
    --textColorA075
    --borderColorA06
    --disabledColorA04
    --disabledColorA06
    --itemActiveColor
    --logo
    --logoSmall
    --error100
    --errorA08
  }
`;
