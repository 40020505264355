export const manageDimensionSector = (editor) => {
  // REMOVE DIMENSION SECTOR FROM STYLE MANAGER FOR HEADER AND FOOTER BLOCKS
  let dimensionsPropertiesRemoved = false;
  const styleManager = editor.StyleManager;

  const dimensionSector = styleManager.getSector('dimension');
  const dimensionSectorProperties = dimensionSector.getProperties();
  const propertiesNames = ['width', 'height', 'max-width', 'min-height'];

  const dimensionSectorPropertiesFiltered = dimensionSectorProperties.filter(
    (property) => propertiesNames.includes(property.id)
  );

  editor.on('component:selected', (component) => {
    if (
      component.get('tagName') === 'header' ||
      component.get('tagName') === 'footer'
    ) {
      for (let i = 0; i < propertiesNames.length; i += 1) {
        styleManager.removeProperty('dimension', propertiesNames[i]);
      }
      dimensionsPropertiesRemoved = true;
    } else if (dimensionsPropertiesRemoved) {
      for (let i = 0; i < dimensionSectorPropertiesFiltered.length; i += 1) {
        styleManager.addProperty(
          'dimension',
          dimensionSectorPropertiesFiltered[i]
        );
      }
      dimensionsPropertiesRemoved = false;
    }
  });
};
