import React from 'react';
import { Col, Form, Input, Select, Checkbox, Card } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ContentCustom } from '../../../components';

const { Option } = Select;

const FormInfo = ({ status, configurations, setPickedConfiguration }) => {
  const { t } = useTranslation();

  return (
    <ContentCustom>
      <Col span={24}>
        <Card title={t('template-documents.form.formInfoTitle')}>
          <Form.Item label={t(`template-documents.form.title`)} name="title">
            <Input />
          </Form.Item>

          <Form.Item label={t(`template-documents.form.status`)} name="status">
            <Select>
              {status.map((item) => (
                <Option key={item} value={item}>
                  {t(`template-documents.form.${item}`)}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label={t(`template-documents.form.useCase`)}
            name="configuration"
          >
            <Select
              onChange={(value) =>
                setPickedConfiguration(
                  configurations.find((config) => config.use_case === value)
                )
              }
            >
              {configurations.map((item) => (
                <Option key={item.use_case} value={item.use_case}>
                  {t(`template-documents.form.${item.use_case}`)}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label={t(`template-documents.form.defaultTemplate`)}
            name="default_template"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
        </Card>
      </Col>
    </ContentCustom>
  );
};

FormInfo.propTypes = {
  status: PropTypes.arrayOf(PropTypes.string).isRequired,
  configurations: PropTypes.arrayOf(
    PropTypes.shape({
      use_case: PropTypes.string,
      collection: PropTypes.string
    })
  ).isRequired,
  setPickedConfiguration: PropTypes.func
};

FormInfo.defaultProps = {
  setPickedConfiguration: null
};

export default FormInfo;
