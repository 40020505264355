import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import GroupModal from './GroupModal';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import { TreeGroupOrga } from './TreeGroupOrga';
import { permissionVerification } from '../../../utils/permissionVerification';

export const ListGroupOrganisation = () => {
  const { dispatchAPI, userPermissions } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();

  const [openModal, setOpenModal] = useState(false);
  const [purpose, setPurpose] = useState('create');
  const [groups, setGroups] = useState();
  const [group, setGroup] = useState();
  const [users, setUsers] = useState([]);
  const [permit, setPermit] = useState(false);

  useEffect(() => {
    const permission = permissionVerification(
      userPermissions,
      '/organisation/modification'
    );
    setPermit(permission);
  }, [userPermissions]);

  const closeOverlay = () => {
    setOpenModal(false);
  };

  const getGroupUsers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/users'
      });
      setUsers(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    getGroupUsers();
  }, []);

  const getGroups = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/permissiongroups'
      });
      setGroups(data);
    } catch (e) {
      message(e);
    }
  };

  const getGroup = async (idFormOverlay) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/permissiongroups/${idFormOverlay}`
      });
      setGroup(data);
    } catch (e) {
      message(e);
    }
  };

  const deleteGroup = async (id) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/permissiongroups/${id}`
      });
      getGroups();
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    getGroups();
  }, []);

  return (
    <>
      <Button
        type="add"
        onClick={() => {
          setPurpose('create');
          setOpenModal(true);
        }}
        disabled={!permit}
      >
        {t('organisation.permissionGroup.create')}
      </Button>

      {openModal && (
        <GroupModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          purpose={purpose}
          closeOverlay={closeOverlay}
          group={group}
          setGroup={setGroup}
          getGroups={getGroups}
        />
      )}
      <TreeGroupOrga
        users={users}
        groups={groups}
        setOpenModal={setOpenModal}
        setPurpose={setPurpose}
        getGroup={getGroup}
        deleteGroup={deleteGroup}
      />
    </>
  );
};
