export const emailModel = ({ activeStep, absenceReasonType }) => {
  switch (activeStep) {
    case 'ACKNOWLEDGMENT_OF_RECEIPT':
      return 'acknowledgment_of_receipt_model';
    case 'RESTITUTION_REQUEST':
      return 'restitution_model';
    case 'INTERVIEW_INVITATION':
      return 'convocation_model';
    case 'NOTIFICATION_OF_DISMISSAL':
      return 'notification_of_dismissal_model';
    case 'ABSENCE_REASON':
      return absenceReasonType === 'justification'
        ? 'justification_model'
        : 'formal_notice_model';
    case 'EMPLOYER_BREAKUP_NOTIFICATION':
      return 'breakup_notification_model';
    case 'BREAKUP_NOTIFICATION':
      return 'breakup_notification_model';
    default:
      return '';
  }
};
