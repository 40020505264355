import React, { useCallback, useEffect, useState } from 'react';
import { Select } from 'antd';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';

const { Option } = Select;

const useFields = () => {
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [employees, setEmployees] = useState([]);
  const [profileTrees, setProfileTrees] = useState([]);

  const fields = [
    {
      name: ['employee'],
      required: true,
      input: (
        <Select loading={isFieldsLoading}>
          {(employees || []).map((item) => (
            <Option key={item._id} value={item._id}>
              {item.first_name} {item.last_name}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['profiletree'],
      required: true,
      input: (
        <Select loading={isFieldsLoading}>
          {(profileTrees || []).map((item) => (
            <Option key={item._id} value={item._id}>
              {item.title}
            </Option>
          ))}
        </Select>
      )
    }
  ];

  const getEmployees = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/employees' });
      setEmployees(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getProfileTrees = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/profiletree' });
      setProfileTrees(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEmployees();
    await getProfileTrees();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    fields,
    isFieldsLoading
  };
};

export default useFields;
