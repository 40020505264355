export const getEmployees = async ({ dispatchAPI, message }) => {
  try {
    const { data } = await dispatchAPI('GET', {
      url: 'employees'
    });
    return data;
  } catch (error) {
    message(error);
  }

  return null;
};
