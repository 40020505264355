import React, { useState, useEffect } from 'react';
import { DatePicker, TimePicker, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import { useAuthContext } from '../../../contexts/AuthContext';

const { Option } = Select;
const { TextArea } = Input;

const useFields = () => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const [premises, setPremises] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [users, setUsers] = useState([]);
  const [enums, setEnums] = useState([]);

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/incidents/enums'
      });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getUsers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/users'
      });
      setUsers(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getEmployees = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/employees'
      });
      setEmployees(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getPremises = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/premise'
      });
      setPremises(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getPremises();
      await getEmployees();
      await getUsers();
      await getEnums();
    })();
  }, []);

  const fields = [
    {
      name: ['type'],
      input: (
        <Input
          defaultValue={t('incidents.form.INTERN')}
          value="INTERN"
          disabled
        />
      )
    },
    {
      name: ['hr_intervenant'],
      rules: [{ required: true }],
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
        >
          {(users || []).map((user) => (
            <Option
              label={user.first_name + user.last_name}
              value={user._id}
              key={user._id}
            >
              {user.last_name} {user.first_name}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['author'],
      rules: [{ required: true }],
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
        >
          {(employees || []).map((employee) => (
            <Option
              label={employee.first_name + employee.last_name}
              value={employee._id}
              key={employee._id}
            >
              {employee.last_name} {employee.first_name}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['date'],
      rules: [{ required: true }],
      input: <DatePicker style={{ width: '100%' }} />
    },
    {
      name: ['hour'],
      rules: [{ required: true }],
      input: <TimePicker style={{ width: '100%' }} format="HH:mm" type="time" />
    },
    {
      name: ['premise'],
      required: true,
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
        >
          {(premises || [])
            .filter((premise) => premise.show)
            .map((premise) => (
              <Option label={premise.label} key={premise._id}>
                {premise.label}
              </Option>
            ))}
        </Select>
      )
    },
    {
      name: ['status'],
      rules: [{ required: true }],
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
        >
          {(enums?.STATUS || []).map((status) => (
            <Option label={status} key={status}>
              {t(`incidents.form.${status}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['description'],
      input: <TextArea rows={4} />
    }
  ];

  return {
    fields
  };
};

export default useFields;
