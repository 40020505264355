import moment from 'moment';
import conventionalInfo from './fields/conventionalInfo';
import resignationInfo from './fields/resignationInfo';
import breakOfTrialInfo from './fields/breakOfTrialInfo';
import dismissalInfo from './fields/dismissalInfo';

const employeePrincipalInfo = (data) => {
  const { first_name, last_name, premise, position, level, grade, start_date } =
    data;

  return [
    {
      label: 'employees.form.last_name',
      span: 1,
      content: last_name || '-'
    },
    {
      label: 'employees.form.first_name',
      span: 2,
      content: first_name || '-'
    },
    {
      label: 'employees.form.premise',
      span: 1,
      content: premise?.label || '-'
    },
    {
      label: 'employees.form.position',
      span: 2,
      content: position?.label || '-'
    },
    {
      label: 'employees.form.grade',
      span: 1,
      content: grade || '-'
    },
    {
      label: 'employees.form.level',
      span: 2,
      content: level || '-'
    },
    {
      label: 'employees.form.start_date',
      span: 3,
      content: (start_date && moment(start_date).format('DD/MM/YYYY')) || '-'
    }
  ];
};

const exitPrincipalInfo = (data, t) => {
  const { conventionalFields } = conventionalInfo(data);
  const { resignationFields } = resignationInfo(data);
  const { employerBreakOfTrialFields, employeeBreakOfTrialFields } =
    breakOfTrialInfo(data, t);
  const {
    realOrSeriousCauseFields,
    faultWithAbandonmentFields,
    faultWithoutAbandonmentFields
  } = dismissalInfo(data, t);
  const { motif, exit_date, break_initited_by, type_of_dismissal } = data;

  const setBreakOfTrialFields = () => {
    switch (break_initited_by) {
      case 'employer':
        return employerBreakOfTrialFields;
      case 'employee':
        return employeeBreakOfTrialFields;
      default:
        return null;
    }
  };

  const setDismissalFields = () => {
    switch (type_of_dismissal) {
      case 'real_or_serious_cause':
        return realOrSeriousCauseFields;
      case 'serious_fault_with_post_abandonment':
        return faultWithAbandonmentFields;
      case 'serious_fault_without_post_abandonment':
        return faultWithoutAbandonmentFields;
      default:
        return null;
    }
  };

  const setFields = () => {
    switch (motif) {
      case 'conventional_rupture':
        return conventionalFields;
      case 'resignation':
        return resignationFields;
      case 'break_of_trial_period':
        return setBreakOfTrialFields();
      case 'dismissal':
        return setDismissalFields();
      default:
        return null;
    }
  };

  const fieldsArray = setFields();
  const fieldsToAdd = Array.isArray(fieldsArray) ? fieldsArray : [];

  return [
    {
      label: 'exits.form.motif',
      span: 1,
      content: t(`exits.motif.${motif}`) || '-'
    },
    {
      label: 'exits.form.end_date_of_contract',
      span: 2,
      content: (exit_date && moment(exit_date).format('DD/MM/YYYY')) || '-'
    },

    ...fieldsToAdd
  ];
};

export { employeePrincipalInfo, exitPrincipalInfo };
