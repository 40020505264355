import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Col, Button } from 'antd';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/ErrorMessage';
import CompetenceRow from './CompetenceRow';

const ListProfiles = ({ list, setList }) => {
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [parcoursTrees, setParcoursTrees] = useState([]);

  const setTitle = (idLine, value) => {
    const newList = list.map((item, index) =>
      index === idLine ? { ...item, title: value } : item
    );
    setList(newList);
  };

  const mouvLineUp = (idLine) => {
    const finalIndex = idLine - 1;
    const elem = list[idLine];
    const firstList = list.filter((_, index) => index < idLine);
    const lastList = list.filter((_, index) => index > idLine);
    const newList = firstList.concat(lastList);
    newList.splice(finalIndex, 0, elem);
    setList(newList);
  };

  const mouvLineDown = (idLine) => {
    const finalIndex = idLine + 1;
    const elem = list[idLine];
    const firstList = list.filter((_, index) => index < idLine);
    const lastList = list.filter((_, index) => index > idLine);
    const newList = firstList.concat(lastList);
    newList.splice(finalIndex, 0, elem);
    setList(newList);
  };

  const deleteLine = (idLine) => {
    const newList = list.filter((item, index) => index !== idLine);
    setList(newList);
  };

  const addLine = () => {
    const newList = list;
    newList.push({
      title: 'Nouveau profil'
    });
    setList(newList);
  };
  const getParcoursTrees = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/trainingtrees/`
      });
      setParcoursTrees(data || []);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  // eslint-disable-next-line require-await
  const getSelectOptions = useCallback(async () => {
    getParcoursTrees();
  }, []);

  useEffect(() => {
    getSelectOptions();
  }, []);

  return (
    <>
      {list.map((item, index) => (
        <CompetenceRow
          key={item._id}
          list={list}
          index={index}
          item={item}
          setTitle={setTitle}
          mouvLineUp={mouvLineUp}
          mouvLineDown={mouvLineDown}
          deleteLine={deleteLine}
          parcoursTrees={parcoursTrees}
        />
      ))}
      <Col span={24}>
        <Button type="dashed" block onClick={addLine}>
          {t('trainings.button.add-training')}
        </Button>
      </Col>
    </>
  );
};

ListProfiles.propTypes = {
  list: PropTypes.arrayOf({ title: PropTypes.string.isRequired }).isRequired,
  setList: PropTypes.func.isRequired
};

export default ListProfiles;
