import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Button, Popconfirm, Skeleton, Row, Card, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { bonusStatus } from '../../../utils/constants/tagColors';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import listContent from './listContent';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../../components';
import { routes, subRoutes } from '../../../utils/constants/adminRoutes';
import { permissionVerification } from '../../../utils/permissionVerification';

const ShowBonus = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { dispatchAPI, userPermissions } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [bonus, setBonus] = useState(null);
  const [permit, setPermit] = useState(false);

  useEffect(() => {
    const permission = permissionVerification(
      userPermissions,
      '/employee-folder/bonus/modification'
    );
    setPermit(permission);
  }, [userPermissions]);

  const getBonus = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/bonus/${id}?populate=employee,employee.position premise`
      });
      setBonus(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getBonus();
    })();
  }, [getBonus]);

  const deleteBonus = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/bonus/${id}` });
      navigate(-1);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  return (
    <>
      <PageHeaderCustom
        title={
          <>
            {`${t('bonus.show.title')} - ${bonus?.employee?.first_name} 
            ${bonus?.employee?.last_name}`}
            <Tag color={bonusStatus[bonus?.status]}>
              {t(`bonus.tags.${bonus?.status}`)}
            </Tag>
          </>
        }
        extra={
          <>
            <Link
              to={{
                pathname: `${routes.EMPLOYEE_FOLDER}${subRoutes.EMPLOYEE_FOLDER.BONUS}/edit/${id}`
              }}
            >
              {permit && (
                <Button type="primary">
                  {`${t('buttons.edit')} `}
                  <EditOutlined />
                </Button>
              )}
            </Link>
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={deleteBonus}
              icon={<WarningOutlined />}
            >
              {permit && (
                <Button type="link" danger>
                  {`${t('buttons.delete')} `}
                  <DeleteOutlined />
                </Button>
              )}
            </Popconfirm>
          </>
        }
      />
      <ContentCustom>
        <Row>
          <Card title={t('bonus.show.main')} className="form-card">
            <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
              <DescriptionList data={listContent(bonus || {}, t)} translate />
            </Skeleton>
          </Card>
        </Row>
      </ContentCustom>
    </>
  );
};

export default ShowBonus;
