import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LinkOutlined } from '@ant-design/icons';
import { routes } from '../../../utils/constants/adminRoutes';

const listContent = (data) => {
  const { t } = useTranslation();
  const {
    employee,
    candidate,
    application,
    offer,
    how_known_candidate,
    why_recommanded
  } = data;

  return [
    {
      label: 'recommandations.form.employee',
      span: 2,
      content:
        (employee && `${employee.first_name} ${employee.last_name}`) || '-'
    },
    {
      label: 'recommandation.form.candidate',
      span: 2,
      content:
        (candidate && `${candidate.first_name} ${candidate.last_name}`) || '-'
    },
    {
      label: 'recommandations.form.application',
      span: 2,
      content:
        (application && (
          <Link
            to={{
              pathname: `${routes.APPLICATIONS}/in-progress/show/${application._id}`
            }}
          >
            <LinkOutlined /> {t('recommandations.show.application_link')}
          </Link>
        )) ||
        '-'
    },
    {
      label: 'recommandations.form.offer',
      span: 2,
      content: (offer && offer.name) || '-'
    },
    {
      label: 'recommandations.form.how_known_candidate',
      span: 3,
      content: how_known_candidate || '-'
    },
    {
      label: 'recommandations.form.why_recommanded',
      span: 3,
      content: why_recommanded || '-'
    }
  ];
};

export default listContent;
