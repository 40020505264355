import dayjs from 'dayjs';

const incidentList = (data) => {
  const { date, author, description, premise } = data;

  return [
    {
      label: 'incidents.show.day',
      span: 3,
      content: (date && dayjs(date).format('DD/MM/YYYY')) || '-'
    },
    {
      label: 'incidents.show.hour',
      span: 1,
      content: (date && dayjs(date).format('H[h]mm')) || '-'
    },
    {
      label: 'incidents.show.premise',
      span: 2,
      content: premise?.label || '-'
    },
    {
      label: 'incidents.show.author',
      span: 1,
      content: (author && `${author?.first_name} ${author?.last_name}`) || '-'
    },
    {
      label: 'incidents.show.description',
      span: 1,
      content: description || '-'
    }
  ];
};

export default incidentList;
