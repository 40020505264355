import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button, Row, Col } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useErrorMessage } from '../../utils/ErrorMessage';
import { ContentCustom } from '../../components/ContentCustom/ContentCustom';
import useFields from './fields';
import {
  formItemLayout,
  tailFormItemLayout
} from '../../utils/constants/formLayout';

const CreateUpdateFormWorkFlow = ({ workflow, updateWorkflow, nextStep }) => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { fields } = useFields();

  const onFinish = (values) => {
    updateWorkflow(values);
    nextStep();
  };

  const onFinishFailed = (errorInfo) => {
    message(errorInfo);
  };

  return (
    <ContentCustom>
      <Row>
        <Col span={24}>
          <Form
            {...formItemLayout}
            name="basic"
            initialValues={workflow}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            {fields.map((field) => (
              <Form.Item
                label={t(`workflows.form.${field.label || field.name}`)}
                name={field.name.toLowerCase()}
                rules={field.rules}
                key={field.name.toLowerCase()}
              >
                {field.input || <Input />}
              </Form.Item>
            ))}
            <Form.Item {...tailFormItemLayout}>
              <Button type="primary" block htmlType="submit">
                Etape suivante
                <ArrowRightOutlined />
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </ContentCustom>
  );
};

CreateUpdateFormWorkFlow.propTypes = {
  isFieldsLoading: PropTypes.bool.isRequired,
  workflow: PropTypes.shape({}).isRequired,
  updateWorkflow: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired
};

export default CreateUpdateFormWorkFlow;
