import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DatePicker, Typography, Select, Form, Checkbox, Row } from 'antd';
import { useAuthContext } from '../../../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../../../utils/ErrorMessage';

const { Option } = Select;

const restitutionValidContent = ({ employee }) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);

  const getUsers = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: 'users' });
      setUsers(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  const materialRestitutionValidFields = [];
  employee?.equipments?.map((equipment) =>
    materialRestitutionValidFields.push({
      noLabel: true,
      name: equipment._id.label,
      input: (
        <>
          <Row justify="space-between" align="middle">
            <Typography style={{ marginRight: '10px' }}>
              {equipment._id.label + t('exits.form.delivered_on')}
            </Typography>
            <Form.Item name={[equipment._id.label, 'given_on']}>
              <DatePicker format="DD/MM/YYYY" />
            </Form.Item>
          </Row>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography style={{ marginRight: '10px' }}>
              {t('exits.form.at')}
            </Typography>
            <Form.Item
              style={{ width: '100%' }}
              name={[equipment._id.label, 'given_to']}
            >
              <Select>
                {users.length &&
                  users.map((user) => (
                    <Option key={user._id} value={user._id}>
                      {user.first_name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </div>
        </>
      )
    })
  );

  const checkListRestitutionValidFields = [
    {
      name: ['check_list', 'invitation_email_send'],
      noLabel: true,
      input: (
        <Checkbox>{t('exits.form.check_list.invitation_email_send')}</Checkbox>
      ),
      valuePropName: 'checked'
    },
    {
      name: ['check_list', 'final_settlement_recovery'],
      noLabel: true,
      input: (
        <Checkbox>
          {t('exits.form.check_list.final_settlement_recovery')}
        </Checkbox>
      ),
      valuePropName: 'checked'
    },
    {
      name: ['check_list', 'access_combo_deleted'],
      noLabel: true,
      input: (
        <Checkbox>{t('exits.form.check_list.access_combo_deleted')}</Checkbox>
      ),
      valuePropName: 'checked'
    },
    {
      name: ['check_list', 'access_payfit_deleted'],
      noLabel: true,
      input: (
        <Checkbox>{t('exits.form.check_list.access_payfit_deleted')}</Checkbox>
      ),
      valuePropName: 'checked'
    }
  ];

  return {
    materialRestitutionValidFields,
    checkListRestitutionValidFields
  };
};

export default restitutionValidContent;
