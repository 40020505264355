import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton, Row, Card, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { ContentCustom, DescriptionList } from '../../../../../components';
import { employeePrincipalInfo, exitPrincipalInfo } from './listContent';
import DocumentsCards from './DocumentsCard';
import CheckListCard from './CheckListCard';
import ListEquipments from './ListEquipements';

const DetailsCards = ({
  employee,
  isLoading,
  exit,
  enums,
  setRefresh,
  refresh,
  stepType
}) => {
  const { t } = useTranslation();

  return (
    <ContentCustom>
      <Row gutter={[24, 0]} align="stretch">
        <Col xs={24} xxl={10}>
          <Card
            title={t('exits.show.employee_personal_info')}
            className="form-card"
          >
            <Row gutter={[0, 24]}>
              <Col xs={34} xxl={18}>
                <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
                  <DescriptionList
                    data={employeePrincipalInfo(employee || {}, t)}
                    translate
                  />
                </Skeleton>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={24} xxl={14}>
          <Card
            title={t('exits.show.exit_personal_info')}
            className="form-card"
          >
            <Row>
              <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
                <DescriptionList
                  data={exitPrincipalInfo(exit || {}, t)}
                  translate
                />
              </Skeleton>
            </Row>
          </Card>
        </Col>
        <Col xs={24} xxl={10}>
          <Card title={t('exits.show.documents')} className="form-card">
            <Row>
              <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
                <DocumentsCards
                  exit={exit}
                  setRefresh={setRefresh}
                  refresh={refresh}
                />
              </Skeleton>
            </Row>
          </Card>
        </Col>
        <Col xs={24} xxl={10}>
          <Card title={t('exits.show.check_list')} className="form-card">
            <Row>
              <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
                <CheckListCard enums={enums} exit={exit} stepType={stepType} />
              </Skeleton>
            </Row>
          </Card>
        </Col>
        <Col xs={24} xl={7}>
          <Card title={t('employees.show.equipments')} className="form-card">
            <Row>
              <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
                <ListEquipments employee={employee} />
              </Skeleton>
            </Row>
          </Card>
        </Col>
      </Row>
    </ContentCustom>
  );
};

export default DetailsCards;

DetailsCards.propTypes = {
  employee: PropTypes.shape({}),
  enums: PropTypes.shape(PropTypes.string),
  exit: PropTypes.shape({
    conventional_rupture_date: PropTypes.string,
    created_at: PropTypes.string,
    employee: PropTypes.string,
    end_of_withdrawal_periods: PropTypes.string,
    exit_date: PropTypes.string,
    motif: PropTypes.string,
    status: PropTypes.string,
    updated_at: PropTypes.string,
    __v: PropTypes.number,
    _id: PropTypes.string
  }),
  isLoading: PropTypes.bool.isRequired,
  setRefresh: PropTypes.func.isRequired,
  refresh: PropTypes.bool.isRequired,
  stepType: PropTypes.string.isRequired
};

DetailsCards.defaultProps = {
  employee: {},
  exit: {},
  enums: {}
};
