import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ListRecommendations from './ListRecommendations';
import CreateUpdateRecommandation from './CreateUpdateRecommendation';
import ShowRecommendation from './ShowRecommandation';

export const RecommendationRouter = () => (
  <Routes>
    <Route
      path="/create"
      element={<CreateUpdateRecommandation purpose="create" />}
    />
    <Route
      path="/edit/:id"
      element={<CreateUpdateRecommandation purpose="edit" />}
    />
    <Route path="/show/:id" element={<ShowRecommendation />} />
    <Route path="/" index element={<ListRecommendations />} />
  </Routes>
);
