import React from 'react';
import { Row, Col, Form } from 'antd';
import PropTypes from 'prop-types';
import CodeMirror from '@uiw/react-codemirror';
import { displayTagInMention } from '../../../utils';

const { Item } = Form;

const EmailConfirmation = ({
  stepInputs,
  confirmEmailFields,
  setConfirmEmailFields
}) => {
  const tagDrag = (event, ElmLabel, field) => {
    // eslint-disable-next-line no-param-reassign
    event.dataTransfer.dropEffect = 'move';
    const data = `@{${ElmLabel}|${field}}`;
    event.dataTransfer.setData('text', data);
  };

  const onChangeMention = (value, key) => {
    const newEmailFields = confirmEmailFields.map((field) => {
      if (field.key === key) return { key, value };
      return field;
    });
    setConfirmEmailFields(newEmailFields);
  };

  // console.log('confirmEmailFields', confirmEmailFields);

  return (
    <Col span={24}>
      {(confirmEmailFields || []).map((field) => (
        <Row key={field.key} style={{ marginBottom: 10 }}>
          <Col span={12} offset={6}>
            <Item label={field.key}>
              <CodeMirror
                style={{ width: '100%' }}
                value={field.value}
                onChange={(value) => onChangeMention(value, field.key)}
                key={`${field.key}`}
                options={{
                  mode: 'javascript',
                  theme: 'material',
                  lineNumbers: true
                }}
              >
                {displayTagInMention(stepInputs, true, tagDrag)}
              </CodeMirror>
            </Item>
          </Col>
        </Row>
      ))}
    </Col>
  );
};

EmailConfirmation.propTypes = {
  stepInputs: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  confirmEmailFields: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired
    })
  ).isRequired,
  setConfirmEmailFields: PropTypes.func.isRequired
};

export default EmailConfirmation;
