import React from 'react';
import OtherModals from '../OtherModals/OtherModals';
import ModalSendMails from '../ModalSendMails/ModalSendMails';
import mailSubject from './mailSubject';

const setAcknowledgmentOfReceiptContent = ({
  activeStep,
  isModalOpen,
  setIsModalOpen,
  employee,
  nextStep,
  exit,
  setRefresh,
  refresh,
  setRestitutionType,
  restitutionType,
  setAcknowledgmentOfReceiptType,
  acknowledgmentOfReceiptType,
  onClose,
  emailModel,
  setIsLoading,
  isLoading
}) => {
  switch (acknowledgmentOfReceiptType) {
    case 'resignation_letter':
      return (
        <OtherModals
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          motif={exit?.motif}
          setRefresh={setRefresh}
          refresh={refresh}
          activeStep={activeStep}
          exit={exit}
          nextStep={nextStep}
          setRestitutionType={setRestitutionType}
          restitutionType={restitutionType}
          setAcknowledgmentOfReceiptType={setAcknowledgmentOfReceiptType}
          acknowledgmentOfReceiptType={acknowledgmentOfReceiptType}
          employee={employee}
          onClose={onClose}
        />
      );
    case 'send_acknowledgment_of_receipt':
      return (
        <ModalSendMails
          emailModel={emailModel}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          recipientsList={[employee] || []}
          isAttachment
          initialSubject={mailSubject({ activeStep })}
          sendMailAction={nextStep}
          employee={employee}
          exit={exit}
          activeStep={activeStep}
          type="acknowledgment_of_receipt"
          setIsLoading={setIsLoading}
          isLoading={isLoading}
        />
      );
    default:
      return null;
  }
};

export const setModals = ({
  activeStep,
  isModalOpen,
  setIsModalOpen,
  employee,
  nextStep,
  exit,
  absenceReasonType,
  setRefresh,
  refresh,
  setRestitutionType,
  restitutionType,
  setAcknowledgmentOfReceiptType,
  acknowledgmentOfReceiptType,
  emailModel,
  setIsLoading,
  isLoading,
  onClose
}) => {
  switch (activeStep) {
    case 'RESTITUTION_REQUEST':
      return (
        <ModalSendMails
          emailModel={emailModel}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          recipientsList={[employee] || []}
          isAttachment={false}
          initialSubject={mailSubject({ activeStep })}
          sendMailAction={nextStep}
          employee={employee}
          exit={exit}
          type="restitution"
          activeStep={activeStep}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
        />
      );
    case 'BREAKUP_NOTIFICATION':
      return (
        <ModalSendMails
          emailModel={emailModel}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          recipientsList={[employee] || []}
          isAttachment={false}
          initialSubject={mailSubject({ activeStep })}
          sendMailAction={nextStep}
          employee={employee}
          exit={exit}
          type="break_of_trial_period"
          activeStep={activeStep}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
        />
      );
    case 'EMPLOYER_BREAKUP_NOTIFICATION':
      return (
        <ModalSendMails
          emailModel={emailModel}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          recipientsList={[employee] || []}
          isAttachment
          initialSubject={mailSubject({ activeStep })}
          sendMailAction={nextStep}
          employee={employee}
          exit={exit}
          type="breakup_notification"
          activeStep={activeStep}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
        />
      );
    case 'INTERVIEW_INVITATION':
      return (
        <ModalSendMails
          emailModel={emailModel}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          recipientsList={[employee] || []}
          isAttachment
          initialSubject={mailSubject({ activeStep })}
          sendMailAction={nextStep}
          employee={employee}
          exit={exit}
          type="interview_invitation"
          activeStep={activeStep}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
        />
      );
    case 'ACKNOWLEDGMENT_OF_RECEIPT':
      return setAcknowledgmentOfReceiptContent({
        activeStep,
        isModalOpen,
        setIsModalOpen,
        employee,
        nextStep,
        exit,
        setRefresh,
        refresh,
        setRestitutionType,
        restitutionType,
        setAcknowledgmentOfReceiptType,
        acknowledgmentOfReceiptType,
        emailModel,
        onClose,
        setIsLoading,
        isLoading
      });
    case 'ABSENCE_REASON':
      return (
        <ModalSendMails
          emailModel={emailModel}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          recipientsList={[employee] || []}
          isAttachment={false}
          initialSubject={mailSubject({ activeStep })}
          sendMailAction={nextStep}
          employee={employee}
          exit={exit}
          type="absence_reason"
          activeStep={activeStep}
          absenceReasonType={absenceReasonType}
          setRefresh={setRefresh}
          refresh={refresh}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
        />
      );
    case 'NOTIFICATION_OF_DISMISSAL':
      return (
        <ModalSendMails
          emailModel={emailModel}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          recipientsList={[employee] || []}
          isAttachment={false}
          initialSubject={mailSubject({ activeStep })}
          sendMailAction={nextStep}
          employee={employee}
          exit={exit}
          type="notification_of_dismissal"
          activeStep={activeStep}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
        />
      );
    default:
      return (
        <OtherModals
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          motif={exit?.motif}
          setRefresh={setRefresh}
          refresh={refresh}
          activeStep={activeStep}
          exit={exit}
          nextStep={nextStep}
          setRestitutionType={setRestitutionType}
          restitutionType={restitutionType}
          setAcknowledgmentOfReceiptType={setAcknowledgmentOfReceiptType}
          acknowledgmentOfReceiptType={acknowledgmentOfReceiptType}
          employee={employee}
          onClose={onClose}
        />
      );
  }
};
