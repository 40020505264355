import React from 'react';
import { Tag } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { routes, subRoutes } from '../../../utils/constants/adminRoutes';
import { bonusStatus } from '../../../utils/constants/tagColors';

const bonusList = (data, t) => {
  const { employee, status, share, date } = data;

  const renderAmount = () => {
    const salary = employee?.gross_monthly_salary || 0;
    return `${salary * (share / 100)} €`;
  };

  return [
    {
      label: 'bonus.form.employee',
      span: 1,
      content: employee ? (
        <Link
          to={`${routes.EMPLOYEE_FOLDER}${subRoutes.EMPLOYEE_FOLDER.EMPLOYEES}/show/${employee._id}`}
        >
          {`${employee.first_name} ${employee.last_name}`}
        </Link>
      ) : (
        '-'
      )
    },
    {
      label: 'bonus.form.premise',
      span: 1,
      content: employee?.premise?.label || '-'
    },
    {
      label: 'bonus.form.status',
      span: 1,
      content:
        (status && (
          <Tag color={bonusStatus[status]}>{t(`bonus.tags.${status}`)}</Tag>
        )) ||
        '-'
    },
    {
      label: 'bonus.form.position',
      span: 1,
      content: employee?.position?.label || '-'
    },
    {
      label: 'bonus.form.share',
      span: 1,
      content: (share && `${share} % salaire`) || '-'
    },
    {
      label: 'bonus.form.amount',
      span: 1,
      content: (employee && share && renderAmount()) || '-'
    },
    {
      label: 'bonus.form.date',
      span: 1,
      content: (date && moment(date).format('MM-YYYY')) || '-'
    }
  ];
};

export default bonusList;
