import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const SetEmailTemplatesOptions = ({ setEmail, emailTemplates, emailModel }) => {
  const { t } = useTranslation();
  const [selectedModelEmail, setSelectedModelEmail] = useState();

  const selectEmailModel = () => {
    const email = emailTemplates.filter((el) => el.type === emailModel)[0];
    setEmail(email);
    setSelectedModelEmail(email._id);
  };

  useEffect(() => {
    selectEmailModel();
  }, [emailModel]);

  return (
    <Select
      placeholder={t('email.modal.choose_model')}
      value={selectedModelEmail}
      style={{ width: '100%' }}
    >
      {(emailTemplates || []).map((template) => (
        <Option value={template._id} key={template._id}>
          {template.default ? (
            <span>{template.name + t('email.modal.default')}</span>
          ) : (
            <span>{template.name}</span>
          )}
        </Option>
      ))}
    </Select>
  );
};

export default SetEmailTemplatesOptions;

SetEmailTemplatesOptions.propTypes = {
  setEmail: PropTypes.func.isRequired,
  emailTemplates: PropTypes.arrayOf({}).isRequired,
  emailModel: PropTypes.string.isRequired
};
