import React, { useState, useEffect } from 'react';
import { DatePicker, Select, Input } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import { useAuthContext } from '../../../contexts/AuthContext';

const { Option } = Select;

const exitInformations = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const { message } = useErrorMessage();

  const [premises, setPremises] = useState([]);
  const [exitEnums, setExitEnums] = useState([]);

  const [resignation, setResignation] = useState([]);

  const [typeOfDismissal, setTypeOfDismissal] = useState([]);
  const [inititedOfBreakOfTrial, setInititedOfBreakOfTrial] = useState([]);
  const [isExitInformationsFieldsLoading, setIsExitInformationsFieldsLoading] =
    useState(true);
  const [noticePeriod, setNoticePeriod] = useState(null);
  const [resignationLetterDate, setResignationLetterDate] = useState(null);
  const [endDateOfContract, setEndDateOfContract] = useState(null);

  const handleChangeNoticePeriodNumber = (e) => {
    setNoticePeriod(e.target.value);
  };

  const handleChangeResignationLetterDate = (date, dateString) => {
    setResignationLetterDate(dateString);
  };

  const getEndDateOfContract = () => {
    if (resignationLetterDate && noticePeriod) {
      const momentDate = moment(resignationLetterDate, 'DD/MM/YYYY');
      setEndDateOfContract(
        moment(moment(momentDate).add(Number(noticePeriod), 'days').toDate())
      );
    }
    return null;
  };

  useEffect(() => {
    getEndDateOfContract();
  }, [noticePeriod, resignationLetterDate]);

  const getExitEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'exits/enums'
      });
      setExitEnums(data);
    } catch (error) {
      if (error.response) message(error.response.data);
    }
  };
  const getPremises = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/premise'
      });
      setPremises(data);
    } catch (e) {
      if (e.response) message(e.response.data);
    }
  };

  useEffect(() => {
    (async () => {
      setIsExitInformationsFieldsLoading(true);
      await getExitEnums();
      await getPremises();
      setIsExitInformationsFieldsLoading(false);
    })();
  }, []);

  const handleInititedOfBreakOfTrial = (value) => {
    switch (value) {
      case 'employer':
        setInititedOfBreakOfTrial([
          {
            label: 'period_of_notice',
            name: ['period_of_notice']
          }
        ]);
        break;
      case 'employee':
        setInititedOfBreakOfTrial([
          {
            label: 'receipt_of_termination_notification',
            name: 'receipt_of_termination_notification',
            input: <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
          },
          {
            label: 'period_of_notice',
            name: ['period_of_notice']
          }
        ]);
        break;
      default:
        break;
    }
  };

  const handleTypeOfDismissal = (value) => {
    switch (value) {
      case 'real_or_serious_cause':
        setTypeOfDismissal([
          {
            label: 'preliminary_interview_invitation_date',
            name: 'preliminary_interview_invitation_date',
            input: <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
          },
          {
            label: 'preliminary_interview_date',
            name: 'preliminary_interview_date',
            input: <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
          },
          {
            label: 'preliminary_interview_location',
            name: 'preliminary_interview_location',
            input: (
              <Select>
                {(premises || []).map((premise) => (
                  <Option key={premise._id} value={premise._id}>
                    {premise?.label}
                  </Option>
                ))}
              </Select>
            )
          },
          {
            label: 'dismissal_notification_date',
            name: 'dismissal_notification_date',
            input: <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
          }
        ]);
        break;
      case 'serious_fault_with_post_abandonment':
        setTypeOfDismissal([
          {
            label: 'date_of_first_request_absence_justification',
            name: 'date_of_first_request_absence_justification',
            input: <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
          },
          {
            label: 'formal_notice_of_absence_justification',
            name: 'formal_notice_of_absence_justification',
            input: <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
          },
          {
            label: 'preliminary_interview_invitation_date',
            name: 'preliminary_interview_invitation_date',
            input: <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
          },
          {
            label: 'preliminary_interview_date',
            name: 'preliminary_interview_date',
            input: <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
          },
          {
            label: 'preliminary_interview_location',
            name: 'preliminary_interview_location',
            input: (
              <Select>
                {(premises || []).map((premise) => (
                  <Option key={premise._id} value={premise._id}>
                    {premise?.label}
                  </Option>
                ))}
              </Select>
            )
          },
          {
            label: 'dismissal_notification_date',
            name: 'dismissal_notification_date',
            input: <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
          }
        ]);
        break;
      case 'serious_fault_without_post_abandonment':
        setTypeOfDismissal([
          {
            label: 'preliminary_interview_invitation_date',
            name: 'preliminary_interview_invitation_date',
            input: <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
          },
          {
            label: 'preliminary_interview_date',
            name: 'preliminary_interview_date',
            input: <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
          },
          {
            label: 'preliminary_interview_location',
            name: 'preliminary_interview_location',
            input: (
              <Select>
                {(premises || []).map((premise) => (
                  <Option key={premise._id} value={premise._id}>
                    {premise?.label}
                  </Option>
                ))}
              </Select>
            )
          },
          {
            label: 'dismissal_notification_date',
            name: 'dismissal_notification_date',
            input: <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
          }
        ]);
        break;
      default:
        break;
    }
  };

  const handleChangeMotif = (value) => {
    setTypeOfDismissal([]);
    switch (value) {
      case 'resignation':
        setResignation([
          {
            label: 'end_date_of_contract',
            name: 'exit_date',
            input: <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
          },
          {
            label: 'date_of_resignation_letter',
            name: ['date_of_resignation_letter'],
            input: (
              <DatePicker
                style={{ width: '100%' }}
                format="DD/MM/YYYY"
                onChange={handleChangeResignationLetterDate}
              />
            )
          },
          {
            label: 'notice_period',
            name: ['notice_period'],
            input: (
              <Input
                style={{ width: '100%' }}
                onChange={handleChangeNoticePeriodNumber}
              />
            )
          }
        ]);
        break;
      case 'dismissal':
        setResignation([
          {
            label: 'end_date_of_contract',
            name: 'exit_date',
            input: <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
          },
          {
            label: 'type_of_dismissal',
            name: ['type_of_dismissal'],
            input: (
              <Select onChange={handleTypeOfDismissal}>
                {(exitEnums.TYPE_OF_DISMISSAL || []).map((item) => (
                  <Option value={item} key={item}>
                    {t(`enums.type_of_dismissal.${item}`)}
                  </Option>
                ))}
              </Select>
            )
          }
        ]);
        break;
      case 'conventional_rupture':
        setResignation([
          {
            label: 'end_date_of_contract',
            name: 'exit_date',
            input: <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
          },
          {
            label: 'conventional_rupture_date',
            name: 'conventional_rupture_date',
            input: <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
          },
          {
            label: 'end_of_withdrawal_periods',
            name: 'end_of_withdrawal_periods',
            input: <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
          }
        ]);
        break;
      case 'break_of_trial_period':
        setResignation([
          {
            label: 'end_date_of_contract',
            name: 'exit_date',
            input: <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
          },
          {
            label: 'break_initited_by',
            name: 'break_initited_by',
            input: (
              <Select onChange={handleInititedOfBreakOfTrial}>
                {(exitEnums.BREAK_INITIATED_BY || []).map((item) => (
                  <Option value={item} key={item}>
                    {t(`enums.break_initited_by.${item}`)}
                  </Option>
                ))}
              </Select>
            )
          }
        ]);
        break;
      default:
        break;
    }
  };

  const exitInformationsFields = [
    {
      label: 'motif',
      name: ['motif'],
      input: (
        <Select onChange={handleChangeMotif}>
          {(exitEnums.MOTIFS || []).map((item) => (
            <Option value={item} key={item}>
              {t(`enums.motifs.${item}`)}
            </Option>
          ))}
        </Select>
      )
    },
    ...resignation,
    ...typeOfDismissal,
    ...inititedOfBreakOfTrial
  ];
  return {
    exitInformationsFields,
    isExitInformationsFieldsLoading,
    endDateOfContract
  };
};

export default exitInformations;
