import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card } from 'antd';

const RHCard = ({ title, time, name, photoRH }) => (
  <Card className="dashboard-interview-card">
    <Row className="dashboard-interview-card-header" justify="space-between">
      <Col span={13}>{title}</Col>
      <Col span={10}>{time}</Col>
    </Row>
    <Row className="dashboard-interview-card-content">
      <Col span={24}>{name}</Col>
      <Col span={24}>{photoRH}</Col>
    </Row>
  </Card>
);

export default RHCard;

RHCard.propTypes = {
  title: PropTypes.func.isRequired,
  time: PropTypes.func.isRequired,
  name: PropTypes.func.isRequired,
  photoRH: PropTypes.func.isRequired
};
