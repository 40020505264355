import React from 'react';
import { Collapse } from 'antd';
import PropTypes from 'prop-types';
import ListFiles from './ListFiles';

const { Panel } = Collapse;

const ListPremises = ({ premise, index }) => (
  <Collapse accordion>
    <Panel header={premise?.label} key={index?.toString()}>
      <ListFiles premise={premise} />
    </Panel>
  </Collapse>
);

export default ListPremises;

const filePropType = PropTypes.shape({
  _id: PropTypes.string,
  type: PropTypes.string,
  filename: PropTypes.string,
  show: PropTypes.bool,
  visibility: PropTypes.arrayOf(PropTypes.string),
  last_update: PropTypes.instanceOf(Date)
});

ListPremises.propTypes = {
  index: PropTypes.number,
  premise: PropTypes.shape({
    _id: PropTypes.string,
    label: PropTypes.string,
    show: PropTypes.bool,
    created_at: PropTypes.instanceOf(Date),
    updated_at: PropTypes.instanceOf(Date),
    files: PropTypes.arrayOf(filePropType)
  }).isRequired
};

ListPremises.defaultProps = {
  index: null
};
