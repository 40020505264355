import React from 'react';
import PropTypes from 'prop-types';
import useFields from './fields';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainer/CreateUpdateContainer';

const CreateUpdateTrainingProgress = ({ purpose }) => {
  const { fields, isFieldsLoading } = useFields();

  return (
    <CreateUpdateContainer
      fields={fields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="trainingprogress"
      resource="trainingprogress"
    />
  );
};

CreateUpdateTrainingProgress.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateTrainingProgress;
