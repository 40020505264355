import React, { useCallback, useEffect, useState } from 'react';
import { DatePicker, Form, Input, Select, Switch, Upload } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  CheckOutlined,
  CloseOutlined,
  FileAddOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import { validatePhoneNumber } from '../../../utils/phoneNumber';
import countryCodes from '../../../utils/countryCodes';

const { Option } = Select;

const useFields = ({ setFile }) => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI, token } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [positions, setPositions] = useState([]);
  const [premises, setPremises] = useState([]);

  const { Dragger } = Upload;

  const draggerProps = {
    name: 'file',
    multiple: true,
    action: `${process.env.REACT_APP_API_URL}/files`,
    headers: { Authorization: `Bearer ${token}` },
    onChange(info) {
      const { status, response } = info.file;

      setFile(response);

      if (status === 'error') {
        message(`${info.file.name} file upload failed.`);
      }
    }
  };

  const fields = [
    {
      name: ['first_name'],
      rules: [{ required: true }]
    },
    {
      name: ['last_name'],
      rules: [{ required: true }]
    },
    {
      name: ['email'],
      rules: [{ required: true }, { type: 'email' }]
    },
    {
      name: ['phone_number'],
      label: 'phone_number.number',
      rules: [{ required: true }],
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: '25%' }}>
              {countryCodes.map((country) => (
                <Option key={country.code} value={country.code}>
                  ({country.code}) {country.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            noStyle
            name={['phone_number', 'number']}
            label="phone_number.number"
            rules={[{ validator: validatePhoneNumber }]}
          >
            <Input style={{ width: '75%' }} />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      name: ['interesting'],
      input: (
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
        />
      )
    },
    {
      name: ['potential_positions'],
      rules: [{ required: true }],
      required: true,
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          loading={isFieldsLoading}
          mode="multiple"
          showArrow
        >
          {(positions || [])
            .filter((pos) => pos.show)
            .map((pos) => (
              <Option label={pos.label} key={pos._id} value={pos._id}>
                {pos.label}
              </Option>
            ))}
        </Select>
      )
    },
    {
      name: ['premise'],
      required: true,
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          loading={isFieldsLoading}
        >
          {(premises || []).map((prem) => (
            <Option label={prem.label} key={prem._id} value={prem._id}>
              {prem.label}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['curriculum'],
      input: (
        <Dragger {...draggerProps}>
          <p className="ant-upload-drag-icon">
            <FileAddOutlined style={{ color: 'var(--textColor)' }} />
          </p>
          <p className="ant-upload-text">{t('files.create.action')}</p>
        </Dragger>
      )
    },
    {
      name: ['last_contact'],
      input: <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
    },
    {
      name: ['warning'],
      input: (
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
        />
      )
    },
    {
      name: ['comment'],
      input: (
        <Input.TextArea
          rows={4}
          placeholder={t('candidates.form.comment_placeholder')}
        />
      )
    }
  ];

  const getPositions = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/positions' });
      setPositions(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getPremises = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/premise' });
      setPremises(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getPositions();
    await getPremises();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    fields,
    isFieldsLoading
  };
};

export default useFields;
