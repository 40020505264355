import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { TrainingPathRouter } from './path/TrainingPathRouter';
import { TrainingProgressRouter } from './TrainingProgress/TrainingProgressRouter';
// import { RecommendationRouter } from './recommendations/RecommendationRouter';
import { subRoutes } from '../../utils/constants/adminRoutes';

export const TrainingsRouter = () => (
  <Routes>
    <Route
      path={`${subRoutes.TRAININGS.PATH}/*`}
      element={<TrainingPathRouter />}
    />
    <Route
      path={`${subRoutes.TRAININGS.FOLLOW}/*`}
      element={<TrainingProgressRouter />}
    />
    {/* <Route
      path={`${subRoutes.APPLICATIONS.CANDIDATES}/*`}
      element={<CandidateRouter />}
    />
    <Route
      path={`${subRoutes.APPLICATIONS.RECOMMANDATIONS}/*`}
      element={<RecommendationRouter />}
    /> */}
  </Routes>
);
