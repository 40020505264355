import React, { useCallback, useEffect, useState } from 'react';
import { DatePicker, Input, Select, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import { bonusStatus } from '../../../utils/constants/tagColors';

const { Option } = Select;

const useFields = () => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [employees, setEmployees] = useState([]);
  const [enums, setEnums] = useState([]);

  const fields = [
    {
      name: ['employee'],
      rules: [{ required: true }],
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          loading={isFieldsLoading}
        >
          {(employees || []).map((employee) => (
            <Option
              label={employee.first_name + employee.last_name}
              key={employee._id}
              value={employee._id}
            >
              {`${employee.first_name} ${employee.last_name}`}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['status'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {(enums?.status || []).map((status) => (
            <Option key={status} value={status}>
              <Tag color={bonusStatus[status]}>{t(`bonus.tags.${status}`)}</Tag>
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['share'],
      input: <Input type="number" addonAfter="%" min={0} max={100} />
    },
    {
      name: ['date'],
      input: (
        <DatePicker style={{ width: '100%' }} picker="month" format="MM-YYYY" />
      )
    }
  ];

  const getEmployees = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/employees' });
      setEmployees(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/enums/?model=Bonus' });
      setEnums(data[0].enums || []);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEmployees();
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    fields,
    isFieldsLoading
  };
};

export default useFields;
