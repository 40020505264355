export const getEmployees = async ({ dispatchAPI, setEmployees, message }) => {
  try {
    const { data } = await dispatchAPI('GET', {
      url: '/employees?is_archived=false'
    });
    setEmployees(data);
  } catch (e) {
    if (e.response) message(e.response.status);
  }
};
