import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { outOfNavRoutes, routes } from '../utils/constants/adminRoutes';
import { Login } from '../routes/login';
import { Dashboard } from '../routes/dashboard/Dashboard';
import { HomeDashboard } from '../routes/homedashboard/Dashboard';
import { Exception } from '../components';
import RequireAuth from './RequireAuth';
import { ProfileRouter } from '../routes/profile/ProfileRouter';
import { Confirmation } from '../routes/emailConfirmation/Confirmation';
import { Settings } from '../routes/settings/settings';
import { UserRouter } from '../routes/users/UserRouter';
import { RecruitmentRouter } from '../routes/recruitments/RecruitmentsRouter';
import { EmployeeFolderRouter } from '../routes/employee-folder/EmployeeFolderRouter';
import { InternCommunicationRouter } from '../routes/intern-communication/InternCommunicationRouter';
import { AdminTheme } from '../assets/styles/Theme/AdminTheme';
import { TrainingsRouter } from '../routes/trainings/TrainingsRouter';
import { SignatureRouter } from '../routes/signatures/SignatureRouter';
import { TemplateDocRouter } from '../routes/template-docs/TemplateDocsRouter';
import { OrganisationRouter } from '../routes/Organisation/OrganisationRouter';
import CreateUpdatePremise from '../routes/settings/premise/CreateUpdatePremise';
import { useAuthContext } from '../contexts/AuthContext';
import PostPwdForm from '../routes/login/PostPwdForm';

const PrivateRoute = ({ children }) => {
  const { isPermitted } = useAuthContext();

  return isPermitted ? children : <Navigate to="/login" />;
};

export const AdminRouter = () => {
  useEffect(() => {
    const body = document.body;
    if (body) {
      body.className = 'admin';
    }
  });

  return (
    <BrowserRouter>
      <AdminTheme />
      <Routes>
        <Route path={`${outOfNavRoutes.LOGIN}/:token`} element={<Login />} />
        <Route path={outOfNavRoutes.LOGIN} element={<Login />} />
        <Route
          path={`${outOfNavRoutes.CONFIRMATION}/:token`}
          element={<Confirmation />}
        />
        <Route
          path={`${outOfNavRoutes.PASSWORD_CREATION}/:urlToken`}
          element={<PostPwdForm />}
        />
        <Route element={<RequireAuth />}>
          <Route path={routes.DASHBOARD} element={<Dashboard />} />
          <Route
            path={`${outOfNavRoutes.PROFILE}/*`}
            element={<ProfileRouter />}
          />
          {/* ------------------------------------------------------------- */}
          <Route path={routes.HOMEDASHBOARD} element={[<HomeDashboard />]} />
          <Route
            path={`${routes.USERS}/*`}
            element={[
              <PrivateRoute>
                <UserRouter />
              </PrivateRoute>
            ]}
          />
          <Route
            path={`${routes.RECRUITMENTS}/*`}
            element={[
              <PrivateRoute>
                <RecruitmentRouter />
              </PrivateRoute>
            ]}
          />
          <Route
            path={`${routes.EMPLOYEE_FOLDER}/*`}
            element={[
              <PrivateRoute>
                <EmployeeFolderRouter />
              </PrivateRoute>
            ]}
          />

          <Route
            path={`${routes.INTERN_COMMUNICATION}/*`}
            element={[
              <PrivateRoute>
                <InternCommunicationRouter />
              </PrivateRoute>
            ]}
          />

          <Route
            path={`${routes.TRAININGS}/*`}
            element={[
              <PrivateRoute>
                <TrainingsRouter />
              </PrivateRoute>
            ]}
          />

          <Route path="/signed_document/*" element={<SignatureRouter />} />

          <Route
            path={outOfNavRoutes.SETTINGS}
            element={[
              <PrivateRoute>
                <Settings />
              </PrivateRoute>
            ]}
          />
          <Route
            path={`${routes.TEMPLATE_DOCS}/*`}
            element={[
              <PrivateRoute>
                <TemplateDocRouter />
              </PrivateRoute>
            ]}
          />
          <Route
            path={`${routes.ORGANISATION}/*`}
            element={[
              <PrivateRoute>
                <OrganisationRouter />
              </PrivateRoute>
            ]}
          />
          <Route
            path={outOfNavRoutes.PREMISE}
            element={<CreateUpdatePremise purpose="create" />}
          />
          <Route
            path={`${outOfNavRoutes.PREMISE_UPDATE}`}
            element={<CreateUpdatePremise purpose="edit" />}
          />
          {/* ------------------------------------------------------------- */}
          <Route path="*" element={<Exception />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
