import React, { useState } from 'react';
import {
  Button,
  Form,
  Input,
  Switch,
  Table,
  InputNumber,
  Row,
  Spin,
  Select
} from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useErrorMessage } from '../../../utils/ErrorMessage';

const { TextArea } = Input;
const { Option } = Select;

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  selectType,
  checkType,
  record,
  index,
  children,
  setColorPicker,
  colorPicker,
  ...restProps
}) => {
  let inputNode;

  switch (inputType) {
    case 'text':
      inputNode = !checkType ? (
        <Input />
      ) : (
        <Switch defaultChecked={record.show} />
      );
      break;
    case 'select':
      inputNode = <Input />;
      break;
    default:
      break;
  }
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0
          }}
          rules={[
            {
              required: true,
              message: `veuillez saisir ${title}!`
            }
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const PositionTable = ({
  positionCols,
  editPositionCol,
  positionInformationsEnums
}) => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const [colorPicker, setColorPicker] = useState('');
  const [loading, setLoading] = useState(false);
  const [fieldValue, setFieldValue] = useState('');

  const isEditing = (record) => record._id === editingKey;

  const saveSwitch = (record) => {
    try {
      editPositionCol(record._id, record);
      setEditingKey('');
    } catch (e) {
      message('Error with Switch change: ', e);
    }
  };

  const save = async (record, type) => {
    const newRecord = record;
    try {
      if (type.length) {
        setLoading(true);
        newRecord[type] = fieldValue;
        editPositionCol(newRecord._id, newRecord);
        setLoading(false);
      } else {
        const row = await form.validateFields();
        editPositionCol(newRecord._id, row);
      }
      setEditingKey('');
      setFieldValue('');
    } catch (errInfo) {
      message('Validate Failed:', errInfo);
    }
  };

  const columns = [
    {
      title: t('settings.positionTable.label'),
      dataIndex: 'label',
      width: '10%',
      editable: true,
      render: (value, record) => (
        <Row align="middle" justify="space-between">
          <TextArea
            style={{ width: '70%' }}
            onChange={(event) => {
              setFieldValue(event.target.value);
            }}
            defaultValue={value}
          />
          <Spin spinning={loading}>
            <Button
              onClick={() => {
                setLoading(true);
                setTimeout(() => {
                  save(record, 'label');
                  setLoading(false);
                }, 500);
              }}
            >
              {t('settings.positionTable.save')}
            </Button>
          </Spin>
        </Row>
      )
    },
    {
      title: t('settings.positionTable.show'),
      dataIndex: 'show',
      width: '3%',
      editable: true,
      render: (value, record) => (
        <Switch
          loading={loading}
          checked={value}
          onClick={() => {
            setLoading(true);
            setTimeout(() => {
              // eslint-disable-next-line no-param-reassign
              record.show = !value;
              saveSwitch(record);
              setLoading(false);
            }, 500);
          }}
        />
      )
    },
    {
      title: t('settings.positionTable.missions'),
      dataIndex: 'missions',
      width: '10%',
      editable: true,
      render: (value, record) => (
        <Row align="middle" justify="space-between">
          <TextArea
            style={{ width: '70%' }}
            onChange={(event) => {
              setFieldValue(event.target.value);
            }}
            defaultValue={value}
          />
          <Spin spinning={loading}>
            <Button
              onClick={() => {
                setLoading(true);
                setTimeout(() => {
                  save(record, 'missions');
                  setLoading(false);
                }, 500);
              }}
            >
              {t('settings.positionTable.save')}
            </Button>
          </Spin>
        </Row>
      )
    },
    {
      title: t('settings.positionTable.required_profile'),
      dataIndex: 'required_profile',
      width: '10%',
      editable: true,
      render: (value, record) => (
        <Row align="middle" justify="space-between">
          <TextArea
            style={{ width: '70%' }}
            onChange={(event) => {
              setFieldValue(event.target.value);
            }}
            defaultValue={value}
          />
          <Spin spinning={loading}>
            <Button
              onClick={() => {
                setLoading(true);
                setTimeout(() => {
                  save(record, 'required_profile');
                  setLoading(false);
                }, 500);
              }}
            >
              {t('settings.positionTable.save')}
            </Button>
          </Spin>
        </Row>
      )
    },
    {
      title: t('settings.positionTable.required_experience'),
      dataIndex: 'required_experience',
      width: '10%',
      editable: true,
      render: (value, record) => (
        <Row align="middle" justify="space-between">
          <TextArea
            style={{ width: '70%' }}
            onChange={(event) => {
              setFieldValue(event.target.value);
            }}
            defaultValue={value}
          />
          <Spin spinning={loading}>
            <Button
              onClick={() => {
                setLoading(true);
                setTimeout(() => {
                  save(record, 'required_experience');
                  setLoading(false);
                }, 500);
              }}
            >
              {t('settings.positionTable.save')}
            </Button>
          </Spin>
        </Row>
      )
    },
    {
      title: t('settings.positionTable.advantages'),
      dataIndex: 'advantages',
      width: '10%',
      editable: true,
      render: (value, record) => (
        <Row align="middle" justify="space-between">
          <TextArea
            style={{ width: '70%' }}
            onChange={(event) => {
              setFieldValue(event.target.value);
            }}
            defaultValue={value}
          />
          <Spin spinning={loading}>
            <Button
              onClick={() => {
                setLoading(true);
                setTimeout(() => {
                  save(record, 'advantages');
                  setLoading(false);
                }, 500);
              }}
            >
              {t('settings.positionTable.save')}
            </Button>
          </Spin>
        </Row>
      )
    },
    {
      title: t('settings.positionTable.category'),
      dataIndex: 'category',
      width: '10%',
      editable: true,
      render: (value, record) => (
        <Row align="middle" justify="space-between">
          <Select
            style={{ width: '70%' }}
            onChange={(event) => {
              setFieldValue(event);
            }}
            showSearch
            defaultValue={value}
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
          >
            {(positionInformationsEnums.categories || []).map((category) => (
              <Option label={t(`enums.categories.${category}`)} key={category}>
                {t(`enums.categories.${category}`)}
              </Option>
            ))}
          </Select>
          <Spin spinning={loading}>
            <Button
              onClick={() => {
                setLoading(true);
                setTimeout(() => {
                  save(record, 'category');
                  setLoading(false);
                }, 500);
              }}
            >
              {t('settings.positionTable.save')}
            </Button>
          </Spin>
        </Row>
      )
    },
    {
      title: t('settings.positionTable.level'),
      dataIndex: 'level',
      width: '9%',
      editable: true,
      render: (value, record) => (
        <Row align="middle" justify="space-between">
          <Select
            style={{ width: '70%' }}
            defaultValue={value}
            onChange={(event) => {
              setFieldValue(event);
            }}
          >
            {(positionInformationsEnums.levels || []).map((level) => (
              <Option key={level}>{level}</Option>
            ))}
          </Select>
          <Spin spinning={loading}>
            <Button
              onClick={() => {
                setLoading(true);
                setTimeout(() => {
                  save(record, 'level');
                  setLoading(false);
                }, 500);
              }}
            >
              {t('settings.positionTable.save')}
            </Button>
          </Spin>
        </Row>
      )
    },
    {
      title: t('settings.positionTable.grade'),
      dataIndex: 'grade',
      width: '9%',
      editable: true,
      render: (value, record) => (
        <Row align="middle" justify="space-between">
          <Select
            style={{ width: '70%' }}
            defaultValue={value}
            onChange={(event) => {
              setFieldValue(event);
            }}
          >
            {(positionInformationsEnums.grades || []).map((grade) => (
              <Option key={grade}>{grade}</Option>
            ))}
          </Select>
          <Spin spinning={loading}>
            <Button
              onClick={() => {
                setLoading(true);
                setTimeout(() => {
                  save(record, 'grade');
                  setLoading(false);
                }, 500);
              }}
            >
              {t('settings.positionTable.save')}
            </Button>
          </Spin>
        </Row>
      )
    },
    {
      title: t('settings.positionTable.salary'),
      dataIndex: 'salary',
      width: '9%',
      editable: true,
      render: (value, record) => (
        <Row align="middle" justify="space-between">
          <InputNumber
            addonAfter="€"
            style={{ width: '70%' }}
            onChange={(event) => {
              setFieldValue(event);
            }}
            defaultValue={value}
          />
          <Spin spinning={loading}>
            <Button
              onClick={() => {
                setLoading(true);
                setTimeout(() => {
                  save(record, 'salary');
                  setLoading(false);
                }, 500);
              }}
            >
              {t('settings.positionTable.save')}
            </Button>
          </Spin>
        </Row>
      )
    }
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: ['color', 'icon'].includes(col.dataIndex)
          ? 'select'
          : 'text',
        selectType: col.dataIndex === 'color' ? 'color' : 'icon',
        checkType: col.dataIndex === 'show',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        colorPicker,
        setColorPicker
      })
    };
  });
  return (
    <Form form={form} component={false}>
      <Table
        scroll={{ x: 2500 }}
        components={{
          body: {
            cell: EditableCell
          }
        }}
        bordered
        dataSource={positionCols}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={false}
      />
    </Form>
  );
};

export default PositionTable;

EditableCell.propTypes = {
  editing: PropTypes.bool.isRequired,
  dataIndex: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  inputType: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  selectType: PropTypes.string.isRequired,
  checkType: PropTypes.bool.isRequired,
  colorPicker: PropTypes.bool.isRequired,
  setColorPicker: PropTypes.func.isRequired,
  record: PropTypes.shape({ show: PropTypes.bool }).isRequired
};

PositionTable.propTypes = {
  positionCols: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  editPositionCol: PropTypes.func.isRequired,
  positionInformationsEnums: PropTypes.shape({
    categories: PropTypes.string,
    levels: PropTypes.string,
    grades: PropTypes.string
  }).isRequired
};
