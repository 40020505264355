const formattedDate = ({ time_start, time_end }) => {
  const start_date = new Date(time_start);
  const end_date = new Date(time_end);

  const start_hours = start_date.getHours().toString().padStart(2, '0');
  const start_minutes = start_date.getMinutes().toString().padStart(2, '0');
  const end_hours = end_date.getHours().toString().padStart(2, '0');
  const end_minutes = end_date.getMinutes().toString().padStart(2, '0');

  const formatted_start_time = `${start_hours}h${start_minutes}`;
  const formatted_end_time = `${end_hours}h${end_minutes}`;
  const formatted_time_range = `${formatted_start_time} - ${formatted_end_time}`;

  return formatted_time_range;
};

export const getInterviews = async ({ dispatchAPI, message }) => {
  try {
    const { data } = await dispatchAPI('GET', {
      url: 'interviews?currentDay=true&populate=candidate,header'
    });
    const interviewsArray = [];

    data.map((interview) =>
      interviewsArray.push({
        title: interview?.header?.label,
        time: formattedDate({
          time_start: interview?.time_start,
          time_end: interview?.time_end
        }),
        name: `${interview?.candidate[0]?.first_name} ${interview?.candidate[0]?.last_name}`
      })
    );
    return interviewsArray;
  } catch (e) {
    message(e);
  }

  return null;
};
