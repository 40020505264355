import React, { useState } from 'react';
import { Switch } from 'antd';
import { ProjectOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import KanbanBoard from './KanbanBoard';
import ListApplications from './ListApplications';
import { PageHeaderCustom } from '../../../../components/PageHeader/PageHeader';
import { ContentCustom } from '../../../../components/ContentCustom/ContentCustom';

const SwitchKanban = styled.div`
  font-size: 16px;
  color: var(--textColorSecondary);
  display: flex;
  align-items: center;
  padding-top: 4px;
  .anticon {
    margin-right: 8px;
  }
  .ant-switch {
    margin-left: 10px;
  }
`;

const Applications = () => {
  const { t } = useTranslation();
  const [kanbanView, setKanbanView] = useState(true);

  return (
    <>
      <PageHeaderCustom
        title={t('applications.show.title')}
        withSubRoutes
        extra={
          <SwitchKanban>
            <ProjectOutlined />
            Kanban
            <Switch
              onChange={() => setKanbanView(!kanbanView)}
              checked={kanbanView}
            />
          </SwitchKanban>
        }
      />
      <ContentCustom>
        {kanbanView ? <KanbanBoard /> : <ListApplications />}
      </ContentCustom>
    </>
  );
};

export default Applications;
