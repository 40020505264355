import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Card } from 'antd';
import useFields from '../../employee-folder/employees/fields/fields';
import { CreateUpdateEntryContainer } from './CreateUpdateEntryContainer';
import EquipementsField from '../../employee-folder/employees/fields/EquipmentsField/EquipmentsField';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import { useAuthContext } from '../../../contexts/AuthContext';

const CreateUpdateEntrie = ({ purpose }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { formFields, isFieldsLoading, position } = useFields();
  const [equipmentTree, setEquipmentTree] = useState({});
  const [equipments, setEquipments] = useState([]);
  const [equipmentData, setEquipmentData] = useState([]);

  const getEmployeeEquipments = async () => {
    if (purpose === 'edit') {
      try {
        const entry = await dispatchAPI('GET', {
          url: `entries/${id}`
        });

        const { data } = await dispatchAPI('GET', {
          url: `employees/${entry.data.employee}?populate=equipments._id`
        });
        setEquipmentData(data.equipments);
      } catch (e) {
        message(e);
      }
    }
  };

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data,
        date_of_birth: data.date_of_birth && moment(data.date_of_birth)
      })
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        equipments: equipmentData
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        equipments: equipmentData
      })
    }
  };

  const getEquipments = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/equipment'
      });
      const options = [];
      data.map((equipment) =>
        options.push({
          label: equipment.label,
          value: equipment._id,
          quantity: equipment.quantity,
          size: equipment.size
        })
      );
      setEquipments(options);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getEquipments();
      await getEmployeeEquipments();
    })();
  }, []);

  const setChildren = (value) => {
    const newTrainingTree = JSON.parse(JSON.stringify(equipmentTree));
    newTrainingTree.children = value;
    setEquipmentTree(newTrainingTree);
  };

  return (
    <CreateUpdateEntryContainer
      formFields={formFields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="entries"
      resource="entries"
      config={config}
      populate="employee"
      position={position}
      formExtra={
        <Card
          className="form-card"
          title={t('cardTitles.entries.equipmentCardTitle')}
        >
          <EquipementsField
            list={equipmentTree.children || []}
            setList={setChildren}
            equipments={equipments}
            setEquipmentData={setEquipmentData}
            equipmentData={equipmentData}
            purpose={purpose}
          />
        </Card>
      }
    />
  );
};

CreateUpdateEntrie.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateEntrie;
