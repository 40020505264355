import React, { useState, useEffect } from 'react';
import { Checkbox } from 'antd';
import { CheckCircleOutlined, HourglassOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { setValidatedSteps } from '../utils/validatedSteps';

const CheckListCard = ({ enums, exit, stepType }) => {
  const [checkedList, setCheckedList] = useState([]);
  const { t } = useTranslation();

  const setCheckedStep = () => {
    const array = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const item of setValidatedSteps({ enums, exit, stepType })
      .array_of_step) {
      if (item === setValidatedSteps({ enums, exit, stepType }).actual_step) {
        if (
          item === 'ACCESS_DELETED' &&
          exit.deleted_access_date &&
          exit.deleted_access_user
        ) {
          array.push(item);
        } else break;
      } else {
        array.push(item);
      }
    }
    setCheckedList(array);
  };

  useEffect(() => {
    if (Object.keys(exit).length) {
      setCheckedStep();
    }
  }, [enums]);

  const onChange = (checkedValues) => {
    setCheckedList(checkedValues);
  };

  const checkboxStyle = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px'
  };

  const checkboxIconStyle = {
    marginRight: '10px',
    height: '20px',
    width: '20px'
  };

  return (
    <Checkbox.Group
      onChange={onChange}
      value={checkedList}
      style={{ display: 'flex', flexDirection: 'column' }}
    >
      {Object.keys(exit).length &&
        setValidatedSteps({ enums, exit, stepType }).array_of_step.map(
          (item) => (
            <div key={item} style={checkboxStyle}>
              <Checkbox value={item} style={{ display: 'none' }} />
              {checkedList.includes(item) ? (
                <CheckCircleOutlined
                  style={{ ...checkboxIconStyle, color: 'green' }}
                />
              ) : (
                <HourglassOutlined style={checkboxIconStyle} />
              )}
              {t(`exits.steps.${item}`)}
            </div>
          )
        )}
    </Checkbox.Group>
  );
};

export default CheckListCard;

CheckListCard.propTypes = {
  enums: PropTypes.shape({ exitsStep: PropTypes.shape({}) }),
  exit: PropTypes.shape({
    deleted_access_user: PropTypes.string,
    deleted_access_date: PropTypes.string,
    break_initited_by: PropTypes.string,
    type_of_dismissal: PropTypes.string,
    conventional_rupture_date: PropTypes.string,
    created_at: PropTypes.string,
    employee: PropTypes.string,
    end_of_withdrawal_periods: PropTypes.string,
    exit_date: PropTypes.string,
    motif: PropTypes.string,
    status: PropTypes.string,
    updated_at: PropTypes.string,
    __v: PropTypes.number,
    _id: PropTypes.string
  }),
  stepType: PropTypes.string.isRequired
};

CheckListCard.defaultProps = {
  exit: {},
  enums: {}
};
