import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Applications from './List/Applications';
import ShowApplication from './Show/ShowApplication';

import { ApplicationsContextProvider } from '../../../contexts/ApplicationsContext';

export const InProgApplicationsRouter = () => (
  <ApplicationsContextProvider>
    <Routes>
      <Route path="/" element={<Applications />} />
      <Route path="/show/:id" element={<ShowApplication />} />
    </Routes>
  </ApplicationsContextProvider>
);
