import React, { useCallback, useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import {
  Button,
  Skeleton,
  Row,
  Card,
  Tag,
  Popconfirm,
  Spin,
  Space
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  RotateLeftOutlined,
  CopyOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { offerStatus } from '../../../utils/constants/tagColors';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import { offerPrincipalInfo, offerContentInfo } from './listContent';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../../components';
import { routes, subRoutes } from '../../../utils/constants/adminRoutes';
import { permissionVerification } from '../../../utils/permissionVerification';

const ShowOffer = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI, userPermissions } = useAuthContext();
  const [isLoading2, setLoading2] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [offer, setOffer] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [permit, setPermit] = useState(false);

  useEffect(() => {
    const permission = permissionVerification(
      userPermissions,
      '/recruitments/offers/modification'
    );
    setPermit(permission);
  }, [userPermissions]);

  const onDuplicate = async () => {
    setLoading2(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/offers/duplicate/${id}`
      });
      setLoading2(false);
      navigate(
        `${routes.RECRUITMENTS}${subRoutes.RECRUITMENTS.OFFERS}/show/${data}`
      );
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getOffer = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/offers/${id}?populate=premise,position`
      });
      setOffer(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  const archivePost = async () => {
    try {
      await dispatchAPI('PATCH', {
        url: `offers/${id}`,
        body: { status: 'ARCHIVED' }
      });
      getOffer();
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getOffer();
    })();
  }, [getOffer, refresh]);

  const restoreOffer = async () => {
    try {
      await dispatchAPI('PATCH', {
        url: `/offers/${id}`,
        body: { status: 'PUBLISHED' }
      });
      setRefresh(!refresh);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  return (
    <>
      <PageHeaderCustom
        title={
          <>
            {t('offers.show.title')}
            {!isLoading && (
              <Tag color={offerStatus[offer?.status]}>
                {t(`offers.tags.${offer?.status}`)}
              </Tag>
            )}
          </>
        }
        extra={
          <>
            <Link
              to={{
                pathname: `${routes.RECRUITMENTS}${subRoutes.RECRUITMENTS.OFFERS}/edit/${id}`
              }}
            >
              {permit && (
                <Button type="primary">
                  {`${t('buttons.edit')} `}
                  <EditOutlined />
                </Button>
              )}
            </Link>
            {permit && (
              <Button type="primary" onClick={() => archivePost()}>{`${t(
                'offers.archive_post'
              )} `}</Button>
            )}
            <Space>
              {offer?.status === 'PUBLISHED' ? (
                <Popconfirm
                  title={t(`datatable.column.action.duplicate.warning`)}
                  okText={t(`datatable.column.action.duplicate.ok`)}
                  okButtonProps={{ type: 'add' }}
                  cancelText={t(`datatable.column.action.duplicate.cancel`)}
                  onConfirm={onDuplicate}
                  icon={<WarningOutlined />}
                >
                  {permit && (
                    <Button
                      type="add"
                      add
                      icon={isLoading2 ? <Spin /> : <CopyOutlined />}
                    >
                      {t(`datatable.column.action.duplicate.title`)}
                    </Button>
                  )}
                </Popconfirm>
              ) : null}
            </Space>

            {permit && (
              <Button
                type="link"
                style={{ color: 'green' }}
                onClick={restoreOffer}
              >
                <RotateLeftOutlined />
                {`${t('buttons.restore')} `}
              </Button>
            )}
          </>
        }
      />
      <ContentCustom>
        <Card
          title={`${t('show_offer.card.principal')} `}
          bordered={false}
          style={{ width: '100%' }}
          className="form-card"
        >
          <Row>
            <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
              <DescriptionList
                data={offerPrincipalInfo(offer || {}, t)}
                translate
              />
            </Skeleton>
          </Row>
        </Card>

        <Card
          title={`${t('show_offer.card.content')} `}
          bordered={false}
          style={{ width: '100%', marginTop: 24 }}
          className="form-card"
        >
          <Row>
            <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
              <DescriptionList
                data={offerContentInfo(offer || {}, t)}
                translate
              />
            </Skeleton>
          </Row>
        </Card>
      </ContentCustom>
    </>
  );
};

export default ShowOffer;
