import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Button, Skeleton, Row, Tag, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  FileAddOutlined,
  DeleteOutlined,
  WarningOutlined
} from '@ant-design/icons';
import dayjs from 'dayjs';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/ErrorMessage';
import { incidentsStatus } from '../../../../utils/constants/tagColors';
import ListInterviews from './ListInterviews';
import listContent from './listContent';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../../../components';
import InterviewDrawer from '../../../../components/InterviewDrawer/InterviewDrawer';
import IncidentModal from './IncidentModal';
import { routes, subRoutes } from '../../../../utils/constants/adminRoutes';
import { permissionVerification } from '../../../../utils/permissionVerification';

const ShowMemo = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const navigate = useNavigate();
  const { dispatchAPI, userPermissions } = useAuthContext();
  const [isLoading, setIsLoading] = useState(true);
  const [incident, setIncident] = useState(null);
  const [visible, setVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [permit, setPermit] = useState(false);

  useEffect(() => {
    const permission = permissionVerification(
      userPermissions,
      '/employee-folder/incidents/modification'
    );
    setPermit(permission);
  }, [userPermissions]);

  const getIncident = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/incidents/${id}?populate=author,premise,hr_intervenant`
      });
      setIncident(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getIncident();
    })();
  }, [refreshData, getIncident]);

  const deleteIncident = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/incidents/${id}` });
      navigate(-1);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  return (
    <>
      <PageHeaderCustom
        title={
          <>
            {t('incidents.show.title') +
              dayjs(incident?.date).format('DD/MM/YYYY [à] H[h]mm')}
            {!isLoading && (
              <Tag color={incidentsStatus[incident?.status]}>
                {t(`incidents.tags.${incident?.status}`)}
              </Tag>
            )}
          </>
        }
        extra={
          <>
            {permit && (
              <Button type="primary" onClick={() => setIsModalOpen(true)}>
                {`${t('incidents.show.button.manage_incident')} `}
                <EditOutlined />
              </Button>
            )}

            <Button type="primary" onClick={() => setVisible(true)}>
              {`${t('incidents.show.button.plan_interview')} `}
              <FileAddOutlined />
            </Button>

            <Link
              to={{
                pathname: `${routes.EMPLOYEE_FOLDER}${subRoutes.EMPLOYEE_FOLDER.INCIDENTS}/edit/${id}`
              }}
            >
              {permit && (
                <Button type="primary">
                  {`${t('buttons.edit')} `}
                  <EditOutlined />
                </Button>
              )}
            </Link>
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={deleteIncident}
              icon={<WarningOutlined />}
            >
              {permit && (
                <Button type="link" danger>
                  {`${t('buttons.delete')} `}
                  <DeleteOutlined />
                </Button>
              )}
            </Popconfirm>
          </>
        }
      />
      <ContentCustom>
        <Row>
          <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
            <DescriptionList data={listContent(incident || {}, t)} translate />
          </Skeleton>
        </Row>
        <ListInterviews refreshData={refreshData} incidentId={incident?._id} />
      </ContentCustom>
      <InterviewDrawer
        setRefreshData={setRefreshData}
        refresh={refreshData}
        visible={visible}
        setVisible={setVisible}
        incidentId={incident?._id}
      />
      <IncidentModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        date={dayjs(incident?.date).format('DD/MM/YYYY [à] H[h]mm')}
        id={incident?._id}
        refreshData={refreshData}
        setRefreshData={setRefreshData}
      />
    </>
  );
};

export default ShowMemo;
