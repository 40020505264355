import React, { useState, useEffect } from 'react';
import { Tag } from 'antd';
import moment from 'moment';
import { memosStatus } from '../../../utils/constants/tagColors';

const memoPrincipalInfo = (data, t) => {
  const { date, author, status, object, premises, scheduledTime } = data;
  const [multiple, setMultiple] = useState(false);

  useEffect(() => {
    if (premises?.length) {
      setMultiple(true);
    }
  }, [premises]);

  return [
    {
      label: 'memos.form.day',
      span: 3,
      content: date ? moment(date).format('L') : '-'
    },
    {
      label: 'memos.form.hour',
      span: 1,
      content: date ? moment(scheduledTime).format('LT') : '-'
    },
    {
      label: 'memos.form.status',
      span: 2,
      content:
        (status && (
          <Tag color={memosStatus[status]}>{t(`memos.tags.${status}`)}</Tag>
        )) ||
        '-'
    },
    {
      label: 'memos.form.author',
      span: 1,
      content: (author && `${author?.first_name} ${author?.last_name}`) || '-'
    },
    {
      label: 'memos.form.premises',
      span: 2,
      content: premises?.length ? premises : 'Tous',
      multiple
    },
    {
      label: 'memos.form.object',
      span: 3,
      content: object || '-'
    }
  ];
};

const memoContentInfo = (data) => {
  const { content } = data;

  return [
    {
      label: 'memos.form.content',
      span: 1,
      content: content || '-'
    }
  ];
};

export { memoPrincipalInfo, memoContentInfo };
