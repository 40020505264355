import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ListOffers from './ListOffers';
import CreateUpdateOffer from './CreateUpdateOffer';
import ShowOffer from './ShowOffer';

export const OfferRouter = () => (
  <Routes>
    <Route path="/create" element={<CreateUpdateOffer purpose="create" />} />
    <Route path="/edit/:id" element={<CreateUpdateOffer purpose="edit" />} />
    <Route path="/show/:id" element={<ShowOffer />} />
    <Route index element={<ListOffers />} />
  </Routes>
);
