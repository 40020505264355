import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import useFields from './fields';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainer/CreateUpdateContainer';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';

const CreateUpdateMemo = ({ purpose }) => {
  const [allEstablishement, setAllEstablishment] = useState(false);
  const [draft, setDraft] = useState(false);
  const [premises, setPremises] = useState([]);
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();
  const { formFields, base64 } = useFields(
    allEstablishement,
    setAllEstablishment,
    draft,
    setDraft,
    premises
  );

  const getPremises = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/premise'
      });
      setPremises(data);
    } catch (e) {
      message(e);
    }
  };

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data,
        date: moment(data.date),
        scheduledTime: moment(data.scheduledTime)
      })
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        author: user._id,
        status: draft ? 'DRAFT' : 'PLANNIFIED',
        ...(base64 ? { photo: base64 } : {})
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        author: user._id,
        status: draft ? 'DRAFT' : 'PLANNIFIED',
        ...(base64 ? { photo: base64 } : {})
      })
    }
  };

  useEffect(() => {
    (async () => {
      await getPremises();
    })();
  }, []);

  return (
    <CreateUpdateContainer
      fields={formFields}
      purpose={purpose}
      baseUrl="memo"
      resource="memos"
      populate="memo"
      config={config}
    />
  );
};

CreateUpdateMemo.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateMemo;
