import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Tag, Row, Switch, Typography } from 'antd';
import { t } from 'i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ListResource } from '../../../components';
import { exitsStatus } from '../../../utils/constants/tagColors';
import { useFormatter } from '../../../utils/formatter';
import { useAuthContext } from '../../../contexts/AuthContext';
import { permissionVerification } from '../../../utils/permissionVerification';

export const statusTag = (status) => {
  const arrayOfTag = [
    { status: 'WAITING', render: t('exits.tags.WAITING') },
    {
      status: 'ACTION_RH',
      render: t('exits.tags.ACTION_RH')
    },
    { status: 'CLOSE', render: t('exits.tags.CLOSE') }
  ];

  for (let i = 0; i < arrayOfTag.length; i += 1) {
    if (status === arrayOfTag[i].status) {
      return (
        <Tag
          style={{ backgroundColor: exitsStatus[status] }}
          className="white-text-tag"
        >
          {arrayOfTag[i].render}
        </Tag>
      );
    }
  }
  return null;
};

const ListExits = () => {
  const { formattedData } = useFormatter();
  const navigate = useNavigate();
  const { userPermissions } = useAuthContext();
  const location = useLocation();
  const { pathname } = location;
  const params = new URLSearchParams(location.search);
  const active = params.get('active');
  const [isActive, setActive] = useState(active === 'true');
  const [permit, setPermit] = useState(false);

  useEffect(() => {
    const permission = permissionVerification(
      userPermissions,
      '/employee-folder/exits/modification'
    );
    setPermit(permission);
  }, [userPermissions]);

  const onChange = () => {
    setActive(!isActive);
    navigate(`${pathname}?active=${!isActive}`);
  };

  const headers = [
    {
      label: 'employee',
      extra_label: 'last_name',
      key: 'employee.last_name'
    },
    {
      label: 'employee',
      extra_label: 'first_name',
      key: 'employee.first_name'
    },
    {
      label: 'position',
      key: 'employee.position.label',
      header_key: 'position'
    },
    {
      label: 'premise',
      key: 'employee.premise.label',
      header_key: 'premise'
    },
    {
      label: 'status',
      key: 'status'
    },
    {
      label: 'exit_date',
      key: 'exit_date'
    },
    {
      label: 'motif',
      key: 'motif'
    },
    {
      label: 'type_of_dismissal',
      key: 'type_of_dismissal'
    },
    {
      label: 'break_initited_by',
      key: 'break_initited_by'
    }
  ];

  const columns = [
    {
      title: t('employees.form.last_name'),
      key: 'last_name',
      dataIndex: 'employee',
      render: (employee) => employee?.last_name,
      sorter: (a, b) => a.employee.last_name.localeCompare(b.employee.last_name)
    },
    {
      title: t('employees.form.first_name'),
      key: 'first_name',
      dataIndex: 'employee',
      render: (employee) => employee?.first_name,
      sorter: (a, b) =>
        a.employee.first_name.localeCompare(b.employee.first_name)
    },
    {
      title: t('employees.form.position'),
      key: 'employee.position',
      dataIndex: 'employee',
      render: (employee) => employee?.position?.label,
      sorter: (a, b) =>
        a.employee.position.label.localeCompare(b.employee.position.label)
    },
    {
      title: t('employees.form.premise'),
      key: 'employee.premise',
      dataIndex: 'employee',
      render: (employee) => employee?.premise?.label,
      sorter: (a, b) =>
        a.employee.premise.label.localeCompare(b.employee.premise.label)
    },
    {
      title: t('exits.form.exit_date'),
      key: 'exit_date',
      dataIndex: 'exit_date',
      render: (exit_date) => moment(exit_date).format('DD/MM/YYYY'),
      sorter: (a, b) => a.employee.exit_date.localeCompare(b.employee.exit_date)
    },
    {
      title: t('exits.form.motif'),
      key: 'motif',
      dataIndex: 'motif',
      render: (motif) => t(`exits.motif.${motif}`),
      sorter: (a, b) => a.employee.motif.localeCompare(b.employee.motif)
    },
    {
      title: t('exits.form.status'),
      key: 'status',
      dataIndex: 'status',
      render: (status) => (
        <Tag color={exitsStatus[status]}>{t(`exits.tags.${status}`)}</Tag>
      ),
      sorter: true,
      filters: Object.keys(exitsStatus).map((r) => ({
        text: t(`exits.tags.${r}`),
        value: r
      }))
    }
  ];

  return (
    <ListResource
      resourceName="exits"
      populate="employee,employee.position,employee.premise"
      formatter={formattedData}
      deleteAction={permit}
      editAction={permit}
      withCreateButton={permit}
      templateType="exits"
      columns={columns}
      headers={headers}
      resourceModelName="Exit"
      extraButtons={
        <Row align="middle" className="list-switch-wrapper">
          <Switch checked={isActive} onChange={onChange} />
          <Typography>{t('exits.show_close_exits')}</Typography>
        </Row>
      }
      extraQuery={!isActive ? 'active=true' : 'active=false'}
    />
  );
};

export default ListExits;
