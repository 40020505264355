import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Tag, Steps, message as antdMessage } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/ErrorMessage';
import { useHandleResize } from '../../../../utils/HandleResize';

import { ContentCustom, PageHeaderCustom } from '../../../../components';
import { statusTag } from '../ListEntries';
import { entriesStatus } from '../../../../utils/constants/tagColors';
import DetailsCards from '../../../employee-folder/employees/ShowEmployee/DetailsCards';
import HeaderExtra from './HeaderExtra';

const { Step } = Steps;

const ShowEntry = () => {
  const { width } = useHandleResize();
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { dispatchAPI, user } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [entry, setEntry] = useState();
  const [employee, setEmployee] = useState();
  const [enums, setEnums] = useState();
  const [activeStep, setActiveStep] = useState('');
  const [files, setFiles] = useState();
  const [trainingprogress, setTrainingProgress] = useState(null);
  const [refresh, setRefresh] = useState(false);

  const setHistoryExchange = async (type) => {
    await dispatchAPI('PATCH', {
      url: `employee/${employee?._id}`,
      body: {
        $push: { history: { type, date: new Date(), by: user._id } }
      }
    });
  };

  const handlePostDocuments = async (document) => {
    try {
      const result = await dispatchAPI('PATCH', {
        url: `/employees/${employee._id}`,
        body: { $addToSet: { files: { _id: files._id, type: document } } }
      });

      if (result) {
        setHistoryExchange(document);
        antdMessage.success(t('documents.success'));
        setRefresh(!refresh);
      }
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getEnums = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/entries/enums`
      });
      setEnums(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  const nextStep = async (target) => {
    let newStep = '';
    if (typeof target === 'string') newStep = target;
    else {
      enums?.entriesStep?.forEach((step, index) => {
        if (step === activeStep) {
          newStep = enums?.entriesStep[index + 1];
        }
      });
    }
    try {
      await dispatchAPI('PATCH', {
        url: `/entries/${id}?step=true`,
        body: { step: newStep }
      });

      if (activeStep === 'FILE') {
        await dispatchAPI('PATCH', {
          url: `/entries/${id}?step=true`,
          body: { status: 'RH_ACTION' }
        });
      }
      setActiveStep(newStep);
      setRefresh(!refresh);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getTrainingProgress = useCallback(
    async ({ emp }) => {
      setIsLoading(true);
      try {
        const populate = 'employee,employee.premise,profiletree';
        if (emp) {
          const { data } = await dispatchAPI('GET', {
            url: `/trainingprogress/${emp.training_progress}?populate=${populate}`
          });
          setTrainingProgress(data);
        }
      } catch (e) {
        message(e);
      }
      setIsLoading(false);
    },
    [id, refresh]
  );

  const getEmployee = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/employees/${entry?.employee}?populate=position,premise,recommended_by,files._id,equipments._id,history.by`
      });
      setEmployee(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [entry]);

  const getEntrie = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/entries/${id}?populate=files._id`
      });
      setEntry(data);
      setActiveStep(data?.step);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  const deleteEntrie = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/entries/${id}` });
      await dispatchAPI('DELETE', { url: `/employees/${employee._id}` });
      navigate(-1);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const validatedSteps = () => {
    const arrayOfSteps = enums?.entriesStep || [];
    for (let i = 0; i < arrayOfSteps.length; i += 1) {
      if (arrayOfSteps[i] === entry?.step) return i;
    }
    return null;
  };

  useEffect(() => {
    (async () => {
      await getEnums();
      await getEntrie();
    })();
  }, [getEntrie, refresh]);

  useEffect(() => {
    (async () => {
      if (entry?.employee !== undefined) await getEmployee();
    })();
  }, [entry, refresh]);

  useEffect(() => {
    (async () => {
      if (employee?.training_progress) {
        await getTrainingProgress({ emp: employee });
      }
    })();
  }, [employee, refresh]);

  return (
    <>
      <PageHeaderCustom
        title={
          employee?.first_name && (
            <>
              {`${employee?.first_name} ${employee?.last_name}`}
              <Tag
                style={{
                  backgroundColor: entriesStatus[entry?.status]
                }}
                className="white-text-tag"
              >
                {statusTag(entry?.status)?.props?.children}
              </Tag>
            </>
          )
        }
        extra={
          <HeaderExtra
            refresh={refresh}
            setRefresh={setRefresh}
            entry={entry}
            employee={employee}
            activeStep={activeStep}
            nextStep={nextStep}
            deleteEntrie={deleteEntrie}
            setHistoryExchange={setHistoryExchange}
            employeeId={entry?.employee}
          />
        }
      />
      <ContentCustom>
        <Steps
          current={validatedSteps()}
          className="entry-steps"
          direction={width < 1400 ? 'vertical' : 'horizontal'}
        >
          {enums?.entriesStep?.map((step) => (
            <Step
              key={step}
              title={t(`entry.steps.${step}`)}
              onClick={() => nextStep(step)}
            />
          ))}
        </Steps>

        <DetailsCards
          entry={entry}
          employee={employee}
          isLoading={isLoading}
          files={employee?.files}
          setFiles={setFiles}
          handlePostDocuments={handlePostDocuments}
          trainingprogress={trainingprogress}
          setRefresh={setRefresh}
          refresh={refresh}
        />
      </ContentCustom>
    </>
  );
};

export default ShowEntry;
