export const setValidatedSteps = ({ exit, enums, stepType }) => {
  switch (exit?.motif) {
    case 'break_of_trial_period':
      return {
        array_of_step: enums?.exitsStep[exit.motif][exit.break_initited_by],
        actual_step: exit[stepType][exit?.break_initited_by]
      };
    case 'dismissal':
      return {
        array_of_step: enums?.exitsStep[exit.motif][exit.type_of_dismissal],
        actual_step: exit[stepType][exit?.type_of_dismissal]
      };
    default:
      return {
        array_of_step: enums?.exitsStep[exit.motif],
        actual_step: exit[stepType]
      };
  }
};

export const validatedSteps = ({ exit, enums, stepType }) => {
  const arrayOfSteps =
    setValidatedSteps({ exit, enums, stepType }).array_of_step || [];
  for (let i = 0; i < arrayOfSteps.length; i += 1) {
    if (
      arrayOfSteps[i] ===
      setValidatedSteps({ exit, enums, stepType }).actual_step
    )
      return i;
  }
  return null;
};
