import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, Button, Popconfirm, Select, InputNumber } from 'antd';
import {
  UpOutlined,
  DownOutlined,
  DeleteOutlined,
  WarningOutlined
} from '@ant-design/icons';

const EquipmentsCards = ({
  equipments,
  setEquipmentData,
  equipmentData,
  list,
  setList
}) => {
  const { t } = useTranslation();

  const sizeEnums = [
    { value: 'S', label: 'S' },
    { value: 'M', label: 'M' },
    { value: 'L', label: 'L' },
    { value: 'XL', label: 'XL' },
    { value: 'XXL', label: 'XXL' }
  ];

  const setLineAttribut = (id, record) => {
    const newList = list.map((item, index) =>
      index === id
        ? {
            ...item,
            quantity: record.quantity,
            label: record.label,
            size: record.size
          }
        : item
    );
    setList(newList);
  };

  const mouvLineUp = (id) => {
    if (id === 0) return;
    setList([
      ...list.slice(0, id - 1),
      list[id],
      list[id - 1],
      ...list.slice(id + 1)
    ]);
  };

  const mouvLineDown = (id) => {
    setList([
      ...list.slice(0, id),
      list[id + 1],
      list[id],
      ...list.slice(id + 2)
    ]);
  };

  const deleteLine = (id) => {
    const newList = list.filter((item, index) => index !== id);
    setList(newList);
  };

  const setEquipmentId = (index, id) => {
    let newData;
    if (equipmentData.length) {
      newData = equipmentData;

      if (newData[index]) {
        newData[index] = { _id: id };
      } else {
        newData.push({ _id: id });
      }
    } else {
      newData = [{ _id: id }];
    }
    setEquipmentData(newData);
  };

  const setQuantity = (index, value) => {
    const newData = equipmentData;
    newData[index].quantity = value;
    setEquipmentData(newData);
  };

  const setSize = (index, value) => {
    const newData = equipmentData;
    newData[index].size = value;
    setEquipmentData(newData);
  };

  return (
    <div>
      {list.map((item, index) => (
        <Card key={item._id}>
          <Row>
            <Col span={18}>
              <Row justify="space-around" wrap={false}>
                <Select
                  placeholder={t('employees.form.equipment')}
                  options={equipments}
                  style={{ minWidth: '50%' }}
                  onChange={(value, record) => {
                    setLineAttribut(index, record);
                    setEquipmentId(index, value);
                  }}
                  value={equipmentData[index]?._id}
                />
                {item.quantity && (
                  <InputNumber
                    placeholder={t('employees.form.quantity')}
                    style={{ minWidth: '20%' }}
                    onChange={(value) => setQuantity(index, value)}
                    value={equipmentData[index]?.quantity}
                  />
                )}
                {item.size && (
                  <Select
                    placeholder={t('employees.form.size')}
                    options={sizeEnums}
                    style={{ minWidth: '20%' }}
                    onChange={(value) => setSize(index, value)}
                    value={equipmentData[index]?.size}
                  />
                )}
              </Row>
            </Col>
            <Col span={6}>
              <Row justify="end">
                <Button
                  type="link"
                  onClick={() => mouvLineUp(index)}
                  disabled={index === 0}
                >
                  <UpOutlined />
                </Button>
                <Button
                  type="link"
                  onClick={() => mouvLineDown(index)}
                  disabled={index === list.length - 1}
                >
                  <DownOutlined />
                </Button>
                <Popconfirm
                  title={t('datatable.column.action.delete.title')}
                  okText={t('datatable.column.action.delete.ok')}
                  okButtonProps={{ type: 'danger' }}
                  cancelText={t('datatable.column.action.delete.cancel')}
                  onConfirm={() => deleteLine(index)}
                  icon={<WarningOutlined />}
                >
                  <Button type="link" danger>
                    <DeleteOutlined />
                  </Button>
                </Popconfirm>
              </Row>
            </Col>
          </Row>
        </Card>
      ))}
    </div>
  );
};

export default EquipmentsCards;

EquipmentsCards.propTypes = {
  setEquipmentData: PropTypes.func.isRequired,
  equipmentData: PropTypes.arrayOf().isRequired,
  equipments: PropTypes.arrayOf({
    label: PropTypes.string,
    value: PropTypes.string,
    quantity: PropTypes.string,
    size: PropTypes.string
  }),
  list: PropTypes.arrayOf({}).isRequired,
  setList: PropTypes.func.isRequired
};

EquipmentsCards.defaultProps = {
  equipments: null
};
