import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  Row,
  Col,
  Button,
  message,
  Input,
  message as antdMessage
} from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import SetEmailTemplatesOptions from './SetEmailTemplatesOptions';
import SetEmailRecipientsOptions from './SetEmailRecipientsOptions';
import DocumentModal from './DocumentModal';

const ModalSendMails = ({
  initialSubject,
  isModalVisible,
  sendMailAction,
  emailModel,
  setIsModalVisible,
  recipientsList,
  employee,
  entry,
  isAttachment,
  type,
  setIsGenerate,
  setHistoryExchange
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [selectedIds, setSelectedIds] = useState([]);
  const [editorState, setEditorState] = useState('');
  const [mailSubject, setMailSubject] = useState(initialSubject);
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [otherAttachments, setOtherAttachments] = useState([]);
  const [email, setEmail] = useState({});

  const getEmails = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/emails?type=${emailModel}`
      });
      setEmailTemplates(data);
    } catch (e) {
      if (e.response) message(e.response);
    }
  };

  useEffect(() => {
    (async () => {
      await getEmails();
    })();
  }, [emailModel]);

  const setEmployeeForEmailModal = () => {
    const list = [];
    if (recipientsList) recipientsList.map((user) => list.push(user._id));
    setSelectedIds(list);
  };

  useEffect(() => {
    setEmployeeForEmailModal();
  }, [recipientsList]);

  const setSubjectForEmailModal = () => {
    if (email !== undefined && Object.keys(email).length && email.data) {
      setMailSubject(email.subject);
      setEditorState(email.data);
    }
  };

  useEffect(() => {
    setSubjectForEmailModal();
  }, [email]);

  const sendEmail = async () => {
    try {
      const content = editorState;
      const urlMail = '/email_sender';

      const result = await dispatchAPI('POST', {
        url: urlMail,
        body: {
          entryId: entry?._id,
          employee,
          content,
          subject: mailSubject,
          isAttachment,
          otherAttachments,
          type
        }
      });

      if (type === 'invitation') {
        await dispatchAPI('PATCH', {
          url: `/entries/${entry?._id}?step=INVITATION_EMAIL`,
          body: {
            active: false,
            status: 'ONBOARDING_CLOSE'
          }
        });
      }

      if (result) {
        setHistoryExchange(`send_${type}_mail`);
        setSelectedIds([]);
        antdMessage.success(t(`entries.email.message.${type}`));
        setIsModalVisible(false);
      }
    } catch (e) {
      if (e.response) message(e.response);
    }
    setIsModalVisible(false);
    setOtherAttachments([]);
    sendMailAction();
    setIsGenerate(false);
  };

  const handleCancel = () => {
    setSelectedIds([]);
    setIsModalVisible(false);
    setOtherAttachments([]);
  };

  return (
    <Modal
      title={t('email.modal.title')}
      maskClosable={false}
      visible={isModalVisible}
      onCancel={() => handleCancel()}
      onOk={() => setIsModalVisible(false)}
      width={800}
      style={{ minHeight: 700 }}
      footer={false}
    >
      <Row>
        <Col span={4}>
          <span>{t('email.modal.recipient')}</span>
        </Col>
        <Col span={12}>
          <SetEmailRecipientsOptions
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
            recipientsList={recipientsList}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 5 }}>
        <Col span={4}>{t('email.modal.model')}</Col>
        <Col span={12}>
          <SetEmailTemplatesOptions
            emailModel={emailModel}
            setEmail={setEmail}
            emailTemplates={emailTemplates}
            t={t}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 5 }}>
        <Col span={4}>
          <span>{t('email.modal.object')}</span>
        </Col>
        <Col span={12}>
          <Input
            value={mailSubject}
            onChange={(e) => setMailSubject(e.target.value)}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 5 }}>
        <Col span={4}>
          <span>{t('email.modal.documents.label')}</span>
        </Col>
        <Col span={12}>
          <Button onClick={() => setIsModalOpen(true)}>
            {t('email.modal.documents.title')}
          </Button>
        </Col>
      </Row>

      <Row style={{ marginTop: 25, width: '100%', height: 150 }}>
        <ReactQuill
          style={{ width: '100%', height: '100%' }}
          value={editorState}
          onChange={(value) => setEditorState(value)}
        />
      </Row>
      <Row style={{ marginTop: 60 }}>
        <Col offset={20} span={4}>
          <Button type="primary" onClick={sendEmail}>
            {t('email.modal.save')}
          </Button>
        </Col>
      </Row>
      <DocumentModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        setOtherAttachments={setOtherAttachments}
        otherAttachments={otherAttachments}
      />
    </Modal>
  );
};

export default ModalSendMails;

ModalSendMails.propTypes = {
  emailModel: PropTypes.string.isRequired,
  isModalVisible: PropTypes.bool.isRequired,
  setIsModalVisible: PropTypes.func.isRequired,
  initialSubject: PropTypes.string.isRequired,
  sendMailAction: PropTypes.func.isRequired,
  recipientsList: PropTypes.arrayOf({}).isRequired,
  employee: PropTypes.arrayOf({}).isRequired,
  entry: PropTypes.arrayOf({}).isRequired,
  isAttachment: PropTypes.bool,
  type: PropTypes.string,
  setIsGenerate: PropTypes.func,
  setHistoryExchange: PropTypes.func
};

ModalSendMails.defaultProps = {
  isAttachment: false,
  type: null,
  setIsGenerate: null,
  setHistoryExchange: null
};
