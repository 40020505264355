import React from 'react';
import PropTypes from 'prop-types';
import { Tag, Empty, Mentions, Divider } from 'antd';
import { Handle, getOutgoers, getIncomers } from 'react-flow-renderer/nocss';

const { Option } = Mentions;

export const getflowCategory = (type) => {
  switch (type) {
    case 'start':
      return 'input';
    case 'end':
      return 'output';
    case 'decision':
      return 'decision';
    case 'subprocess':
      return 'subprocess';
    case 'log':
      return 'log';
    default:
      return 'default';
  }
};

export const SubProcessNode = ({ data }) => (
  <div className={data.isConform ? '' : 'isNotConform'}>
    <Handle type="target" position="top" id="02" style={{ borderRadius: 0 }} />
    <Handle
      type="source"
      position="bottom"
      id="04"
      style={{ borderRadius: 0 }}
    />
    <div>{data.label}</div>
  </div>
);

SubProcessNode.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.string,
    isConform: PropTypes.bool
  }).isRequired
};

export const DecisionNode = ({ data }) => {
  const hasInvertedOutputs =
    data.operations &&
    data.operations.sideDecision &&
    data.operations.sideDecision.left === false;

  return (
    <div
      className={
        data.isConform !== undefined && !data.isConform ? 'isNotConform' : ''
      }
    >
      <Handle
        type="target"
        position="top"
        id="01"
        style={{ borderRadius: 0 }}
      />
      <Handle
        type="source"
        position="left"
        id="02"
        style={{
          borderRadius: 0,
          background: hasInvertedOutputs
            ? 'var(--errorColor)'
            : 'var(--addColor)'
        }}
      />
      <Handle
        type="source"
        position="right"
        id="03"
        style={{
          borderRadius: 0,
          background: hasInvertedOutputs
            ? 'var(--addColor)'
            : 'var(--errorColor)'
        }}
      />
      <span>{data.label}</span>
    </div>
  );
};

DecisionNode.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.string,
    isConform: PropTypes.bool,
    operations: PropTypes.shape({
      sideDecision: PropTypes.shape({
        left: PropTypes.bool,
        right: PropTypes.bool
      })
    })
  }).isRequired
};

export const selectTagColor = (type) => {
  switch (type) {
    case 'ObjectId':
      return 'magenta';
    case 'Date':
      return 'red';
    case 'String':
      return 'cyan';
    case 'Boolean':
      return 'purple';
    case 'Number':
      return 'green';
    case 'Array':
      return 'gold';
    case 'Object':
      return 'volcano';
    default:
      return 'default';
  }
};

const displayTagsForComplexTypes = (
  label,
  higherLevelField,
  type,
  draggable,
  onTagDrag
) => {
  let formattedTags = '';

  if (typeof type === 'object' || type.length > 0) {
    formattedTags = Object.entries(type).map((subEntry) => {
      const field = `${higherLevelField}.${subEntry[0]}`;
      const finalType =
        subEntry[1] && typeof subEntry[1].type === 'string'
          ? subEntry[1].type
          : 'other';

      if (draggable) {
        return (
          <Tag
            key={`${label}|${field}|${finalType}`}
            color={selectTagColor(finalType)}
            onDragStart={(event) => onTagDrag(event, label, field)}
            draggable
            contentEditable={false}
            className="draggable"
          >{`${label} - ${field} (${finalType})`}</Tag>
        );
      }
      return (
        <Tag
          key={`${label}|${field}|${finalType}`}
          color={selectTagColor(finalType)}
          className="not-draggable"
        >{`${label} - ${field} (${finalType})`}</Tag>
      );
    });
  }

  return formattedTags;
};

displayTagsForComplexTypes.propTypes = {
  label: PropTypes.string.isRequired,
  higherLevelField: PropTypes.string.isRequired,
  type: PropTypes.shape({
    field: PropTypes.string,
    type: PropTypes.string
  }).isRequired,
  draggable: PropTypes.bool,
  onTagDrag: PropTypes.func
};

displayTagsForComplexTypes.defaultProps = {
  draggable: false,
  onTagDrag: () => {}
};

const isSrcOutputsIsDefined = (SrcOutputs) =>
  SrcOutputs !== undefined && Object.keys(SrcOutputs).length > 0;

const isInputDefined = (input) =>
  input !== undefined && input.type !== undefined;

const displaySourceTags = (sourceElm, draggable, onTagDrag) => {
  const SrcOutputs = sourceElm.data.outputs;
  const ElmLabel = sourceElm.data.label;

  if (isSrcOutputsIsDefined(SrcOutputs)) {
    const fields = Object.keys(SrcOutputs);

    const tags = fields.map((field) => {
      const inputDefined = isInputDefined(SrcOutputs[field]);
      const inputTypeIsString =
        inputDefined && typeof SrcOutputs[field].type === 'string';
      const inputIsObject =
        inputDefined &&
        SrcOutputs[field].type &&
        SrcOutputs[field].type.length > 0;

      let type = '';
      let finalTest = false;
      if (!inputDefined && !inputTypeIsString && !inputIsObject) {
        type = 'other';
        finalTest = true;
      } else if (
        typeof SrcOutputs[field].type === 'string' ||
        (inputDefined && SrcOutputs[field].type.length > 0)
      ) {
        type =
          SrcOutputs[field] && typeof SrcOutputs[field].type === 'string'
            ? SrcOutputs[field].type
            : 'other';
        finalTest = true;
      }

      if (finalTest) {
        const finalType = type;

        if (draggable) {
          return (
            <Tag
              key={`${ElmLabel}|${field}|${finalType}`}
              color={selectTagColor(finalType)}
              onDragStart={(event) => onTagDrag(event, ElmLabel, field)}
              draggable
              contentEditable={false}
              className="draggable"
            >{`${ElmLabel} - ${field} (${finalType})`}</Tag>
          );
        }
        return (
          <Tag
            key={`${ElmLabel}|${field}|${type}`}
            color={selectTagColor(type)}
            className="not-draggable"
          >{`${ElmLabel} - ${field} (${type})`}</Tag>
        );
      }
      const complexTags = displayTagsForComplexTypes(
        ElmLabel,
        field,
        (SrcOutputs[field] && SrcOutputs[field].type) || 'other',
        draggable,
        onTagDrag
      );
      return complexTags;
    });
    return tags;
  }
  return <Empty />;
};

export const mapInputs = (sourceElements, draggable, onTagDrag) => {
  const tagsToDisplay = sourceElements.map((sourceElm) => {
    const tags = displaySourceTags(sourceElm, draggable, onTagDrag);
    return (
      <div key={JSON.stringify(sourceElm)} className="input-tag-wrapper">
        <Divider>{`Source: ${sourceElm.data.label}`}</Divider>
        {tags}
      </div>
    );
  });
  return tagsToDisplay;
};

mapInputs.propTypes = {
  sourceElements: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string,
      data: PropTypes.shape({
        label: PropTypes.string
      })
    })
  ).isRequired,
  draggable: PropTypes.bool,
  onTagDrag: PropTypes.func
};

mapInputs.defaultProps = {
  draggable: false,
  onTagDrag: () => {}
};

export const mapOutputs = (outputs) => {
  // console.log('outputs', outputs);

  if (Object.entries(outputs).length > 0) {
    const tagsToDisplay = Object.entries(outputs).map((output) => {
      const field = output[0];
      const type = (output[1] && output[1].type) || 'other';
      // console.log('name', field);
      // console.log('type', type);
      return (
        <Tag
          key={`${field}|${type}`}
          color={selectTagColor(type)}
          className="not-draggable"
        >{`${field} (${type})`}</Tag>
      );
    });
    return (
      <div className="input-tag-wrapper">
        <Divider>All Outputs</Divider>
        {tagsToDisplay}
      </div>
    );
  }
  return <Empty />;
};

export const displayTagInMention = (sourceElements, isInDecision = false) => {
  const tagsToDisplay = sourceElements.map((sourceElm) => {
    const SrcOutputs = sourceElm.data.outputs;
    const ElmLabel = sourceElm.data.label;

    if (isSrcOutputsIsDefined(SrcOutputs)) {
      const fields = Object.keys(SrcOutputs);

      const tags = fields.map((field) => {
        if (SrcOutputs[field] && typeof SrcOutputs[field].type === 'string') {
          const type =
            typeof SrcOutputs[field].type === 'string'
              ? SrcOutputs[field].type
              : 'other';

          return (
            <Option
              key={`{${ElmLabel}|${field}}`}
              value={`${isInDecision ? '@' : ''}{${ElmLabel}|${field}}`}
            >
              <Tag
                key={`${ElmLabel}|${field}|${SrcOutputs[field].type}`}
                color={selectTagColor(SrcOutputs[field].type)}
                className="not-draggable"
              >{`${ElmLabel} - ${field} (${type})`}</Tag>
            </Option>
          );
        }
        const complexTags = displayTagsForComplexTypes(
          ElmLabel,
          field,
          (SrcOutputs[field] && SrcOutputs[field].type) || 'other'
        );
        return complexTags;
      });
      return tags;
    }
    return '';
  });
  return tagsToDisplay;
};

export const hasMissingLink = (element, elements) => {
  if (element.type === 'input') {
    const outgoers = getOutgoers(element, elements);
    return outgoers.length === 0;
  }
  if (element.type === 'output') {
    const incomers = getIncomers(element, elements);
    return incomers.length === 0;
  }
  if (element.type === 'default') {
    const incomers = getIncomers(element, elements);
    const outgoers = getOutgoers(element, elements);
    return incomers.length === 0 || outgoers.length === 0;
  }
  if (element.type === 'decision') {
    const incomers = getIncomers(element, elements);
    const outgoers = getOutgoers(element, elements);
    return incomers.length === 0 || outgoers.length < 2;
  }
  return false;
};

export const testHasMissingLinks = (elements) => {
  if (elements.length === 0) return true;
  let hasMissingLinks = false;
  elements.forEach((element) => {
    if (element.type !== 'smoothstep' && hasMissingLink(element, elements)) {
      hasMissingLinks = true;
    }
  });
  return hasMissingLinks;
};

export const isValidSmoothstep = (newSmoothstep, nodes) => {
  const res = { status: true, message: '' };
  if (newSmoothstep.source === newSmoothstep.target) {
    res.message = 'Source and target are the same';
    res.status = false;
    return res;
  }

  const indenticalSmoothsteps = nodes.filter((node) => {
    if (node.id === newSmoothstep.source) {
      if (node.type === 'output') {
        res.message = 'workflows.messages.error.smoothstep.invalid_start';
        return false;
      }
    }
    if (node.id === newSmoothstep.target) {
      if (node.type === 'input') {
        res.message = 'workflows.messages.error.smoothstep.invalid_end';
        return false;
      }
    }
    if (node.type === 'smoothstep') {
      if (
        (node.source === newSmoothstep.source &&
          node.target === newSmoothstep.target) ||
        (node.source === newSmoothstep.target &&
          node.target === newSmoothstep.source)
      ) {
        res.message = 'workflows.messages.error.smoothstep.identical';
        return false;
      }
    }
    return true;
  });
  if (indenticalSmoothsteps.length !== nodes.length) res.status = false;
  return res;
};

const controlWFLUpdateElements = (newNode, elements) => {
  const newElements = elements.map((elm) => {
    if (elm.id === newNode.id) return newNode;
    return elm;
  });
  return newElements;
};

export const controlWFLIntegrity = (selectedElement, elements) => {
  // 1 - On vérifie que les liens sont tous créés
  const hasMissingLinks = testHasMissingLinks(elements);

  // if selectedElement doesn't exist in elements, it has been deleted and we should return elements
  const selectedElementExists = elements.some(
    (element) => element.id === selectedElement.id
  );

  if (!selectedElementExists) return elements;

  if (hasMissingLinks) {
    const newElements = elements.map((element) => {
      const newElement = element;
      if (newElement.type !== 'output' && newElement.type !== 'smoothstep') {
        newElement.data.isConform = !hasMissingLink(newElement, elements);
      }
      return newElement;
    });
    return newElements;
  }
  // 2 - A partir du dernier modifié, on déclare tous les enfants non-ok
  if (Object.keys(selectedElement).length === 0 && elements.length > 0)
    // eslint-disable-next-line no-param-reassign
    selectedElement = elements[0];

  if (selectedElement && selectedElement.id !== undefined) {
    let newSelectedElement = {
      ...elements.find((elm) => elm.id === selectedElement.id)
    };
    if (newSelectedElement && newSelectedElement?.data) {
      newSelectedElement = {
        ...newSelectedElement,
        data: { ...newSelectedElement.data, isConform: true }
      };
    }

    // if it's type default, check that data.taskType is not empty ; else it's not conform
    if (newSelectedElement.type === 'default' && newSelectedElement.data) {
      if (
        !newSelectedElement.data?.taskType ||
        newSelectedElement.data.taskType === ''
      ) {
        newSelectedElement.data.isConform = false;
      }
    }

    let updatedList = getOutgoers(selectedElement, elements);
    let updatedElements = controlWFLUpdateElements(
      newSelectedElement,
      elements
    );

    // 2 - Parcourir le graph
    while (updatedList.length > 0) {
      const newElement = updatedList[0];

      const newOutgoers = getOutgoers(newElement, elements);

      updatedList = updatedList.filter((item, pos) => pos !== 0);
      updatedList = updatedList.concat(newOutgoers);
      updatedList = Array.from(new Set(updatedList));
      updatedElements = controlWFLUpdateElements(newElement, updatedElements);
    }
    return updatedElements;
  }
  return elements;
};

export const hasOneInputNode = (elements) => {
  const startNodes = elements.filter((element) => element.type === 'input');
  return startNodes.length === 1;
};

export const hasOneOutputNode = (elements) => {
  const endNodes = elements.filter((element) => element.type === 'output');
  return endNodes.length === 1;
};

export const validateWorkflow = (elements) => {
  const nonConformElms = [];

  if (!(hasOneInputNode(elements) && hasOneOutputNode(elements))) return false;

  if (testHasMissingLinks(elements)) return false;
  if (!elements || elements.length === 0) return false;
  elements.forEach((element) => {
    const isRelevantNode =
      element.type !== 'smoothstep' && element.type !== 'output';
    const isConform = isRelevantNode && element.data.isConform === true;
    if (isRelevantNode && !isConform) nonConformElms.push(element);
  });

  if (nonConformElms.length > 0) return false;
  return true;
};
