/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { Button, Input, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import InterviewTitleTable from './interviewTitleTable';

const initialColumns = [
  {
    title: 'Label',
    dataIndex: 'label',
    key: 'label',
    render: (text) => <Input InitialValue={text} />
  }
];

const InterviewTitleSettings = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [interviewtitlesCols, setInterviewTitleCols] = useState(initialColumns);
  const newInterviewTitleCol = {
    label: 'Nouvelle ligne',
    show: true
  };

  const getInterviewTitleCols = async () => {
    try {
      const data = await dispatchAPI('GET', { url: '/interviewtitles' });
      setInterviewTitleCols(data.data);
    } catch (e) {
      message.error(e.message);
    }
  };

  const addInterviewTitleCol = async () => {
    try {
      await dispatchAPI('POST', {
        url: '/interviewtitles',
        body: newInterviewTitleCol
      });
      getInterviewTitleCols();
    } catch (e) {
      message.error(e.message);
    }
  };

  const editInterviewTitleCol = async (_id, row) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/interviewtitles/${_id}`,
        body: row
      });
    } catch (e) {
      message.error(e.message);
    }
    getInterviewTitleCols();
  };
  useEffect(() => {
    getInterviewTitleCols();
  }, []);

  return (
    <>
      <InterviewTitleTable
        interviewtitlesCols={interviewtitlesCols}
        editInterviewTitlesCol={editInterviewTitleCol}
      />
      <Button
        type="dashed"
        style={{ width: '100%', height: 50 }}
        onClick={addInterviewTitleCol}
      >
        {t('settings.interviewTitleTable.addinterviewtitlescol')}
      </Button>
    </>
  );
};

export default InterviewTitleSettings;
