import React, { useState, useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Typography, Switch, Row } from 'antd';
import { ListResource } from '../../../../components';
import { useFormatter } from '../../../../utils/formatter';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { permissionVerification } from '../../../../utils/permissionVerification';

const { Text } = Typography;

export const ListView = ({ myInterviewsOnly, customActionLine }) => {
  const { t } = useTranslation();
  const { formattedData } = useFormatter();
  const navigate = useNavigate();
  const location = useLocation();
  const { userPermissions } = useAuthContext();
  const { pathname } = location;
  const params = new URLSearchParams(location.search);
  const archived = params.get('archived');
  const [isArchived, setIsArchived] = useState(archived === 'true');
  const [permit, setPermit] = useState(false);

  useEffect(() => {
    const permission = permissionVerification(
      userPermissions,
      '/recruitments/interviews/modification'
    );
    setPermit(permission);
  }, [userPermissions]);

  const onChange = () => {
    setIsArchived(!isArchived);
    navigate(`${pathname}?archived=${!isArchived}`);
  };

  const renderDates = (date_start, interview) => {
    const { startTime, endTime } = interview;
    return `Le ${moment(date_start).format('DD/MM/YYYY')} de ${moment(
      startTime
    ).format('HH:mm')} à ${moment(endTime).format('HH:mm')}`;
  };

  const renderInterlocutors = (interlocutors) => {
    if (interlocutors.length === 0)
      return <Text italic>{t('interviews.form.none')}</Text>;

    let result = '';
    interlocutors.forEach((interlocutor) => {
      result += `${interlocutor.first_name} ${interlocutor.last_name}`;
    });
    return result;
  };

  const headers = [
    {
      label: 'header',
      model_key: 'interviewtitle',
      key: 'header.label'
    },
    {
      label: 'rh_employee',
      model_key: 'user',
      extra_label: 'rh_employee_last_name',
      key: 'rh_employee.last_name'
    },
    {
      label: 'rh_employee',
      model_key: 'user',
      extra_label: 'rh_employee_first_name',
      key: 'rh_employee.first_name'
    },
    {
      label: 'interlocutors',
      extra_label: 'interlocutor_last_name',
      model_key: 'employee',
      multiple_data_key: 'last_name',
      key: 'interlocutors.last_name'
    },
    {
      label: 'interlocutors',
      model_key: 'employee',
      extra_label: 'interlocutor_first_name',
      multiple_data_key: 'first_name',
      key: 'interlocutors.first_name'
    },
    {
      label: 'date',
      key: 'date'
    },
    {
      label: 'time_start',
      key: 'time_start'
    },
    {
      label: 'time_end',
      key: 'time_start'
    },
    {
      label: 'premise',
      key: 'premise.label'
    },
    {
      label: 'frame',
      model_key: 'interviewframe',
      key: 'frame.title'
    },
    {
      label: 'comment',
      key: 'comment'
    }
  ];

  const columns = [
    {
      title: t('interviews.form.header'),
      key: 'header',
      dataIndex: 'header',
      render: (header) => header?.label,
      width: '15%',
      sorter: (a, b) => a.header.label.localeCompare(b.header.label)
    },
    {
      title: t('interviews.form.rh_employee'),
      key: 'rh_name',
      dataIndex: 'rh_employee',
      width: '17%',
      render: (rh_employee) =>
        `${rh_employee?.first_name} ${rh_employee?.last_name}`,
      sorter: (a, b) =>
        a.rh_employee.first_name.localeCompare(b.rh_employee.first_name)
    },
    {
      title: t('interviews.form.interlocutors'),
      key: 'interlocutors',
      dataIndex: 'interlocutors',
      width: '17%',
      render: (interlocutors) => renderInterlocutors(interlocutors),
      sorter: (a, b) => {
        const aNames = a.interlocutors.map(
          (interlocutor) => interlocutor.first_name
        );
        const bNames = b.interlocutors.map(
          (interlocutor) => interlocutor.first_name
        );
        return aNames.join().localeCompare(bNames.join());
      }
    },
    {
      title: t('interviews.form.date'),
      key: 'date',
      dataIndex: 'date',
      width: '21%',
      render: (date_start, interview) => renderDates(date_start, interview),
      sorter: (a, b) => a.date.localeCompare(b.date),
      defaultSortOrder: 'descend'
    },
    {
      title: t('interviews.form.premise'),
      key: 'premise',
      dataIndex: 'premise',
      width: '15%',
      render: (premise) => premise[0]?.label,
      sorter: (a, b) => a.premise[0].label.localeCompare(b.premise[0].label)
    },
    {
      title: t('interviews.form.frame'),
      key: 'frame',
      dataIndex: 'frame',
      width: '15%',
      render: (frame) => frame?.label,
      sorter: (a, b) => a.frame.label.localeCompare(b.frame.label)
    }
  ];

  const setExtraQuery = () => {
    let extraQuery = '';
    if (isArchived) {
      extraQuery += 'is_archived=true&';
    } else {
      extraQuery += 'is_archived=false&';
    }
    if (myInterviewsOnly) {
      extraQuery += 'userOnly=true';
    }
    return extraQuery;
  };

  return (
    <ListResource
      resourceName="interviews"
      columns={columns}
      headers={headers}
      deleteAction={permit}
      editAction={permit}
      withCreateButton={permit}
      formatter={formattedData}
      templateType="interviews"
      resourceModelName="Interviews"
      populate="header,rh_employee,interlocutors,premise,frame,candidate"
      extraButtons={
        <Row align="middle" className="list-switch-wrapper">
          <Switch checked={isArchived} onChange={onChange} />
          <p>{t('users.archived')}</p>
        </Row>
      }
      extraQuery={setExtraQuery()}
    >
      {customActionLine()}
    </ListResource>
  );
};

ListView.propTypes = {
  myInterviewsOnly: PropTypes.arrayOf(PropTypes.shape({})),
  customActionLine: PropTypes.func.isRequired
};

ListView.defaultProps = {
  myInterviewsOnly: []
};
