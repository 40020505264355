import React from 'react';
import { Button, Row, Col } from 'antd';

import { addWeeks, startOfWeek } from 'date-fns';

import { CalendarHeaderProps } from './types';
import { useTranslation } from 'react-i18next';
import { MonthName } from './MonthName';
import { SwitchModes } from '../SwitchModes';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

export const CalendarHeader: React.FunctionComponent<CalendarHeaderProps> = ({
  startWeek,
  setStartWeek,
  weekConfiguration,
  modes,
  mode,
  setMode,
  extraHeader
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Row
        justify="space-between"
        align="middle"
        style={{ marginBottom: '20px' }}
      >
        <Col
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <div style={{ display: 'flex', marginRight: '20px' }}>
            <Button
              className="linkButton"
              onClick={() => setStartWeek(startOfWeek(new Date()))}
            >
              {t('calendar.buttons.today')}
            </Button>
            <div style={{ display: 'flex', padding: '0 10px' }}>
              <Button
                className="light-btn"
                style={{ margin: '0 5px' }}
                onClick={() => setStartWeek(addWeeks(startWeek, -1))}
              >
                <LeftOutlined />
              </Button>
              <Button
                className="light-btn"
                style={{ margin: '0 5px' }}
                onClick={() => setStartWeek(addWeeks(startWeek, 1))}
              >
                <RightOutlined />
              </Button>
            </div>
          </div>
          <div style={{ alignSelf: 'center' }}>
            <MonthName
              startWeek={startWeek}
              weekConfiguration={weekConfiguration}
            />
          </div>
        </Col>
        <Row align="middle">
          <Col>{extraHeader}</Col>
          <Col>
            <SwitchModes modes={modes} mode={mode} setMode={setMode} />
          </Col>
        </Row>
      </Row>
    </>
  );
};
