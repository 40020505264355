import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Datatable } from '../../../../components/DataTable/Datatable';

const ListInterviews = ({ refreshData, incidentId }) => {
  const { t } = useTranslation();

  const setFormattedDate = (date) => {
    const newDate = new Date(date);
    const day = `0${newDate.getDate()}`.slice(-2);
    const month = `0${newDate.getMonth() + 1}`.slice(-2);
    const year = newDate.getFullYear();
    const hour = `0${newDate.getHours()}`.slice(-2);
    const minute = `0${newDate.getMinutes()}`.slice(-2);
    const formattedDate = `${day}/${month}/${year} ${hour}h${minute}`;

    return formattedDate;
  };

  const columns = [
    {
      title: t('interviews.form.interlocutor'),
      key: 'interlocutors',
      render: (interlocutors) =>
        interlocutors.map(
          (inter) => `${inter?.first_name} ${inter?.last_name}`
        ),
      dataIndex: 'interlocutors',
      sorter: (a, b) =>
        a.interlocutors[0].first_name.localeCompare(
          b.interlocutors[0].first_name
        )
    },
    {
      title: t('interviews.form.rh_employee'),
      key: 'rh_employee',
      render: (rh_employee) =>
        rh_employee
          ? `${rh_employee?.first_name} ${rh_employee?.last_name}`
          : '-',
      dataIndex: 'rh_employee',
      sorter: (a, b) =>
        a.rh_employee.first_name.localeCompare(b.rh_employee.first_name)
    },
    {
      title: t('interviews.form.object'),
      key: 'object',
      dataIndex: 'object',
      sorter: (a, b) => a.object.localeCompare(b.object)
    },
    {
      title: t('interviews.form.date'),
      key: 'date',
      render: (date) => setFormattedDate(date),
      dataIndex: 'date',
      sorter: (a, b) => a.date.localeCompare(b.date)
    },
    {
      title: t('interviews.form.premise'),
      key: 'premise',
      dataIndex: 'premise',
      render: (premise) => premise[0]?.label || '-',
      sorter: (a, b) => a.premise?.label.localeCompare(b.premise?.label)
    }
  ];

  return (
    <Datatable
      forceRefresh={refreshData}
      resourceName="interviews"
      columns={columns}
      extraQuery={`type=INCIDENT&incidentId=${incidentId}`}
      populate="interlocutors,rh_employee,incident_author"
      resourceModelName="User"
      style={{ marginTop: 24, width: '100vw' }}
      showAction={false}
      editAction={false}
      deleteAction={false}
    />
  );
};

export default ListInterviews;

ListInterviews.propTypes = {
  incidentId: PropTypes.string,
  refreshData: PropTypes.func.isRequired
};

ListInterviews.defaultProps = {
  incidentId: null
};
