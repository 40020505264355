import React from 'react';
import { Typography } from 'antd';
import moment from 'moment';

const { Text } = Typography;

const interviewList = (data) => {
  const {
    header,
    rh_employee,
    interlocutors,
    date,
    time_start,
    time_end,
    premise,
    comment
  } = data;

  const renderDates = () =>
    `Le ${moment(date).format('DD/MM/YYYY')} de ${moment(time_start).format(
      'HH:mm'
    )} à ${moment(time_end).format('HH:mm')}`;

  const renderInterlocutors = (interloc) => {
    if (interloc.length === 0) return <Text italic>Aucun</Text>;

    const result = [];
    interloc.forEach((interlocutor) =>
      result.push(`${interlocutor.first_name} ${interlocutor.last_name}`)
    );
    return result;
  };

  return [
    {
      label: 'interviews.form.header',
      span: 1,
      content: header ? header.label : '-'
    },
    {
      label: 'interviews.form.date',
      span: 1,
      content: data ? renderDates() : '-'
    },
    {
      label: 'interviews.form.rh_employee',
      span: 1,
      content: rh_employee
        ? `${rh_employee.first_name}${rh_employee.last_name}`
        : '-'
    },
    {
      label: 'interviews.form.interlocutors',
      span: 1,
      content: interlocutors ? renderInterlocutors(interlocutors) : '-'
    },
    {
      label: 'interviews.form.premise',
      span: 1,
      content: premise ? premise.label : '-'
    },
    {
      label: 'interviews.form.comment',
      span: 1,
      content: comment || '-'
    }
  ];
};

export default interviewList;
