import moment from 'moment';

const breakOfTrialInfo = (data, t) => {
  const {
    break_initited_by,
    period_of_notice,
    receipt_of_termination_notification
  } = data;

  const employerBreakOfTrialFields = [
    {
      label: 'exits.form.break_initited_by',
      span: 1,
      content:
        (break_initited_by &&
          t(`enums.break_initited_by.${break_initited_by}`)) ||
        '-'
    },
    {
      label: 'exits.form.period_of_notice',
      span: 2,
      content: (period_of_notice && `${period_of_notice} jours`) || '-'
    }
  ];

  const employeeBreakOfTrialFields = [
    {
      label: 'exits.form.break_initited_by',
      span: 1,
      content:
        (break_initited_by &&
          t(`enums.break_initited_by.${break_initited_by}`)) ||
        '-'
    },
    {
      label: 'exits.form.receipt_of_termination_notification',
      span: 1,
      content:
        (receipt_of_termination_notification &&
          moment(receipt_of_termination_notification).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      label: 'exits.form.period_of_notice',
      span: 1,
      content: (period_of_notice && `${period_of_notice} jours`) || '-'
    }
  ];

  return { employerBreakOfTrialFields, employeeBreakOfTrialFields };
};

export default breakOfTrialInfo;
