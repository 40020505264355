import React from 'react';
import PropTypes from 'prop-types';
import Repository from './ApplicationCard';
import { useApplicationsContext } from '../../../../contexts/ApplicationsContext';

const CardsOffer = ({ filterOffer, filterPremise, id }) => {
  const { applications } = useApplicationsContext();

  const filteredApplications = applications.filter((application) => {
    if (filterOffer.length > 0) {
      if (!filterOffer.includes(application.offer.name)) {
        return false;
      }
    }

    if (filterPremise.length > 0) {
      if (!filterPremise.includes(application.offer.premise.label)) {
        return false;
      }
    }

    return application.status._id === id;
  });

  return (
    <div>
      {filteredApplications.map((application) => (
        <Repository application={application} key={application._id} />
      ))}
    </div>
  );
};

export default CardsOffer;

CardsOffer.propTypes = {
  id: PropTypes.string.isRequired,
  filterOffer: PropTypes.arrayOf(PropTypes.string),
  filterPremise: PropTypes.arrayOf(PropTypes.string)
};

CardsOffer.defaultProps = {
  filterOffer: [],
  filterPremise: []
};
