import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Input, Modal, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';

const GroupModal = ({
  openModal,
  setOpenModal,
  purpose,
  closeOverlay,
  group,
  setGroup,
  getGroups
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [form] = Form.useForm();

  const createGroup = async (values) => {
    try {
      await dispatchAPI('POST', {
        url: '/permissiongroups',
        body: values
      });
      getGroups();
    } catch (e) {
      message(e);
    }
  };

  const updateGroup = async (values) => {
    const value = {
      label: values.label
    };
    try {
      await dispatchAPI('PATCH', {
        url: `/permissiongroups/${group._id}`,
        body: value
      });
      getGroups();
    } catch (e) {
      message(e);
    }
  };

  const handleSubmit = (values) => {
    if (purpose === 'create') {
      createGroup(values);
    } else {
      updateGroup(values);
    }
    closeOverlay();
  };

  const handleCancel = () => {
    setOpenModal(false);
    setGroup(null);
  };

  return (
    <Modal
      centered
      open={openModal}
      onCancel={() => {
        handleCancel();
      }}
      width={700}
      footer={false}
    >
      <h1>{t(`organisation.form.title.${purpose}`)}</h1>
      <Form
        onFinish={handleSubmit}
        form={form}
        initialValues={{
          label: group?.label
        }}
      >
        <Form.Item name="label" label={t(`organisation.form.label`)}>
          <Input />
        </Form.Item>
        <Row justify="end">
          <Button
            onClick={() => {
              handleCancel();
            }}
          >
            {t(`organisation.form.button.cancel`)}
          </Button>
          <Button type="add" htmlType="submit">
            {t(`organisation.form.button.${purpose}`)}
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};

GroupModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  purpose: PropTypes.string,
  closeOverlay: PropTypes.func.isRequired,
  group: PropTypes.shape({
    _id: PropTypes.string,
    label: PropTypes.string
  }),
  setGroup: PropTypes.func.isRequired,
  getGroups: PropTypes.func.isRequired
};

GroupModal.defaultProps = {
  purpose: null,
  group: null
};

export default GroupModal;
