/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Button, Col, Tabs, message } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import AddEditActivityForm from './AddEditActivityForm';
import ListApplicationActivity from './ListApplicationActivity';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/ErrorMessage';
import { useHandleResize } from '../../../../utils/HandleResize';
import delActivity from './utils/delActivity';
import updateActivity from './utils/updateActivity';
import addActivity from './utils/addActivity';
import { permissionVerification } from '../../../../utils/permissionVerification';

const { TabPane } = Tabs;

const successMessage = message.success;

export const ActivitiesTabs = ({ setSelectedTab, goToAddActivity }) => {
  const { t } = useTranslation();
  const { userPermissions } = useAuthContext();
  const { width } = useHandleResize();
  const [permit, setPermit] = useState(false);

  useEffect(() => {
    const permission = permissionVerification(
      userPermissions,
      '/recruitments/in-progress/modification'
    );
    setPermit(permission);
  }, [userPermissions]);

  const menuItems = [
    { name: t('applications.tabs.activities'), key: 'activity' },
    { name: t('applications.tabs.notes'), key: 'note' },
    { name: t('applications.tabs.calls'), key: 'call' },
    { name: t('applications.tabs.meetings'), key: 'meeting' }
  ];

  return (
    <Col
      xs={24}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      <Tabs
        onChange={(key) => {
          setSelectedTab(key);
        }}
        className="application-activities-tabs"
      >
        {menuItems.map((item) => (
          <TabPane tab={item.name} key={item.key} />
        ))}
      </Tabs>
      <Button
        type="primary"
        style={{
          display: 'flex',
          alignItems: 'center'
        }}
        onClick={goToAddActivity}
        disabled={!permit}
      >
        <PlusCircleOutlined style={{ fontSize: 20, marginTop: 2 }} />
        {width > 680 && `${t('applications.show.add_activity')}`}
      </Button>
    </Col>
  );
};

export const ShowActivitiesDetails = ({
  application,
  listActivity,
  selectedTab,
  setListActivity,
  setSelectedTab,
  setFormActivity,
  purpose,
  setPurpose
}) => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const { message: errorMessage } = useErrorMessage();
  const [itemSelected, setItemSelected] = useState(null);

  const handleAddActivity = (values) => {
    addActivity({
      values,
      dispatchAPI,
      application,
      successMessage,
      setListActivity,
      setFormActivity,
      message,
      uuid,
      t
    });
  };

  const handleUpdateActivity = (values) => {
    updateActivity({
      values,
      dispatchAPI,
      application,
      itemSelected,
      successMessage,
      errorMessage,
      setListActivity,
      setFormActivity,
      t
    });
  };

  const handleDelActivity = (values) => {
    delActivity({
      values,
      setListActivity,
      dispatchAPI,
      application,
      successMessage,
      errorMessage,
      setFormActivity,
      t
    });
  };

  const editActivity = async (elem) => {
    if (elem.type === 'meeting') {
      try {
        const { data } = await dispatchAPI('GET', {
          url: `interviews/${elem.interview_id}`
        });

        const fields = { ...elem, ...data, type: elem.type };
        setItemSelected(fields);
      } catch (e) {
        message(e);
      }
    } else {
      setItemSelected(elem);
    }
    setPurpose('edit');
    setListActivity(false);
    setFormActivity(true);
    setSelectedTab('activity');
  };

  const showActivities = () => {
    if (!listActivity) {
      return (
        <AddEditActivityForm
          purpose={purpose}
          handleAddActivity={handleAddActivity}
          handleUpdateActivity={handleUpdateActivity}
          listActivity={listActivity}
          setFormActivity={setFormActivity}
          setListActivity={setListActivity}
          itemSelected={itemSelected}
        />
      );
    }
    return (
      <ListApplicationActivity
        listActivity={listActivity}
        selectedTab={selectedTab}
        editActivity={editActivity}
        handleDelActivity={handleDelActivity}
      />
    );
  };

  return showActivities();
};
