import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, Button, Spin } from 'antd';
import { Link } from 'react-router-dom';
import { useErrorMessage } from '../../utils/ErrorMessage';
import { useAuthContext } from '../../contexts/AuthContext';
import { ContentCustom, PageHeaderCustom } from '../../components';
import TodayInterviews from './TodayInterviews';
import AlertCard from './AlertCard';
import { getEmployees } from './utils/getEmployees';
import { getInterviews } from './utils/getInterviews';
import { permissionVerification } from '../../utils/permissionVerification';

const DashboardCard = ({ title, children }) => (
  <Card title={title} bordered={false} className="form-card show-card">
    {children}
  </Card>
);

DashboardCard.propTypes = {
  title: PropTypes.string.isRequired
};

export const HomeDashboard = () => {
  const { dispatchAPI, userPermissions } = useAuthContext();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const [interviews, setInterviews] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [permit, setPermit] = useState(false);

  useEffect(() => {
    const permission = permissionVerification(
      userPermissions,
      '/homedashboard/modification'
    );
    setPermit(permission);
  }, [userPermissions]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const [interviewsData, employeesData] = await Promise.all([
        getInterviews({ dispatchAPI, message }),
        getEmployees({ dispatchAPI, message })
      ]);
      if (interviewsData) setInterviews(interviewsData);
      if (employeesData) setEmployees(employeesData);
      setIsLoading(false);
    })();
  }, []);

  return (
    <>
      <PageHeaderCustom title={t('dashboard.title')} />
      <ContentCustom>
        <Row gutter={[24, 0]} align="stretch">
          <Col xs={24} xl={18}>
            <DashboardCard title={t('dashboard.todays-meetings')}>
              <Spin spinning={isLoading}>
                <TodayInterviews interviews={interviews} />
              </Spin>
            </DashboardCard>
          </Col>
          <Col xs={24} xl={6} space>
            <DashboardCard title={t('dashboard.shortcuts.title')}>
              <Row
                className="dashboard-btn-wrapper"
                gutter={[8, 8]}
                align="middle"
              >
                <Col xs={24} lg={8} xl={24}>
                  <Link to="../recruitments/interviews/create">
                    <Button type="primary" block disabled={!permit}>
                      {t('dashboard.shortcuts.new-meeting')}
                    </Button>
                  </Link>
                </Col>
                <Col xs={24} lg={8} xl={24}>
                  <Link to="../recruitments/candidates/create">
                    <Button type="primary" block disabled={!permit}>
                      {t('dashboard.shortcuts.new-applicant')}
                    </Button>
                  </Link>
                </Col>
                <Col xs={24} lg={8} xl={24}>
                  <Link to="../recruitments/recommendations/create">
                    <Button type="primary" block disabled={!permit}>
                      {t('dashboard.shortcuts.new-recommendation')}
                    </Button>
                  </Link>
                </Col>
                <Col xs={24} lg={8} xl={24}>
                  <Link to="../employee-folder/incidents/create">
                    <Button type="primary" block disabled={!permit}>
                      {t('dashboard.shortcuts.new-incident')}
                    </Button>
                  </Link>
                </Col>
              </Row>
            </DashboardCard>
          </Col>
        </Row>
        <Row gutter={[24, 0]} align="stretch">
          <Col xs={24} xl={12}>
            <DashboardCard title={t('dashboard.planned-shifts')}>
              <Card
                style={{ backgroundColor: 'lightgrey', textAlign: 'center' }}
              >
                {t('global.in-development')}
              </Card>
            </DashboardCard>
          </Col>
          <Col xs={24} xl={12}>
            <DashboardCard title={t('dashboard.yesterdays-turnover')}>
              <Card
                style={{ backgroundColor: 'lightgrey', textAlign: 'center' }}
              >
                {t('global.in-development')}
              </Card>
            </DashboardCard>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <DashboardCard title={t('dashboard.alert.title')}>
              <Spin spinning={isLoading}>
                <AlertCard employees={employees} />
              </Spin>
            </DashboardCard>
          </Col>
        </Row>
      </ContentCustom>
    </>
  );
};
