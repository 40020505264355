import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button, Drawer, Form } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';
import useFields from '../../routes/employee-folder/incidents/showIncident/fields';
import { useGenerateFormItem } from '../../utils/GenerateFormItem';

const InterviewDrawer = ({
  visible,
  setVisible,
  setRefreshData,
  refreshData,
  incidentId
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const generateFormItem = useGenerateFormItem();
  const [users, setUsers] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [interviewtitles, setInterviewtitles] = useState([]);
  const [frames, setFrames] = useState([]);
  const [premises, setPremises] = useState([]);
  const { fields } = useFields({
    users,
    employees,
    interviewtitles,
    frames,
    premises
  });

  const onClose = () => {
    form.resetFields();
    setVisible(false);
  };

  const getUsers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/users'
      });

      const options = [];
      data.map((user) =>
        options.push({
          value: user._id,
          label: `${user.first_name} ${user.last_name}`
        })
      );
      setUsers(options);
    } catch (e) {
      message(e);
    }
  };

  const getEmployees = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/employees'
      });

      const options = [];
      data.map((user) =>
        options.push({
          value: user._id,
          label: `${user.first_name} ${user.last_name}`
        })
      );
      setEmployees(options);
    } catch (e) {
      message(e);
    }
  };

  const getInterviewtitles = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/interviewtitles' });
      const options = [];
      data.map((title) =>
        options.push({
          value: title._id,
          label: title.label
        })
      );
      setInterviewtitles(options);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getFrames = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/interviewframes' });
      const options = [];
      data.map((frame) =>
        options.push({
          value: frame._id,
          label: frame.label
        })
      );
      setFrames(options);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getPremises = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/premise' });
      const options = [];
      data.map((premise) =>
        options.push({
          value: premise._id,
          label: premise.label
        })
      );
      setPremises(options);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getUsers();
      await getEmployees();
      await getInterviewtitles();
      await getFrames();
      await getPremises();
    })();
  }, []);

  const generateDateFormat = ({ date, hour }) => {
    const newMoment = moment({
      year: date.year(),
      month: date.month(),
      date: date.date(),
      hour: hour.hour(),
      minute: hour.minute()
    });

    return newMoment;
  };

  const onFinishForm = async (values) => {
    const body = {
      ...values,
      date: generateDateFormat({ date: values.day, hour: values.time_start }),
      type: 'INCIDENT',
      incident: incidentId
    };
    try {
      await dispatchAPI('POST', {
        url: '/interviews',
        body
      });
      onClose();
      setRefreshData(!refreshData);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  return (
    <Drawer
      destroyOnClose
      title={t('interviews.form.title.planned')}
      width={480}
      onClose={onClose}
      visible={visible}
      getContainer={false}
    >
      <Form
        wrapperCol={{ span: 24 }}
        labelCol={{ span: 24 }}
        form={form}
        onFinish={onFinishForm}
      >
        {fields.map((field) => generateFormItem('interviews', field))}
        <Form.Item style={{ marginTop: 16 }}>
          <Button
            style={{ margin: '0 10px' }}
            type="link"
            danger
            onClick={onClose}
          >
            {`${t('buttons.cancel')} `}
            <CloseOutlined />
          </Button>
          <Button type="add" htmlType="submit">
            {`${t('buttons.save')} `}
            <CheckOutlined />
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default InterviewDrawer;

InterviewDrawer.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  setRefreshData: PropTypes.func,
  refreshData: PropTypes.bool,
  incidentId: PropTypes.string
};

InterviewDrawer.defaultProps = {
  visible: false,
  setVisible: null,
  setRefreshData: null,
  refreshData: false,
  incidentId: null
};
