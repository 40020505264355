/* eslint-disable radix */
/* eslint-disable array-callback-return */
import React, { useState } from 'react';
import CodeMirror from '@uiw/react-codemirror';
import { Button, Row, Col, Input, Select } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { mapInputs, displayTagInMention } from '../../utils';
import {
  generateInitialFinalInput,
  generateInitialFinalInputs
} from '../../../../utils/generateFinalInputs';

const { Option } = Select;
const typesArray = ['String', 'Number', 'Date', 'Object', 'ObjectId'];

const ETLStepTransform = ({
  stepInputs,
  finalInputs,
  setFinalInputs,
  isCRUD,
  nextStep
}) => {
  const [keyMax, setKeyMax] = useState(finalInputs.length || 1);

  const [fields, setFields] = useState(
    (JSON.stringify(finalInputs) !== '{}' && finalInputs) ||
      generateInitialFinalInputs('ETL')
  );

  const addField = () => {
    setKeyMax(keyMax + 1);
    const newField = generateInitialFinalInput('ETL', keyMax);
    const newFields = [...fields, newField];
    setFields(newFields);
  };

  const removeField = (key) => {
    const newFields = fields.filter((field) => field.key !== key);
    setFields(newFields);
  };

  const tagDrag = (event, ElmLabel, field) => {
    // eslint-disable-next-line no-param-reassign
    event.dataTransfer.dropEffect = 'move';
    const data = `@{${ElmLabel}|${field}}`;
    event.dataTransfer.setData('text', data);
  };

  const updateField = (key, type, value) => {
    const newFields = fields.map((field) => {
      const newField = field;
      if (field.key === key) {
        newField[type] = value;
        return newField;
      }
      return field;
    });
    setFields(newFields);
  };

  const onChangeMention = (value, key) => {
    const newFields = [...fields];
    newFields[key].formula = value;
    setFields(newFields);
  };
  const checkBtnEnable = () => {
    let isNoEmptyFormula = true;
    fields.map((field) => {
      if (field.required && field.formula === '') isNoEmptyFormula = false;
    });
    return isNoEmptyFormula;
  };

  const isBtnEnabled = checkBtnEnable();

  const finishTransform = () => {
    setFinalInputs(fields);
    nextStep();
  };

  const displaySelectFormula = (field) => (
    <Select
      style={{ width: '100%' }}
      value={field.formula}
      onChange={(value) => onChangeMention(value, field.key)}
      key={`formula-${field.key}`}
    >
      {field.enum.map((value) => (
        <Option value={value} key={value}>
          {value}
        </Option>
      ))}
    </Select>
  );

  const displayMentionsFormula = (field) => (
    <CodeMirror
      style={{ width: '100%' }}
      value={field.formula}
      options={{
        mode: 'javascript',
        theme: 'material',
        lineNumbers: true
      }}
      onChange={(value) => onChangeMention(value, field.key)}
    >
      {displayTagInMention(stepInputs)}
    </CodeMirror>
  );

  return (
    <Row>
      <Col span={24}>
        <Row style={{ marginBottom: 15 }}>
          {mapInputs(stepInputs, true, tagDrag)}
        </Row>
        {fields.map((field) => (
          <Row
            key={`row-${field.key}`}
            style={{ flexWrap: 'nowrap', marginBottom: 8 }}
            align="middle"
          >
            {isCRUD && field.required && (
              <Col
                span={1}
                style={{
                  color: 'var(--errorColor)',
                  textAlign: 'right',
                  paddingRight: 8
                }}
              >
                *
              </Col>
            )}
            <Col key={`col1-${field.key}`} span={6}>
              <Input
                placeholder="Nom de l'ouput"
                value={field.outputName}
                disabled={isCRUD}
                onChange={(event) =>
                  updateField(field.key, 'outputName', event.target.value)
                }
                style={{ width: '100%' }}
                key={`outputName-${field.key}`}
              />
            </Col>
            <Col key={`col2-${field.key}`} span={12} style={{ marginLeft: 10 }}>
              {field.enum !== undefined
                ? displaySelectFormula(field)
                : displayMentionsFormula(field)}
            </Col>
            <Col key={`col3-${field.key}`} span={4} style={{ marginLeft: 10 }}>
              <Select
                showSearch
                disabled={isCRUD}
                key={`outputType-${field.key}`}
                onChange={(value) =>
                  updateField(field.key, 'outputType', value)
                }
                value={field.outputType}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
                style={{ maxWidth: '100%', minWidth: 100 }}
              >
                {typesArray.map((type) => (
                  <Option key={type} value={type}>
                    {type}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col key={`col4-${field.key}`}>
              {!isCRUD && (
                <MinusCircleOutlined
                  className="dynamic-delete-button"
                  onClick={() => removeField(field.key)}
                  style={{ color: 'var(--errorColor)' }}
                />
              )}
            </Col>
          </Row>
        ))}

        {!isCRUD && (
          <Row>
            <Button
              type="dashed"
              onClick={() => addField()}
              style={{ width: '100%', margin: '8px 0 16px' }}
              icon={<PlusOutlined />}
              block
            >
              Ajouter un output
            </Button>
          </Row>
        )}

        <Button
          type="add"
          block
          disabled={!isBtnEnabled}
          onClick={finishTransform}
        >
          Valider
        </Button>
      </Col>
    </Row>
  );
};

ETLStepTransform.propTypes = {
  stepInputs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  finalInputs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setFinalInputs: PropTypes.func.isRequired,
  isCRUD: PropTypes.bool,
  nextStep: PropTypes.func.isRequired
};

ETLStepTransform.defaultProps = {
  isCRUD: false
};

export default ETLStepTransform;
