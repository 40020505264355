import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ListDocuments from './ListDocuments';
import CreateUpdateDocument from './CreateUpdateDocument';

export const DocumentRouter = () => (
  <Routes>
    <Route path="/create" element={<CreateUpdateDocument purpose="create" />} />
    <Route
      path="/edit/:id/:fileId"
      element={<CreateUpdateDocument purpose="edit" />}
    />
    <Route path="/*" element={<ListDocuments />} />
  </Routes>
);
