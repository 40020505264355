import React from 'react';
import { Switch, Upload, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { UploadOutlined } from '@ant-design/icons/lib';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import { useAuthContext } from '../../../contexts/AuthContext';

const { Dragger } = Upload;

const useFields = ({ premises, positions, setFile, setShow, show }) => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { token } = useAuthContext();

  const type = [
    { value: 'welcome_booklet', label: "Livret d'accueil" },
    { value: 'presentation', label: 'Presentation' },
    { value: 'other', label: 'Autre' }
  ];

  const draggerProps = {
    name: 'file',
    action: `${process.env.REACT_APP_API_URL}/files`,
    headers: { Authorization: `Bearer ${token}` },
    onChange(info) {
      const { status, response } = info.file;

      setFile(response);

      if (status === 'error') {
        message(`${info.file.name} file upload failed.`);
      }
    }
  };

  const handleSwitchChange = (checked) => {
    setShow(checked);
  };

  const createFields = [
    {
      name: ['type'],
      input: <Select options={type} />,
      rules: [{ required: true }]
    },
    {
      name: ['premise'],
      input: <Select options={premises} />,
      rules: [{ required: true }]
    },
    {
      name: ['visibility'],
      input: <Select mode="multiple" options={positions} />,
      rules: [{ required: true }]
    },
    {
      name: ['filename'],
      rules: [{ required: true }]
    },
    {
      name: ['show'],
      input: <Switch onChange={handleSwitchChange} checked={show} />
    },
    {
      name: ['file'],
      input: (
        <Dragger {...draggerProps}>
          <p className="ant-upload-drag-icon">
            <UploadOutlined style={{ color: 'var(--textColor)' }} />
          </p>
          <p className="ant-upload-text">{t('files.create.action')}</p>
        </Dragger>
      ),
      rules: [{ required: true }]
    }
  ];

  const editFields = [
    {
      name: ['type'],
      input: <Select options={type} />,
      rules: [{ required: true }]
    },
    {
      name: ['premise'],
      input: <Select options={premises} disabled />,
      rules: [{ required: true }]
    },
    {
      name: ['visibility'],
      input: <Select mode="multiple" options={positions} />,
      rules: [{ required: true }]
    },
    {
      name: ['filename'],
      rules: [{ required: true }]
    },
    {
      name: ['show'],
      input: <Switch onChange={handleSwitchChange} checked={show} />
    }
  ];

  return {
    createFields,
    editFields
  };
};

export default useFields;
