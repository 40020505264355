import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Progress, Row, Switch } from 'antd';
import { ListResource } from '../../../components';
import { useAuthContext } from '../../../contexts/AuthContext';
import { permissionVerification } from '../../../utils/permissionVerification';

const ListTrainingProgresss = () => {
  const { t } = useTranslation();
  const { userPermissions } = useAuthContext();
  const [isArchived, setIsArchived] = useState(false);
  const [permit, setPermit] = useState(false);

  useEffect(() => {
    const permission = permissionVerification(
      userPermissions,
      '/trainings/follow-up/modification'
    );
    setPermit(permission);
  }, [userPermissions]);

  const headers = [
    {
      label: 'emoployee',
      key: 'emoployee'
    },
    {
      label: 'profiletree',
      key: 'profiletree'
    },
    {
      label: 'progress',
      key: 'progress'
    }
  ];

  const columns = [
    {
      title: t('trainingprogress.form.employee'),
      key: 'employee',
      dataIndex: 'employee',
      sorter: true,
      render: (employee) => `${employee?.first_name} ${employee?.last_name}`
    },
    {
      title: t('trainingprogress.form.profiletree'),
      key: 'profiletree',
      dataIndex: 'profiletree',
      sorter: true,
      render: (profiletree) => `${profiletree?.title}`
    },
    {
      title: t('trainingprogress.form.progress'),
      key: 'progress',
      dataIndex: 'progress',
      sorter: true,
      render: (progress) => <Progress percent={progress} />
    },
    {
      title: t('trainingprogress.form.premise'),
      key: 'employee',
      dataIndex: 'employee',
      sorter: true,
      render: (employee) => `${employee?.premise.label}`
    }
  ];
  return (
    <ListResource
      resourceName="trainingprogress"
      columns={columns}
      headers={headers}
      editAction={permit}
      deleteAction={permit}
      withCreateButton={permit}
      resourceModelName="Trainingprogress"
      populate="employee,employee.premise,profiletree"
      extraButtons={
        <Row align="middle" className="list-switch-wrapper">
          <Switch onChange={() => setIsArchived(!isArchived)} />
          <p>{t('trainingprogress.archived')}</p>
        </Row>
      }
      extraQuery={isArchived ? 'is_archived=true' : 'is_archived=false'}
    />
  );
};

export default ListTrainingProgresss;
