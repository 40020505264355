import React from 'react';
import { useTranslation } from 'react-i18next';
import { endOfWeek, getMonth, format, getWeek, addWeeks } from 'date-fns';
import { Tag, Typography } from 'antd';

interface MonthNameProps {
  startWeek: Date;
  weekConfiguration: string;
}

export const MonthName: React.FunctionComponent<MonthNameProps> = ({
  startWeek,
  weekConfiguration
}) => {
  const { t } = useTranslation();
  const getMonthName = () => {
    const endOfWeekDate = endOfWeek(startWeek);

    if (getMonth(endOfWeekDate) == getMonth(startWeek)) {
      return `${t(`calendar.months.${format(startWeek, 'MMMM')}`)}     ${format(
        startWeek,
        'y'
      )}`;
    }

    return `${t(`calendar.months.${format(startWeek, 'MMMM')}`)} ${
      format(startWeek, 'y') !== format(endOfWeekDate, 'y')
        ? format(startWeek, 'y')
        : ''
    } - ${t(`calendar.months.${format(endOfWeekDate, 'MMMM')}`)} ${format(
      endOfWeekDate,
      'y'
    )}`;
  };

  return (
    <div style={{ display: 'flex', maxHeight: '25px' }}>
      <Typography.Title
        level={5}
        style={{ marginBottom: 0, marginRight: '10px' }}
      >
        {getMonthName()}
      </Typography.Title>
      <Tag>
        {`${t('calendar.week')}
        ${
          weekConfiguration === 'ISO'
            ? getWeek(startWeek)
            : getWeek(new Date(addWeeks(startWeek, -1)))
        }`}
      </Tag>
    </div>
  );
};
