import React, { useState, useEffect } from 'react';
import { DatePicker, Select } from 'antd';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';

const personnalInformations = () => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { Option } = Select;

  const [
    isPersonnalInformationsFieldsLoading,
    setIsPersonnalInformationsFieldsLoading
  ] = useState(true);

  const [employees, setEmployees] = useState([]);
  const [employee, setEmployee] = useState({});

  const getListEmployees = async () => {
    setIsPersonnalInformationsFieldsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/employees?populate=premise position'
      });
      setEmployees(data);
      setIsPersonnalInformationsFieldsLoading(false);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getListEmployees();
    })();
  }, []);

  const handleEmployeeChange = (value) => {
    const employeeSelectedIndex = employees.findIndex((e) => e._id === value);
    setEmployee(employees[employeeSelectedIndex]);
  };

  const employeeInformationsFields = [
    {
      name: ['name'],
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          onChange={handleEmployeeChange}
        >
          {(employees || []).map((e) => (
            <Option
              label={e.first_name + e.last_name}
              key={e._id}
              value={e._id}
            >
              {e.first_name} {e.last_name}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'premise',
      name: ['premise', 'label']
    },
    {
      label: 'position',
      name: ['position', 'label']
    },
    {
      label: 'grade',
      name: ['grade']
    },
    {
      label: 'level',
      name: ['level']
    },
    {
      label: 'start_date',
      name: ['start_date'],
      input: (
        <DatePicker
          disabled="true"
          style={{ width: '100%' }}
          format="DD/MM/YYYY"
        />
      )
    }
  ];

  return {
    employeeInformationsFields,
    isPersonnalInformationsFieldsLoading,
    employee
  };
};

export default personnalInformations;
