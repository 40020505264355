/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { Timeline, Typography } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

const ListEvents = ({ employee }) => {
  const { t } = useTranslation();

  return (
    <Timeline
      style={{
        maxHeight: '400px',
        overflowY: 'scroll',
        width: '100%'
      }}
    >
      {(employee?.history || []).map((event, index) => {
        const formattedDate = moment(event.date).format('DD/MM/YYYY à HH:mm');

        return (
          <Timeline.Item
            style={{
              marginTop: '10px'
            }}
            key={index}
          >
            <Text>{t(`employees.form.events.${event.type}`)}</Text>
            <br />
            <Text type="secondary" italic style={{ color: 'grey' }}>
              {`par ${event.by.first_name} ${event.by.last_name} le ${formattedDate}`}
            </Text>
          </Timeline.Item>
        );
      })}
    </Timeline>
  );
};

ListEvents.propTypes = {
  employee: PropTypes.shape({
    _id: PropTypes.string,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        by: PropTypes.shape({
          first_name: PropTypes.string,
          last_name: PropTypes.string
        }),
        date: PropTypes.string
      })
    )
  })
};

ListEvents.defaultProps = {
  employee: {}
};

export default ListEvents;
