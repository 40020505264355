import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Card, Row, Col, Button, Switch } from 'antd';
import { PageHeaderCustom, ContentCustom } from '../../../components';
import { routes, subRoutes } from '../../../utils/constants/adminRoutes';
import ParcoursTree from './ParcoursTree/ParcoursTree';
import ProfileTree from './ProfileTree/ProfileTree';
import { permissionVerification } from '../../../utils/permissionVerification';
import { useAuthContext } from '../../../contexts/AuthContext';

export const ListTraining = () => {
  const { t } = useTranslation();
  const { userPermissions } = useAuthContext();
  const [isTrainingArchived, setIsTrainingArchived] = useState(false);
  const [isProfileArchived, setIsProfileArchived] = useState(false);
  const [permit, setPermit] = useState(false);

  useEffect(() => {
    const permission = permissionVerification(
      userPermissions,
      '/trainings/trainings-path/parcourstree/modification' &&
        '/trainings/trainings-path/profiletree/modification'
    );
    setPermit(permission);
  }, [userPermissions]);

  return (
    <>
      <PageHeaderCustom title={t('trainings.title')} />

      <ContentCustom>
        <Row gutter={[16, 16]}>
          <Col xs={24} xl={12}>
            <Card
              title={t('trainings.trainingtrees.title')}
              extra={
                <Row align="middle" className="list-switch-wrapper">
                  <Switch
                    onChange={() => setIsTrainingArchived(!isTrainingArchived)}
                  />
                  <p>{t('trainings.trainingtrees.archived')}</p>
                </Row>
              }
              bordered={false}
              className="form-card training-list-card"
            >
              <ParcoursTree isArchived={isTrainingArchived} />
              <Link
                to={{
                  pathname: `${routes.TRAININGS}${subRoutes.TRAININGS.PATH}/create`
                }}
              >
                <Button type="dashed" block disabled={!permit}>
                  {t('trainings.button.add-path')}
                </Button>
              </Link>
            </Card>
          </Col>
          <Col xs={24} xl={12}>
            <Card
              title={t('trainings.profiletrees.title')}
              extra={
                <Row align="middle" className="list-switch-wrapper">
                  <Switch
                    onChange={() => setIsProfileArchived(!isProfileArchived)}
                  />
                  <p>{t('trainings.profiletrees.archived')}</p>
                </Row>
              }
              bordered={false}
              className="form-card training-list-card"
            >
              <ProfileTree isArchived={isProfileArchived} />
              <Link
                to={{
                  pathname: `${routes.TRAININGS}${subRoutes.TRAININGS.PATH}/profiletree/create`
                }}
              >
                <Button type="dashed" block disabled={!permit}>
                  {t('trainings.button.add-profile')}
                </Button>
              </Link>
            </Card>
          </Col>
        </Row>
      </ContentCustom>
    </>
  );
};
