import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Button, Popconfirm, Skeleton, Row, Avatar, Card, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';
import listContent from './listContent';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../components';
import { routes } from '../../utils/constants/adminRoutes';
import { permissionVerification } from '../../utils/permissionVerification';

const ShowUser = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { dispatchAPI, userPermissions } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [permit, setPermit] = useState(false);

  useEffect(() => {
    const permission = permissionVerification(
      userPermissions,
      '/users/modification'
    );
    setPermit(permission);
  }, [userPermissions]);

  const getUser = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/users/${id}?populate=position,permissions`
      });
      setUser(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getUser();
    })();
  }, [getUser]);

  const deleteUser = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/users/${id}` });
      navigate(-1);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  return (
    <>
      <PageHeaderCustom
        title={t('users.show.title')}
        extra={
          <>
            <Link to={{ pathname: `${routes.USERS}/edit/${id}` }}>
              {permit && (
                <Button type="primary">
                  {`${t('buttons.edit')} `}
                  <EditOutlined />
                </Button>
              )}
            </Link>
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={deleteUser}
              icon={<WarningOutlined />}
            >
              {permit && (
                <Button type="link" danger>
                  {`${t('buttons.delete')} `}
                  <DeleteOutlined />
                </Button>
              )}
            </Popconfirm>
          </>
        }
      />
      <ContentCustom>
        <Card
          title={t('users.show.main')}
          bordered={false}
          style={{ width: '100%' }}
          className="form-card"
        >
          <Row gutter={[0, 0]}>
            <Skeleton
              loading={isLoading}
              title={0}
              paragraph={0}
              avatar={{ size: 100 }}
              active
            >
              <Avatar size={100} src={user && user.photo ? user.photo : ``} />
            </Skeleton>
            <Divider />
          </Row>
          <Row>
            <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
              <DescriptionList data={listContent(user || {}, t)} translate />
            </Skeleton>
          </Row>
        </Card>
      </ContentCustom>
    </>
  );
};

export default ShowUser;
