import React from 'react';
import { Input, Select, Form } from 'antd';
import { validatePhoneNumber } from '../../../utils/phoneNumber';
import countryCodes from '../../../utils/countryCodes';

const { Option } = Select;
const { TextArea } = Input;

const useFields = ({ employees, offers, isFieldsLoading }) => {
  const fields = [
    {
      name: ['employee'],
      rules: [{ required: true }],
      required: true,
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          loading={isFieldsLoading}
        >
          {(employees || []).map((employee) => (
            <Option
              label={employee.first_name + employee.last_name}
              key={employee._id}
              value={employee._id}
            >
              {employee.first_name} {employee.last_name}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['offer'],
      rules: [{ required: true }],
      required: true,
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          loading={isFieldsLoading}
        >
          {(offers || []).map((offer) => (
            <Option label={offer.name} key={offer._id} value={offer._id}>
              {`${offer.name} (${offer.status})`}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['_id'],
      hidden: true
    },
    {
      name: ['first_name'],
      rules: [{ required: true }]
    },
    {
      name: ['last_name'],
      rules: [{ required: true }]
    },
    {
      name: ['email'],
      rules: [{ type: 'email' }]
    },
    {
      name: ['phone_number'],
      label: 'phone_number',
      rules: [{ required: true }],
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: '25%' }}>
              {countryCodes.map((country) => (
                <Option key={country.code} value={country.code}>
                  ({country.code}) {country.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            noStyle
            name={['phone_number', 'number']}
            label="phone_number.number"
            rules={[{ validator: validatePhoneNumber }]}
          >
            <Input style={{ width: '75%' }} />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      label: 'how_known_candidate',
      name: ['how_known_candidate'],
      input: <TextArea rows="6" />
    },
    {
      label: 'why_recommanded',
      name: ['why_recommanded'],
      input: <TextArea rows="6" />
    }
  ];

  return {
    fields,
    isFieldsLoading
  };
};

export default useFields;
