import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Skeleton, Row, Col, Upload, Collapse } from 'antd';
import {
  InboxOutlined,
  CheckCircleOutlined,
  HourglassOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import ListProvideDocuments from './ListProvideDocuments';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../../utils/ErrorMessage';

const { Dragger } = Upload;
const { Panel } = Collapse;

const CollapseProvideDocuments = ({
  setFiles,
  provideDocuments,
  isLoading,
  files,
  handlePostDocuments,
  employee
}) => {
  const { token } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const [types, setTypes] = useState([]);

  const getTypes = () => {
    const filtredTypes = files.map((obj) => obj.type);
    setTypes(filtredTypes);
  };

  useEffect(() => {
    getTypes();
  }, [files]);

  const draggerProps = {
    name: 'file',
    multiple: true,
    action: `${process.env.REACT_APP_API_URL}/files`,
    headers: { Authorization: `Bearer ${token}` },
    onChange(info) {
      const { status, response } = info.file;

      setFiles(response);

      if (status === 'error') {
        message(`${info.file.name} file upload failed.`);
      }
    }
  };
  return (
    <div>
      {provideDocuments.length &&
        provideDocuments.map((document) => (
          <Collapse accordion>
            <Panel
              extra={
                types.length && types.includes(document) ? (
                  <CheckCircleOutlined style={{ color: 'green' }} />
                ) : (
                  <HourglassOutlined />
                )
              }
              header={t(`employees.documents.${document}`)}
              key={document}
            >
              <Row style={{ width: '100%' }}>
                <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
                  <Row
                    gutter={[24, 24]}
                    align="middle"
                    style={{ width: '100%' }}
                  >
                    <Col xs={24} lg={8}>
                      <div>
                        <Dragger {...draggerProps}>
                          <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                          </p>
                          <p className="ant-upload-text">
                            {t('employees.form.button.drag_and_drop')}
                          </p>
                        </Dragger>
                        {files && (
                          <Button onClick={() => handlePostDocuments(document)}>
                            {t('buttons.register')}
                          </Button>
                        )}
                      </div>
                    </Col>
                    <Col xs={24} lg={16}>
                      <ListProvideDocuments
                        data={employee}
                        document={document}
                      />
                    </Col>
                  </Row>
                </Skeleton>
              </Row>
            </Panel>
          </Collapse>
        ))}
    </div>
  );
};

export default CollapseProvideDocuments;

CollapseProvideDocuments.propTypes = {
  employee: PropTypes.shape({
    _id: PropTypes.string,
    photo: PropTypes.string,
    documents: PropTypes.arrayOf({})
  }),
  isLoading: PropTypes.bool.isRequired,
  files: PropTypes.arrayOf(PropTypes.shape({ _id: PropTypes.string })),
  setFiles: PropTypes.func.isRequired,
  handlePostDocuments: PropTypes.func.isRequired,
  provideDocuments: PropTypes.arrayOf(PropTypes.string).isRequired
};

CollapseProvideDocuments.defaultProps = {
  employee: {},
  files: []
};
