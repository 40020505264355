import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Row, Form, Spin, Button, Col } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { PageHeaderCustom } from '../../../components/PageHeader/PageHeader';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import {
  formItemLayout,
  tailFormItemLayout
} from '../../../utils/constants/formLayout';
import FormInfo from './FormInfo';
import { GrapesJSEditor } from './GrapesJS/GrapesJSEditor';
import { routes } from '../../../utils/constants/adminRoutes';

export const DocEditor = ({ purpose }) => {
  const [form] = Form.useForm();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [status, setStatus] = useState([]);
  const [configurations, setConfigurations] = useState([]);
  const [template, setTemplate] = useState();
  const editorRef = useRef(null);
  const [populateList, setPopulateList] = useState([]);
  const [pickedConfiguration, setPickedConfiguration] = useState();
  const [step, setStep] = useState(0);
  const [formValues, setFormValues] = useState({
    title: '',
    status: '',
    default_template: ''
  });

  const getResource = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `template-documents/${id}`
      });
      setTemplate(data);
      setPopulateList(data.populateList);
      setFormValues({
        title: data.title,
        status: data.status,
        default_template: data.default_template
      });
      setPickedConfiguration(data.configuration);
      form.setFieldsValue({
        ...data,
        configuration: data.configuration.use_case
      });
    } catch (e) {
      message(e);
    }
  }, []);

  const getEnums = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/template-documents/enums'
      });
      setConfigurations(data.configuration);
      setStatus(data.status);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (purpose === 'edit') {
      setIsLoading(true);
      if (!isLoading && configurations)
        (async () => {
          await getResource();
        })();
    }
  }, [getResource]);

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      await getEnums();
    })();
  }, []);

  const createTemplateDocument = async () => {
    setIsSubmitting(true);
    const editor = editorRef.current;
    const content = editor.runCommand('gjs-get-inlined-html');
    try {
      await dispatchAPI('POST', {
        url: 'template-documents',
        body: {
          ...formValues,
          populateList,
          configuration: pickedConfiguration,
          content
        }
      });
      navigate(`${routes.TEMPLATE_DOCS}`);
    } catch (e) {
      setIsSubmitting(false);

      message(e);
    }
  };

  const updateTemplateDocument = async () => {
    setIsSubmitting(true);
    const editor = editorRef.current;
    const content = editor.runCommand('gjs-get-inlined-html');

    try {
      await dispatchAPI('PATCH', {
        url: `template-documents/${id}`,
        body: {
          ...formValues,
          populateList,
          configuration: pickedConfiguration,
          content
        }
      });
      navigate(`${routes.TEMPLATE_DOCS}`);
    } catch (e) {
      setIsSubmitting(false);
      message(e);
    }
  };

  const handleSubmit = async (values) => {
    if (purpose === 'edit') await updateTemplateDocument(values);
    if (purpose === 'create') await createTemplateDocument(values);
  };

  return (
    <>
      {step === 0 && <PageHeaderCustom title={t(`template-documents.title`)} />}
      <Spin spinning={isLoading}>
        <Form
          {...formItemLayout}
          layout="horizontal"
          form={form}
          onFinish={handleSubmit}
          onValuesChange={(_, allValues) => setFormValues(allValues)}
        >
          {step === 0 && (
            <FormInfo
              status={status}
              configurations={configurations}
              populateList={populateList}
              setPickedConfiguration={setPickedConfiguration}
              pickedConfiguration={pickedConfiguration}
            />
          )}
          {step === 1 && (
            <GrapesJSEditor
              template={template}
              purpose={purpose}
              setPopulateList={setPopulateList}
              pickedConfiguration={pickedConfiguration}
              editorRef={editorRef}
            />
          )}
          {step === 0 ? (
            <Row justify="center">
              <Col
                xs={{
                  span: 24,
                  offset: 0
                }}
                sm={{
                  span: 14,
                  offset: 8
                }}
                md={{ span: 12, offset: 8 }}
                lg={{ span: 10, offset: 6 }}
              >
                <Button
                  style={{ margin: '0 10px' }}
                  type="link"
                  danger
                  onClick={() => navigate(-1)}
                >
                  <CloseOutlined />
                  {`${t('buttons.cancel')} `}
                </Button>
                <Button
                  type="add"
                  onClick={() => setStep(1)}
                  disabled={!pickedConfiguration}
                >
                  <CheckOutlined />
                  {t('template-documents.form.edit_template')}
                </Button>
              </Col>
            </Row>
          ) : (
            <Form.Item {...tailFormItemLayout}>
              <Row justify="center">
                <Button
                  style={{ margin: '0 10px' }}
                  type="link"
                  danger
                  onClick={() => navigate(-1)}
                >
                  <CloseOutlined />
                  {`${t('buttons.cancel')} `}
                </Button>

                <Button type="add" htmlType="submit" loading={isSubmitting}>
                  <CheckOutlined />
                  {`${t('buttons.save')} `}
                </Button>
              </Row>
            </Form.Item>
          )}
        </Form>
      </Spin>
    </>
  );
};

DocEditor.propTypes = {
  purpose: PropTypes.string.isRequired
};
