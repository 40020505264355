import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Typography, Card, Input, Button, notification } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/ErrorMessage';
import { ContentCustom, PageHeaderCustom } from '../../../../components';
import ListTrainings from './ListTrainings';

const CreateUpdateTraining = ({ purpose }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [trainingTree, setTrainingTree] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const getTrainingTree = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/trainingtrees/${id}`
      });
      setTrainingTree(data || []);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    if (purpose === 'edit') await getTrainingTree();
  }, []);

  const setTitle = (value) => {
    const newTrainingTree = JSON.parse(JSON.stringify(trainingTree));
    newTrainingTree.title = value;
    setTrainingTree(newTrainingTree);
  };

  const setChildren = (value) => {
    const newTrainingTree = JSON.parse(JSON.stringify(trainingTree));
    newTrainingTree.children = value;
    setTrainingTree(newTrainingTree);
  };

  const saveTrainingTree = async () => {
    setIsSubmitting(true);
    try {
      if (purpose === 'edit') {
        await dispatchAPI('PATCH', {
          url: `/trainingtrees/${id}`,
          body: trainingTree
        });

        notification.success({
          message: 'La formation a bien été mise à jour',
          key: 'network_feedback'
        });
      } else {
        await dispatchAPI('POST', {
          url: `/trainingtrees`,
          body: trainingTree
        });

        notification.success({
          message: 'La formation a bien été créée',
          key: 'network_feedback'
        });
      }
      navigate(-1);
      setIsSubmitting(false);
    } catch (e) {
      setIsSubmitting(false);
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return (
    <>
      <PageHeaderCustom
        title={t(`trainings.trainingtrees.form.title.${purpose}`)}
      />
      <ContentCustom>
        <Row>
          <Col span={24}>
            <Typography.Text>
              {t('trainings.trainingtrees.form.name')}
            </Typography.Text>
          </Col>
          <Col span={24}>
            <Input
              value={trainingTree.title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Card
              title={t('trainings.trainingtrees.show.trainings')}
              bordered={false}
              className="form-card training-form-card"
            >
              <ListTrainings
                list={trainingTree.children || []}
                setList={setChildren}
              />
            </Card>
          </Col>
        </Row>
        <Row justify="end" style={{ gap: 8 }}>
          <Button type="link" danger onClick={() => navigate(-1)}>
            {`${t('buttons.cancel')} `}
            <CloseOutlined />
          </Button>
          <Button type="add" onClick={saveTrainingTree} loading={isSubmitting}>
            {`${t('buttons.save')} `}
            <CheckOutlined />
          </Button>
        </Row>
      </ContentCustom>
    </>
  );
};

CreateUpdateTraining.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateTraining;
