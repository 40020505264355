import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Tag, Steps, message as antdMessage } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/ErrorMessage';
import { useHandleResize } from '../../../../utils/HandleResize';

import { ContentCustom, PageHeaderCustom } from '../../../../components';
import { statusTag } from '../ListExits';
import { exitsStatus } from '../../../../utils/constants/tagColors';
import DetailsCards from './DetailsCards/DetailsCards';
import HeaderExtra from './HeaderExtra/HeaderExtra';
import Modals from './Modals/Modals';

import setStep from './utils/setStep';
import setExitType from './utils/exitType';
import { validatedSteps } from './utils/validatedSteps';
import { emailModel } from './utils/emailModel';

const ShowExit = () => {
  const { width } = useHandleResize();
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(true);
  const [exit, setExit] = useState({});
  const [employee, setEmployee] = useState({});
  const [enums, setEnums] = useState({});
  const [activeStep, setActiveStep] = useState('');
  const [stepType, setStepType] = useState('');
  const [files, setFiles] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [restitutionType, setRestitutionType] = useState('');
  const [absenceReasonType, setAbsenceReasonType] = useState('');
  const [acknowledgmentOfReceiptType, setAcknowledgmentOfReceiptType] =
    useState('');
  const { exitType } = setExitType({ exit });

  const handlePostDocuments = async () => {
    try {
      const formData = new FormData();
      files.forEach((doc) =>
        formData.append('files', doc.originFileObj || doc)
      );
      await dispatchAPI('POST', {
        url: `/employees/upload/${id}`,
        body: formData
      });
      antdMessage.success(t('documents.success'));
      setRefresh(!refresh);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getEnums = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/exits/enums`
      });
      setEnums(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  const setNextStep = () => {
    switch (exit.motif) {
      case 'break_of_trial_period':
        return enums?.exitsStep[exit.motif][exit.break_initited_by];
      case 'dismissal':
        return enums?.exitsStep[exit.motif][exit.type_of_dismissal];
      default:
        return enums?.exitsStep[exit.motif];
    }
  };

  const nextStep = async (target) => {
    let newStep = '';
    if (typeof target === 'string') newStep = target;
    else {
      setNextStep().forEach((step, index) => {
        if (step === activeStep) {
          newStep = setNextStep()[index + 1];
        }
      });
    }
    try {
      await dispatchAPI('PATCH', {
        url: `/exits/${id}`,
        body: exitType(newStep)
      });
      setActiveStep(newStep);
      setRefresh(!refresh);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getEmployee = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/employees/${exit?.employee}?populate=position,premise,recommended_by,files,equipments._id,history.by,equipments`
      });
      setEmployee(data);

      if (data?.equipments.length === 0 && !exit?.is_material) {
        await dispatchAPI('PATCH', {
          url: `/exits/${exit._id}`,
          body: { is_material: true }
        });
      }
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [exit]);

  const getExit = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/exits/${id}?populate=equipments._id,preliminary_interview_location,previous_meeting_location`
      });
      setExit(data);
      setStepType(`${data?.motif}_step`);
      if (data?.break_initited_by) {
        setActiveStep(data[`${data?.motif}_step`][data.break_initited_by]);
      } else if (data?.type_of_dismissal) {
        setActiveStep(data[`${data?.motif}_step`][data.type_of_dismissal]);
      } else {
        setActiveStep(data[`${data?.motif}_step`]);
      }
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  const deleteExit = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/exits/${id}` });
      navigate(-1);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getEnums();
      await getExit();
    })();
  }, [getExit, refresh]);

  useEffect(() => {
    (async () => {
      if (exit?.employee !== undefined) await getEmployee();
    })();
  }, [exit, refresh]);

  useEffect(() => {
    emailModel({ activeStep, absenceReasonType });
  }, [activeStep]);

  return (
    <>
      <PageHeaderCustom
        title={
          employee.first_name && (
            <>
              {`${employee?.first_name} ${employee?.last_name}`}
              <Tag
                style={{
                  backgroundColor: exitsStatus[exit?.status]
                }}
                className="white-text-tag"
              >
                {statusTag(exit?.status)?.props?.children}
              </Tag>
            </>
          )
        }
        extra={
          <HeaderExtra
            exit={exit}
            employee={employee}
            activeStep={activeStep}
            nextStep={nextStep}
            deleteExit={deleteExit}
            setIsModalOpen={setIsModalOpen}
            setRestitutionType={setRestitutionType}
            restitutionType={restitutionType}
            setAcknowledgmentOfReceiptType={setAcknowledgmentOfReceiptType}
            setAbsenceReasonType={setAbsenceReasonType}
            setRefresh={setRefresh}
            refresh={refresh}
          />
        }
      />
      <ContentCustom>
        {stepType && stepType.length && (
          <Steps
            current={validatedSteps({ exit, enums, stepType })}
            className="entry-steps"
            direction={width < 1400 ? 'vertical' : 'horizontal'}
          >
            {enums?.exitsStep &&
              stepType.length &&
              setStep({ exit, enums, nextStep, t })}
          </Steps>
        )}
        <DetailsCards
          employee={employee}
          exit={exit}
          enums={enums}
          stepType={stepType}
          isLoading={isLoading}
          files={employee?.files}
          setRefresh={setRefresh}
          refresh={refresh}
          setFiles={setFiles}
          handlePostDocuments={handlePostDocuments}
        />
      </ContentCustom>
      {!isLoading && (
        <Modals
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          motif={exit?.motif}
          setRefresh={setRefresh}
          refresh={refresh}
          activeStep={activeStep}
          exit={exit}
          nextStep={nextStep}
          setRestitutionType={setRestitutionType}
          restitutionType={restitutionType}
          employee={employee}
          setAcknowledgmentOfReceiptType={setAcknowledgmentOfReceiptType}
          acknowledgmentOfReceiptType={acknowledgmentOfReceiptType}
          setAbsenceReasonType={setAbsenceReasonType}
          absenceReasonType={absenceReasonType}
          emailModel={emailModel({ activeStep, absenceReasonType })}
        />
      )}
    </>
  );
};

export default ShowExit;
