import headerIcon from '../../../../../../assets/images/icons/header-icon.png';
import footerIcon from '../../../../../../assets/images/icons/footer-icon.png';

export const registerHeaderAndFooter = (editor) => {
  const blockManager = editor.Blocks;
  const domComponents = editor.DomComponents;

  const hasBlock = (type) => {
    const components = editor.DomComponents.getComponents();
    return components.some(
      (component) => component.get('attributes')['data-type'] === type
    );
  };

  const removeBlockFromPanel = (type) => {
    const blocks = blockManager.getAll();
    const block = blocks.find(
      (item) => item.get('attributes')['data-type'] === type
    );
    if (block) {
      blockManager.remove(block.get('id'));
    }
  };

  const addBlockToPanel = (block) => {
    blockManager.add(block.id, {
      label: block.label,
      category: block.category,
      content: block.content,
      attributes: { 'data-type': block.attributes['data-type'] }
    });
  };

  const headerBlock = {
    label: `<img src="${headerIcon}" alt="Header Icon" width="50" height="50" style="margin-bottom: 10px;"> Header`,
    category: 'Basic',
    content:
      '<header style="height: 100px; width: 100%; font-size: 18px;"><div class="header-content">This is the header block</div></header>',
    attributes: { 'data-type': 'header' }
  };

  const footerBlock = {
    label: `<img src="${footerIcon}" alt="Header Icon" width="50" height="50" style="margin-bottom: 10px;"> Footer`,
    category: 'Basic',
    content:
      '<footer style="height: 100px; width: 100%; font-size: 18px;"><div class="footer-content">This is the footer block</div></footer>',
    attributes: { 'data-type': 'footer' }
  };

  domComponents.addType('header', {
    isComponent: (el) => el.tagName === 'HEADER',
    model: {
      defaults: {
        tagName: 'HEADER',
        droppable: true,
        attributes: {
          'data-type': 'header'
        },
        traits: ['name']
      }
    }
  });

  domComponents.addType('footer', {
    isComponent: (el) => el.tagName === 'FOOTER',
    model: {
      defaults: {
        tagName: 'FOOTER',
        droppable: true,
        attributes: {
          'data-type': 'footer'
        },
        traits: ['name']
      }
    }
  });

  blockManager.add('header', headerBlock);

  blockManager.add('footer', footerBlock);

  if (hasBlock('header')) {
    removeBlockFromPanel('header');
  }

  if (hasBlock('footer')) {
    removeBlockFromPanel('footer');
  }

  editor.on('component:remove', (model) => {
    const attributes = model.getAttributes();
    const type = attributes['data-type'];

    if (type === 'header' && !hasBlock('header')) {
      addBlockToPanel(headerBlock);
    }

    if (type === 'footer' && !hasBlock('footer')) {
      addBlockToPanel(footerBlock);
    }
  });

  editor.on('component:add', (component) => {
    const componentType = component.get('type');

    if (componentType === 'header' || componentType === 'footer') {
      removeBlockFromPanel(componentType);
    }
  });
};
