const mailSubject = ({ activeStep }) => {
  switch (activeStep) {
    case 'PROMISE':
      return "Votre promesse d'embauche est prête";
    case 'WELCOME_EMAIL':
      return 'Bienvenue';
    case 'DOCS_VALIDATED':
      return 'Votre contrat de travail est prêt';
    case 'INVITATION_EMAIL':
      return 'Bienvenue chez nous !';
    default:
      return 'Tous les documents sont valides';
  }
};

export default mailSubject;
