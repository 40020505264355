const setExitType = ({ exit }) => {
  const patchDismissalStep = (newStep) => {
    switch (exit?.type_of_dismissal) {
      case 'real_or_serious_cause':
        return { dismissal_step: { real_or_serious_cause: newStep } };
      case 'serious_fault_with_post_abandonment':
        return {
          dismissal_step: { serious_fault_with_post_abandonment: newStep }
        };
      case 'serious_fault_without_post_abandonment':
        return {
          dismissal_step: { serious_fault_without_post_abandonment: newStep }
        };
      default:
        return null;
    }
  };

  const exitType = (newStep) => {
    switch (exit.motif) {
      case 'resignation':
        return { resignation_step: newStep };
      case 'conventional_rupture':
        return { conventional_rupture_step: newStep };
      case 'dismissal':
        return patchDismissalStep(newStep);
      case 'break_of_trial_period':
        return exit?.break_initited_by === 'employer'
          ? { break_of_trial_period_step: { employer: newStep } }
          : { break_of_trial_period_step: { employee: newStep } };
      default:
        return {};
    }
  };

  return { exitType };
};

export default setExitType;
