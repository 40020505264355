import moment from 'moment';

const updateActivity = async ({
  values,
  dispatchAPI,
  application,
  itemSelected,
  successMessage,
  errorMessage,
  setListActivity,
  setFormActivity,
  t
}) => {
  let newActivities;

  if (values.type === 'meeting') {
    const durationInMs = values.time_end.diff(values.time_start);
    const durationInHours = moment.duration(durationInMs).asHours();

    const interviewAdress = await dispatchAPI('GET', {
      url: `premise/${values.premise}`
    });
    const body = {
      ...values,
      type: 'CANDIDATE',
      application: application._id,
      candidate: application.candidate._id
    };

    const { data } = await dispatchAPI('PATCH', {
      url: `interviews/${itemSelected.interview_id}?populate=header`,
      body
    });

    newActivities = application.activities.map((activity) => {
      if (activity.interview_id === itemSelected._id)
        return {
          ...values,
          _id: activity._id,
          dateTime: values.date,
          duration: durationInHours.toFixed(2),
          address: interviewAdress?.data?.label,
          title: data?.header.label,
          interview_id: data._id
        };
      return activity;
    });
  } else {
    newActivities = application.activities.map((activity) => {
      if (activity._id === itemSelected._id)
        return {
          ...values,
          _id: activity._id,
          dateTime: values.dateTime
        };
      return activity;
    });
  }

  try {
    await dispatchAPI('PATCH', {
      url: `/applications/${application._id}`,
      body: {
        activities: newActivities
      }
    });

    successMessage(t('applications.show.update_activity_success'));
  } catch (e) {
    if (e.response) errorMessage(e.response.status);
  }
  setListActivity(true);
  setFormActivity(false);
};

export default updateActivity;
