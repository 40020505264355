import React, { useState } from 'react';
import {
  Button,
  Form,
  Input,
  Popconfirm,
  Switch,
  Table,
  Row,
  Spin
} from 'antd';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import { useErrorMessage } from '../../../utils/ErrorMessage';

const { TextArea } = Input;

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  selectType,
  checkType,
  record,
  index,
  children,
  setColorPicker,
  colorPicker,
  ...restProps
}) => {
  let inputNode;
  switch (inputType) {
    case 'text':
      inputNode = !checkType ? (
        <Input />
      ) : (
        <Switch defaultChecked={record.show} />
      );
      break;
    case 'select':
      inputNode = <Input />;
      break;
    default:
      break;
  }
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0
          }}
          rules={[
            {
              required: true,
              message: `veuillez saisir une ${title}!`
            }
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const PremiseTable = ({ premiseCols, editPremiseCol }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const [colorPicker, setColorPicker] = useState('');
  const [switchLoading, setSwitchLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [description, setDescription] = useState('');
  const { message } = useErrorMessage();

  const isEditing = (record) => record._id === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      label: record.label,
      show: record.show,
      ...record
    });
    setEditingKey(record._id);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const saveSwitch = (record) => {
    try {
      editPremiseCol(record._id, record);
      setEditingKey('');
    } catch (e) {
      message('Error with Switch change: ', e);
    }
  };

  const saveDescription = (record) => {
    const newRecord = record;
    try {
      setButtonLoading(true);
      newRecord.description = description;
      editPremiseCol(newRecord._id, newRecord);
      setDescription('');
      setButtonLoading(false);
    } catch (e) {
      message('Error with Description change: ', e);
    }
  };

  const columns = [
    {
      title: t('settings.premiseTable.label'),
      dataIndex: 'label',
      width: '20%',
      editable: true
    },
    {
      title: t('settings.premiseTable.show'),
      dataIndex: 'show',
      width: '10%',
      editable: true,
      render: (value, record) => (
        <Switch
          loading={switchLoading}
          checked={value}
          onClick={() => {
            setSwitchLoading(true);
            setTimeout(() => {
              // eslint-disable-next-line no-param-reassign
              record.show = !value;
              saveSwitch(record);
              setSwitchLoading(false);
            }, 500);
          }}
        />
      )
    },
    {
      title: t('settings.premiseTable.description'),
      dataIndex: 'description',
      width: '30%',
      editable: true,
      render: (value, record) => (
        <Row align="middle" justify="space-between">
          <TextArea
            style={{ width: '80%' }}
            onChange={(event) => {
              setDescription(event.target.value);
            }}
            defaultValue={value}
          />
          <Spin spinning={switchLoading}>
            <Button
              loading={buttonLoading}
              onClick={() => {
                setSwitchLoading(true);
                setTimeout(() => {
                  saveDescription(record);
                  setSwitchLoading(false);
                }, 500);
              }}
            >
              {t('settings.premiseTable.save')}
            </Button>
          </Spin>
        </Row>
      )
    },
    {
      title: t('settings.premiseTable.action'),
      dataIndex: 'operation',
      width: '20%',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <div>
            <Link
              to={{
                pathname: `/premise/edit/${record._id}`
              }}
            >
              <Button>
                {t('buttons.edit')}
                <EditOutlined />
              </Button>
            </Link>
            <Popconfirm
              title={t('settings.messages.confirm-deletion')}
              onConfirm={cancel}
            >
              <Button>
                {t('buttons.cancel')}
                <CloseOutlined />
              </Button>
            </Popconfirm>
          </div>
        ) : (
          <Button
            onClick={() => edit(record)}
            disabled={editingKey !== ''}
            type="primary"
          >
            {t('buttons.edit')}
            <EditOutlined />
          </Button>
        );
      }
    }
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: ['color', 'icon'].includes(col.dataIndex)
          ? 'select'
          : 'text',
        selectType: col.dataIndex === 'color' ? 'color' : 'icon',
        checkType: col.dataIndex === 'show',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        colorPicker,
        setColorPicker
      })
    };
  });
  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell
          }
        }}
        bordered
        dataSource={premiseCols}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={false}
      />
    </Form>
  );
};

export default PremiseTable;

EditableCell.propTypes = {
  editing: PropTypes.bool.isRequired,
  dataIndex: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  inputType: PropTypes.string.isRequired,
  selectType: PropTypes.string.isRequired,
  checkType: PropTypes.bool.isRequired,
  record: PropTypes.shape({ show: PropTypes.bool }).isRequired,
  index: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  setColorPicker: PropTypes.func.isRequired,
  colorPicker: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired
};

PremiseTable.propTypes = {
  premiseCols: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  editPremiseCol: PropTypes.func.isRequired
};
