import React, { useState, useEffect } from 'react';
import { Button, Popconfirm, message as antdMessage, Row } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined,
  FileProtectOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../../utils/ErrorMessage';
import { useGenerateReport } from '../../../../../utils/generateReport';
import { routes, subRoutes } from '../../../../../utils/constants/adminRoutes';
import setHeader from './utils/setHeader';
import { permissionVerification } from '../../../../../utils/permissionVerification';

const HeaderExtra = ({
  exit,
  employee,
  activeStep,
  nextStep,
  deleteExit,
  setIsModalOpen,
  setRestitutionType,
  restitutionType,
  setAcknowledgmentOfReceiptType,
  setRefresh,
  refresh,
  setAbsenceReasonType
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { message } = useErrorMessage();
  const { dispatchAPI, userPermissions } = useAuthContext();
  const { generateDoc } = useGenerateReport();
  const [exitType, setExitType] = useState('');
  const [isDocLoading, setIsDocLoading] = useState(false);
  const navigate = useNavigate();
  const [permit, setPermit] = useState(false);

  useEffect(() => {
    const permission = permissionVerification(
      userPermissions,
      '/employee-folder/exits/modification'
    );
    setPermit(permission);
  }, [userPermissions]);

  useEffect(() => {
    setExitType(exit?.motif);
  }, [exit]);

  const showEmployee = () => {
    navigate(`../../employees/show/${employee._id}`);
  };

  const setModel = () => {
    switch (activeStep) {
      case 'ACKNOWLEDGMENT_OF_RECEIPT':
        return 'acknowledgment_of_receipt';
      case 'BREAKUP_NOTIFICATION':
        return 'breakup_notification';
      case 'EMPLOYER_BREAKUP_NOTIFICATION':
        return 'breakup_notification';
      case 'INTERVIEW_INVITATION':
        return 'interview_invitation';
      default:
        return null;
    }
  };

  const setBodyStep = (type) => {
    switch (type) {
      case 'generate_acknowledgment_of_receipt':
        return { is_acknowledgment_of_receipt_generated: true };
      case 'generate_breakup_notification':
        return { is_breakup_notification_generated: true };
      case 'generate_interview_invitation':
        return { is_interview_invitation_generated: true };
      default:
        return null;
    }
  };

  const generateFile = async (type) => {
    try {
      setIsDocLoading(true);
      const { data } = await dispatchAPI('GET', {
        url: `/files/templates/${setModel()}.docx`
      });
      const result = await generateDoc(setModel(), exit._id, data);

      if (result && type) {
        await dispatchAPI('PATCH', {
          url: `/exits/${exit._id}`,
          body: setBodyStep(type)
        });
      }
      setIsDocLoading(false);
      if (result) {
        setRefresh(!refresh);
        antdMessage.success(t(`exits.generatedFile.message.${activeStep}`));
      }
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const updateStatus = async () => {
    try {
      await dispatchAPI('PATCH', {
        url: `/exits/${exit._id}`,
        body: { status: 'ACTION_RH' }
      });
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  return (
    <Row gutter={[16, 16]}>
      <Button type="primary" onClick={showEmployee}>
        <FileProtectOutlined />
        {t('exits.header.button.show_output_sheet')}
      </Button>
      {setHeader({
        exitType,
        activeStep,
        nextStep,
        setRestitutionType,
        setIsModalOpen,
        exit,
        restitutionType,
        setAcknowledgmentOfReceiptType,
        generateFile,
        isDocLoading,
        setAbsenceReasonType,
        updateStatus,
        t
      })}
      <Link
        to={{
          pathname: `${routes.EMPLOYEE_FOLDER}${subRoutes.EMPLOYEE_FOLDER.EXITS}/edit/${id}`
        }}
      >
        {permit && (
          <Button type="primary">
            {`${t('buttons.edit')} `}
            <EditOutlined />
          </Button>
        )}
      </Link>
      <Popconfirm
        title={t('datatable.column.action.delete.title')}
        okText={t('datatable.column.action.delete.ok')}
        okButtonProps={{ type: 'danger' }}
        cancelText={t('datatable.column.action.delete.cancel')}
        onConfirm={deleteExit}
        icon={<WarningOutlined />}
      >
        {permit && (
          <Button type="link" danger>
            {`${t('buttons.delete')} `}
            <DeleteOutlined />
          </Button>
        )}
      </Popconfirm>
    </Row>
  );
};

HeaderExtra.propTypes = {
  exit: PropTypes.shape({
    motif: PropTypes.string,
    _id: PropTypes.string
  }),
  employee: PropTypes.shape({
    activeStep: PropTypes.string,
    _id: PropTypes.string
  }),
  activeStep: PropTypes.string,
  deleteExit: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  setRestitutionType: PropTypes.func.isRequired,
  restitutionType: PropTypes.string.isRequired,
  setAcknowledgmentOfReceiptType: PropTypes.func.isRequired,
  setAbsenceReasonType: PropTypes.func,
  setRefresh: PropTypes.func.isRequired,
  refresh: PropTypes.bool.isRequired
};

HeaderExtra.defaultProps = {
  employee: {},
  exit: {},
  activeStep: '',
  setAbsenceReasonType: null
};

export default HeaderExtra;
