/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from 'react';
import { Steps } from 'antd';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/ErrorMessage';
import { useApplicationsContext } from '../../../../contexts/ApplicationsContext';

const { Step } = Steps;

export const SelectApplicationStatus = ({
  applicationStatusIndex,
  setCurrentApplication,
  setIsLoading
}) => {
  const { application, kanbanCols, fetchApplicationById } =
    useApplicationsContext();
  const { dispatchAPI } = useAuthContext();
  const { message: errorMessage } = useErrorMessage();
  const [kanbanColumns, setKanbanCols] = useState([]);

  const getApplicationStatusIndex = () => {
    let indexCol = 0;
    // eslint-disable-next-line array-callback-return
    kanbanCols.map((col, index) => {
      if (col._id === applicationStatusIndex) indexCol = index;
    });
    return indexCol;
  };

  const getKanbans = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/kanbans' });
      setKanbanCols(data);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  const editStatus = async (value) => {
    let newStatusId = '';
    let newKanbanCol = null;
    kanbanColumns.forEach((col, index) => {
      if (index === value) {
        newStatusId = col._id;
        newKanbanCol = col;
      }
    });

    setCurrentApplication({ ...application, status: newKanbanCol });
    setIsLoading(true);
    try {
      await dispatchAPI('PATCH', {
        url: `/applications/${application._id}`,
        body: {
          status: newStatusId
        }
      });
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
    setIsLoading(false);
  };

  const onSelectChange = (value) => editStatus(value);

  const getSelectOptions = useCallback(async () => {
    setIsLoading(true);
    await fetchApplicationById(application._id);
    await getKanbans();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, []);

  return (
    <div style={{ marginBottom: 20 }}>
      <Steps
        current={getApplicationStatusIndex()}
        progressDot
        onChange={onSelectChange}
      >
        {kanbanCols &&
          kanbanCols.map((item) => <Step title={item.label} key={item._id} />)}
      </Steps>
    </div>
  );
};
