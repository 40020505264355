import moment from 'moment';

const addActivity = async ({
  values,
  dispatchAPI,
  application,
  successMessage,
  setListActivity,
  setFormActivity,
  message,
  uuid,
  t
}) => {
  const initActivities = application?.activities || [];

  const activityId = uuid();

  if (values.type === 'meeting') {
    const durationInMs = values.time_end.diff(values.time_start);
    const durationInHours = moment.duration(durationInMs).asHours();

    const interviewTitle = await dispatchAPI('GET', {
      url: `interviewtitles/${values.header}`
    });

    const interviewAdress = await dispatchAPI('GET', {
      url: `premise/${values.premise}`
    });

    const body = {
      ...values,
      type: 'CANDIDATE',
      application: application._id,
      candidate: application.candidate._id
    };
    try {
      const { data } = await dispatchAPI('POST', {
        url: 'interviews',
        body
      });

      const modifiedValues = { ...values, _id: activityId };

      await dispatchAPI('PATCH', {
        url: `/applications/${application._id}`,
        body: {
          activities: [
            ...initActivities,
            {
              ...modifiedValues,
              duration: durationInHours.toFixed(2),
              address: interviewAdress?.data?.label,
              title: interviewTitle?.data?.label,
              interview_id: data._id,
              dateTime: values.date
            }
          ]
        }
      });

      successMessage(t('applications.show.add_activity_success'));
    } catch (e) {
      message(e);
    }
  } else {
    const modifiedValues = { ...values, _id: activityId };

    await dispatchAPI('PATCH', {
      url: `/applications/${application._id}`,
      body: {
        activities: [...initActivities, modifiedValues]
      }
    });
  }

  setFormActivity(false);
  setListActivity(true);
};

export default addActivity;
