import React from 'react';
import { Select } from 'antd';

const useFields = ({ employees }) => {
  const status = [
    { value: 'TREAT', label: 'A traiter' },
    { value: 'PROCESS', label: 'En cours de traitement' },
    { value: 'RESOLVED', label: 'Résolu' }
  ];

  const fields = [
    {
      name: ['status'],
      rules: [{ required: true }],
      input: <Select options={status} />
    },
    {
      name: ['hr_intervenant'],
      rules: [{ required: true }],
      input: <Select options={employees} />
    }
  ];

  return {
    fields
  };
};

export default useFields;
