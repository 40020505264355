import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';

const ListEquipments = ({ employee }) => {
  // eslint-disable-next-line no-unused-vars
  const onChange = (e) => {};

  return (employee?.equipments || []).map((equipment) => (
    <Card
      key={equipment._id}
      style={{ width: '100%', marginBottom: 10 }}
      bodyStyle={{ padding: 10 }}
    >
      <CheckCircleOutlined style={{ color: 'green', marginRight: 10 }} />
      <span>{equipment._id.label}</span>
    </Card>
  ));
};

ListEquipments.propTypes = {
  employee: PropTypes.shape({
    _id: PropTypes.string
  })
};

ListEquipments.defaultProps = {
  employee: {}
};

export default ListEquipments;
