import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Button, Popconfirm, Skeleton, Row, Card, Collapse } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import listContent from './listContent';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../../components';
import { routes, subRoutes } from '../../../utils/constants/adminRoutes';
import { permissionVerification } from '../../../utils/permissionVerification';

const { Panel } = Collapse;

const ShowInterview = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { dispatchAPI, userPermissions } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [interview, setInterview] = useState(null);
  const [permit, setPermit] = useState(false);

  useEffect(() => {
    const permission = permissionVerification(
      userPermissions,
      '/recruitments/interviews/modification'
    );
    setPermit(permission);
  }, [userPermissions]);

  const getInterview = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/interviews/${id}?populate=header,rh_employee,interlocutors,premise,frame`
      });
      setInterview(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getInterview();
    })();
  }, [getInterview]);

  const deleteInterview = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/interviews/${id}` });
      navigate(-1);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  return (
    <>
      <PageHeaderCustom
        title={t('interviews.show.title')}
        extra={
          <>
            <Link
              to={{
                pathname: `${routes.RECRUITMENTS}${subRoutes.RECRUITMENTS.INTERVIEWS}/edit/${id}`
              }}
            >
              {permit && (
                <Button type="primary">
                  {`${t('buttons.edit')} `}
                  <EditOutlined />
                </Button>
              )}
            </Link>
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={deleteInterview}
              icon={<WarningOutlined />}
            >
              {permit && (
                <Button type="link" danger>
                  {`${t('buttons.delete')} `}
                  <DeleteOutlined />
                </Button>
              )}
            </Popconfirm>
          </>
        }
      />
      <ContentCustom>
        <Row>
          <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
            <Card
              title="Informations Principales"
              bordered={false}
              style={{ width: '100%' }}
              className="form-card"
            >
              <DescriptionList
                data={listContent(interview || {}, t)}
                translate
              />
            </Card>
          </Skeleton>
        </Row>
        <Row>
          <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
            <Collapse style={{ width: '100%' }}>
              <Panel header={interview?.frame?.label || ''} key="1">
                efezfzef
              </Panel>
            </Collapse>
          </Skeleton>
        </Row>
      </ContentCustom>
    </>
  );
};

export default ShowInterview;
