import React, { useEffect, useState } from 'react';
import { Col, Layout, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';
import ForgotPwdForm from './ForgotPwdForm';
import { useHandleResize } from '../../utils/HandleResize';
import ChangePwdForm from './ChangePwdForm';
import Logo from '../../assets/images/logo.png';
import BackgroundPhoto from '../../assets/images/timothy-dykes-Lq1rOaigDoY-unsplash.jpg';

export const Login = () => {
  const { token } = useParams();
  const { t } = useTranslation();
  const { Title } = Typography;
  const { width } = useHandleResize();
  const [currentForm, setCurrentForm] = useState('login');
  const forms = {
    login: <LoginForm switchForm={(f) => setCurrentForm(f)} />,
    register: <RegisterForm switchForm={(f) => setCurrentForm(f)} />,
    forgotPwd: <ForgotPwdForm switchForm={(f) => setCurrentForm(f)} />,
    changePwd: (
      <ChangePwdForm switchForm={(f) => setCurrentForm(f)} token={token} />
    )
  };

  useEffect(() => {
    if (token) setCurrentForm('changePwd');
  }, [token]);

  return (
    <div>
      <Layout
        style={{
          minHeight: '100vh',
          maxHeight: '100vh',
          display: 'inherit',
          overflow: 'hidden'
        }}
      >
        <Row>
          <Col
            xs={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}
            style={{ backgroundColor: 'var(--componentBackground)' }}
          >
            <Row
              justify={width < 993 ? 'center' : 'start'}
              align="middle"
              style={{
                height: '100vh',
                maxHeight: '100vh',
                backgroundImage: `url(${BackgroundPhoto})`,
                overflow: 'hidden',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover'
              }}
            >
              <Col
                xxl={7}
                xl={9}
                lg={12}
                sm={18}
                xs={24}
                style={{
                  height: '100vh',
                  maxHeight: '100vh',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                  textAlign: 'center',
                  padding: width < 993 ? '20px 32px' : '40px 40px 0 40px',
                  background: 'rgba(9, 9, 9, 0.6)',
                  marginLeft: width < 993 ? 0 : 130,
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  backdropFilter: width > 992 && 'blur(4px)'
                }}
              >
                <Row align="middle" justify="center">
                  <img
                    style={{
                      width: 90
                    }}
                    alt="Logo"
                    src={Logo}
                  />
                  <span className="logo-vbr logo-login">Groupe VBR</span>
                </Row>
                <Col>
                  <Title
                    style={{
                      textAlign: 'center',
                      marginBottom: 40,
                      fontSize: 36,
                      color: 'var(--clientColor)',
                      fontFamily: 'Cambria, Georgia, serif',
                      fontWeight: 400
                    }}
                  >
                    {t(`login.title.${currentForm}`)}
                  </Title>
                  {forms[currentForm]}
                </Col>
              </Col>
            </Row>
          </Col>
        </Row>
      </Layout>
    </div>
  );
};
