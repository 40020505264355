import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Progress } from 'antd';
import { Link } from 'react-router-dom';
import { routes, subRoutes } from '../../../utils/constants/adminRoutes';

const userList = (data) => {
  const { employee, profiletree, progress } = data;
  const { t } = useTranslation();

  return [
    {
      label: t('trainingprogress.form.employee'),
      span: 1,
      content:
        (employee && (
          <Link
            to={`${routes.EMPLOYEE_FOLDER}${subRoutes.EMPLOYEE_FOLDER.EMPLOYEES}/show/${employee._id}`}
          >{`${employee.first_name} ${employee.last_name}`}</Link>
        )) ||
        '-'
    },
    {
      label: t('trainingprogress.form.profiletree'),
      span: 2,
      content:
        (profiletree && (
          <Link
            to={`${routes.TRAININGS}${subRoutes.TRAININGS.PATH}/profiletree/show/${profiletree._id}`}
          >
            {profiletree.title}
          </Link>
        )) ||
        '-'
    },
    {
      label: t('trainingprogress.form.premise'),
      span: 3,
      content: (employee && `${employee.premise.label}`) || '-'
    },
    {
      label: t('trainingprogress.form.progress'),
      span: 1,
      content:
        (progress && <Progress percent={progress} style={{ width: 200 }} />) ||
        '-'
    },
    {
      label: t('trainingprogress.form.start_date'),
      span: 2,
      content:
        (employee && moment(employee.start_date).format('DD/MM/YYYY')) || '-'
    }
  ];
};

export default userList;
