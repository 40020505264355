import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Button, Popconfirm, Skeleton, Row, Image, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import { memoPrincipalInfo, memoContentInfo } from './listContent';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../../components';
import { subRoutes, routes } from '../../../utils/constants/adminRoutes';
import { permissionVerification } from '../../../utils/permissionVerification';

const ShowMemo = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { dispatchAPI, userPermissions } = useAuthContext();
  const [isLoading, setIsLoading] = useState(true);
  const [memo, setMemo] = useState(null);
  const [permit, setPermit] = useState(false);

  useEffect(() => {
    const permission = permissionVerification(
      userPermissions,
      '/intern-communication/memos/modification'
    );
    setPermit(permission);
  }, [userPermissions]);

  const getMemo = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/memos/${id}?populate=author,premises`
      });
      setMemo(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getMemo();
    })();
  }, [getMemo]);

  const deleteMemo = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/memos/${id}` });
      navigate(-1);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  return (
    <>
      <PageHeaderCustom
        title={
          <div>
            {!isLoading && moment(memo?.date).format('DD/MM/YYYY [à] H:mm')}
          </div>
        }
        extra={
          <>
            <Link
              to={{
                pathname: `${routes.INTERN_COMMUNICATION}${subRoutes.INTERN_COMMUNICATION.MEMOS}/edit/${id}`
              }}
            >
              {permit && (
                <Button type="primary">
                  {`${t('buttons.edit')} `}
                  <EditOutlined />
                </Button>
              )}
            </Link>
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={deleteMemo}
              icon={<WarningOutlined />}
            >
              {permit && (
                <Button type="link" danger>
                  {`${t('buttons.delete')} `}
                  <DeleteOutlined />
                </Button>
              )}
            </Popconfirm>
          </>
        }
      />
      <ContentCustom>
        <Card
          title={t('memos.show.main')}
          bordered={false}
          style={{ width: '100%', marginTop: 24 }}
          className="form-card"
        >
          <Row>
            <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
              <DescriptionList
                data={memoPrincipalInfo(memo || {}, t)}
                translate
              />
            </Skeleton>
          </Row>
        </Card>

        <Card
          title={t('memos.show.content')}
          bordered={false}
          style={{ width: '100%', marginTop: 24 }}
          className="form-card"
        >
          <div style={{ display: 'flex' }}>
            <Row style={{ width: '50%' }}>
              <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
                <DescriptionList
                  data={memoContentInfo(memo || {}, t)}
                  translate
                />
              </Skeleton>
            </Row>
            <Row
              style={{
                width: '50%',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Skeleton loading={isLoading} avatar active>
                <Image src={memo && memo.photo ? memo.photo : ``} />
              </Skeleton>
            </Row>
          </div>
        </Card>
      </ContentCustom>
    </>
  );
};

export default ShowMemo;
