export const setTitle = ({
  activeStep,
  restitutionType,
  acknowledgmentOfReceiptType,
  absenceReasonType,
  t
}) => {
  switch (activeStep) {
    case 'RESTITUTION_VALIDATED':
      return t(`exits.modals.${activeStep}.${restitutionType}`);
    case 'ACKNOWLEDGMENT_OF_RECEIPT':
      return t(`exits.modals.${activeStep}.${acknowledgmentOfReceiptType}`);
    case 'ABSENCE_REASON':
      return t(`exits.modals.${activeStep}.${absenceReasonType}`);
    default:
      return t(`exits.modals.${activeStep}`);
  }
};
