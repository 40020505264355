import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Tag, Switch } from 'antd';
import PropTypes from 'prop-types';
import { Datatable } from './DataTable/Datatable';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import { useAuthContext } from '../../../contexts/AuthContext';
import { usersPosition } from '../../../utils/constants/tagColors';
import { permissionVerification } from '../../../utils/permissionVerification';

const ListFiles = ({ premise }) => {
  const { t } = useTranslation();
  const { dispatchAPI, userPermissions } = useAuthContext();
  const { message } = useErrorMessage();
  const [permit, setPermit] = useState(false);

  useEffect(() => {
    const permission = permissionVerification(
      userPermissions,
      '/intern-communication/documents/modification'
    );
    setPermit(permission);
  }, [userPermissions]);

  const updateVisibility = async (show, id) => {
    try {
      const fileIndex = premise.files.findIndex((file) => file._id === id);

      if (fileIndex !== -1) {
        // eslint-disable-next-line no-param-reassign
        premise.files[fileIndex] = {
          ...premise.files[fileIndex],
          show
        };
      }
      await dispatchAPI('PATCH', {
        url: `premise/${premise._id}`,
        body: { files: premise.files }
      });
    } catch (e) {
      message(t('errors.message.500'));
    }
  };

  const formatDate = (date) => {
    const newDate = new Date(date);
    const formattedDate = newDate.toLocaleDateString('fr-FR');

    return formattedDate;
  };

  const columns = [
    {
      title: t('files.filename'),
      key: 'filename',
      dataIndex: 'filename',
      sorter: true
    },
    {
      title: t('files.last_update'),
      key: 'last_update',
      dataIndex: 'last_update',
      render: (date) => formatDate(date),
      sorter: true
    },
    {
      title: t('files.visibility'),
      key: 'visibility',
      dataIndex: 'visibility',
      render: (visibility, record) =>
        visibility?.map((v, index) => (
          <Tag color={usersPosition[record.visibility[index].type]}>
            {v.label}
          </Tag>
        )),
      sorter: true
    },
    {
      title: t('files.show'),
      key: 'show, _id',
      dataIndex: 'show',
      render: (show, record) => (
        <Switch
          checkedChildren="Affiché"
          unCheckedChildren="Masqué"
          defaultChecked={show}
          onChange={(checked) => updateVisibility(checked, record._id)}
        />
      ),
      sorter: true
    }
  ];

  return (
    <Datatable
      resourceName="premise"
      extraQuery={`_id=${premise?._id}`}
      columns={columns}
      populate="files.visibility"
      resourceModelName="Premise"
      style={{ marginTop: 24, width: '100vw' }}
      showAction={false}
      editAction={permit}
      deleteAction={permit}
      premise={premise}
      scroll={{ x: 900 }}
    />
  );
};

export default ListFiles;

const filePropType = PropTypes.shape({
  _id: PropTypes.string,
  type: PropTypes.string,
  filename: PropTypes.string,
  show: PropTypes.bool,
  visibility: PropTypes.arrayOf(PropTypes.string),
  last_update: PropTypes.instanceOf(Date)
});

ListFiles.propTypes = {
  premise: PropTypes.shape({
    _id: PropTypes.string,
    label: PropTypes.string,
    show: PropTypes.bool,
    created_at: PropTypes.instanceOf(Date),
    updated_at: PropTypes.instanceOf(Date),
    files: PropTypes.arrayOf(filePropType)
  }).isRequired
};
