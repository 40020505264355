import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { PageHeaderCustom } from '../../../../components/PageHeader/PageHeader';
import { ContentCustom } from '../../../../components/ContentCustom/ContentCustom';
import { SelectApplicationStatus } from './SelectApplicationStatus';
import ApplicationDetails from './DetailsApplication';
import Documents from './Documents';
import EditDrawer from '../List/EditDrawer';
import { useApplicationsContext } from '../../../../contexts/ApplicationsContext';
import EditApplication from './EditApplication';
import { ActivitiesTabs, ShowActivitiesDetails } from './ActivitiesDetails';
import Interviews from './Interviews';
import Recommandations from './Recommandations';
import { useHandleResize } from '../../../../utils/HandleResize';

const ShowApplication = () => {
  const {
    application,
    setCurrentApplication,
    fetchApplicationById,
    updateApplication,
    dataRefresh
  } = useApplicationsContext();

  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const { id } = useParams();
  const [formActivity, setFormActivity] = useState(false);
  const [listActivity, setListActivity] = useState(true);
  const [selectedTab, setSelectedTab] = useState('activity');
  const [purpose, setPurpose] = useState();
  const { width } = useHandleResize();

  const goToAddActivity = () => {
    setPurpose('create');
    setListActivity(false);
    setFormActivity(true);
  };

  const getSelectOptions = useCallback(async () => {
    setIsLoading(true);
    await fetchApplicationById(id);
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions, listActivity, formActivity, dataRefresh]);

  return (
    <>
      <PageHeaderCustom
        title={t('applications.show.title')}
        withSubRoutes
        extra={<EditApplication />}
      />
      <Spin spinning={isLoading}>
        <ContentCustom>
          <Row>
            <Col span={24}>
              {application && (
                <SelectApplicationStatus
                  applicationStatusIndex={
                    application.status ? application.status._id : 0
                  }
                  setCurrentApplication={setCurrentApplication}
                  setIsLoading={setIsLoading}
                />
              )}
            </Col>
          </Row>

          <Row gutter={[0, 12]}>
            <Col xl={10} xs={24} />

            <Col
              xl={13}
              xs={24}
              style={{
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'flex-end',
                marginLeft: width < 1200 ? 0 : 20
              }}
            >
              <ActivitiesTabs
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                goToAddActivity={goToAddActivity}
              />
            </Col>
          </Row>

          <Row style={{ flexWrap: 'wrap-reverse' }} gutter={[0, 24]}>
            <Col xl={10} xs={24}>
              <ApplicationDetails
                fetchData={fetchApplicationById}
                application={application}
                isLoading={isLoading}
                updateApplication={updateApplication}
              />
            </Col>

            <Col xl={13} xs={24} className="application-activities-wrapper">
              <ShowActivitiesDetails
                application={application}
                listActivity={listActivity}
                purpose={purpose}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                setListActivity={setListActivity}
                setFormActivity={setFormActivity}
                setPurpose={setPurpose}
              />
            </Col>
          </Row>

          <Row gutter={[16, 12]} style={{ marginTop: 32 }}>
            <Documents application={application} isLoading={isLoading} />
          </Row>

          <Row gutter={[16, 12]} style={{ marginTop: 32 }}>
            <Interviews
              application={application}
              setCurrentApplication={setCurrentApplication}
              updateApplication={updateApplication}
              listActivity={listActivity}
            />
          </Row>

          <Row gutter={[16, 12]} style={{ marginTop: 32 }}>
            <Recommandations
              application={application}
              setCurrentApplication={setCurrentApplication}
              updateApplication={updateApplication}
            />
          </Row>
        </ContentCustom>
        <EditDrawer />
      </Spin>
    </>
  );
};

export default ShowApplication;
