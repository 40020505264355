import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { Button, Card, Col, Row } from 'antd';
import { FileTextOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';
import {
  ContentCustom,
  DescriptionList,
  PageHeaderCustom
} from '../../components';
import { listContent } from './listContent';

export const ShowTemplate = () => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { id } = useParams();
  const [template, setTemplate] = useState();

  const getTemplate = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `template-documents/${id}?populate=author`
      });
      setTemplate(data);
    } catch (e) {
      message(e);
    }
  };

  const generatePDF = async () => {
    try {
      const { data } = await dispatchAPI('POST', {
        url: `template-documents/generateRawPDF/${id}`,
        responseType: 'arraybuffer' // Set the responseType to receive the response as an ArrayBuffer
      });
      // Create a Blob object from the ArrayBuffer

      const blob = new Blob([data], { type: 'application/pdf' });

      // Create a URL from the Blob object
      const url = URL.createObjectURL(blob);

      // Use the URL to create a download link or display the PDF in the browser
      // Example: Create a download link
      const link = document.createElement('a');
      link.href = url;
      link.download = 'document.pdf';
      link.click();

      // Clean up the URL object
      URL.revokeObjectURL(url);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getTemplate();
    })();
  }, []);

  return (
    <>
      <PageHeaderCustom
        title={template?.title}
        extra={
          <Button onClick={generatePDF}>
            <FileTextOutlined />
            {t('template-documents.show.generate_PDF')}
          </Button>
        }
      />
      <ContentCustom>
        <Row gutter={[8, 8]}>
          <Col span={8}>
            <Card title={t('template-documents.show.infos')}>
              <DescriptionList
                data={listContent(template || {}, t)}
                translate
              />
            </Card>
          </Col>
          <Col span={16}>
            <Card>{template && parse(template.content)}</Card>
          </Col>
        </Row>
      </ContentCustom>
    </>
  );
};
