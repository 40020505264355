import React from 'react';
import { Calendar } from 'antd';
import { monthAnnualHeader } from './monthAnnualHeader';
import { CalendarMode } from 'antd/lib/calendar/generateCalendar';

interface MonthAnnualCalendarProps {
  cellRender?:
    | ((date: moment.Moment, events: Array<any>) => React.ReactNode)
    | null;
  extraHeader?:
    | ((
        value: moment.Moment,
        onChange: (value: moment.Moment) => void
      ) => React.ReactNode)
    | null;
  mode: CalendarMode | undefined;
  setMode?: ((mode: string) => void) | null;
  modes: Array<string>;
  events: Array<{ [key: string]: any }>;
  dayRange: string;
}

export const MonthAnnualCalendar: React.FC<MonthAnnualCalendarProps> = ({
  cellRender,
  extraHeader,
  mode,
  modes,
  setMode,
  events,
  dayRange
}) => {
  const handleClassName = () => {
    switch (dayRange) {
      case 'withSaturday':
        return 'saturday-calendar';
      case 'weekends':
        return 'weekends-calendar';
      default:
        return 'only-weekdays-calendar';
    }
  };

  return (
    <Calendar
      dateCellRender={(date: moment.Moment) =>
        cellRender && cellRender(date, events)
      }
      monthCellRender={(date: moment.Moment) =>
        cellRender && cellRender(date, events)
      }
      headerRender={({ value, onChange }) =>
        monthAnnualHeader(
          { value, onChange },
          extraHeader,
          modes,
          mode,
          setMode
        )
      }
      mode={mode}
      className={handleClassName()}
    />
  );
};
