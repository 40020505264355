import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Button, Popconfirm, message as antdMessage } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  WarningOutlined,
  CalendarOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/ErrorMessage';
import { PageHeaderCustom } from '../../../../components';
import {
  outOfNavRoutes,
  routes,
  subRoutes
} from '../../../../utils/constants/adminRoutes';

import DetailsCards from './DetailsCards';
import EndDateModal from './EndDateModal';
import { TemplateDocumentButton } from '../../../../components/TemplateDocumentButton/TemplateDocumentButton';
import { permissionVerification } from '../../../../utils/permissionVerification';

const ShowEmployee = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { dispatchAPI, userPermissions } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [employee, setEmployee] = useState({});
  const [entry, setEntry] = useState({});
  const [files, setFiles] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [trainingprogress, setTrainingProgress] = useState([]);
  const [motifsEnums, setMotifsEnums] = useState([]);
  const [permit, setPermit] = useState(false);

  useEffect(() => {
    const permission = permissionVerification(
      userPermissions,
      '/employee-folder/employees/modification'
    );
    setPermit(permission);
  }, [userPermissions]);

  const getMotifsEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/employees/enums`
      });
      setMotifsEnums(data.MOTIFS);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    getMotifsEnums();
  }, []);

  const handlePostDocuments = async (document) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/employees/${employee._id}`,
        body: { $addToSet: { files: { _id: files._id, type: document } } }
      });
      antdMessage.success(t('documents.success'));
      setRefresh(!refresh);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getEntry = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/entrys/${id}?populate=files._id`
      });
      setEntry(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  const getTrainingProgress = useCallback(
    async ({ emp }) => {
      setIsLoading(true);
      try {
        const populate = 'employee,employee.premise,profiletree';
        if (emp) {
          const { data } = await dispatchAPI('GET', {
            url: `/trainingprogress/${emp.training_progress}?populate=${populate}`
          });
          setTrainingProgress(data);
        }
      } catch (e) {
        message(e);
      }
      setIsLoading(false);
    },
    [id, refresh]
  );

  const getEmployee = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/employees/${id}?populate=position,premise,recommended_by,files._id,equipments._id,history.by`
      });
      setEmployee(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getEmployee();
      await getEntry();
    })();
  }, [getEmployee, refresh]);

  useEffect(() => {
    (async () => {
      if (employee.training_progress) {
        await getTrainingProgress({ emp: employee });
      }
    })();
  }, [employee, refresh]);

  const deleteEmployee = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/employees/${id}` });
      navigate(-1);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  return (
    <>
      <PageHeaderCustom
        title={`${employee?.first_name} ${employee?.last_name}`}
        extra={
          <>
            <TemplateDocumentButton
              collection="Entry"
              use_case="PROMISE_OF_EMPLOYMENT"
              button_text="test"
            />
            <Link
              to={{
                pathname: `${outOfNavRoutes.APPOINTMENT}`
              }}
            >
              {permit && (
                <Button type="primary">
                  {`${t('buttons.appointment')} `} <CalendarOutlined />
                </Button>
              )}
            </Link>
            <Link
              to={{
                pathname: `${routes.EMPLOYEE_FOLDER}${subRoutes.EMPLOYEE_FOLDER.EMPLOYEES}/edit/${id}`
              }}
            >
              {permit && (
                <Button type="primary">
                  {`${t('buttons.edit')} `}
                  <EditOutlined />
                </Button>
              )}
            </Link>
            <Popconfirm
              title={t('datatable.column.action.exit.title')}
              okText={t('datatable.column.action.exit.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.exit.cancel')}
              onConfirm={() => setIsModalOpen(true)}
              icon={<WarningOutlined />}
            >
              {permit && (
                <Button type="link" danger>
                  {`${t('buttons.exit')} `}
                </Button>
              )}
            </Popconfirm>
            <Popconfirm
              title={t('datatable.column.action.archive.title')}
              okText={t('datatable.column.action.archive.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.archive.cancel')}
              onConfirm={deleteEmployee}
              icon={<WarningOutlined />}
            >
              {permit && (
                <Button type="link" danger>
                  {`${t('buttons.archive')} `}
                </Button>
              )}
            </Popconfirm>
          </>
        }
      />
      <DetailsCards
        entry={entry}
        employee={employee}
        isLoading={isLoading}
        files={employee?.files}
        setFiles={setFiles}
        handlePostDocuments={handlePostDocuments}
        trainingprogress={trainingprogress}
        setRefresh={setRefresh}
        refresh={refresh}
      />
      <EndDateModal
        id={id}
        setRefresh={setRefresh}
        refresh={refresh}
        dispatchAPI={dispatchAPI}
        message={message}
        t={t}
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        motifsEnums={motifsEnums}
      />
    </>
  );
};

export default ShowEmployee;
