import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Select, Form, Upload, Switch } from 'antd';
import { FileAddOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/ErrorMessage';
import countryCodes from '../../../../utils/countryCodes';
import { validatePhoneNumber } from '../../../../utils/phoneNumber';

const { Option } = Select;
const { TextArea } = Input;
const { Dragger } = Upload;

const useFields = ({ visible, setFile, application }) => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI, token } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [offers, setOffers] = useState([]);
  const [kanbanCols, setKanbanCols] = useState([]);
  const [isCandidateExist, setIsCandidateExist] = useState(!!application);
  const [candidates, setCandidates] = useState([]);

  const sourceCandidacy = [
    { value: 'INDEED', label: t('applications.form.INDEED') },
    {
      value: 'LINKEDIN',
      label: t('applications.form.LINKEDIN')
    },
    { value: 'RH', label: t('applications.form.RH') },
    {
      value: 'RECOMMENDED',
      label: t('applications.form.RECOMMENDED')
    }
  ];

  const draggerProps = {
    name: 'file',
    action: `${process.env.REACT_APP_API_URL}/files`,
    headers: { Authorization: `Bearer ${token}` },
    onChange(info) {
      const { status, response } = info.file;

      setFile(response);

      if (status === 'error') {
        message(`${info.file.name} file upload failed.`);
      }
    }
  };

  const fields = [
    {
      name: ['switch_candidate'],
      hidden: !!application,
      input: (
        <Switch
          checked={!isCandidateExist}
          onChange={() => setIsCandidateExist(!isCandidateExist)}
        />
      )
    },
    {
      name: ['candidates'],
      hidden: !isCandidateExist,
      rules: [{ required: isCandidateExist }],
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          allowClear
          style={{ width: '100%' }}
          loading={isFieldsLoading}
          placeholder={t('applications.form.candidates_placeholder')}
        >
          {(candidates || []).map((candidate) => (
            <Option
              label={candidate.last_name + candidate.first_name}
              value={candidate._id}
              key={candidate._id}
            >
              {`${candidate.last_name} ${candidate.first_name}`}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['candidate', '_id'],
      hidden: true
    },
    {
      name: ['candidate', 'first_name'],
      hidden: isCandidateExist,
      rules: [{ required: !isCandidateExist }]
    },
    {
      name: ['candidate', 'last_name'],
      hidden: isCandidateExist,
      rules: [{ required: !isCandidateExist }]
    },
    {
      name: ['offer'],
      rules: [{ required: true }],
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          loading={isFieldsLoading}
        >
          {(offers || []).map((offer) => (
            <Option
              label={offer.name + offer.premise.label}
              value={offer._id}
              key={offer._id}
            >
              {`${offer.name} - ${offer.premise.label}`}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['source_candidacy'],
      rules: [{ required: true }],
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          loading={isFieldsLoading}
        >
          {(sourceCandidacy || []).map((source) => (
            <Option
              label={source.label}
              value={source.value}
              key={source.value}
            >
              {source.label}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['status'],
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          loading={isFieldsLoading}
        >
          {kanbanCols.map(({ _id, label }) => (
            <Option label={label} value={_id} key={_id}>
              {label}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['candidate', 'email'],
      hidden: isCandidateExist,
      rules: [{ type: 'email', required: !isCandidateExist }]
    },
    {
      name: ['phone_number'],
      hidden: isCandidateExist,
      label: 'phone_number',
      rules: [{ required: !isCandidateExist }],
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: '25%' }}>
              {countryCodes.map((country) => (
                <Option key={country.code} value={country.code}>
                  ({country.code}) {country.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            noStyle
            name={['phone_number', 'number']}
            label="phone_number.number"
            rules={!isCandidateExist && [{ validator: validatePhoneNumber }]}
          >
            <Input style={{ width: '75%' }} />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      name: ['curriculum'],
      input: (
        <Dragger {...draggerProps}>
          <p className="ant-upload-drag-icon">
            <FileAddOutlined style={{ color: 'var(--textColor)' }} />
          </p>
          <p className="ant-upload-text">{t('files.create.action')}</p>
        </Dragger>
      )
    },
    {
      name: ['additionnal_information'],
      input: <TextArea rows={4} />
    }
  ];

  const getCandidates = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'candidates?is_archived=false'
      });
      setCandidates(data);
    } catch (e) {
      message(e);
    }
  };

  const getKanbans = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/kanbans' });
      setKanbanCols(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getOffers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/offers?is_archived=false&populate=premise'
      });
      setOffers(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getCandidates();
    await getKanbans();
    await getOffers();
    setIsFieldsLoading(false);
  }, [visible]);

  useEffect(() => {
    if (visible)
      (async () => {
        await getSelectOptions();
      })();
  }, [getSelectOptions]);

  return { fields, isFieldsLoading, isCandidateExist };
};

export default useFields;
