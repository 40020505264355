import React, { useState, useEffect } from 'react';
import { DatePicker, Select, Form } from 'antd';
import { useAuthContext } from '../../../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../../../utils/ErrorMessage';

const { Option } = Select;

const accessDeletedContent = () => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [users, setUsers] = useState([]);
  const getUsers = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: 'users' });
      setUsers(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  const accessDeletedFields = [
    {
      label: 'deleted_access_date',
      name: ['deleted_access_date'],
      rules: [{ required: true }],
      input: <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
    },
    {
      label: 'deleted_access_user',
      name: ['deleted_access_user'],
      rules: [{ required: true }],
      input: (
        <Form.Item name={['deleted_access_user']}>
          <Select>
            {users.length &&
              users.map((user) => (
                <Option key={user._id} value={user._id}>
                  {user.first_name}
                </Option>
              ))}
          </Select>
        </Form.Item>
      )
    }
  ];

  return {
    accessDeletedFields
  };
};

export default accessDeletedContent;
