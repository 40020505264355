import moment from 'moment';

export const createEvents = (
  rawEvents: any[],
  weekCellRender: (event: object, allDay: boolean) => any
) => {
  const events: any[] = [];

  rawEvents?.map((event) => {
    console.log('lalala', event);
    if (event.startTime) {
      const startTime = moment(event.startTime).toDate();
      const endTime = moment(event.endTime).toDate();

      events.push({
        startTime,
        endTime,
        title: weekCellRender(event, false),
        key: event._id
      });
    } else {
      const startTime = moment(event.date).startOf('day').toDate();
      const endTime = moment(event.date).endOf('day').toDate();

      events.push({
        allDay: true,
        startTime,
        endTime,
        title: weekCellRender(event, true),
        key: event._id
      });
    }
  });

  return events;
};
