import React from 'react';
import PropTypes from 'prop-types';
import {
  DeleteOutlined,
  FormOutlined,
  PhoneOutlined,
  CalendarOutlined,
  EditOutlined
} from '@ant-design/icons';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Col, Row, Timeline } from 'antd';
import styled from 'styled-components';
import { useApplicationsContext } from '../../../../contexts/ApplicationsContext';

const Line = styled.div`
  position: relative;
  background-color: #f0f0f0;
  margin-right: 40px;
  padding: 15px;
  .type {
    font-weight: bold;
    font-size: 20px;
    text-transform: capitalize;
  }
`;

const DisplayActivity = ({ elem, editActivity, handleDelActivity }) => {
  const { t } = useTranslation();
  const dateTime = elem.dateTime ? new Date(elem.dateTime) : null;
  let durationTime;

  const selectActivityIcon = (type) => {
    switch (type) {
      case 'note':
        return <FormOutlined />;
      case 'call':
        return <PhoneOutlined />;
      case 'meeting':
        return <CalendarOutlined />;
      default:
        return <FormOutlined />;
    }
  };

  if (elem.type === 'meeting') {
    const hours = Math.floor(elem.duration);
    const minutes = Math.round((elem.duration - hours) * 60);

    durationTime = `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}`;
  }

  return (
    <Timeline.Item key={elem}>
      {elem.dateTime && (
        <div style={{ color: 'white' }} className="date">
          {moment(dateTime).format('LLLL')}
        </div>
      )}
      <Line>
        <EditOutlined
          onClick={() => editActivity(elem)}
          style={{
            position: 'absolute',
            top: 10,
            right: 30,
            cursor: 'pointer'
          }}
        />
        <DeleteOutlined
          onClick={() => handleDelActivity(elem)}
          style={{
            position: 'absolute',
            top: 10,
            right: 10,
            cursor: 'pointer'
          }}
        />
        <div className="type">
          {selectActivityIcon(elem.type)}
          <span style={{ marginLeft: 10 }}>{elem.title}</span>
        </div>
        <Row>
          <Col span={20}>
            <div className="desc" style={{ margin: '10px 0px 10px 0px' }}>
              {elem.desc}
            </div>
            {elem.type === 'meeting' && (
              <div
                className="desc"
                style={{ fontStyle: 'italic', fontSize: 12 }}
              >
                {elem.address
                  ? t('applications.show.place') + elem.address
                  : '-'}
              </div>
            )}
            {elem.type === 'meeting' && (
              <div
                className="duration"
                style={{ fontStyle: 'italic', fontSize: 12 }}
              >
                {durationTime
                  ? `${t('applications.show.duration') + durationTime} h`
                  : '-'}
              </div>
            )}
          </Col>
        </Row>
      </Line>
    </Timeline.Item>
  );
};
DisplayActivity.propTypes = {
  elem: PropTypes.oneOfType([PropTypes.object]),
  editActivity: PropTypes.func.isRequired,
  handleDelActivity: PropTypes.func.isRequired
};
DisplayActivity.defaultProps = {
  elem: null
};

const ListApplicationActivity = ({
  selectedTab,
  editActivity,
  handleDelActivity
}) => {
  const { application } = useApplicationsContext();

  const listActivitiesTimeline = () => {
    const sortedArray = application?.activities.sort(
      (a, b) => new Date(b.dateTime) - new Date(a.dateTime)
    );

    return sortedArray.map((elem) => (
      <DisplayActivity
        editActivity={editActivity}
        handleDelActivity={handleDelActivity}
        elem={elem}
        key={elem._id}
      />
    ));
  };

  if (application?.activities) {
    return (
      <div style={{ paddingLeft: '14px', paddingTop: '20px', minHeight: 100 }}>
        {selectedTab === 'call' &&
          application?.activities
            .filter((activity) => activity.type === 'call')
            .map((elem) => (
              <DisplayActivity
                editActivity={editActivity}
                handleDelActivity={handleDelActivity}
                elem={elem}
                key={elem._id}
              />
            ))}
        {selectedTab === 'note' &&
          application?.activities
            .filter((activity) => activity.type === 'note')
            .map((elem) => (
              <DisplayActivity
                editActivity={editActivity}
                handleDelActivity={handleDelActivity}
                elem={elem}
                key={elem._id}
              />
            ))}
        {selectedTab === 'meeting' &&
          application?.activities
            .filter((activity) => activity.type === 'meeting')
            .map((elem) => (
              <DisplayActivity
                editActivity={editActivity}
                handleDelActivity={handleDelActivity}
                elem={elem}
                key={elem._id}
              />
            ))}
        {selectedTab === 'activity' && (
          <Timeline>{listActivitiesTimeline()}</Timeline>
        )}
      </div>
    );
  }
  return null;
};
ListApplicationActivity.propTypes = {
  selectedTab: PropTypes.string,
  editActivity: PropTypes.func.isRequired,
  handleDelActivity: PropTypes.func.isRequired
};
ListApplicationActivity.defaultProps = {
  selectedTab: 'activity'
};

export default ListApplicationActivity;
