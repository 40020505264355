import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Layout } from 'antd';
import { Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Logo from '../../assets/images/logostrategin.png';
import HeaderLayout from './HeaderLayout/HeaderLayoutDashboard';
import { useHandleResize } from '../../utils/HandleResize';
import background from '../../assets/images/background-dashboard.jpg';

const { Content, Footer } = Layout;

const StyledLayout = styled.div`
  height: 100vh;
  background-size: cover;
  background-image: url(${background}) !important;

  > .ant-layout {
    background: transparent;
  }
`;

const StyledContent = styled.div`
  margin: 56px 0 0 0;
  min-height: calc(100vh - 56px);
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  @media (max-width: 992px) {
    margin-left: 0;
  }

  @media (max-width: 576px) {
    margin-left: 0px;
  }
`;

const StyledFooter = styled.footer`
  padding: 8px 24px;
  text-align: center;
  color: var(--textColor);
  border-top: 0;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(4px);
  a {
    color: var(--primaryColor);
  }
`;

export const DashboardLayout = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(window.innerWidth < 992);
  const { width, height } = useHandleResize();
  const [collapseWidth, setCollapseWidth] = useState(
    window.innerWidth < 576 ? 0 : 80
  );

  useEffect(() => {
    if (width < 576) {
      setCollapseWidth(0);
    } else {
      setCollapseWidth(80);
    }
  }, [width, height]);

  useEffect(() => {
    document
      .getElementById('mainContent')
      .scrollTo({ behavior: 'smooth', top: 0 });
  }, [location]);

  return (
    <StyledLayout as={Layout}>
      <Layout>
        <HeaderLayout
          collapsed={collapsed}
          collapseSider={setCollapsed}
          collapseWidth={collapseWidth}
          themeLogo={Logo}
        />
        <StyledContent as={Content} id="mainContent">
          <Outlet />
          <StyledFooter as={Footer}>
            {`${t('global.title')} ©${moment().year()} powered by `}
            <a href="https://strateg.in">Strateg.in</a>
          </StyledFooter>
        </StyledContent>
      </Layout>
    </StyledLayout>
  );
};
