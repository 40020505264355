/* eslint-disable prettier/prettier */
const countryCodes = [
  { code: '+1', name: 'USA/CAN' },
  { code: '+7', name: 'RUS' },
  { code: '+20', name: 'EGY' },
  { code: '+27', name: 'RSA' },
  { code: '+30', name: 'GRE' },
  { code: '+31', name: 'NED' },
  { code: '+32', name: 'BEL' },
  { code: '+33', name: 'FRA' },
  { code: '+34', name: 'ESP' },
  { code: '+36', name: 'HUN' },
  { code: '+39', name: 'ITA' },
  { code: '+40', name: 'ROU' },
  { code: '+41', name: 'SUI' },
  { code: '+43', name: 'AUT' },
  { code: '+44', name: 'GBR' },
  { code: '+45', name: 'DEN' },
  { code: '+46', name: 'SWE' },
  { code: '+47', name: 'NOR' },
  { code: '+48', name: 'POL' },
  { code: '+49', name: 'GER' },
  { code: '+51', name: 'PER' },
  { code: '+52', name: 'MEX' },
  { code: '+53', name: 'CUB' },
  { code: '+54', name: 'ARG' },
  { code: '+55', name: 'BRA' },
  { code: '+56', name: 'CHI' },
  { code: '+57', name: 'COL' },
  { code: '+58', name: 'VEN' },
  { code: '+60', name: 'MAS' },
  { code: '+61', name: 'AUS' },
  { code: '+62', name: 'INA' },
  { code: '+63', name: 'PHI' },
  { code: '+64', name: 'NZL' },
  { code: '+65', name: 'SGP' },
  { code: '+66', name: 'THA' },
  { code: '+81', name: 'JPN' },
  { code: '+82', name: 'KOR' },
  { code: '+84', name: 'VIE' },
  { code: '+86', name: 'CHN' },
  { code: '+90', name: 'TUR' },
  { code: '+91', name: 'IND' },
  { code: '+92', name: 'PAK' },
  { code: '+93', name: 'AFG' },
  { code: '+94', name: 'LKA' },
  { code: '+95', name: 'MMR' },
  { code: '+98', name: 'IRN' },
  { code: '+211', name: 'SSD' },
  { code: '+212', name: 'MAR' }
];

export default countryCodes;
