import React from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const SetEmailRecipientsOptions = ({
  selectedIds,
  setSelectedIds,
  recipientsList
}) => {
  const { t } = useTranslation();

  const handleChange = (value) => {
    setSelectedIds(value);
  };

  return (
    <Select
      placeholder={t('email.modal.choose_recipient')}
      value={selectedIds}
      onChange={handleChange}
      style={{ width: '100%' }}
    >
      {(recipientsList || []).map((recipient) => (
        <Option value={recipient._id} key={recipient._id}>
          {`${recipient.first_name} ${recipient.last_name}`}
        </Option>
      ))}
    </Select>
  );
};

export default SetEmailRecipientsOptions;

SetEmailRecipientsOptions.propTypes = {
  selectedIds: PropTypes.arrayOf({}).isRequired,
  setSelectedIds: PropTypes.func.isRequired,
  recipientsList: PropTypes.arrayOf(PropTypes.string).isRequired
};
