import { Tabs } from 'antd';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ContentCustom, PageHeaderCustom } from '../../components';
import { ListGroupOrganisation } from './PermissionGroup/ListGroupOrganisation';
import { GestionPermissions } from './GestionPermission/GestionPermissions';
import { useAuthContext } from '../../contexts/AuthContext';
import { permissionVerification } from '../../utils/permissionVerification';

export const Organisation = () => {
  const { t } = useTranslation();
  const { userPermissions } = useAuthContext();
  const [permit, setPermit] = useState(false);

  useEffect(() => {
    const permission = permissionVerification(
      userPermissions,
      '/organisation/modification'
    );
    setPermit(permission);
  }, [userPermissions]);

  const [permissionView, setPermissionView] = useState(false);
  return (
    <>
      <PageHeaderCustom title={t(`organisation.title.${permissionView}`)} />
      <ContentCustom>
        <Tabs
          defaultActiveKey="1"
          onChange={() => {
            setPermissionView(!permissionView);
          }}
        >
          <Tabs.TabPane tab={t('organisation.groups.title')} key="1" />
          <Tabs.TabPane
            tab={t('organisation.permission.title')}
            key="2"
            disabled={!permit}
          />
        </Tabs>
        {permissionView ? <GestionPermissions /> : <ListGroupOrganisation />}
      </ContentCustom>
    </>
  );
};
