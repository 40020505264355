import React from 'react';
import moment from 'moment';
import { getFormattedPhoneNumber } from '../../../utils/phoneNumber';

const listContent = (data) => {
  const {
    first_name,
    last_name,
    email,
    phone_number,
    potential_positions,
    premise,
    last_contact,
    comment
  } = data;

  const setPotentialPosition = (positions) => {
    const potentialPositions =
      (positions &&
        positions.map((position, index, array) => {
          if (index === array.length - 1) {
            return position?.label;
          }
          return `${position?.label}, `;
        })) ||
      '-';

    return potentialPositions;
  };

  return [
    {
      label: 'candidates.form.first_name',
      span: 1,
      content: first_name || '-'
    },
    {
      label: 'candidates.form.last_name',
      span: 1,
      content: last_name || '-'
    },
    {
      label: 'candidates.form.email',
      span: 1,
      content: email ? <a href={`mailto:${email}`}>{email}</a> : '-'
    },
    {
      label: 'candidates.form.phone_number_full',
      span: 1,
      content:
        phone_number && phone_number.number
          ? getFormattedPhoneNumber(
              phone_number.country_code,
              phone_number.number
            )
          : '-'
    },
    {
      label: 'candidates.form.premise',
      span: 1,
      content: (premise && premise.label) || '-'
    },
    {
      label: 'candidates.form.potential_positions',
      span: 2,
      content: setPotentialPosition(potential_positions)
    },
    {
      label: 'candidates.form.last_contact',
      span: 2,
      content: last_contact ? moment(last_contact).format('ll') : '-'
    },
    {
      label: 'candidates.form.comment',
      span: 2,
      content: comment || '-'
    }
  ];
};

export default listContent;
