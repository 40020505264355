// Main nav menuItems
export const routes = {
  HOME: '/',
  DASHBOARD: '/dashboard',
  USERS: '/users',
  RECRUITMENTS: '/recruitments',
  EMPLOYEE_FOLDER: '/employee-folder',
  INTERN_COMMUNICATION: '/intern-communication',
  TRAININGS: '/trainings',
  SETTINGS: '/settings',
  TEMPLATE_DOCS: '/template-docs',
  WORKFLOWS: '/workflows'
};

// Main nav subMenuItems
export const subRoutes = {
  TRAININGS: {
    PATH: '/trainings-path',
    FOLLOW: '/follow-up'
  },
  RECRUITMENTS: {
    OFFERS: '/offers',
    CANDIDATES: '/candidates',
    RECOMMANDATIONS: '/recommendations',
    INPROGRESS: '/in-progress',
    INTERVIEWS: '/interviews',
    ENTRIES: '/entries'
  },
  EMPLOYEE_FOLDER: {
    EMPLOYEES: '/employees',
    BONUS: '/bonus',
    INCIDENTS: '/incidents',
    EXITS: '/exits'
  },
  INTERN_COMMUNICATION: {
    DOCUMENTS: '/documents',
    MEMOS: '/memos'
  }
};

// Other routes
export const outOfNavRoutes = {
  LOGIN: '/login',
  CONFIRMATION: '/email-confirmation',
  PROFILE: '/profile',
  SETTINGS: '/settings',
  PREMISE: '/premise/create',
  PASSWORD_CREATION: '/post-pwd',
  PREMISE_UPDATE: '/premise/update:id'
};

// url search parameters
export const pathSearches = {};
