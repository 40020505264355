import moment from 'moment';

const setBonusAmount = (data) => {
  let amount = '';
  if (data?.share && data?.employee?.gross_monthly_salary) {
    amount = data.share * data.employee.gross_monthly_salary;
  }
  return amount;
};

export const useFormatter = () => {
  const formattedData = (data) => ({
    ...data,
    end_of_publication_date: moment(data?.end_of_publication_date).format(
      'DD/MM/YYYY'
    ),
    premise:
      (Array.isArray(data?.premise) && { label: data?.premise[0]?.label }) ||
      data?.premise?.label ||
      data?.employee?.premise?.label,
    position: data?.position?.label || data?.employee?.position?.label,
    employee: {
      last_name: data?.employee?.last_name,
      first_name: data?.employee?.first_name
    },
    candidate: {
      last_name: data?.candidate?.last_name,
      first_name: data?.candidate?.first_name
    },
    rh_employee: {
      last_name: data?.rh_employee?.last_name,
      first_name: data?.rh_employee?.first_name
    },
    author: {
      last_name: data?.author?.last_name,
      first_name: data?.author?.first_name
    },
    hr_intervenant: {
      last_name: data?.hr_intervenant?.last_name,
      first_name: data?.hr_intervenant?.first_name
    },
    last_contact: moment(data?.last_contact).format('DD/MM/YYYY'),
    created_at: moment(data?.created_at).format('DD/MM/YYYY'),
    header: { label: data?.header?.label },
    frame: { title: data?.frame?.label },
    start_date: moment(data?.start_date).format('DD/MM/YYYY'),
    time_start: moment(data?.time_start).format('DD/MM/YYYY HH:mm'),
    time_end: moment(data?.time_end).format('DD/MM/YYYY HH:mm'),
    date: moment(data?.date).format('DD/MM/YYYY'),
    amount: setBonusAmount(data),
    exit_date: moment(data?.exit_date).format('DD/MM/YYYY'),
    expected_start_date: moment(data?.expected_start_date).format('DD/MM/YYYY'),
    premises: data?.premises?.map((premise) => premise.label).join(', '),
    application: data?.application?.offer?.name,
    country_code: data?.phone_number?.country_code,
    number: data?.phone_number?.number,
    status: data?.status || data?.application?.status?.label,
    interlocutors: {
      last_name: data?.interlocutors
        ?.map((interlocutor) => interlocutor.last_name)
        .join(', '),
      first_name: data?.interlocutors
        ?.map((interlocutor) => interlocutor.first_name)
        .join(', ')
    },
    potential_positions: data?.potential_positions
      ?.map((item) => item.label)
      .join(', ')
  });
  return { formattedData };
};
