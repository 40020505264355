import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Layout } from 'antd';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Logo from '../../assets/images/logo.png';
import HeaderLayout from './HeaderLayout/HeaderLayout';
import { useHandleResize } from '../../utils/HandleResize';
import { AdminNavMenu } from './AdminNavMenu';
import { UpdateAlert } from '..';

const { Content, Footer, Sider } = Layout;

const StyledLayout = styled.div`
  height: 100vh;

  > .ant-layout {
    background: var(--bodyBackground);
  }
`;

const StyledSider = styled.div`
  height: 100vh;
  z-index: 10;
  box-shadow: 4px 0 8px rgba(9, 9, 9, 0.1);
  overflow-x: hidden;
  position: fixed;
  left: 0;
`;

const LogoDiv = styled.div`
  position: relative;
  margin-top: 16px;
  height: 50px;
  padding-left: 0;
  overflow: hidden;
  transition: all 0.3s;
  z-index: 900;
  display: flex;
  justify-content: center;
  img {
    display: inline-block;
    height: 50px;
    vertical-align: middle;
  }
  a {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
  }
`;

const StyledContent = styled.div`
  margin: 64px 0 0 256px;
  overflow: auto;

  @media (max-width: 992px) {
    margin-left: 80px;
  }

  @media (max-width: 576px) {
    margin-left: 0;
  }
`;

const StyledFooter = styled.footer`
  padding: 8px 25px;
  text-align: center;
  margin-left: 255px;
  background-color: var(--bodyBackground);
  color: var(--textColor60);
  border-top: 1px solid rgba(9, 9, 9, 0.1);

  @media (max-width: 992px) {
    margin-left: 80px;
  }

  @media (max-width: 576px) {
    margin-left: 0;
  }

  a {
    color: var(--textColor60);
    &:hover {
      color: var(--primaryHover);
    }
  }
`;

export const AdminLayout = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(window.innerWidth < 992);
  const { width, height } = useHandleResize();
  const [collapseWidth, setCollapseWidth] = useState(
    window.innerWidth < 576 ? 0 : 80
  );

  const onCollapse = (newCollapsed) => {
    setCollapsed(newCollapsed);
  };

  useEffect(() => {
    if (width < 576) {
      setCollapseWidth(0);
    } else {
      setCollapseWidth(80);
    }
  }, [width, height]);

  useEffect(() => {
    document
      .getElementById('mainContent')
      .scrollTo({ behavior: 'smooth', top: 0 });
  }, [location]);

  return (
    <StyledLayout as={Layout}>
      <UpdateAlert />
      <StyledSider
        as={Sider}
        width={256}
        breakpoint="lg"
        trigger={null}
        collapsible
        collapsedWidth={collapseWidth}
        collapsed={collapsed}
        onCollapse={onCollapse}
      >
        <LogoDiv>
          <Link to="/dashboard">
            <img alt="Logo" src={Logo} />
            {!collapsed && (
              <span className="logo-vbr">
                Groupe <b>VBR</b>
              </span>
            )}
          </Link>
        </LogoDiv>
        <AdminNavMenu setCollapsed={setCollapsed} />
      </StyledSider>
      <Layout>
        <HeaderLayout
          collapsed={collapsed}
          collapseSider={setCollapsed}
          collapseWidth={collapseWidth}
        />
        <StyledContent as={Content} id="mainContent">
          <Outlet />
        </StyledContent>
        <StyledFooter as={Footer}>
          {`${t('global.title')} ©${moment().year()} powered by `}
          <a href="https://strateg.in">Strateg.in</a>
        </StyledFooter>
      </Layout>
    </StyledLayout>
  );
};
