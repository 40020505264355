const checkFilterObjectKeys = (keyArray, filterObjectKeys) => {
  let sliceIndexFilter;
  let boolean;
  for (let i = 0; i < keyArray.length; i += 1) {
    for (let j = 0; j < filterObjectKeys.length; j += 1) {
      if (keyArray[i] === filterObjectKeys[j]) {
        sliceIndexFilter = i - 1;
        boolean = true;
      }
    }
  }

  return { sliceIndexFilter, boolean };
};

const pickCorrectTranslation = (showText, formText, t, boolean) => {
  const formatText = (inputString) =>
    inputString.charAt(0).toUpperCase() +
    inputString.substring(1).toLowerCase();

  const filterObjectCase = (string) => {
    const stringArray = string.split('.');
    const rawArray = stringArray.slice(0, 1);
    rawArray.push('title');
    return formatText(t(`${rawArray.join('.')}`).slice(0, -1));
  };

  switch (true) {
    case formatText(t(showText)).includes(
      'returned an object instead of string'
    ) && boolean:
      return formatText(
        `${filterObjectCase(showText)} - ${formatText(t(`${showText}.title`))}`
      );
    case formatText(t(formText)).includes(
      'returned an object instead of string'
    ) && boolean:
      return `${filterObjectCase(formText)} - ${formatText(
        t(`${formText}.title`)
      )}`;
    case formatText(t(showText)).includes(
      'returned an object instead of string'
    ):
      return formatText(t(`${showText}.title`));
    case formatText(t(formText)).includes(
      'returned an object instead of string'
    ):
      return formatText(t(`${formText}.title`));
    case showText !== t(showText):
      return formatText(t(showText));
    case formText !== t(formText):
      return formatText(t(formText));
    default:
      return t(formText);
  }
};

export const createTranslationKey = (source, key, t, p) => {
  const userKeys = ['hr_intervenant', 'author', 'rh_employee'];
  const filterObjectKeys = ['phone_number', 'address', 'recommended_by'];
  let showText;
  let formText;

  const keyArray = key.split('.');

  const { sliceIndexFilter, boolean } = checkFilterObjectKeys(
    keyArray,
    filterObjectKeys
  );

  const sliceIndex =
    boolean === true && source === 'tag' ? sliceIndexFilter : -2;

  // FIRST LEVEL TRANSFORMATION
  if (keyArray.length === 2) {
    const showTextArray = [...keyArray];
    if (userKeys.includes(showTextArray[0])) {
      showTextArray.splice(0, 1, 'users');
    }
    showTextArray.splice(0, 1, `${p(keyArray[0])}`);
    showTextArray.splice(1, 0, 'show');

    showText = showTextArray.join('.');

    const formTextArray = [...keyArray];
    if (userKeys.includes(formTextArray[0])) {
      formTextArray.splice(0, 1, 'users');
    }
    formTextArray.splice(0, 1, `${p(keyArray[0])}`);
    formTextArray.splice(1, 0, 'form');
    formText = formTextArray.join('.');

    return pickCorrectTranslation(showText, formText, t);
  }

  // SECOND LEVEL TRANSFORMATION
  const showTextArray = [...keyArray].slice(sliceIndex);
  if (userKeys.includes(showTextArray[0])) {
    showTextArray.splice(0, 1, 'users');
  }
  showTextArray.splice(0, 1, `${p(showTextArray[0])}`);
  showTextArray.splice(1, 0, 'show');
  showText = showTextArray.join('.');

  const formTextArray = [...keyArray].slice(sliceIndex);
  if (userKeys.includes(formTextArray[0])) {
    formTextArray.splice(0, 1, 'users');
  }
  formTextArray.splice(0, 1, `${p(formTextArray[0])}`);
  formTextArray.splice(1, 0, 'form');
  formText = formTextArray.join('.');

  return pickCorrectTranslation(showText, formText, t, boolean);
};
