import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Switch, Tag, Row } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { ListResource } from '../../../components';
import { offerStatus } from '../../../utils/constants/tagColors';
import { useFormatter } from '../../../utils/formatter';
import { useAuthContext } from '../../../contexts/AuthContext';
import { permissionVerification } from '../../../utils/permissionVerification';

const ListOffers = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const { userPermissions } = useAuthContext();
  const params = new URLSearchParams(location.search);
  const archived = params.get('archived');
  const { t } = useTranslation();
  const [isArchived, setIsArchived] = useState(archived === 'true');
  const { formattedData } = useFormatter();
  const [permit, setPermit] = useState(false);

  useEffect(() => {
    const permission = permissionVerification(
      userPermissions,
      '/recruitments/offers/modification'
    );
    setPermit(permission);
  }, [userPermissions]);

  const onChange = () => {
    setIsArchived(!isArchived);
    navigate(`${pathname}?archived=${!isArchived}`);
  };

  const headers = [
    {
      label: 'name',
      key: 'name'
    },
    {
      label: 'position',
      key: 'position.label',
      header_key: 'position'
    },
    {
      label: 'premise',
      key: 'premise.label',
      header_key: 'premise'
    },
    {
      label: 'status',
      key: 'status'
    },
    {
      label: 'description',
      key: 'description'
    },
    {
      label: 'missions',
      key: 'missions'
    },
    {
      label: 'required_profile',
      key: 'required_profile'
    },
    {
      label: 'required_experience',
      key: 'required_experience'
    },
    {
      label: 'advantages',
      key: 'advantages'
    },
    {
      label: 'contract_type',
      key: 'contract_type'
    },
    {
      label: 'work_time',
      key: 'work_time'
    },
    {
      label: 'salary',
      key: 'salary'
    },
    {
      label: 'salary_period',
      key: 'salary_period'
    },
    {
      label: 'expected_start_date',
      key: 'expected_start_date'
    },
    {
      label: 'premise_adress',
      key: 'premise_adress'
    },
    {
      label: 'end_of_publication_date',
      key: 'end_of_publication_date'
    }
  ];

  const columns = [
    {
      title: t('offers.form.name'),
      key: 'name',
      dataIndex: 'name',
      sorter: true
    },
    {
      title: t('offers.form.premise'),
      key: 'premise',
      dataIndex: 'premise',
      render: (premise) => premise.label,
      sorter: true
    },
    {
      title: t('offers.form.status'),
      key: 'status',
      dataIndex: 'status',
      render: (status) => (
        <Tag color={offerStatus[status]}>{t(`offers.tags.${status}`)}</Tag>
      ),
      sorter: true,
      filters: Object.keys(offerStatus).map((r) => ({
        text: t(`offers.tags.${r}`),
        value: r
      }))
    },
    {
      title: t('offers.form.end_of_publication_date'),
      key: 'end_of_publication_date',
      dataIndex: 'end_of_publication_date',
      render: (end_of_publication_date) =>
        moment(end_of_publication_date).format('ll'),
      sorter: true
    },
    {
      title: t('offers.form.candidates'),
      key: 'candidates',
      dataIndex: 'candidates',
      sorter: true,
      render: (candidates) => candidates.length
    }
  ];

  return (
    <ListResource
      resourceName="offers"
      populate="premise,position"
      formatter={formattedData}
      deleteAction={permit}
      editAction={permit}
      withCreateButton={permit}
      templateType="offers"
      columns={columns}
      headers={headers}
      resourceModelName="Offer"
      extraButtons={
        <Row align="middle" className="list-switch-wrapper">
          <Switch checked={isArchived} onChange={onChange} />
          <p>{t('offers.archived')}</p>
        </Row>
      }
      extraQuery={isArchived ? 'is_archived=true' : 'is_archived=false'}
    />
  );
};

export default ListOffers;
