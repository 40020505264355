import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Layout } from 'antd';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Logo from '../../assets/images/logo.svg';
import LogoCollapsed from '../../assets/images/logostrateginCollapsed.png';
import HeaderLayout from './HeaderLayout/HeaderLayout';
import { useHandleResize } from '../../utils/HandleResize';
import { DeveloperNavMenu } from './DeveloperNavMenu';
import { UpdateAlert } from '..';

const { Content, Footer, Sider } = Layout;

const StyledLayout = styled.div`
  height: 100vh;
`;

const StyledSider = styled.div`
  background-color: var(--primaryColor);
  height: 100vh;
  z-index: 10;
  box-shadow: 4px 0px 8px rgba(9, 9, 9, 0.1);
  overflow-x: hidden;
  position: fixed;
  left: 0;
`;

const LogoDiv = styled.div`
  position: relative;
  padding-left: 0;
  margin: 40px 0 24px 0;
  overflow: hidden;
  transition: all 0.3s;
  z-index: 900;
  display: flex;
  justify-content: center;
  img {
    display: inline-block;
    height: 72px;
    vertical-align: middle;
  }
`;

const StyledContent = styled.div`
  margin: 64px 0 0 256px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  @media (max-width: 992px) {
    margin-left: 100px;
  }

  @media (max-width: 576px) {
    margin-left: 24px;
  }
`;

const StyledFooter = styled.footer`
  padding: 8px 25px;
  text-align: center;
  margin-left: 255px;
  background: var(--bodyBackground);
  color: var(--textColor);
  box-shadow: 0px -3px 4px rgba(0, 0, 0, 0.15);
  text-transform: uppercase;

  a {
    color: var(--primaryColor);
  }

  @media (max-width: 992px) {
    margin-left: 80px;
  }

  @media (max-width: 576px) {
    margin-left: 0;
  }
`;

export const DeveloperLayout = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(window.innerWidth < 992);
  const { width, height } = useHandleResize();
  const [collapseWidth, setCollapseWidth] = useState(
    window.innerWidth < 576 ? 0 : 80
  );

  const onCollapse = (newCollapsed) => {
    setCollapsed(newCollapsed);
  };

  useEffect(() => {
    if (width < 576) {
      setCollapseWidth(0);
    } else {
      setCollapseWidth(80);
    }
  }, [width, height]);

  useEffect(() => {
    document
      .getElementById('mainContent')
      .scrollTo({ behavior: 'smooth', top: 0 });
  }, [location]);

  return (
    <StyledLayout as={Layout}>
      <UpdateAlert />
      <StyledSider
        as={Sider}
        width={256}
        breakpoint="lg"
        trigger={null}
        collapsible
        collapsedWidth={collapseWidth}
        collapsed={collapsed}
        onCollapse={onCollapse}
      >
        <LogoDiv>
          <Link to="/">
            <img alt="Logo" src={collapsed ? LogoCollapsed : Logo} />
          </Link>
        </LogoDiv>
        <DeveloperNavMenu setCollapsed={setCollapsed} />
      </StyledSider>
      <Layout>
        <HeaderLayout
          collapsed={collapsed}
          collapseSider={setCollapsed}
          collapseWidth={collapseWidth}
        />
        <StyledContent as={Content} id="mainContent">
          <Outlet />
        </StyledContent>
        <StyledFooter as={Footer}>
          {`${t('global.title')} ©${moment().year()} powered by `}
          <a href="https://strateg.in">Strateg.in</a>
        </StyledFooter>
      </Layout>
    </StyledLayout>
  );
};
