import React from 'react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { templateStatus } from '../../utils/constants/tagColors';

export const listContent = (data = {}) => {
  const { t } = useTranslation();
  const { title, status, author, default_template } = data;

  return [
    {
      label: 'template-documents.show.template-title',
      span: 3,
      content: title || t('errors.form.not_specified')
    },
    {
      label: 'template-documents.show.status',
      span: 3,
      content:
        (status && (
          <Tag color={templateStatus[status]}>
            {t(`template-documents.show.${status}`)}
          </Tag>
        )) ||
        t('errors.form.not_specified')
    },
    {
      label: 'template-documents.show.author',
      span: 3,
      content:
        (author && `${author.first_name} ${author.last_name}`) ||
        t('errors.form.not_specified')
    },
    {
      label: 'template-documents.show.default_template',
      span: 3,
      content:
        default_template === true ? (
          <CheckCircleOutlined style={{ color: 'green' }} />
        ) : (
          <CloseCircleOutlined style={{ color: 'red' }} />
        )
    }
  ];
};
