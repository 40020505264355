import React from 'react';
import { Button, Col, Spin } from 'antd';
import { MailOutlined, UploadOutlined } from '@ant-design/icons';

const ResignationHeader = ({
  activeStep,
  nextStep,
  setIsModalOpen,
  setRestitutionType,
  exit,
  setAcknowledgmentOfReceiptType,
  generateFile,
  isDocLoading,
  updateStatus,
  t
}) => {
  const renderAdditionalButtons = () => {
    switch (activeStep) {
      case 'OUTPUT_SHEET':
        return (
          <Col>
            <Button
              type="primary"
              danger
              onClick={() => {
                updateStatus();
                nextStep();
              }}
            >
              {t(`exits.header.button.${activeStep}`)}
            </Button>
          </Col>
        );
      case 'ACKNOWLEDGMENT_OF_RECEIPT':
        return (
          <>
            <Col>
              {exit && !exit.is_resignation_letter && (
                <Button
                  type="primary"
                  onClick={() => {
                    setIsModalOpen(true);
                    setAcknowledgmentOfReceiptType('resignation_letter');
                  }}
                >
                  <UploadOutlined />
                  {t(`exits.header.button.${activeStep}.resignation_letter`)}
                </Button>
              )}
            </Col>
            <Col>
              {exit &&
                exit.is_resignation_letter &&
                !exit.is_acknowledgment_of_receipt_generated && (
                  <Spin spinning={isDocLoading}>
                    <Button
                      type="primary"
                      disabled={isDocLoading}
                      onClick={() => {
                        setAcknowledgmentOfReceiptType(
                          'generate_acknowledgment_of_receipt'
                        );
                        generateFile('generate_acknowledgment_of_receipt');
                      }}
                    >
                      <UploadOutlined />
                      {t(
                        `exits.header.button.${activeStep}.generate_acknowledgment_of_receipt`
                      )}
                    </Button>
                  </Spin>
                )}
            </Col>
            <Col>
              {exit && exit.is_acknowledgment_of_receipt_generated && (
                <Button
                  type="primary"
                  onClick={() => {
                    setIsModalOpen(true);
                    setAcknowledgmentOfReceiptType(
                      'send_acknowledgment_of_receipt'
                    );
                  }}
                >
                  <MailOutlined />
                  {t(
                    `exits.header.button.${activeStep}.send_acknowledgment_of_receipt`
                  )}
                </Button>
              )}
            </Col>
          </>
        );
      case 'RESTITUTION_REQUEST':
        return (
          <Col>
            <Button
              type="primary"
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              <MailOutlined />
              {t(`exits.header.button.${activeStep}`)}
            </Button>
          </Col>
        );
      case 'RESTITUTION_VALIDATED':
        return (
          <>
            <Col>
              {exit && !exit?.is_material ? (
                <Button
                  type="primary"
                  onClick={() => {
                    setIsModalOpen(true);
                    setRestitutionType('material');
                  }}
                >
                  {t(`exits.header.button.${activeStep}.material`)}
                </Button>
              ) : null}
            </Col>
            <Col>
              {exit && !exit?.is_check_list_validated ? (
                <Button
                  type="primary"
                  onClick={() => {
                    setIsModalOpen(true);
                    setRestitutionType('check_list');
                  }}
                >
                  {t(`exits.header.button.${activeStep}.check_list`)}
                </Button>
              ) : null}
            </Col>
          </>
        );
      case 'ACCESS_DELETED':
        return (
          <Col>
            <Button
              type="primary"
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              {t(`exits.header.button.${activeStep}`)}
            </Button>
          </Col>
        );
      default:
        return null;
    }
  };

  return renderAdditionalButtons();
};

export default ResignationHeader;
