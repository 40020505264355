/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { Button, Input, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import InterviewFrameTable from './interviewFrameTable';

const initialColumns = [
  {
    title: 'Label',
    dataIndex: 'label',
    key: 'label',
    render: (text) => <Input InitialValue={text} />
  }
];

const InterviewFrameSettings = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [interviewframeCols, setInterviewFrameCols] = useState(initialColumns);
  const newInterviewFrameCol = {
    title: 'Nouvelle ligne',
    label: 'Nouvelle ligne',
    show: true
  };

  const getInterviewFrameCols = async () => {
    try {
      const data = await dispatchAPI('GET', { url: '/interviewframes' });
      setInterviewFrameCols(data.data);
    } catch (e) {
      message.error(e.message);
    }
  };

  const addInterviewFrameCol = async () => {
    try {
      await dispatchAPI('POST', {
        url: '/interviewframes',
        body: newInterviewFrameCol
      });
      getInterviewFrameCols();
    } catch (e) {
      message.error(e.message);
    }
  };

  const editInterviewFrameCol = async (_id, row) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/interviewframes/${_id}`,
        body: row
      });
    } catch (e) {
      message.error(e.message);
    }
    getInterviewFrameCols();
  };
  useEffect(() => {
    getInterviewFrameCols();
  }, []);

  return (
    <>
      <InterviewFrameTable
        interviewframeCols={interviewframeCols}
        editInterviewFrameCol={editInterviewFrameCol}
      />
      <Button
        type="dashed"
        style={{ width: '100%', height: 50 }}
        onClick={addInterviewFrameCol}
      >
        {t('settings.interviewframeTable.addinterviewframescol')}
      </Button>
    </>
  );
};

export default InterviewFrameSettings;
