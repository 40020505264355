import React from 'react';
import { Row, Upload, Button, Col } from 'antd';
import PropTypes from 'prop-types';
import { InboxOutlined, DownloadOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../../utils/ErrorMessage';
import { useDownloadDocument } from '../../../../../utils/downloadDoc';

const { Dragger } = Upload;

const DocumentsCards = ({ exit, setRefresh, refresh }) => {
  const { message } = useErrorMessage();
  const { token, dispatchAPI } = useAuthContext();
  const { downloadDocument } = useDownloadDocument();

  const addDocumentToExit = async (file) => {
    const body = {
      $push: {
        documents: {
          _id: file._id,
          type: 'file',
          filename: file.metadata.originalName,
          contentType: file.contentType,
          metadata: { originalName: file.metadata.originalName }
        }
      }
    };
    try {
      await dispatchAPI('PATCH', { url: `exits/${exit._id}`, body });
      setRefresh(!refresh);
    } catch (e) {
      message(e);
    }
  };

  const draggerProps = {
    name: 'file',
    multiple: true,
    action: `${process.env.REACT_APP_API_URL}/files`,
    headers: { Authorization: `Bearer ${token}` },
    onChange(info) {
      const { status, response } = info.file;

      addDocumentToExit(response);

      if (status === 'error') {
        message(`${info.file.name} file upload failed.`);
      }
    }
  };

  return (
    <>
      <Row>
        {exit &&
          exit?.documents &&
          exit?.documents.map((document) => (
            <>
              <Col>
                <h1>{document.filename}</h1>
              </Col>
              <Col>
                <Button
                  onClick={() =>
                    downloadDocument({
                      _id: document._id,
                      metadata: document.metadata,
                      contentType: document.contentType
                    })
                  }
                  type="link"
                >
                  <DownloadOutlined style={{ fontSize: 18 }} />
                </Button>
              </Col>
            </>
          ))}
      </Row>

      <Row style={{ width: '100%' }}>
        <Dragger {...draggerProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Glisser / Déposer</p>
        </Dragger>
      </Row>
    </>
  );
};

export default DocumentsCards;

DocumentsCards.propTypes = {
  employee: PropTypes.shape({}),
  exit: PropTypes.shape({
    conventional_rupture_date: PropTypes.string,
    documents: PropTypes.arrayOf({
      _id: PropTypes.string,
      type: PropTypes.string,
      filename: PropTypes.string,
      contentType: PropTypes.string,
      metadata: PropTypes.shape({ originalName: PropTypes.string })
    }),
    created_at: PropTypes.string,
    employee: PropTypes.string,
    end_of_withdrawal_periods: PropTypes.string,
    exit_date: PropTypes.string,
    motif: PropTypes.string,
    status: PropTypes.string,
    updated_at: PropTypes.string,
    __v: PropTypes.number,
    _id: PropTypes.string
  }),
  setRefresh: PropTypes.func.isRequired,
  refresh: PropTypes.bool.isRequired
};

DocumentsCards.defaultProps = {
  employee: {},
  exit: {}
};
