import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import dayjs from 'dayjs';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import { ListResource } from '../../../components';
import { incidentsStatus } from '../../../utils/constants/tagColors';
import { useFormatter } from '../../../utils/formatter';
import { permissionVerification } from '../../../utils/permissionVerification';

const ListIncidents = () => {
  const { t } = useTranslation();
  const { dispatchAPI, userPermissions } = useAuthContext();
  const { message } = useErrorMessage();
  const { formattedData } = useFormatter();
  const [premises, setPremises] = useState([]);
  const [permit, setPermit] = useState(false);

  useEffect(() => {
    const permission = permissionVerification(
      userPermissions,
      '/employee-folder/incidents/modification'
    );
    setPermit(permission);
  }, [userPermissions]);

  const getPremises = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: 'premise' });
      setPremises(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getPremises();
    })();
  }, []);

  const headers = [
    {
      label: 'date',
      key: 'date'
    },
    {
      label: 'premise',
      key: 'premise.label',
      header_key: 'premise'
    },
    {
      label: 'author',
      extra_label: 'author_last_name',
      key: 'employee.last_name',
      header_key: 'author.last_name',
      model_key: 'employee'
    },
    {
      label: 'author',
      extra_label: 'author_first_name',
      key: 'employee.first_name',
      header_key: 'author.first_name',
      model_key: 'employee'
    },
    {
      label: 'hr_intervenant',
      extra_label: 'hr_intervenant_last_name',
      key: 'user.last_name',
      header_key: 'hr_intervenant.last_name',
      model_key: 'user'
    },
    {
      label: 'hr_intervenant',
      extra_label: 'hr_intervenant_first_name',
      key: 'user.first_name',
      header_key: 'hr_intervenant.first_name',
      model_key: 'user'
    },
    {
      label: 'status',
      key: 'status',
      model_key: 'status'
    }
  ];

  const columns = [
    {
      title: t('incidents.form.date'),
      key: 'date',
      render: (date) => dayjs(date).format('[Le] DD/MM/YYYY [à] H[h]mm') || '-',
      dataIndex: 'date',
      width: 200,
      sorter: true
    },
    {
      title: t('incidents.form.premise'),
      key: 'premise',
      render: (premise) => premise?.label || '-',
      dataIndex: 'premise',
      sorter: true,
      width: 300,
      filters:
        premises &&
        premises.map((premise) => ({
          text: premise?.label,
          value: premise?._id
        }))
    },
    {
      title: t('incidents.form.author'),
      key: 'author',
      render: (author) => `${author?.first_name} ${author?.last_name}` || '-',
      dataIndex: 'author',
      width: 300,
      sorter: true
    },
    {
      title: t('incidents.form.hr_intervenant'),
      key: 'hr_intervenant',
      render: (hr_intervenant) =>
        hr_intervenant
          ? `${hr_intervenant?.first_name} ${hr_intervenant?.last_name}`
          : '-',
      dataIndex: 'hr_intervenant',
      width: 300,
      sorter: true
    },
    {
      title: t('incidents.form.status'),
      key: 'status',
      dataIndex: 'status',
      width: 200,
      render: (status) => (
        <Tag color={incidentsStatus[status]}>
          {t(`incidents.tags.${status}`)}
        </Tag>
      ),
      sorter: true,
      filters: Object.keys(incidentsStatus).map((r) => ({
        text: t(`incidents.tags.${r}`),
        value: r
      }))
    }
  ];

  return (
    <ListResource
      resourceName="incidents"
      formatter={formattedData}
      templateType="incidents"
      columns={columns}
      headers={headers}
      deleteAction={permit}
      editAction={permit}
      withCreateButton={permit}
      populate="premise,author,hr_intervenant"
      resourceModelName="Incident"
    />
  );
};

export default ListIncidents;
