import { processString } from '../../utils/processString';

export const registerPlaceholderComponent = (
  editor,
  selectOptions,
  setPopulateList
) => {
  const updatePopulateList = (populateItem) => {
    const formattedString = processString(populateItem);
    if (formattedString) {
      setPopulateList((prevPopulateList) => {
        const isPartialMatch = prevPopulateList.some((item) =>
          item.includes(formattedString)
        );
        if (isPartialMatch) {
          return prevPopulateList;
        }

        const removedObsoletePopulateItems = (array, string) =>
          array.filter((item) => !string.includes(item));

        const updatedPrevPopulateList = removedObsoletePopulateItems(
          prevPopulateList,
          formattedString
        );

        return [...updatedPrevPopulateList, formattedString];
      });
    }
    return false;
  };

  editor.RichTextEditor.add('custom-vars', {
    icon: `<select class="placeholder-select"><option value="">- Select -</option>${selectOptions}</select>`,
    event: 'change',
    result: (rte, action) => {
      const placeholderItem = action.btn.firstChild.value;
      updatePopulateList(placeholderItem);
      const span = document.createElement('span');
      span.textContent = placeholderItem;
      span.style.display = 'inline-block';
      span.style.overflowWrap = 'anywhere';
      return rte.insertHTML(span.outerHTML);
    },
    update: (rte, action) => {
      let updateAction = action.btn.firstChild.value;
      updateAction = '';
      return updateAction;
    }
  });
};
