import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { useAuthContext } from '../contexts/AuthContext';
import { useErrorMessage } from '../utils/ErrorMessage';
import { outOfNavRoutes, routes } from '../utils/constants/adminRoutes';
import { AdminLayout } from '../components/layouts/AdminLayout';
import { permissionVerification } from '../utils/permissionVerification';
import { DashboardLayout } from '../components/layouts/DashboardLayout';

const RequireAuth = () => {
  const { message } = useErrorMessage();
  const { isValid, dispatchAPI, token, user, setIsPermitted } =
    useAuthContext();
  const [userPermissions, setUserPermissions] = useState([]);
  const location = useLocation();
  const { '*': urlToken } = useParams();

  const isPostRoute = urlToken?.includes('post-pwd');

  const getUserPermissions = async (userId) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/users/${userId}?populate=permissions,permissions.permission`
      });
      setUserPermissions(data?.permissions?.permission);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    if (user) {
      getUserPermissions(user?._id);
    }
  }, [user]);

  useEffect(() => {
    if (token && userPermissions) {
      const permit = permissionVerification(
        userPermissions,
        location?.pathname
      );
      setIsPermitted(permit);
    }
  }, [location, token]);

  if (!isValid && !isPostRoute) {
    return Navigate({
      to: {
        pathname: outOfNavRoutes.LOGIN
      },
      state: { from: location }
    });
  }

  if (location.pathname === routes.DASHBOARD) {
    return <DashboardLayout />;
  }

  return <AdminLayout />;
};

RequireAuth.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    state: PropTypes.shape({
      type: PropTypes.string,
      model: PropTypes.shape({
        name: PropTypes.string
      })
    })
  })
};

RequireAuth.defaultProps = {
  location: { pathname: '/' }
};

export default RequireAuth;
