import { Button, Divider, Row, Select, Tree, notification } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import { transformTreeData } from './TransformToTreeNodes';

export const GestionPermissions = () => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();

  const [groupsPermissions, setGroupsPermissions] = useState();
  const [permission, setPermission] = useState();
  const [treeData, setTreeData] = useState([]);
  const [newcheckedKeys, setNewCheckedKeys] = useState([]);
  const [defaultChecked, setDefaultChecked] = useState([]);

  const getGroupsPermissions = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/permissiongroups'
      });
      setGroupsPermissions(data);
    } catch (e) {
      message(e);
    }
  };

  const getPermissions = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/permissions/${permission}`
      });
      setTreeData(transformTreeData(t, data, setDefaultChecked));
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    getGroupsPermissions();
  }, []);

  const handleOnSelect = (value) => {
    setPermission(value);
  };

  useEffect(() => {
    if (permission) {
      getPermissions();
    }
  }, [permission]);

  const handleOnCkeck = (checkedKeys) => {
    setNewCheckedKeys(checkedKeys);
    setDefaultChecked(checkedKeys);
  };

  const handleCancel = () => {
    setPermission(null);
    setTreeData([]);
    setNewCheckedKeys([]);
  };

  const handleSave = async () => {
    try {
      await dispatchAPI('PATCH', {
        url: `/permissions/${permission}`,
        body: {
          permissions: newcheckedKeys
        }
      });
      notification.success({
        message: t('organisation.message.permissions_saved_successfully')
      });
      setPermission(null);
      setNewCheckedKeys([]);
    } catch (e) {
      message(e);
    }
  };

  const handleDefaultChecked = () => defaultChecked;

  return (
    <>
      <Select
        allowClear
        style={{
          width: 320
        }}
        onSelect={(value) => {
          handleOnSelect(value);
        }}
      >
        {groupsPermissions?.map((group) => (
          <Select.Option key={group?._id} value={group?.permission}>
            {group?.label}
          </Select.Option>
        ))}
      </Select>
      <Divider />
      <Tree
        checkedKeys={handleDefaultChecked()}
        checkable
        autoExpandParent
        isLoading
        treeData={treeData}
        onCheck={(checkedKeys) => {
          handleOnCkeck(checkedKeys);
        }}
        className="permission-tree"
      />
      <Row>
        <Button
          type="link"
          onClick={() => {
            handleCancel();
          }}
        >
          {t('organisation.permission.button.cancel')}
        </Button>
        <Button
          type="primary"
          onClick={() => {
            handleSave();
          }}
        >
          <CheckOutlined />
          {t('organisation.permission.button.save')}
        </Button>
      </Row>
    </>
  );
};
