import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { CandidateRouter } from './candidates/CandidateRouter';
import { RecommendationRouter } from './recommendations/RecommendationRouter';
import { OfferRouter } from './offers/OfferRouter';
import { InProgApplicationsRouter } from './in-progress/InProgApplicationsRouter';
import { InterviewRouter } from './interviews/InterviewRouter';
import { EntryRouter } from './entries/EntryRouter';
import { subRoutes } from '../../utils/constants/adminRoutes';

export const RecruitmentRouter = () => (
  <Routes>
    <Route
      path={`${subRoutes.RECRUITMENTS.OFFERS}/*`}
      element={<OfferRouter />}
    />
    <Route
      path={`${subRoutes.RECRUITMENTS.CANDIDATES}/*`}
      element={<CandidateRouter />}
    />
    <Route
      path={`${subRoutes.RECRUITMENTS.RECOMMANDATIONS}/*`}
      element={<RecommendationRouter />}
    />
    <Route
      path={`${subRoutes.RECRUITMENTS.INPROGRESS}/*`}
      element={<InProgApplicationsRouter />}
    />
    <Route
      path={`${subRoutes.RECRUITMENTS.INTERVIEWS}/*`}
      element={<InterviewRouter />}
    />
    <Route
      path={`${subRoutes.RECRUITMENTS.ENTRIES}/*`}
      element={<EntryRouter />}
    />
  </Routes>
);
