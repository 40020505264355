/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, Button, Popconfirm, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  UpOutlined,
  DownOutlined,
  DeleteOutlined,
  WarningOutlined
} from '@ant-design/icons';

const { Option } = Select;

const CompetenceRow = ({
  list,
  parcoursTrees,
  item,
  setTitle,
  index,
  mouvLineDown,
  mouvLineUp,
  deleteLine
}) => {
  const { t } = useTranslation();

  return (
    <Card key={item._id}>
      <Row justify="space-between">
        <Col>
          <Select
            style={{ width: 500 }}
            onChange={(e) => setTitle(index, e)}
            defaultValue={item.title}
          >
            {parcoursTrees.map((parcoursTree) => (
              <Option key={parcoursTree._id} value={parcoursTree._id}>
                {parcoursTree.title}
              </Option>
            ))}
          </Select>
        </Col>
        <Col span={6}>
          <Row justify="end">
            <Button
              type="link"
              onClick={() => mouvLineUp(index)}
              disabled={index === 0}
            >
              <UpOutlined />
            </Button>
            <Button
              type="link"
              onClick={() => mouvLineDown(index)}
              // eslint-disable-next-line react/prop-types
              disabled={index === list.length - 1}
            >
              <DownOutlined />
            </Button>
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={() => deleteLine(index)}
              icon={<WarningOutlined />}
            >
              <Button type="link" danger>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

CompetenceRow.propTypes = {
  list: PropTypes.shape({
    title: PropTypes.string.isRequired,
    _id: PropTypes.string.isRequired
  }),
  parcoursTrees: PropTypes.shape({
    title: PropTypes.string.isRequired,
    _id: PropTypes.string.isRequired
  }),
  item: PropTypes.objectOf({}).isRequired,
  setTitle: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  mouvLineDown: PropTypes.func.isRequired,
  mouvLineUp: PropTypes.func.isRequired,
  deleteLine: PropTypes.func.isRequired
};

CompetenceRow.defaultProps = {
  list: [],
  parcoursTrees: []
};

export default CompetenceRow;
