import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ListCandidates from './ListCandidates';
import CreateUpdateCandidate from './CreateUpdateCandidate';
import ShowCandidate from './ShowCandidate';

export const CandidateRouter = () => (
  <Routes>
    <Route
      path="/create"
      element={<CreateUpdateCandidate purpose="create" />}
    />
    <Route
      path="/edit/:id"
      element={<CreateUpdateCandidate purpose="edit" />}
    />
    <Route path="/show/:id" element={<ShowCandidate />} />
    <Route index element={<ListCandidates />} />
  </Routes>
);
