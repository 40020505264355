import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ValidFirstSignature from './ValidFirstSignature';
import ValidSecondSignature from './ValidSecondSignature';

export const SignatureRouter = () => (
  <Routes>
    <Route
      path="/first_signer/:signatureId/:entrieId"
      element={<ValidFirstSignature />}
    />
    <Route
      path="/second_signer/:signatureId/:entrieId"
      element={<ValidSecondSignature />}
    />
  </Routes>
);
