import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ListExits from './ListExits';
import ShowExit from './ShowExit/ShowExit';
import CreateExitEmployee from './CreateExitEmployee';

export const ExitsRouter = () => (
  <Routes>
    <Route path="/create" element={<CreateExitEmployee purpose="create" />} />
    <Route path="/edit/:id" element={<CreateExitEmployee purpose="edit" />} />
    <Route path="/show/:id" element={<ShowExit />} />
    <Route index element={<ListExits />} />
  </Routes>
);
