import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { DocumentRouter } from './documents/DocumentRouter';
import { MemoRouter } from './memos/MemoRouter';
import { subRoutes } from '../../utils/constants/adminRoutes';

export const InternCommunicationRouter = () => (
  <Routes>
    <Route
      path={`${subRoutes.INTERN_COMMUNICATION.DOCUMENTS}/*`}
      element={<DocumentRouter />}
    />
    <Route
      path={`${subRoutes.INTERN_COMMUNICATION.MEMOS}/*`}
      element={<MemoRouter />}
    />
  </Routes>
);
