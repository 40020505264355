import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ListBonus from './ListBonus';
import CreateUpdateBonus from './CreateUpdateBonus';
import ShowBonus from './ShowBonus';

export const BonusRouter = () => (
  <Routes>
    <Route path="/create" element={<CreateUpdateBonus purpose="create" />} />
    <Route path="/edit/:id" element={<CreateUpdateBonus purpose="edit" />} />
    <Route path="/show/:id" element={<ShowBonus />} />
    <Route index element={<ListBonus />} />
  </Routes>
);
