export const getOffers = async ({ dispatchAPI, setOffers, message }) => {
  try {
    const { data } = await dispatchAPI('GET', {
      url: '/offers?is_archived=false&populate=status'
    });
    setOffers(data);
  } catch (e) {
    if (e.response) message(e.response.status);
  }
};
