import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button, Form, message as antdMessage } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import useFields from '../listContent';
import { useAuthContext } from '../../../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../../../utils/ErrorMessage';
import { useGenerateFormItem } from '../../../../../../utils/GenerateFormItem';
import { setBody } from './utils/setBody';
import { setNextStep } from './utils/setNextStep';

const Modals = ({
  setRefresh,
  refresh,
  activeStep,
  exit,
  nextStep,
  setRestitutionType,
  restitutionType,
  employee,
  onClose,
  setAcknowledgmentOfReceiptType,
  acknowledgmentOfReceiptType
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const generateFormItem = useGenerateFormItem();
  const [file, setFile] = useState([]);
  const { fieldsToAdd } = useFields({
    activeStep,
    setFile,
    setRestitutionType,
    restitutionType,
    setAcknowledgmentOfReceiptType,
    acknowledgmentOfReceiptType,
    employee,
    exit
  });

  const setSuccessMessage = () => {
    switch (activeStep) {
      case 'RESTITUTION_VALIDATED':
        return `exits.other_modals.messages.success.${activeStep}.${restitutionType}`;
      default:
        return `exits.other_modals.messages.success.${activeStep}`;
    }
  };

  const onFinishForm = async (value) => {
    try {
      const { data } = await dispatchAPI('PATCH', {
        url: `/exits/${exit._id}`,
        body: setBody({ value, activeStep, file, restitutionType })
      });

      if (activeStep === 'ACCESS_DELETED') {
        await dispatchAPI('PATCH', {
          url: `employees/${employee._id}`,
          body: { active: false }
        });
        await dispatchAPI('PATCH', {
          url: `exits/${exit._id}`,
          body: { active: false }
        });
      }
      setNextStep({ activeStep, exit: data, nextStep });
      setRefresh(!refresh);
      onClose();
      antdMessage.success(t(setSuccessMessage()));
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const setSaveButton = () => {
    switch (activeStep) {
      case 'RESTITUTION_VALIDATED':
        return restitutionType === 'material'
          ? 'buttons.valid_material'
          : 'buttons.valid_check_list';
      default:
        return 'buttons.save';
    }
  };

  const formData = () => {
    form.setFieldsValue({
      ...exit
    });
  };

  useEffect(() => {
    formData();
  }, [exit]);

  return (
    <Form
      wrapperCol={{ span: 24 }}
      labelCol={{ span: 24 }}
      form={form}
      onFinish={onFinishForm}
    >
      {fieldsToAdd &&
        fieldsToAdd.map((field) => generateFormItem('exits', field))}
      <Form.Item style={{ marginTop: 16 }}>
        <Button
          style={{ margin: '0 10px' }}
          type="link"
          danger
          onClick={onClose}
        >
          {`${t('buttons.cancel')} `}
          <CloseOutlined />
        </Button>
        <Button type="add" htmlType="submit">
          {`${t(setSaveButton())} `}
          <CheckOutlined />
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Modals;

Modals.propTypes = {
  exit: PropTypes.string.isRequired,
  employee: PropTypes.shape({
    activeStep: PropTypes.string,
    _id: PropTypes.string
  }),
  activeStep: PropTypes.string,
  nextStep: PropTypes.func.isRequired,
  setRestitutionType: PropTypes.func.isRequired,
  restitutionType: PropTypes.string.isRequired,
  setAcknowledgmentOfReceiptType: PropTypes.func.isRequired,
  setRefresh: PropTypes.func.isRequired,
  refresh: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  acknowledgmentOfReceiptType: PropTypes.string
};

Modals.defaultProps = {
  employee: {},
  activeStep: '',
  onClose: null,
  acknowledgmentOfReceiptType: ''
};
