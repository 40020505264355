import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col, Typography, Divider } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Datatable } from '../../../../components/DataTable/Datatable';
import { routes, subRoutes } from '../../../../utils/constants/adminRoutes';

const { Title } = Typography;
const { Text } = Typography;

const Interviews = ({ application, listActivity }) => {
  const { t } = useTranslation();

  const renderDates = (date_start, interview) => {
    const { time_start, time_end } = interview;
    return `Le ${moment(date_start).format('DD/MM/YYYY')} de ${moment(
      time_start
    ).format('HH:mm')} à ${moment(time_end).format('HH:mm')}`;
  };

  const renderInterlocutors = (interlocutors) => {
    if (interlocutors.length === 0)
      return <Text italic>{t('applications.show.none')}</Text>;

    let result = '';
    interlocutors.forEach((interlocutor) => {
      result += `${interlocutor.first_name} ${interlocutor.last_name}`;
    });
    return result;
  };

  const columns = [
    {
      title: t('interviews.form.header'),
      key: 'header',
      dataIndex: 'header',
      render: (header) => header?.label,
      sorter: true
    },
    {
      title: t('interviews.form.rh_employee'),
      key: 'rh_name',
      dataIndex: 'rh_employee',
      render: (rh_employee) =>
        `${rh_employee?.first_name} ${rh_employee?.last_name}`,
      sorter: (a, b) =>
        a.rh_employee.first_name.localeCompare(b.rh_employee.first_name)
    },
    {
      title: t('interviews.form.interlocutors'),
      key: 'interlocutors',
      dataIndex: 'interlocutors',
      render: (interlocutors) => renderInterlocutors(interlocutors),
      sorter: true
    },
    {
      title: t('interviews.form.date'),
      key: 'date',
      dataIndex: 'date',
      render: (date_start, interview) => renderDates(date_start, interview),
      sorter: (a, b) => a.date.localeCompare(b.date)
    },
    {
      title: t('interviews.form.premise'),
      key: 'premise',
      dataIndex: 'premise',
      render: (premise) => premise?.label,
      sorter: true
    },
    {
      title: t('interviews.form.frame'),
      key: 'frame',
      dataIndex: 'frame',
      render: (frame) => frame?.label,
      sorter: true
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      width: 120,
      render: (record) => (
        <Link
          to={{
            pathname: `${routes.RECRUITMENTS}${subRoutes.RECRUITMENTS.INTERVIEWS}/show/${record._id}`
          }}
        >
          <EyeOutlined style={{ fontSize: 18 }} />
        </Link>
      )
    }
  ];

  return (
    <>
      <Divider orientation="left" plain>
        <Title level={3}>{t('applications.tabs.meetings')}</Title>
      </Divider>

      <Col span={24}>
        {application && (
          <Datatable
            scroll={{ x: 1200 }}
            resourceName="interviews"
            columns={columns}
            populate="rh_employee,candidate"
            customActionColumn
            forceRefresh={listActivity}
            extraQuery={`type=CANDIDATE&applicationId=${application._id}&is_archived=false`}
          />
        )}
      </Col>
    </>
  );
};

export default Interviews;

Interviews.propTypes = {
  application: PropTypes.shape({ _id: PropTypes.string }).isRequired,
  listActivity: PropTypes.bool.isRequired
};
