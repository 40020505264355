import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Datatable } from '../../../components';

const ListApplications = ({ candidates }) => {
  const { t } = useTranslation();

  const columns = [
    {
      title: t('offers.form.name'),
      key: 'name',
      dataIndex: 'offer',
      render: (offer) => offer?.name || '-',
      sorter: true
    },
    {
      title: t('offers.form.premise'),
      key: 'premise',
      dataIndex: 'offer',
      render: (offer) => offer?.premise?.label || '-',
      sorter: true
    },
    {
      title: t('offers.form.end_of_publication_date'),
      key: 'end_of_publication_date',
      dataIndex: 'offer',
      render: (offer) => moment(offer.errorendOfPublicationDate).format('ll'),
      sorter: true
    }
  ];

  return (
    <Datatable
      style={{ width: '100%' }}
      resourceName="application"
      populate="candidate,offer,offer.position,offer.premise"
      columns={columns}
      resourceModelName="Application"
      extraQuery={`candidate=${candidates._id}`}
      showAction={false}
      editAction={false}
      deleteAction={false}
    />
  );
};

export default ListApplications;

ListApplications.propTypes = {
  candidates: PropTypes.arrayOf({}).isRequired
};
