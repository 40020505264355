import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import useFields from './fields';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainer/CreateUpdateContainer';

const CreateUpdateCandidate = ({ purpose }) => {
  const [file, setFile] = useState();
  const { fields, isFieldsLoading } = useFields({ setFile });

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data,
        last_contact: data.last_contact && moment(data.last_contact)
      })
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        curriculum: file?._id
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        curriculum: file?._id
      })
    }
  };

  return (
    <CreateUpdateContainer
      fields={fields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="candidates"
      resource="candidates"
      config={config}
    />
  );
};

CreateUpdateCandidate.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateCandidate;
