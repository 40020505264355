import React from 'react';
import { Steps } from 'antd';

const { Step } = Steps;

const setStep = ({ exit, enums, t }) => {
  switch (exit.motif) {
    case 'dismissal':
      return enums?.exitsStep[exit.motif][exit.type_of_dismissal].map(
        (step) => <Step key={step} title={t(`exits.steps.${step}`)} />
      );
    case 'break_of_trial_period':
      return enums?.exitsStep[exit.motif][exit.break_initited_by].map(
        (step) => <Step key={step} title={t(`exits.steps.${step}`)} />
      );
    default:
      return enums?.exitsStep[exit.motif].map((step) => (
        <Step key={step} title={t(`exits.steps.${step}`)} />
      ));
  }
};

export default setStep;
