import React, { useEffect } from 'react';
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useLocation
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { outOfNavRoutes, routes } from '../utils/constants/developerRoutes';
import { Login } from '../routes/login';
import { Home } from '../routes/home/Home';
import { Dashboard } from '../routes/dashboard/Dashboard';
import { Exception } from '../components';
import { useAuthContext } from '../contexts/AuthContext';
import { DeveloperLayout } from '../components/layouts/DeveloperLayout';
import { ProfileRouter } from '../routes/profile/ProfileRouter';
import { Confirmation } from '../routes/emailConfirmation/Confirmation';
import { Settings } from '../routes/settings/settings';
import { UserRouter } from '../routes/users/UserRouter';
import { RecruitmentRouter } from '../routes/recruitments/RecruitmentsRouter';
import { EmployeeFolderRouter } from '../routes/employee-folder/EmployeeFolderRouter';
import { InternCommunicationRouter } from '../routes/intern-communication/InternCommunicationRouter';
import { AdminTheme } from '../assets/styles/Theme/AdminTheme';
import { TrainingsRouter } from '../routes/trainings/TrainingsRouter';
import { SignatureRouter } from '../routes/signatures/SignatureRouter';
import { TemplateDocRouter } from '../routes/template-docs/TemplateDocsRouter';
import CreateUpdatePremise from '../routes/settings/premise/CreateUpdatePremise';
import { WorkflowRouter } from '../routes/workflows/WorkflowRouter';
import PostPwdForm from '../routes/login/PostPwdForm';

const RequireAuth = () => {
  const { isValid } = useAuthContext();
  const location = useLocation();

  if (!isValid) {
    return Navigate({
      to: {
        pathname: outOfNavRoutes.LOGIN
      },
      state: { from: location }
    });
  }

  return <DeveloperLayout />;
};

RequireAuth.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    state: PropTypes.shape({
      type: PropTypes.string,
      model: PropTypes.shape({
        name: PropTypes.string
      })
    })
  })
};

RequireAuth.defaultProps = {
  location: { pathname: '/' }
};

export const DeveloperRouter = () => {
  useEffect(() => {
    const body = document.body;
    if (body) {
      body.className = 'admin';
    }
  });

  return (
    <BrowserRouter>
      <AdminTheme />
      <Routes>
        <Route path={`${outOfNavRoutes.LOGIN}/:token`} element={<Login />} />
        <Route path={outOfNavRoutes.LOGIN} element={<Login />} />
        <Route
          path={`${outOfNavRoutes.CONFIRMATION}/:token`}
          element={<Confirmation />}
        />
        <Route
          path={`${outOfNavRoutes.PASSWORD_CREATION}/:urlToken`}
          element={<PostPwdForm />}
        />
        <Route element={<RequireAuth />}>
          <Route
            path={`${outOfNavRoutes.PROFILE}/*`}
            element={<ProfileRouter />}
          />
          {/* ------------------------------------------------------------- */}
          <Route path={routes.HOME} element={<Home />} />
          <Route path={routes.DASHBOARD} element={<Dashboard />} />
          <Route path={`${routes.USERS}/*`} element={<UserRouter />} />
          <Route
            path={`${routes.RECRUITMENTS}/*`}
            element={<RecruitmentRouter />}
          />
          <Route
            path={`${routes.EMPLOYEE_FOLDER}/*`}
            element={<EmployeeFolderRouter />}
          />

          <Route
            path={`${routes.INTERN_COMMUNICATION}/*`}
            element={<InternCommunicationRouter />}
          />

          <Route path={`${routes.TRAININGS}/*`} element={<TrainingsRouter />} />

          <Route path="/signed_document/*" element={<SignatureRouter />} />

          <Route path={outOfNavRoutes.SETTINGS} element={<Settings />} />
          <Route
            path={`${routes.TEMPLATE_DOCS}/*`}
            element={<TemplateDocRouter />}
          />
          <Route
            path={outOfNavRoutes.PREMISE}
            element={<CreateUpdatePremise purpose="create" />}
          />
          <Route
            path={outOfNavRoutes.PREMISE_UPDATE}
            element={<CreateUpdatePremise purpose="update" />}
          />
          <Route path={`${routes.WORKFLOWS}/*`} element={<WorkflowRouter />} />
          {/* ------------------------------------------------------------- */}
          <Route path="*" element={<Exception />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
