import React from 'react';
import { Button, Col, Spin } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { TemplateDocumentButton } from '../../../../../../components/TemplateDocumentButton/TemplateDocumentButton';

const FaultWithoutAHeader = ({
  activeStep,
  nextStep,
  setIsModalOpen,
  setRestitutionType,
  exit,
  isDocLoading,
  updateStatus,
  t
}) => {
  const renderAdditionalButtons = () => {
    switch (activeStep) {
      case 'OUTPUT_SHEET':
        return (
          <Col>
            <Button
              type="primary"
              danger
              onClick={() => {
                updateStatus();
                nextStep();
              }}
            >
              {t(`exits.header.button.${activeStep}`)}
            </Button>
          </Col>
        );
      case 'INTERVIEW_INVITATION':
        return (
          <>
            <Col>
              {exit && !exit.is_interview_invitation_generated && (
                <Spin spinning={isDocLoading}>
                  <TemplateDocumentButton
                    collection="Exit"
                    use_case="CONVOCATION"
                    button_text={t(
                      `exits.header.button.${activeStep}.generate`
                    )}
                    onClick={() => {
                      updateStatus();
                      nextStep();
                    }}
                  />
                </Spin>
              )}
            </Col>
            <Col>
              {exit && exit.is_interview_invitation_generated && (
                <Button
                  type="primary"
                  onClick={() => {
                    setIsModalOpen(true);
                  }}
                >
                  <MailOutlined />
                  {t(`exits.header.button.${activeStep}.send`)}
                </Button>
              )}
            </Col>
          </>
        );
      case 'NOTIFICATION_OF_DISMISSAL':
        return (
          <Col>
            <Button
              type="primary"
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              <MailOutlined />
              {t(`exits.header.button.${activeStep}`)}
            </Button>
          </Col>
        );
      case 'RESTITUTION_REQUEST':
        return (
          <Col>
            <Button
              type="primary"
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              <MailOutlined />
              {t(`exits.header.button.${activeStep}`)}
            </Button>
          </Col>
        );
      case 'RESTITUTION_VALIDATED':
        return (
          <>
            <Col>
              {exit && !exit?.is_material ? (
                <Button
                  type="primary"
                  onClick={() => {
                    setIsModalOpen(true);
                    setRestitutionType('material');
                  }}
                >
                  {t(`exits.header.button.${activeStep}.material`)}
                </Button>
              ) : null}
            </Col>
            <Col>
              {exit && !exit?.is_check_list_validated ? (
                <Button
                  type="primary"
                  onClick={() => {
                    setIsModalOpen(true);
                    setRestitutionType('check_list');
                  }}
                >
                  {t(`exits.header.button.${activeStep}.check_list`)}
                </Button>
              ) : null}
            </Col>
          </>
        );
      case 'ACCESS_DELETED':
        return (
          <Col>
            <Button
              type="primary"
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              {t(`exits.header.button.${activeStep}`)}
            </Button>
          </Col>
        );
      default:
        return null;
    }
  };

  return renderAdditionalButtons();
};

export default FaultWithoutAHeader;
