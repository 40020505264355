export const permissionVerificationDashboard = (userPermissions, path) => {
  const basePath = '/permission';
  const isUserAllowed = (routeObject) => {
    const fullPath = `${basePath}${path}`;
    const pathSegments = fullPath
      .split('/')
      .filter((segment) => segment !== '');

    const checkPath = (currentRoute, segments) => {
      if (segments.length === 0) {
        if (Object.values(currentRoute).some((child) => child === true)) {
          return true;
        }
        return false;
      }

      const [currentSegment, ...remainingSegments] = segments;

      const nextRoute = currentRoute[currentSegment];

      if (nextRoute === true) {
        return true;
      }
      if (nextRoute) {
        return checkPath(nextRoute, remainingSegments);
      }

      return false;
    };

    const hasVisualizationChild = checkPath(routeObject, pathSegments);

    return hasVisualizationChild;
  };

  return isUserAllowed(userPermissions);
};
