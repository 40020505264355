import React, { useState, useEffect } from 'react';
import EmployerBreak from './EmployerBreak';
import EmployeeBreak from './EmployeeBreak';

const BreakOfTrialHeader = ({
  activeStep,
  nextStep,
  exit,
  setIsModalOpen,
  setRestitutionType,
  restitutionType,
  generateFile,
  isDocLoading,
  updateStatus,
  t
}) => {
  const [inititedBreak, setInitidedBreak] = useState('');

  useEffect(() => {
    setInitidedBreak(exit?.break_initited_by);
  }, [exit]);

  const setHeader = () => {
    switch (inititedBreak) {
      case 'employer':
        return (
          <EmployerBreak
            activeStep={activeStep}
            nextStep={nextStep}
            setIsModalOpen={setIsModalOpen}
            setRestitutionType={setRestitutionType}
            restitutionType={restitutionType}
            exit={exit}
            generateFile={generateFile}
            isDocLoading={isDocLoading}
            t={t}
            updateStatus={updateStatus}
          />
        );
      case 'employee':
        return (
          <EmployeeBreak
            activeStep={activeStep}
            nextStep={nextStep}
            setIsModalOpen={setIsModalOpen}
            setRestitutionType={setRestitutionType}
            restitutionType={restitutionType}
            exit={exit}
            generateFile={generateFile}
            isDocLoading={isDocLoading}
            t={t}
            updateStatus={updateStatus}
          />
        );
      default:
        return null;
    }
  };

  return setHeader();
};

export default BreakOfTrialHeader;
