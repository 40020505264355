export const processString = (populateItem) => {
  const splittedStrings = populateItem.split('.');
  const filteredStrings = [];

  const isDateOrAddress = (item) =>
    item.includes('phone_number') || item.includes('address');

  if (splittedStrings.length > 2) {
    for (let i = 0; i < splittedStrings.length; i += 1) {
      if (
        i !== 0 &&
        i !== splittedStrings.length - 1 &&
        !isDateOrAddress(splittedStrings[i])
      ) {
        filteredStrings.push(splittedStrings[i]);
      }
    }
  } else {
    return null;
  }
  return filteredStrings.join('.');
};
