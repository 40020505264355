import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch, Tag, Row } from 'antd';
import { ListResource } from '../../components';
import { userRoles } from '../../utils/constants/tagColors';
import { permissionVerification } from '../../utils/permissionVerification';
import { useAuthContext } from '../../contexts/AuthContext';

const ListUsers = () => {
  const { t } = useTranslation();
  const [isArchived, setIsArchived] = useState(false);
  const { userPermissions } = useAuthContext();
  const [permit, setPermit] = useState(false);

  useEffect(() => {
    const permission = permissionVerification(
      userPermissions,
      '/users/modification'
    );
    setPermit(permission);
  }, [userPermissions]);

  const headers = [
    {
      label: 'last_name',
      key: 'last_name'
    },
    {
      label: 'first_name',
      key: 'first_name'
    },
    {
      label: 'email',
      key: 'email'
    },
    {
      label: 'phone_number.country_code',
      key: 'phone_number.country_code'
    },
    {
      label: 'phone_number.number',
      key: 'phone_number.number'
    },
    {
      label: 'role',
      key: 'role'
    }
  ];

  const columns = [
    {
      title: t('users.form.last_name'),
      key: 'last_name',
      dataIndex: 'last_name',
      sorter: true
    },
    {
      title: t('users.form.first_name'),
      key: 'first_name',
      dataIndex: 'first_name',
      sorter: true
    },
    {
      title: t('users.form.role'),
      key: 'role',
      dataIndex: 'role',
      render: (role) => (
        <Tag color={userRoles[role.split(':')[1]]}>
          {t(`users.tags.${role.split(':')[1]}`)}
        </Tag>
      ),
      sorter: true,
      filters: Object.keys(userRoles).map((r) => ({
        text: t(`users.tags.${r}`),
        value:
          r.split('-').length > 1
            ? `${r.split('-')[1].toLowerCase()}s:${r}`
            : `${r.toLowerCase()}s:${r}`
      }))
    },
    {
      title: t('users.form.email'),
      key: 'email',
      dataIndex: 'email',
      sorter: true
    }
  ];
  return (
    <ListResource
      resourceName="users"
      columns={columns}
      headers={headers}
      editAction={permit}
      withCreateButton={permit}
      deleteAction={permit}
      templateType="users"
      resourceModelName="User"
      extraButtons={
        <Row align="middle" className="list-switch-wrapper">
          <Switch onChange={() => setIsArchived(!isArchived)} />
          <p>{t('users.archived')}</p>
        </Row>
      }
      extraQuery={isArchived ? 'is_archived=true' : 'is_archived=false'}
    />
  );
};

export default ListUsers;
