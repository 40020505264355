import React from 'react';
import PropTypes from 'prop-types';
import { Descriptions, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

export const DescriptionList = ({ title, data, skipEmpty, translate }) => {
  const { t } = useTranslation();
  return (
    <div>
      <Descriptions title={title}>
        {data.map(
          (item) =>
            (item.content !== '-' || !skipEmpty) &&
            (item.multiple ? (
              <Descriptions.Item
                label={translate ? t(`${item.label}`) : item.label}
                span={item.span || 1}
                key={item.label}
              >
                {item.content.map((element) => (
                  <Typography style={{ fontWeight: 600, margin: 10 }}>
                    {element.label}
                  </Typography>
                ))}
              </Descriptions.Item>
            ) : (
              <Descriptions.Item
                label={translate ? t(`${item.label}`) : item.label}
                span={item.span || 1}
                key={item.label}
              >
                <Typography style={{ fontWeight: 600 }}>
                  {item.content || '-'}
                </Typography>
              </Descriptions.Item>
            ))
        )}
      </Descriptions>
    </div>
  );
};

DescriptionList.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  skipEmpty: PropTypes.bool,
  translate: PropTypes.bool
};

DescriptionList.defaultProps = {
  title: null,
  skipEmpty: false,
  translate: false
};
