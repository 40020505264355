import personnalInformations from './personnalInformationsFields';
import exitInformations from './exitInformationsFields';

const useFields = () => {
  const {
    employeeInformationsFields,
    isPersonnalInformationsFieldsLoading,
    employee
  } = personnalInformations();
  const {
    exitInformationsFields,
    isExitInformationsFieldsLoading,
    endDateOfContract
  } = exitInformations();

  let loadingFields;
  if (isPersonnalInformationsFieldsLoading || isExitInformationsFieldsLoading) {
    loadingFields = true;
  } else {
    loadingFields = false;
  }

  const formFields = [employeeInformationsFields, exitInformationsFields];
  return {
    formFields,
    loadingFields,
    employee,
    endDateOfContract
  };
};

export default useFields;
