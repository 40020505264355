import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CheckCircleOutlined, HourglassOutlined } from '@ant-design/icons';
import { Skeleton, Row, Col, Collapse } from 'antd';
import ListGeneratedDocuments from './ListGeneratedDocuments';

const { Panel } = Collapse;

const CollapseGeneratedDocuments = ({
  generatedDocuments,
  entry,
  isLoading
}) => {
  const { t } = useTranslation();
  const [types, setTypes] = useState([]);

  const getTypes = () => {
    const filtredTypes = generatedDocuments.map((obj) => obj.type);
    setTypes(filtredTypes);
  };

  useEffect(() => {
    getTypes();
  }, [generatedDocuments]);

  return (
    <div>
      {generatedDocuments.length &&
        generatedDocuments.map((document) => (
          <Collapse accordion>
            <Panel
              extra={
                types.length && types.includes(document.type) ? (
                  <CheckCircleOutlined style={{ color: 'green' }} />
                ) : (
                  <HourglassOutlined />
                )
              }
              header={t(`employees.documents.${document.type}`)}
              key={document.type}
            >
              <Row style={{ width: '100%' }}>
                <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
                  <Row
                    gutter={[24, 24]}
                    align="middle"
                    style={{ width: '100%' }}
                  >
                    <Col xs={24} lg={16}>
                      <ListGeneratedDocuments
                        data={entry}
                        document={document.type}
                      />
                    </Col>
                  </Row>
                </Skeleton>
              </Row>
            </Panel>
          </Collapse>
        ))}
    </div>
  );
};

export default CollapseGeneratedDocuments;

CollapseGeneratedDocuments.propTypes = {
  employee: PropTypes.shape({
    _id: PropTypes.string,
    photo: PropTypes.string,
    documents: PropTypes.arrayOf({})
  }),
  entry: PropTypes.arrayOf({}),
  isLoading: PropTypes.bool.isRequired,
  generatedDocuments: PropTypes.arrayOf(PropTypes.string).isRequired
};

CollapseGeneratedDocuments.defaultProps = {
  employee: {},
  entry: []
};
