import React from 'react';
import { Typography, Menu } from 'antd';
import { setCompletedTraining } from './setCompletedTraining';

export const menu = ({
  trainingtree,
  children,
  dispatchAPI,
  trainingprogress,
  setRefresh,
  refresh,
  message,
  trainingTrees,
  t
}) => (
  <Menu>
    <Menu.Item key="1">
      <Typography.Link
        onClick={() =>
          setCompletedTraining({
            trainingtree,
            children,
            type: 'SUCCESS',
            dispatchAPI,
            trainingprogress,
            setRefresh,
            refresh,
            message,
            trainingTrees
          })
        }
      >
        {t('trainingprogress.dropdown.success')}
      </Typography.Link>
    </Menu.Item>
    <Menu.Item key="2">
      <Typography.Link
        onClick={() =>
          setCompletedTraining({
            trainingtree,
            children,
            type: 'FAILED',
            dispatchAPI,
            trainingprogress,
            setRefresh,
            refresh,
            message,
            trainingTrees
          })
        }
      >
        {t('trainingprogress.dropdown.failed')}
      </Typography.Link>
    </Menu.Item>
  </Menu>
);
