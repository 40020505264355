import React, { useEffect } from 'react';
import { useAuthContext } from '../contexts/AuthContext';
import { UserRouter } from './UserRouter';
import { AdminRouter } from './AdminRouter';
import { useThemeContext } from '../contexts/ThemeContext';
import { DeveloperRouter } from './DeveloperRouter';

export const RouterDispatch = () => {
  const { user } = useAuthContext();
  const { setTheme } = useThemeContext();

  useEffect(() => {
    const body = document.body;
    if (body && !user) {
      body.className = 'user';
      setTheme('user');
    }
  }, [user]);

  const dispatchRouter = () => {
    switch (user?.role) {
      case 'admins:ADMIN':
      case 'admins:SUPER-ADMIN':
        return <AdminRouter />;
      case 'developer:DEVELOPER':
        return <DeveloperRouter />;
      case 'users:USER':
      case 'users:SUPER-USER':
      default:
        return <UserRouter />;
    }
  };
  return dispatchRouter();
};
