import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Modal, Form } from 'antd';
import useFields from './documentFields';
import { useGenerateFormItem } from '../../../../../utils/GenerateFormItem';

const DocumentModal = ({
  isModalOpen,
  setIsModalOpen,
  setOtherAttachments,
  otherAttachments
}) => {
  const { t } = useTranslation();
  const generateFormItem = useGenerateFormItem();
  const { fields } = useFields({ setOtherAttachments, otherAttachments });

  const handleCancel = () => {
    setIsModalOpen(false);
    setOtherAttachments([]);
  };

  return (
    <Modal
      title={t('email.modal.documents.title')}
      open={isModalOpen}
      onOk={() => setIsModalOpen(false)}
      onCancel={handleCancel}
    >
      <Form wrapperCol={{ span: 24 }} labelCol={{ span: 24 }}>
        {fields.map((field) => generateFormItem('documents', field))}
      </Form>
    </Modal>
  );
};

export default DocumentModal;

DocumentModal.propTypes = {
  isModalOpen: PropTypes.bool,
  setIsModalOpen: PropTypes.func,
  setOtherAttachments: PropTypes.func.isRequired,
  otherAttachments: PropTypes.arrayOf(PropTypes.shape({}))
};

DocumentModal.defaultProps = {
  isModalOpen: false,
  setIsModalOpen: null,
  otherAttachments: []
};
