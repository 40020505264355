import React, { useState, useEffect } from 'react';
import { Col, Row, Modal } from 'antd';
import { ContentCustom } from '../../components';
import { widgets } from './utils';
import { Widget } from './Widget';
import { permissionVerificationDashboard } from './permissionVerificationDashboard';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';

export const Dashboard = () => {
  const { user, dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const [modalData, setModalData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState();
  const [baseData, setBaseData] = useState();
  const [userPermissions, setUserPermissions] = useState([]);

  const getUserPermissions = async (userId) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/users/${userId}?populate=permissions,permissions.permission`
      });
      setUserPermissions(data?.permissions?.permission);
    } catch (e) {
      message(e);
    }
  };

  const widgetData = () => {
    const data = widgets.filter(
      (widget) => widget?.type === 'modal' || widget?.type === 'card'
    );

    const filteredData = data.filter((item) => {
      if (permissionVerificationDashboard(userPermissions, item?.perm)) {
        return item;
      }
      return null;
    });
    setBaseData(filteredData);
  };

  const initializeDashboard = async () => {
    if (user) {
      await getUserPermissions(user?._id);
    }
    widgetData();
  };

  useEffect(() => {
    initializeDashboard();
  }, [user]);

  useEffect(() => {
    widgetData();
  }, [userPermissions]);

  const widgetModalData = () => {
    const data = widgets.filter((item) => item?.parent === modalTitle?.title);
    const filteredData = data.filter((item) => {
      if (permissionVerificationDashboard(userPermissions, item?.perm)) {
        return item;
      }
      return null;
    });
    setModalData(filteredData);
  };

  const onClick = (widget) => {
    const wid = widget;
    setModalVisible(true);
    setModalTitle(wid);
  };

  useEffect(() => {
    widgetModalData();
  }, [modalTitle, userPermissions]);

  return (
    <ContentCustom>
      <Modal
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
        className="dashboard-modal"
      >
        <Row justify="center" className="dashboard-modal-title">
          <Widget key={modalTitle?.title} widget={modalTitle} />
        </Row>
        <Row gutter={[16, 16]}>
          {modalData.map((widget) => (
            <Col xs={24} sm={12} md={8}>
              <Widget key={widget?.title} widget={widget} />
            </Col>
          ))}
        </Row>
      </Modal>
      <Row
        gutter={[24, 24]}
        justify="center"
        align="middle"
        className="widget-wrapper"
      >
        {baseData?.map((widget) => (
          <Col xs={24} sm={12} md={8} lg={6} xl={6} xxl={4}>
            <Row justify="center">
              <Widget key={widget.title} widget={widget} onClick={onClick} />
            </Row>
          </Col>
        ))}
      </Row>
    </ContentCustom>
  );
};
