import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, Col, Input, Row } from 'antd';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useAuthContext } from '../../../contexts/AuthContext';
import { PageHeaderCustom } from '../../../components';
import { useErrorMessage } from '../../../utils/ErrorMessage';

const CreateTemplateEmail = ({ type, id }) => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [editorState, setEditorState] = useState('');
  const [email, setEmail] = useState({});
  const [modelSubject, setModelSubject] = useState('');
  const [modelTitle, setModelTitle] = useState('');

  const getEmail = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: `/emails/${id}` });
      setEmail(data);
    } catch (e) {
      if (e.response) message(e.response);
    }
  };

  useEffect(() => {
    (async () => {
      await getEmail();
    })();
  }, []);

  const selectTitle = (value) => {
    setModelTitle(value);
  };

  const selectSubject = (value) => {
    setModelSubject(value);
  };

  useEffect(() => {
    if (Object.keys(email).length && email.data) {
      if (email.subject) setModelSubject(email.subject);
      setEditorState(email.data);
    }
  }, [email]);

  const createModelEmail = async (body) => {
    try {
      await dispatchAPI('POST', {
        url: '/emails',
        body
      });
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const addModelEmail = async () => {
    const htmlBody = editorState;
    const body = {
      ...email,
      type,
      data: htmlBody,
      subject: modelSubject,
      name: modelTitle
    };
    await createModelEmail(body);
  };

  return (
    <>
      <PageHeaderCustom title={t(`settings.email.${type}`)} />
      <Card>
        <Row style={{ marginBottom: 4 }}>
          <Col span={24}>
            <span>{t('settings.email.send')}</span>
          </Col>
        </Row>
        <Row style={{ marginBottom: 16 }}>
          <Col span={24}>
            <Input
              value={modelTitle}
              onChange={(e) => selectTitle(e.target.value)}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: 4 }}>
          <Col span={24}>
            <span>{t('settings.email.object')}</span>
          </Col>
        </Row>
        <Row style={{ marginBottom: 16 }}>
          <Col span={24}>
            <Input
              value={modelSubject}
              onChange={(e) => selectSubject(e.target.value)}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: 4 }}>
          <Col span={24}>
            <span>{t('settings.email.content')}</span>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <ReactQuill
              style={{ width: '100%', height: '100%' }}
              value={editorState}
              onChange={(value) => setEditorState(value)}
            />
          </Col>
        </Row>
        <Row>
          <Col span={4} offset={20}>
            <Button type="add" onClick={() => addModelEmail()}>
              {t('settings.email.save')}
            </Button>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default CreateTemplateEmail;

CreateTemplateEmail.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired
};
