import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useFields from './fields';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainer/CreateUpdateContainer';

const CreateUpdatePremise = ({ purpose }) => {
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const { fields, base64 } = useFields({
    setIsFieldsLoading,
    isFieldsLoading
  });

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data,
        ...(base64 ? { logo: base64 } : {})
      })
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        ...(base64 ? { logo: base64 } : {})
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        ...(base64 ? { logo: base64 } : {})
      })
    }
  };

  return (
    <CreateUpdateContainer
      fields={fields}
      loadingFields={false}
      purpose={purpose}
      baseUrl="premise"
      resource="premise"
      config={config}
    />
  );
};

CreateUpdatePremise.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdatePremise;
