import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import useFields from './fields';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainer/CreateUpdateContainer';

const CreateUpdateUser = ({ purpose }) => {
  const { fields, isFieldsLoading } = useFields();

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data,
        date: data.date && moment(data.date),
        time_start: data.time_start && moment(data.time_start),
        time_end: data.time_end && moment(data.time_end)
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        date: data.date && moment(data.date),
        time_start: data.time_start && moment(data.time_start),
        time_end: data.time_end && moment(data.time_end)
      })
    }
  };

  return (
    <CreateUpdateContainer
      fields={fields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="interviews"
      resource="interviews"
      config={config}
    />
  );
};

CreateUpdateUser.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateUser;
