import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Button, Popconfirm, Skeleton, Row, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import listContent from './listContent';
import ParcourTreeCard from './ParcourTreeCard';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../../components';
import { routes } from '../../../utils/constants/adminRoutes';
import { permissionVerification } from '../../../utils/permissionVerification';

const ShowTrainingProgress = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { dispatchAPI, userPermissions } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [trainingprogress, setTrainingProgress] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [permit, setPermit] = useState(false);

  useEffect(() => {
    const permission = permissionVerification(
      userPermissions,
      '/trainings/follow-up/modification'
    );
    setPermit(permission);
  }, [userPermissions]);

  const getTrainingProgress = useCallback(async () => {
    setIsLoading(true);
    try {
      const populate = 'employee,employee.premise,profiletree';
      const { data } = await dispatchAPI('GET', {
        url: `/trainingprogress/${id}?populate=${populate}`
      });
      setTrainingProgress(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id, refresh]);

  useEffect(() => {
    (async () => {
      await getTrainingProgress();
    })();
  }, [getTrainingProgress]);

  const deleteTrainingProgress = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/trainingprogress/${id}` });
      navigate(-1);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  return (
    <>
      <PageHeaderCustom
        title={t('trainingprogress.show.title')}
        extra={
          <>
            <Link to={{ pathname: `${routes.USERS}/edit/${id}` }}>
              {permit && (
                <Button type="primary">
                  {`${t('buttons.edit')} `}
                  <EditOutlined />
                </Button>
              )}
            </Link>
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={deleteTrainingProgress}
              icon={<WarningOutlined />}
            >
              {permit && (
                <Button type="link" danger>
                  {`${t('buttons.delete')} `}
                  <DeleteOutlined />
                </Button>
              )}
            </Popconfirm>
          </>
        }
      />
      <ContentCustom>
        <Card title={t('trainingprogress.show.general')} className="form-card">
          <Row>
            <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
              <DescriptionList
                data={listContent(trainingprogress || {}, t)}
                translate
              />
            </Skeleton>
          </Row>
        </Card>
        <Card
          title={t('trainingprogress.show.trainingtree')}
          className="form-card"
        >
          <Row>
            <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
              <ParcourTreeCard
                trainingprogress={trainingprogress}
                setRefresh={setRefresh}
                refresh={refresh}
              />
            </Skeleton>
          </Row>
        </Card>
      </ContentCustom>
    </>
  );
};

export default ShowTrainingProgress;
