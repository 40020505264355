import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import useFields from './fields';
import { CreateExitEmployeeContainer } from './CreateExitEmployeeContainer';

const CreateExitEmployee = ({ purpose }) => {
  const { formFields, isFieldsLoading, employee, endDateOfContract } =
    useFields();

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data,
        date_of_birth: data.date_of_birth && moment(data.date_of_birth)
      })
    },
    onCreateResource: {
      setBody: (data) => ({ ...data, employee: data.name, status: 'WAITING' })
    }
  };
  return (
    <CreateExitEmployeeContainer
      formFields={formFields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="exits"
      resource="exits"
      config={config}
      populate="employee"
      employee={employee}
      endDateOfContract={endDateOfContract}
    />
  );
};

CreateExitEmployee.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateExitEmployee;
