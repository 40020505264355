import moment from 'moment';

const dismissalInfo = (data, t) => {
  const {
    type_of_dismissal,
    preliminary_interview_invitation_date,
    preliminary_interview_date,
    preliminary_interview_location,
    dismissal_notification_date,
    date_of_first_request_absence_justification,
    formal_notice_of_absence_justification
  } = data;

  const realOrSeriousCauseFields = [
    {
      label: 'exits.form.type_of_dismissal',
      span: 1,
      content:
        (type_of_dismissal &&
          t(`enums.type_of_dismissal.${type_of_dismissal}`)) ||
        '-'
    },
    {
      label: 'exits.form.preliminary_interview_invitation_date',
      span: 2,
      content:
        (preliminary_interview_invitation_date &&
          moment(preliminary_interview_invitation_date).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      label: 'exits.form.preliminary_interview_date',
      span: 3,
      content:
        (preliminary_interview_date &&
          moment(preliminary_interview_date).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      label: 'exits.form.preliminary_interview_location',
      span: 1,
      content: preliminary_interview_location?.label || '-'
    },
    {
      label: 'exits.form.dismissal_notification_date',
      span: 2,
      content:
        (dismissal_notification_date &&
          moment(dismissal_notification_date).format('DD/MM/YYYY')) ||
        '-'
    }
  ];

  const faultWithAbandonmentFields = [
    {
      label: 'exits.form.type_of_dismissal',
      span: 1,
      content:
        (type_of_dismissal &&
          t(`enums.type_of_dismissal.${type_of_dismissal}`)) ||
        '-'
    },
    {
      label: 'exits.form.date_of_first_request_absence_justification',
      span: 2,
      content:
        (date_of_first_request_absence_justification &&
          moment(date_of_first_request_absence_justification).format(
            'DD/MM/YYYY'
          )) ||
        '-'
    },
    {
      label: 'exits.form.date_of_first_request_absence_justification',
      span: 3,
      content:
        (date_of_first_request_absence_justification &&
          moment(date_of_first_request_absence_justification).format(
            'DD/MM/YYYY'
          )) ||
        '-'
    },
    {
      label: 'exits.form.preliminary_interview_invitation_date',
      span: 2,
      content:
        (preliminary_interview_invitation_date &&
          moment(preliminary_interview_invitation_date).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      label: 'exits.form.preliminary_interview_date',
      span: 3,
      content:
        (preliminary_interview_date &&
          moment(preliminary_interview_date).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      label: 'exits.form.preliminary_interview_location',
      span: 1,
      content: preliminary_interview_location?.label || '-'
    },
    {
      label: 'exits.form.dismissal_notification_date',
      span: 2,
      content:
        (dismissal_notification_date &&
          moment(dismissal_notification_date).format('DD/MM/YYYY')) ||
        '-'
    }
  ];

  const faultWithoutAbandonmentFields = [
    {
      label: 'exits.form.type_of_dismissal',
      span: 1,
      content:
        (type_of_dismissal &&
          t(`enums.type_of_dismissal.${type_of_dismissal}`)) ||
        '-'
    },
    {
      label: 'exits.form.preliminary_interview_invitation_date',
      span: 2,
      content:
        (preliminary_interview_invitation_date &&
          moment(preliminary_interview_invitation_date).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      label: 'exits.form.formal_notice_of_absence_justification',
      span: 3,
      content:
        (formal_notice_of_absence_justification &&
          moment(formal_notice_of_absence_justification).format(
            'DD/MM/YYYY'
          )) ||
        '-'
    },
    {
      label: 'exits.form.preliminary_interview_location',
      span: 1,
      content: preliminary_interview_location?.label || '-'
    },
    {
      label: 'exits.form.dismissal_notification_date',
      span: 2,
      content:
        (dismissal_notification_date &&
          moment(dismissal_notification_date).format('DD/MM/YYYY')) ||
        '-'
    }
  ];

  return {
    realOrSeriousCauseFields,
    faultWithAbandonmentFields,
    faultWithoutAbandonmentFields
  };
};

export default dismissalInfo;
