import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Tag, Row, Switch } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { ListResource } from '../../../components';
import { memosStatus } from '../../../utils/constants/tagColors';
import { useFormatter } from '../../../utils/formatter';
import { useAuthContext } from '../../../contexts/AuthContext';
import { permissionVerification } from '../../../utils/permissionVerification';

const ListMemos = () => {
  const { t } = useTranslation();
  const { formattedData } = useFormatter();
  const navigate = useNavigate();
  const { userPermissions } = useAuthContext();
  const location = useLocation();
  const { pathname } = location;
  const params = new URLSearchParams(location.search);
  const archived = params.get('archived');
  const [isArchived, setIsArchived] = useState(archived === 'true');
  const [permit, setPermit] = useState(false);

  useEffect(() => {
    const permission = permissionVerification(
      userPermissions,
      '/intern-communication/memos/modification'
    );
    setPermit(permission);
  }, [userPermissions]);

  const onChange = () => {
    setIsArchived(!isArchived);
    navigate(`${pathname}?archived=${!isArchived}`);
  };

  const headers = [
    {
      label: 'date',
      key: 'date'
    },
    {
      label: 'premises',
      key: 'premises',
      model_key: 'premise',
      multiple_data_key: 'label'
    },
    {
      label: 'object',
      key: 'object'
    },
    {
      label: 'author',
      extra_label: 'author_last_name',
      key: 'author.last_name',
      model_key: 'user'
    },
    {
      label: 'author',
      extra_label: 'author_first_name',
      key: 'author.first_name',
      model_key: 'user'
    },
    {
      label: 'status',
      key: 'status'
    }
  ];

  const columns = [
    {
      title: t('memos.form.day'),
      key: 'date',
      dataIndex: 'date',
      width: '20%',
      render: (date) => moment(date).format('DD/MM/YYYY [à] H:mm'),
      sorter: true
    },
    {
      title: t('memos.show.premise'),
      key: 'premises',
      width: '20%',
      render: (premises) =>
        (premises &&
          premises.map((premise, index, array) => {
            if (index === array.length - 1) {
              return premise?.label;
            }
            return `${premise?.label}, `;
          })) ||
        'Tous',
      dataIndex: 'premises',
      ellipsis: true,
      sorter: true
    },
    {
      title: t('memos.form.object'),
      key: 'object',
      width: '15%',
      dataIndex: 'object',
      sorter: true
    },
    {
      title: t('memos.form.author'),
      key: 'author',
      width: '15%',
      render: (author) => `${author?.first_name} ${author?.last_name}` || '-',
      dataIndex: 'author',
      sorter: true
    },
    {
      title: t('memos.form.status'),
      key: 'status',
      width: '15%',
      dataIndex: 'status',
      render: (status) => (
        <Tag color={memosStatus[status]}>{t(`memos.tags.${status}`)}</Tag>
      ),
      sorter: true,
      filters: Object.keys(memosStatus).map((status) => ({
        text: t(`memos.tags.${status}`),
        value: status
      }))
    },
    {
      title: t('memos.form.created_at'),
      key: 'created_at',
      width: '15%',
      render: (created_at) => moment(created_at).format('DD/MM/YYYY [à] H:mm'),
      dataIndex: 'created_at',
      sorter: true
    }
  ];
  return (
    <ListResource
      resourceName="memos"
      columns={columns}
      headers={headers}
      deleteAction={permit}
      editAction={permit}
      withCreateButton={permit}
      formatter={formattedData}
      templateType="memos"
      resourceModelName="Memo"
      populate="author,premises"
      extraButtons={
        <Row align="middle" className="list-switch-wrapper">
          <Switch checked={isArchived} onChange={onChange} />
          <p>{t('memos.archived')}</p>
        </Row>
      }
      extraQuery={isArchived ? 'is_archived=true' : 'is_archived=false'}
    />
  );
};

export default ListMemos;
