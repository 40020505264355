import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { PageHeaderCustom } from '../../../../components';
import { CustomHeader } from './CustomHeader';
import { Calendar } from '../../../../components/Calendar/Calendar';
import {
  monthCellRender,
  weekCellRender
} from '../../../../components/Calendar/CellRenders.tsx';

export const CalendarView = ({ customActionLine, myInterviewsOnly }) => {
  const { t } = useTranslation();

  const setExtraQuery = () => {
    let extraQuery = '';
    if (myInterviewsOnly) {
      extraQuery += 'userOnly=true';
    }
    return extraQuery;
  };

  return (
    <>
      <PageHeaderCustom title={t('home.title')} />
      <CustomHeader />
      {customActionLine()}
      <Calendar
        modes={['week', 'month']}
        resourceName="interviews"
        monthCellRender={monthCellRender}
        weekCellRender={weekCellRender}
        extraQuery={setExtraQuery()}
        populate="candidate"
        hourEnd={22}
        hourStart={8}
        noAllDayRow
        initialMode="month"
        dayRange="withSunday"
      />
    </>
  );
};

CalendarView.propTypes = {
  myInterviewsOnly: PropTypes.arrayOf(PropTypes.shape({})),
  customActionLine: PropTypes.func.isRequired
};

CalendarView.defaultProps = {
  myInterviewsOnly: []
};
