import React from 'react';
import {
  HomeOutlined,
  UserOutlined,
  SettingOutlined,
  SolutionOutlined,
  IdcardOutlined,
  TeamOutlined,
  CommentOutlined,
  ApartmentOutlined,
  DiffOutlined,
  RadarChartOutlined
} from '@ant-design/icons';

const navMenuLogos = {
  HOME: <HomeOutlined />,
  DASHBOARD: <HomeOutlined />,
  USERS: <UserOutlined />,
  RECRUITMENTS: <IdcardOutlined />,
  EMPLOYEE_FOLDER: <TeamOutlined />,
  INTERN_COMMUNICATION: <CommentOutlined />,
  TRAININGS: <SolutionOutlined />,
  SETTINGS: <SettingOutlined />,
  ORGANISATION: <ApartmentOutlined />,
  TEMPLATE_DOCS: <DiffOutlined />,
  HOMEDASHBOARD: <RadarChartOutlined />
};

export default navMenuLogos;
