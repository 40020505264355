import React from 'react';
import { useTranslation } from 'react-i18next';
import { Layout, Collapse } from 'antd';
import PositionSettings from './position/positionSettings';
import PremiseSettings from './premise/premiseSettings';
import InterviewTitleSettings from './interviewTitle/interviewTitleSettings';
import InterviewFrameSettings from './interviewFrame/interviewFrameSettings';
import EquipmentSettings from './equipment/equipmentSettings';
import KanbanSettings from './applications/kanbanSettings';
import { PageHeaderCustom } from '../../components';
import ListTypeEmail from './emails/ListTypeEmail';
import ListTemplate from './templates/ListTemplates';
import ListImportTemplate from './import-templates/ListImportTemplates';

const { Content } = Layout;
const { Panel } = Collapse;

export const Settings = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageHeaderCustom title={t('settings.title')} />

      <Content
        className="site-layout-background"
        style={{
          marginTop: 2,
          padding: 24,
          backgroundColor: '#fff'
        }}
      >
        <Collapse>
          <Panel header={t('settings.positionTable.title')} key="1">
            <PositionSettings />
          </Panel>
          <Panel header={t('settings.premiseTable.title')} key="2">
            <PremiseSettings />
          </Panel>
          <Panel header={t('settings.kanbanTable.title')} key="3">
            <KanbanSettings />
          </Panel>
          <Panel header={t('settings.interviewTitleTable.title')} key="4">
            <InterviewTitleSettings />
          </Panel>
          <Panel header={t('settings.interviewframeTable.title')} key="5">
            <InterviewFrameSettings />
          </Panel>
          <Panel header={t('settings.equipmentTable.headtitle')} key="6">
            <EquipmentSettings />
          </Panel>
          <Panel header={t('settings.email.title')} key="7">
            <ListTypeEmail />
          </Panel>
          <Panel header={t('settings.template.title')} key="8">
            <ListTemplate />
          </Panel>
          <Panel header={t('settings.import_template.title')} key="9">
            <ListImportTemplate />
          </Panel>
        </Collapse>
      </Content>
    </>
  );
};
