import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { FilePdfOutlined } from '@ant-design/icons';
import { Button, Spin } from 'antd';
import PropTypes from 'prop-types';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';

export const TemplateDocumentButton = ({
  collection,
  use_case,
  button_text,
  onClick
}) => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const generatePDF = async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('POST', {
        url: `template-documents/generatePDF/${id}`,
        body: {
          collectionName: collection,
          use_case
        },
        responseType: 'arraybuffer' // Set the responseType to receive the response as an ArrayBuffer
      });
      setIsLoading(false);
      // Create a Blob object from the ArrayBuffer

      const blob = new Blob([data], { type: 'application/pdf' });

      // Create a URL from the Blob object
      const url = URL.createObjectURL(blob);

      // Use the URL to create a download link or display the PDF in the browser
      // Example: Create a download link
      const link = document.createElement('a');
      link.href = url;
      link.download = 'document.pdf';
      link.click();

      // Clean up the URL object
      return URL.revokeObjectURL(url);
    } catch (e) {
      if (e.response.status === 404) return message('TEMPLATE_NOT_FOUND');
      return message(e);
    }
  };

  return (
    <Button
      type="primary"
      onClick={() => {
        generatePDF();
        if (onClick) onClick();
      }}
      icon={isLoading ? <Spin /> : <FilePdfOutlined />}
    >
      {`${t(`${button_text}`)} `}
    </Button>
  );
};

TemplateDocumentButton.propTypes = {
  collection: PropTypes.string.isRequired,
  use_case: PropTypes.string.isRequired,
  button_text: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

TemplateDocumentButton.defaultProps = {
  onClick: null
};
