import pluralize from 'pluralize';
import { createTranslationKey } from './createTranslationKey';
import { pickTagColor } from './pickTagColor';

/**
 * Escapes special characters in a string for use in HTML.
 * This helps prevent Cross-Site Scripting (XSS) attacks by ensuring
 * characters like <, >, &, ", and ' are converted to their HTML encoded forms.
 *
 * @function
 * @param {string} unsafe - The string that might contain unsafe characters.
 * @returns {string} The string with special characters replaced by their HTML encoded forms.
 */
const escapeHtml = (unsafe) =>
  unsafe
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;');

/**
 * Constructs a full key path by joining the parent key path and the current key.
 * If the current key already contains a period (indicating it's a full path), it returns the current key as is.
 *
 * @function
 * @param {string} [parentKeyPath=""] - The key path of the parent.
 * @param {string} currentKey - The key of the current item.
 * @returns {string} The full key path.
 */
const getKey = (parentKeyPath, currentKey) => {
  if (currentKey.includes('.')) {
    return currentKey;
  }
  return parentKeyPath ? `${parentKeyPath}.${currentKey}` : currentKey;
};

/**
 * Generates an HTML string of options or optgroups based on the provided data.
 * Each item can either result in an <option> element or an <optgroup> if it has subcontent.
 *
 * @function
 * @param {Array} data - The data array containing tags. Each tag can have a key, type, and optional subcontent.
 * @param {string} parentKeyPath - The key path of the parent for the current recursion level.
 * @param {Function} t - The translation function.
 * @returns {string} The HTML string of options and optgroups.
 */
export const generateOptions = (data, parentKeyPath, t) =>
  data
    .map((tag) => {
      const currentKey = getKey(parentKeyPath, tag.key);

      const tagColor = pickTagColor(tag.type);

      if (!tag.subcontent.length) {
        return `<option value="{{${escapeHtml(
          currentKey
        )}}}" style="color: ${escapeHtml(tagColor)}">${escapeHtml(
          createTranslationKey('tag', currentKey, t, pluralize)
        )}</option>`;
      }
      const subOptions = generateOptions(tag.subcontent, currentKey, t);
      return `<optgroup label="${escapeHtml(
        createTranslationKey('header', currentKey, t, pluralize)
      )}" style="color:black">${subOptions}</optgroup>`;
    })
    .join('');
