import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, Input, Button, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  UpOutlined,
  DownOutlined,
  DeleteOutlined,
  WarningOutlined
} from '@ant-design/icons';

const ListTrainings = ({ list, setList }) => {
  const { t } = useTranslation();

  const setTitle = (id, value) => {
    const newList = list.map((item, index) =>
      index === id ? { ...item, title: value } : item
    );
    setList(newList);
  };

  const mouvLineUp = (id) => {
    const finalIndex = id - 1;
    const elem = list[id];
    const firstList = list.filter((_, index) => index < id);
    const lastList = list.filter((_, index) => index > id);
    const newList = firstList.concat(lastList);
    newList.splice(finalIndex, 0, elem);
    setList(newList);
  };

  const mouvLineDown = (id) => {
    const finalIndex = id + 1;
    const elem = list[id];
    const firstList = list.filter((_, index) => index < id);
    const lastList = list.filter((_, index) => index > id);
    const newList = firstList.concat(lastList);
    newList.splice(finalIndex, 0, elem);
    setList(newList);
  };

  const deleteLine = (id) => {
    const newList = list.filter((item, index) => index !== id);
    setList(newList);
  };

  const addLine = () => {
    const newList = list;
    newList.push({
      title: 'Nouvelle formation'
    });
    setList(newList);
  };

  return (
    <>
      {list.map((item, index) => (
        <Card key={item._id}>
          <Row>
            <Col span={18}>
              <Input
                value={item.title}
                onChange={(e) => setTitle(index, e.target.value)}
              />
            </Col>
            <Col span={6}>
              <Row justify="end">
                <Button
                  type="link"
                  onClick={() => mouvLineUp(index)}
                  disabled={index === 0}
                >
                  <UpOutlined />
                </Button>
                <Button
                  type="link"
                  onClick={() => mouvLineDown(index)}
                  disabled={index === list.length - 1}
                >
                  <DownOutlined />
                </Button>
                <Popconfirm
                  title={t('datatable.column.action.delete.title')}
                  okText={t('datatable.column.action.delete.ok')}
                  okButtonProps={{ type: 'danger' }}
                  cancelText={t('datatable.column.action.delete.cancel')}
                  onConfirm={() => deleteLine(index)}
                  icon={<WarningOutlined />}
                >
                  <Button type="link" danger>
                    <DeleteOutlined />
                  </Button>
                </Popconfirm>
              </Row>
            </Col>
          </Row>
        </Card>
      ))}
      <Button type="dashed" block onClick={addLine}>
        {t('trainings.button.add-training')}
      </Button>
    </>
  );
};

ListTrainings.propTypes = {
  list: PropTypes.string.isRequired,
  setList: PropTypes.func.isRequired
};

export default ListTrainings;
