import React, { useState, useEffect } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { routes, subRoutes } from '../../../../utils/constants/adminRoutes';
import { useApplicationsContext } from '../../../../contexts/ApplicationsContext';
import { permissionVerification } from '../../../../utils/permissionVerification';
import { useAuthContext } from '../../../../contexts/AuthContext';

const { Search } = Input;

export const CustomHeader = () => {
  const { t } = useTranslation();
  const { searchFilter, setSearchFilter, dataRefresh, setDataRefresh } =
    useApplicationsContext();
  const navigate = useNavigate();
  const { userPermissions } = useAuthContext;
  const [permit, setPermit] = useState(false);

  useEffect(() => {
    const permission = permissionVerification(
      userPermissions,
      '/recruitments/interviews/modification'
    );
    setPermit(permission);
  }, [userPermissions]);

  const searchResource = (val) => {
    setSearchFilter(val);
    setDataRefresh(!dataRefresh);
  };

  return (
    <Row
      style={{
        justifyContent: 'space-between',
        padding: '1rem 1rem',
        background: 'var(--componentBackground)'
      }}
    >
      <Col>
        <Search
          allowClear
          placeholder={t('placeholder.search')}
          defaultValue={searchFilter}
          onSearch={(val) => searchResource(val)}
        />
      </Col>
      <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          type="add"
          onClick={() =>
            navigate(
              `${routes.RECRUITMENTS}${subRoutes.RECRUITMENTS.INTERVIEWS}/create`
            )
          }
          disabled={!permit}
        >
          <PlusOutlined />
          {t('interviews.buttons.create')}
        </Button>
      </Col>
    </Row>
  );
};
