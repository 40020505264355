import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import EquipmentsCards from './EquipmentsCards';

const EquipmentsField = ({
  list,
  setList,
  equipments,
  setEquipmentData,
  equipmentData,
  purpose
}) => {
  const { t } = useTranslation();

  const setEmployeeEquipments = () => {
    const newList = list;

    equipmentData.map((item) =>
      newList.push({
        quantity: item._id.quantity,
        label: item._id.label,
        size: item._id.size
      })
    );
    setList(newList);
  };

  const addLine = () => {
    const newList = list;
    newList.push({
      title: ''
    });

    setList(newList);
  };

  useEffect(() => {
    if (purpose === 'edit') {
      setEmployeeEquipments();
    }
  }, [equipmentData]);

  return (
    <>
      <EquipmentsCards
        equipments={equipments}
        setEquipmentData={setEquipmentData}
        equipmentData={equipmentData}
        list={list}
        setList={setList}
      />
      <Button type="dashed" block onClick={addLine}>
        {t('employees.form.button.add_equipment')}
      </Button>
    </>
  );
};

EquipmentsField.propTypes = {
  list: PropTypes.string.isRequired,
  setList: PropTypes.func.isRequired,
  setEquipmentData: PropTypes.func.isRequired,
  equipmentData: PropTypes.arrayOf().isRequired,
  equipments: PropTypes.arrayOf({
    label: PropTypes.string,
    value: PropTypes.string,
    quantity: PropTypes.string,
    size: PropTypes.string
  }),
  purpose: PropTypes.string.isRequired
};

EquipmentsField.defaultProps = {
  equipments: null
};

export default EquipmentsField;
