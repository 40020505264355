import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Button, Popconfirm, Skeleton, Row, Card, Steps } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/ErrorMessage';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../../../components';
import { routes } from '../../../../utils/constants/adminRoutes';
import listContent from './listContent';
import { permissionVerification } from '../../../../utils/permissionVerification';

const { Step } = Steps;

const ShowTrainingTree = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { dispatchAPI, userPermissions } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [trainingtree, setTrainingTree] = useState({});
  const [permit, setPermit] = useState(false);

  useEffect(() => {
    const permission = permissionVerification(
      userPermissions,
      '/trainings/trainings-path/parcourstree/modification'
    );
    setPermit(permission);
  }, [userPermissions]);

  const getTrainingTree = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/trainingtrees/${id}`
      });
      setTrainingTree(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getTrainingTree();
    })();
  }, [getTrainingTree]);

  const deleteTrainingTree = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/trainingtrees/${id}` });
      navigate(-1);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  return (
    <>
      <PageHeaderCustom
        title={t('trainings.trainingtrees.show.title')}
        extra={
          <>
            <Link to={{ pathname: `${routes.OFFERS}/edit/${id}` }}>
              {permit && (
                <Button type="primary">
                  {`${t('buttons.edit')} `}
                  <EditOutlined />
                </Button>
              )}
            </Link>
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={deleteTrainingTree}
              icon={<WarningOutlined />}
            >
              {permit && (
                <Button type="link" danger>
                  {`${t('buttons.delete')} `}
                  <DeleteOutlined />
                </Button>
              )}
            </Popconfirm>
          </>
        }
      />
      <ContentCustom>
        <Card
          title={t('trainings.trainingtrees.show.general')}
          bordered={false}
          style={{ width: '100%' }}
          className="form-card"
        >
          <Row>
            <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
              <DescriptionList
                data={listContent(trainingtree || {}, t)}
                translate
              />
            </Skeleton>
          </Row>
        </Card>
        <Card
          title={t('trainings.trainingtrees.show.trainings')}
          bordered={false}
          style={{ width: '100%' }}
          className="form-card"
        >
          <Row style={{ width: '100%' }}>
            <Steps direction="vertical">
              {(trainingtree?.children || []).map((item) => (
                <Step title={item.title} />
              ))}
            </Steps>
          </Row>
        </Card>

        <Card
          title={t('trainings.trainingtrees.show.employee-progress')}
          bordered={false}
          style={{ width: '100%' }}
          className="form-card"
        >
          <Card style={{ backgroundColor: 'lightgrey', textAlign: 'center' }}>
            {t('global.in-development')}
          </Card>
        </Card>
      </ContentCustom>
    </>
  );
};

export default ShowTrainingTree;
