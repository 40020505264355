import React, { useCallback, useEffect, useState, forwardRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form, Row, Spin, Card } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import moment from 'moment/moment';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import { ContentCustom, PageHeaderCustom } from '../../../components';

import { useGenerateFormItem } from '../../../utils/GenerateFormItem';
import { formEntriesCardsTitles } from './formEntriesCardsTitles';

import {
  formCardItemLayout,
  tailFormItemLayout
} from '../../../utils/constants/formLayout';

export const CreateExitEmployeeContainer = forwardRef(
  (
    {
      purpose,
      formFields,
      loadingFields,
      resource,
      baseUrl,
      config,
      formExtra,
      tradKey,
      submitLabel,
      customSubmit,
      isParentLoading,
      employee,
      endDateOfContract
    },
    ref
  ) => {
    const { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { message } = useErrorMessage();
    const { dispatchAPI } = useAuthContext();
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const generateFields = useGenerateFormItem();
    const [form] = Form.useForm();
    const { onCreateResource, onUpdateResource } = config;

    const updateResource = async (body) => {
      setIsSubmitting(true);
      try {
        await dispatchAPI('PATCH', {
          url: `${baseUrl}/${id}`,
          body:
            onUpdateResource && onUpdateResource.setBody
              ? onUpdateResource.setBody(body)
              : body
        });
        navigate(-1);
      } catch (e) {
        setIsSubmitting(false);
        message(e);
      }
    };
    const createResource = async (body) => {
      setIsSubmitting(true);
      try {
        await dispatchAPI('POST', {
          url: `${baseUrl}`,
          body:
            onCreateResource && onCreateResource.setBody
              ? onCreateResource.setBody(body)
              : body
        });
        navigate(-1);
      } catch (e) {
        setIsSubmitting(false);
        message(e);
      }
    };
    const getResource = useCallback(async () => {
      setIsLoading(true);
      try {
        const { data } = await dispatchAPI('GET', {
          url: `${baseUrl}/${id}?populate=employee.premise,employee.position`
        });
        form.setFieldsValue({
          name: data?.employee?._id,
          premise: data?.employee?.premise,
          position: data?.employee?.position,
          grade: data?.employee?.grade,
          level: data?.employee?.level,
          start_date: data?.employee.start_date
            ? moment(data.employee.start_date)
            : undefined
        });
      } catch (e) {
        message(e);
      }
      setIsLoading(false);
    }, [purpose, id, loadingFields, baseUrl]);

    const formData = () => {
      form.setFieldsValue({
        ...employee,
        end_date_of_contract: endDateOfContract,
        start_date: employee?.start_date
          ? moment(employee.start_date)
          : undefined
      });
    };

    useEffect(() => {
      if (purpose === 'edit' && id) {
        setIsLoading(true);
        if (!loadingFields)
          (async () => {
            await getResource();
          })();
      }
      formData();
    }, [getResource, employee, endDateOfContract]);

    const handleSubmit = async (values) => {
      if (customSubmit) customSubmit(values);
      else {
        if (purpose === 'edit') await updateResource(values);
        if (purpose === 'create') await createResource(values);
      }
    };

    return (
      <>
        <PageHeaderCustom title={t(`${resource}.form.title.${purpose}`)} />
        <ContentCustom>
          <Spin spinning={isLoading || isParentLoading}>
            <Form
              colon
              ref={ref}
              {...formCardItemLayout}
              onFinish={handleSubmit}
              form={form}
              layout="horizontal"
            >
              {formFields.map((fields, index) => (
                <Card
                  className="form-card"
                  key={formEntriesCardsTitles[index]}
                  title={t(`cardTitles.exits.${formEntriesCardsTitles[index]}`)}
                >
                  {fields.map((field) =>
                    generateFields(tradKey || resource, field)
                  )}
                </Card>
              ))}
              {formExtra}
              <Form.Item {...tailFormItemLayout}>
                <Row justify="end">
                  <Button
                    style={{ margin: '0 10px' }}
                    type="link"
                    danger
                    onClick={() => navigate(-1)}
                  >
                    {`${t('buttons.cancel')} `}
                    <CloseOutlined />
                  </Button>
                  <Button type="add" htmlType="submit" loading={isSubmitting}>
                    {`${t(submitLabel || 'buttons.save')} `}
                    <CheckOutlined />
                  </Button>
                </Row>
              </Form.Item>
            </Form>
          </Spin>
        </ContentCustom>
      </>
    );
  }
);

CreateExitEmployeeContainer.propTypes = {
  purpose: PropTypes.string.isRequired,
  formFields: PropTypes.arrayOf(PropTypes.arrayOf).isRequired,
  baseUrl: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  loadingFields: PropTypes.bool,
  config: PropTypes.shape({
    onGetResource: PropTypes.shape({
      setFields: PropTypes.func
    }),
    onCreateResource: PropTypes.shape({
      setBody: PropTypes.func
    }),
    onUpdateResource: PropTypes.shape({
      setBody: PropTypes.func
    })
  }),
  formExtra: PropTypes.element,
  tradKey: PropTypes.string,
  submitLabel: PropTypes.string,
  customSubmit: PropTypes.func,
  isParentLoading: PropTypes.bool,
  employee: PropTypes.shape(PropTypes.shape),
  endDateOfContract: PropTypes.shape(PropTypes.shape)
};

CreateExitEmployeeContainer.defaultProps = {
  config: {},
  loadingFields: false,
  formExtra: null,
  tradKey: null,
  submitLabel: null,
  customSubmit: null,
  isParentLoading: false,
  employee: {},
  endDateOfContract: {}
};
