import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Datatable } from '../../components';
import { useColumns } from './columns';
import { useAuthContext } from '../../contexts/AuthContext';
import { permissionVerification } from '../../utils/permissionVerification';

export const TemplateDocsDatatable = ({ useCaseParams }) => {
  const [forceRefresh, setForceRefresh] = useState(false);
  const columns = useColumns(forceRefresh, setForceRefresh);
  const { userPermissions } = useAuthContext();
  const [permit, setPermit] = useState(false);

  useEffect(() => {
    const permission = permissionVerification(
      userPermissions,
      '/template-docs/modification'
    );
    setPermit(permission);
  }, [userPermissions]);

  return (
    <Datatable
      columns={columns}
      editAction={permit}
      deleteAction={permit}
      resourceName="template-documents"
      forceRefresh={forceRefresh}
      extraQuery={`configuration.use_case=${useCaseParams}&populate=author`}
    />
  );
};

TemplateDocsDatatable.propTypes = {
  useCaseParams: PropTypes.string.isRequired
};
