import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ListEmployees from './ListEmployees';
import CreateUpdateEmployee from './CreateUpdateEmployee';
import ShowEmployee from './ShowEmployee/ShowEmployee';

export const EmployeeRouter = () => (
  <Routes>
    <Route path="/create" element={<CreateUpdateEmployee purpose="create" />} />
    <Route path="/edit/:id" element={<CreateUpdateEmployee purpose="edit" />} />
    <Route path="/show/:id" element={<ShowEmployee />} />
    <Route index element={<ListEmployees />} />
  </Routes>
);
