import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Col } from 'antd';
import PropTypes from 'prop-types';

const MenuCard = ({ icon, title, route, enabled }) => (
  <Col
    className="landing-card-col"
    xs={24}
    sm={12}
    md={8}
    lg={6}
    xl={4}
    xxl={3}
  >
    {enabled ? (
      <Link to={route}>
        <Card className="landing-card">
          <div className="landing-card-img">{icon}</div>
          <p className="landing-card-title">{title}</p>
        </Card>
      </Link>
    ) : (
      <Card className="landing-card disabled">
        <div className="landing-card-img">{icon}</div>
        <p className="landing-card-title">{title}</p>
      </Card>
    )}
  </Col>
);

MenuCard.propTypes = {
  icon: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  enabled: PropTypes.bool
};

MenuCard.defaultProps = {
  enabled: false
};

export default MenuCard;
