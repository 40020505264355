/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { Button, Input, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import PositionTable from './positionTable';

const initialColumns = [
  {
    title: 'Label',
    dataIndex: 'label',
    key: 'label',
    render: (text) => <Input InitialValue={text} />
  }
];

const PositionSettings = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [positionCols, setPositionCols] = useState(initialColumns);
  const [positionInformationsEnums, setPositionInformationsEnums] = useState(
    []
  );
  const newPositionCol = {
    label: 'Nouvelle ligne',
    show: true
  };

  const getPositionInformationsEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'employees/enums/position-informations'
      });
      setPositionInformationsEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getPositionCols = async () => {
    try {
      const data = await dispatchAPI('GET', { url: '/positions' });
      setPositionCols(data.data);
    } catch (e) {
      message.error(e.message);
    }
  };

  const addPositionCol = async () => {
    try {
      await dispatchAPI('POST', { url: '/positions', body: newPositionCol });
      getPositionCols();
    } catch (e) {
      message.error(e.message);
    }
  };

  const editPositionCol = async (_id, row) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/positions/${_id}`,
        body: row
      });
    } catch (e) {
      message.error(e.message);
    }
    getPositionCols();
  };
  useEffect(() => {
    getPositionCols();
    getPositionInformationsEnums();
  }, []);

  return (
    <>
      <PositionTable
        positionCols={positionCols}
        editPositionCol={editPositionCol}
        positionInformationsEnums={positionInformationsEnums}
      />
      <Button
        type="dashed"
        style={{ width: '100%', height: 50 }}
        onClick={addPositionCol}
      >
        {t('settings.positionTable.addpositioncol')}
      </Button>
    </>
  );
};

export default PositionSettings;
