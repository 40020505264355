import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { routes, subRoutes } from '../../../../utils/constants/adminRoutes';

import { Datatable } from '../../../../components/DataTable/Datatable';

const { Text } = Typography;

const ListInterview = ({ employee }) => {
  const { t } = useTranslation();

  const renderDates = (date_start, interview) => {
    const { time_start, time_end } = interview;
    return `Le ${moment(date_start).format('DD/MM/YYYY')} de ${moment(
      time_start
    ).format('HH:mm')} à ${moment(time_end).format('HH:mm')}`;
  };

  const renderInterlocutors = (interlocutors) => {
    if (interlocutors.length === 0)
      return <Text italic>{t('interviews.form.none')}</Text>;

    let result = '';
    interlocutors.forEach((interlocutor) => {
      result += `${interlocutor.first_name} ${interlocutor.last_name}`;
    });
    return result;
  };

  const columns = [
    {
      title: t('interviews.form.header'),
      key: 'header',
      dataIndex: 'header',
      render: (header) => header?.label,
      sorter: true
    },
    {
      title: t('interviews.form.rh_employee'),
      key: 'rh_name',
      dataIndex: 'rh_employee',
      render: (rh_employee) =>
        `${rh_employee?.first_name} ${rh_employee?.last_name}`,
      sorter: (a, b) =>
        a.rh_employee.first_name.localeCompare(b.rh_employee.first_name)
    },
    {
      title: t('interviews.form.interlocutors'),
      key: 'interlocutors',
      dataIndex: 'interlocutors',
      render: (interlocutors) => renderInterlocutors(interlocutors),
      sorter: true
    },
    {
      title: t('interviews.form.date'),
      key: 'date',
      dataIndex: 'date',
      render: (date_start, interview) => renderDates(date_start, interview),
      sorter: true
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      width: 120,
      render: (record) => (
        <Link
          to={{
            pathname: `${routes.RECRUITMENTS}${subRoutes.RECRUITMENTS.INTERVIEWS}/show/${record._id}`
          }}
        >
          <EyeOutlined style={{ fontSize: 18 }} />
        </Link>
      )
    }
  ];

  const extraQuery = `interlocutors=${employee?._id}`;
  return (
    <Datatable
      scroll={{ x: 1000 }}
      style={{ marginTop: 24 }}
      resourceName="interviews"
      columns={columns}
      extraQuery={extraQuery}
      populate="header"
      customActionColumn
      editAction={false}
      deleteAction={false}
    />
  );
};

ListInterview.propTypes = {
  employee: PropTypes.shape({
    _id: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string
  })
};

ListInterview.defaultProps = {
  employee: {}
};

export default ListInterview;
