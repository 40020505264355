import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ListEntries from './ListEntries';
import ShowEntry from './ShowEntry/ShowEntry';
import CreateUpdateEntry from './CreateUpdateEntry';

export const EntryRouter = () => (
  <Routes>
    <Route path="/create" element={<CreateUpdateEntry purpose="create" />} />
    <Route path="/edit/:id" element={<CreateUpdateEntry purpose="edit" />} />
    <Route path="/show/:id" element={<ShowEntry />} />
    <Route index element={<ListEntries />} />
  </Routes>
);
