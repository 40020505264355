import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Popconfirm, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { routes, subRoutes } from '../../../../utils/constants/adminRoutes';
import ModalSendMails from '../../../employee-folder/employees/ShowEmployee/ModalSendMails/ModalSendMails';
import renderAdditionalButtons from './AdditionalButtons';
import { permissionVerification } from '../../../../utils/permissionVerification';
import { useAuthContext } from '../../../../contexts/AuthContext';

const HeaderExtra = ({
  entry,
  employee,
  activeStep,
  nextStep,
  deleteEntrie,
  setRefresh,
  refresh,
  setHistoryExchange,
  employeeId
}) => {
  const { t } = useTranslation();
  const [isGenerate, setIsGenerate] = useState(false);
  const { userPermissions } = useAuthContext();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAttachment, setIsAttachment] = useState();
  const [type, setType] = useState(null);
  const [permit, setPermit] = useState(false);

  useEffect(() => {
    const permission = permissionVerification(
      userPermissions,
      '/recruitments/entries/modification'
    );
    setPermit(permission);
  }, [userPermissions]);

  const mailSubject = () => {
    switch (activeStep) {
      case 'PROMISE':
        return "Votre promesse d'embauche est prête";
      case 'WELCOME_EMAIL':
        return 'Bienvenue';
      case 'DOCS_VALIDATED':
        return 'Votre contrat de travail est prêt';
      case 'INVITATION_EMAIL':
        return 'Bienvenue chez nous !';
      default:
        return 'Tous les documents sont valides';
    }
  };

  const emailModel = () => {
    switch (activeStep) {
      case 'PROMISE':
        return 'promise_model';
      case 'WELCOME_EMAIL':
        return 'welcome_model';
      case 'INVITATION_EMAIL':
        return 'invitation_model';
      default:
        return '';
    }
  };

  return (
    <Row gutter={[16, 16]}>
      {renderAdditionalButtons({
        setIsAttachment,
        setType,
        setIsModalVisible,
        nextStep,
        setRefresh,
        refresh,
        setIsGenerate,
        isGenerate,
        activeStep,
        entry,
        setHistoryExchange,
        t,
        employeeId
      })}
      <Col>
        <Link
          to={{
            pathname: `${routes.RECRUITMENTS}${subRoutes.RECRUITMENTS.ENTRIES}/edit/${entry?._id}`
          }}
        >
          {permit && (
            <Button type="primary">
              {`${t('buttons.edit')} `}
              <EditOutlined />
            </Button>
          )}
        </Link>
        <Popconfirm
          title={t('datatable.column.action.delete.title')}
          okText={t('datatable.column.action.delete.ok')}
          okButtonProps={{ type: 'danger' }}
          cancelText={t('datatable.column.action.delete.cancel')}
          onConfirm={deleteEntrie}
          icon={<WarningOutlined />}
        >
          {permit && (
            <Button type="link" danger>
              {`${t('buttons.delete')} `}
              <DeleteOutlined />
            </Button>
          )}
        </Popconfirm>
        <ModalSendMails
          emailModel={emailModel()}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          recipientsList={[employee] || []}
          isAttachment={isAttachment}
          initialSubject={mailSubject()}
          sendMailAction={nextStep}
          setIsGenerate={setIsGenerate}
          employee={employee}
          entry={entry}
          type={type}
          setHistoryExchange={setHistoryExchange}
        />
      </Col>
    </Row>
  );
};

HeaderExtra.propTypes = {
  entry: PropTypes.arrayOf({}).isRequired,
  employee: PropTypes.shape({
    activeStep: PropTypes.string,
    _id: PropTypes.string
  }),
  activeStep: PropTypes.string,
  deleteEntrie: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  setRefresh: PropTypes.func.isRequired,
  refresh: PropTypes.bool,
  setHistoryExchange: PropTypes.func,
  employeeId: PropTypes.string.isRequired
};

HeaderExtra.defaultProps = {
  employee: {},
  activeStep: '',
  refresh: false,
  setHistoryExchange: null
};

export default HeaderExtra;
