export const translateEditor = (editor) => {
  const languageManager = editor.I18n;
  const blockManager = editor.BlockManager;

  const blocksToUpdate = [
    { id: 'column1', replaceLabel: 'Une colonne' },
    { id: 'column2', replaceLabel: 'Deux colonnes' },
    { id: 'column3', replaceLabel: 'Trois colonnes' },
    { id: 'column3-7', replaceLabel: 'Colonnes 3-7' },
    { id: 'text', replaceLabel: 'Texte' },
    { id: 'link', replaceLabel: 'Lien' },
    { id: 'image', replaceLabel: 'Image' },
    { id: 'video', replaceLabel: 'Vidéo' },
    { id: 'map', replaceLabel: 'Carte' },
    { id: 'link-block', replaceLabel: 'Bloc de lien' },
    { id: 'quote', replaceLabel: 'Citation' },
    { id: 'text-basic', replaceLabel: 'Texte basique' },
    { id: 'form', replaceLabel: 'Formulaire' },
    { id: 'input', replaceLabel: 'Champ de saisie' },
    { id: 'textarea', replaceLabel: 'Zone de texte' },
    { id: 'select', replaceLabel: 'Sélection' },
    { id: 'button', replaceLabel: 'Bouton' },
    { id: 'label', replaceLabel: 'Étiquette' },
    { id: 'checkbox', replaceLabel: 'Case à cocher' },
    { id: 'radio', replaceLabel: 'Bouton radio' },
    { id: 'tabs', replaceLabel: 'Onglets' },
    { id: 'flexbox', replaceLabel: 'FlexBox' },
    { id: 'sect100', replaceLabel: 'Section 100%' },
    { id: 'sect50', replaceLabel: 'Section 50%' },
    { id: 'sect30', replaceLabel: 'Section 30%' },
    { id: 'sect37', replaceLabel: 'Section 37%' },
    { id: 'divider', replaceLabel: 'Diviseur' },
    { id: 'text-sect', replaceLabel: 'Section de texte' },
    { id: 'grid-items', replaceLabel: 'Éléments de grille' },
    { id: 'list-items', replaceLabel: 'Éléments de liste' }
  ];

  const replaceBlockLabels = () => {
    for (let i = 0; i < blocksToUpdate.length; i += 1) {
      const block = blockManager.get(blocksToUpdate[i].id);

      block.set('label', blocksToUpdate[i].replaceLabel);
    }
  };

  languageManager.setMessages({
    fr: {
      assetManager: {
        addButton: 'Ajouter image',
        inputPlh: 'http://path/to/the/image.jpg',
        modalTitle: 'Choisir une image',
        uploadTitle: 'Cliquer/Glisser pour déposer un fichier'
      },
      deviceManager: {
        device: 'Device',
        devices: {
          desktop: 'Bureau',
          mobileLandscape: 'Mobile/Paysage',
          mobilePortrait: 'Mobile/Portrait',
          tablet: 'Tablette'
        }
      },
      blockManager: {
        categories: {
          Basic: 'Composants de bases',
          forms: 'Composants de formulaire'
        }
      },
      styleManager: {
        empty: "Choisir un élément avant d'utiliser le gestionnaire de styles",
        fileButton: 'Images',
        layer: 'Couches',
        sectors: {
          decorations: 'Décorations',
          dimension: 'Dimensions',
          extra: 'Extras',
          flex: 'Flex',
          general: 'Général',
          layout: 'Layout',
          typography: 'Typographie'
        },
        properties: {
          'align-content': 'Alignement du contenu',
          'align-items': 'Alignement des éléments',
          'align-self': 'Alignement sur soi-même',
          background: 'Arrière-plan',
          'background-color': 'Couleur de fond',
          border: 'Bordure',
          'border-radius': 'Rayon de la bordure',
          bottom: 'Bas',
          'box-shadow': 'Ombre de la boîte',
          color: 'Couleur',
          display: 'Affichage',
          'flex-basis': 'flex-basis',
          'flex-direction': 'flex-direction',
          'flex-grow': 'flex-grow',
          'flex-shrink': 'flex-shrink',
          'flex-wrap': 'flex-wrap',
          float: 'Flottant',
          'font-family': 'Police',
          'font-size': 'Taille de police',
          'font-weight': 'Épaisseur de police',
          height: 'Hauteur',
          'justify-content': 'Justifier',
          left: 'Gauche',
          'letter-spacing': 'Espacement des lettres',
          'line-height': 'Hauteur de ligne',
          margin: 'Marge',
          'font-style': 'Style de police',
          'max-width': 'Largeur maximale',
          'min-height': 'Hauteur minimale',
          opacity: 'Opacité',
          order: 'Ordre',
          padding: 'Remplissage',
          position: 'Position',
          right: 'Droite',
          'text-align': 'Alignement texte',
          'text-shadow': 'Ombre texte',
          top: 'Haut',
          transform: 'Transformation',
          transition: 'Transition',
          width: 'Largeur',
          'text-decoration': 'Décoration du texte',
          'vertical-align': 'Alignement vertical'
        },
        options: {}
      },
      traitManager: {
        empty: 'Choisir un élément pour pouvoir modifier ses paramètres',
        label: 'Paramètres du composant',
        traits: {
          attributes: {
            alt: {
              name: 'alt',
              placeholder: 'Écrivez votre texte ici'
            },
            href: { placeholder: 'Exemple:  https://google.com' },
            id: { placeholder: 'Écrivez votre texte ici' },
            title: { placeholder: 'Écrivez votre texte ici' }
          },
          labels: {
            title: 'Titre',
            id: 'ID',
            href: 'Lien vers',
            target: 'Cible',
            provider: 'Fournisseur',
            autoplay: 'Lecture automatique',
            loop: 'Lecture en boucle',
            controls: 'Contrôles',
            address: 'Adresse',
            mapType: 'Type de carte'
          },
          options: {
            target: {
              false: 'Cette fenêtre',
              _blank: 'Nouvelle fenêtre'
            }
          }
        }
      }
    }
  });

  replaceBlockLabels();
  blockManager.render();
};
