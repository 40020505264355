export const permissionVerification = (userPermissions, path) => {
  const basePath = '/permission/RH/GroupeVbrRh';

  const isUserAllowed = (routeObject) => {
    if (path === '/' || path === '/dashboard' || path === '') {
      return true;
    }

    const fullPath = `${basePath}${path}`.replaceAll('-', '_');
    const pathSegments = fullPath
      .split('/')
      .filter((segment) => segment !== '');

    const checkPath = (currentRoute, segments) => {
      if (!currentRoute) {
        return false;
      }

      if (segments.length === 0) {
        if (currentRoute?.visualisation === true) {
          return true;
        }
        if (
          Object.values(currentRoute).some(
            (child) => child?.visualisation === true
          )
        ) {
          return true;
        }
        return false;
      }

      const [currentSegment, ...remainingSegments] = segments;

      if (remainingSegments[0] === 'create') {
        remainingSegments[0] = 'modification';
      }
      if (remainingSegments[0] === 'edit') {
        remainingSegments[0] = 'modification';
      }
      if (remainingSegments[0] === 'show') {
        remainingSegments[0] = 'visualisation';
      }

      const nextRoute = currentRoute[currentSegment];

      if (nextRoute === true) {
        return true;
      }
      if (nextRoute) {
        return checkPath(nextRoute, remainingSegments);
      }
      return false;
    };

    const hasVisualizationChild = checkPath(routeObject, pathSegments);

    return hasVisualizationChild;
  };

  return isUserAllowed(userPermissions);
};
