import React, { useCallback, useEffect, useState } from 'react';
import {
  DatePicker,
  Input,
  Select,
  Tag,
  Switch,
  InputNumber,
  Form
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import { offerStatus } from '../../../utils/constants/tagColors';

const { Option } = Select;
const { TextArea } = Input;

const useFields = ({ setIsLogo, isLogo }) => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [enums, setEnums] = useState({});
  const [positions, setPositions] = useState([]);
  const [premises, setPremises] = useState([]);
  const [premise] = useState({});
  const [position, setPosition] = useState({});

  const handleChangesposition = (value) => {
    const selectedPosition = positions.find((p) => p._id === value);
    setPosition(selectedPosition);
  };

  const fields = [
    {
      name: ['position'],
      rules: [{ required: true }],
      required: true,
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          loading={isFieldsLoading}
          onChange={handleChangesposition}
        >
          {(positions || [])
            .filter((p) => p.show)
            .map((p) => (
              <Option label={p.label} key={p._id} value={p._id}>
                {p.label}
              </Option>
            ))}
        </Select>
      )
    },
    {
      name: ['premise'],
      rules: [{ required: true }],
      required: true,
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          loading={isFieldsLoading}
        >
          {(premises || [])
            .filter((p) => p.show)
            .map((p) => (
              <Option label={p.label} key={p._id} value={p._id}>
                {p.label}
              </Option>
            ))}
        </Select>
      )
    },
    {
      name: ['end_of_publication_date'],
      rules: [{ required: true }],
      input: <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
    },
    {
      name: ['name'],
      rules: [{ required: true }]
    },
    {
      name: ['premise_adress'],
      rules: [{ required: true }]
    },
    {
      name: ['expected_start_date'],
      rules: [{ required: false }],
      input: <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
    },
    {
      name: ['status'],
      required: true,
      input: (
        <Select loading={isFieldsLoading}>
          {(enums.offerCategories || []).map((cat) => (
            <Option key={cat} value={cat}>
              <Tag color={offerStatus[cat]}>{t(`offers.tags.${cat}`)}</Tag>
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['logo_usage'],
      input: <Switch defaultChecked onChange={() => setIsLogo(!isLogo)} />
    },
    {
      label: 'description',
      name: ['description'],
      input: <TextArea rows="6" />
    },
    {
      label: 'missions',
      name: ['missions'],
      input: <TextArea value={position?.missions || ''} rows="6" />
    },
    {
      label: 'required_profile',
      name: ['required_profile'],
      input: <TextArea value={position?.required_profile || ''} rows="6" />
    },
    {
      label: 'required_experience',
      name: ['required_experience'],
      input: <TextArea value={position?.required_experience || ''} rows="6" />
    },
    {
      label: 'advantages',
      name: ['advantages'],
      input: <TextArea value={position?.advantages || ''} rows="6" />
    },
    {
      label: 'contract_type',
      name: ['contract_type'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {(enums.contractCategories || []).map((cat) => (
            <Option key={cat} value={cat}>
              <Tag color={offerStatus[cat]}>{t(`offers.tags.${cat}`)}</Tag>
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['work_time'],
      rules: [{ required: true }],
      input: (
        <Input.Group compact>
          <Form.Item noStyle name={['work_time', 'number']}>
            <InputNumber style={{ width: 'calc(100% - 110px)' }} />
          </Form.Item>
          <Form.Item
            noStyle
            name={['work_time', 'time_period']}
            initialValue="WEEK"
          >
            <Select style={{ width: 110 }}>
              <Option value="WEEK">{t(`offers.form.work_times.week`)}</Option>
              <Option value="MONTH">{t(`offers.form.work_times.month`)}</Option>
            </Select>
          </Form.Item>
        </Input.Group>
      )
    },
    {
      name: ['salary'],
      rules: [{ required: true }],
      input: <InputNumber style={{ width: '100%' }} addonAfter="€" />
    },
    {
      name: ['salary_period'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {(enums.periodCategories || []).map((cat) => (
            <Option key={cat} value={cat}>
              <Tag color={offerStatus[cat]}>{t(`offers.tags.${cat}`)}</Tag>
            </Option>
          ))}
        </Select>
      )
    }

    // {
    //  name: ['bonus'],
    // input: <InputNumber style={{ width: '100%' }} addonAfter="€" />
    // }
  ];

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/offers/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getPositions = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/positions' });
      setPositions(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getPremises = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/premise' });
      setPremises(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    await getPositions();
    await getPremises();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    fields,
    isFieldsLoading,
    premise,
    position
  };
};

export default useFields;
