import React from 'react';
import { Tag } from 'antd';
import { userRoles } from '../../utils/constants/tagColors';
import { getFormattedPhoneNumber } from '../../utils/phoneNumber';

const userList = (data, t) => {
  const {
    first_name,
    last_name,
    role,
    email,
    phone_number,
    position,
    permissions
  } = data;

  return [
    {
      label: 'users.form.role',
      span: 3,
      content:
        (role && (
          <Tag color={userRoles[role.split(':')[1]]}>
            {t(`users.tags.${role.split(':')[1]}`)}
          </Tag>
        )) ||
        '-'
    },
    {
      label: 'users.form.permissions',
      span: 1,
      content: permissions?.label || '-'
    },
    {
      label: 'users.form.last_name',
      span: 1,
      content: last_name || '-'
    },
    {
      label: 'users.form.first_name',
      span: 2,
      content: first_name || '-'
    },
    {
      label: 'users.form.position',
      content: position?.label || '-'
    },
    {
      label: 'users.form.email',
      span: 1,
      content: email ? <a href={`mailto:${email}`}>{email}</a> : '-'
    },
    {
      label: 'users.form.phone_number_full',
      span: 2,
      content:
        phone_number && phone_number.number
          ? getFormattedPhoneNumber(
              phone_number.country_code,
              phone_number.number
            )
          : '-'
    }
  ];
};

export default userList;
