import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Collapse, Row, Col, Typography, Button, Dropdown, Badge } from 'antd';
import {
  DashOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import { menu } from './utils/menu';
import { getLastTrainingDate } from './utils/getLastTrainingDate';

const { Panel } = Collapse;

const ParcourTreeCard = ({
  trainingprogress,
  setRefresh,
  refresh,
  setLastTrainingDate
}) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const [trainingTrees, setTrainingTrees] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getTrainingProgress = async () => {
    setIsLoading(true);
    const trainingTreeData = [];
    try {
      const profileTree = trainingprogress?.profiletree;
      if (profileTree) {
        await Promise.all(
          profileTree.children.map(async (trainingtree) => {
            const { data } = await dispatchAPI('GET', {
              url: `/trainingtree/${trainingtree.title}`
            });
            trainingTreeData.push(data);
          })
        );
        setTrainingTrees(trainingTreeData);
        if (setLastTrainingDate) {
          getLastTrainingDate({
            trainingTreeData,
            trainingprogress,
            setLastTrainingDate
          });
        }
      }
    } catch (error) {
      message(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      await getTrainingProgress();
    })();
  }, [refresh, trainingprogress]);

  return (
    <div style={{ width: '100%' }}>
      {!isLoading && (
        <Collapse>
          {trainingTrees.map((trainingtree, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Panel header={trainingtree?.title} key={index}>
              {trainingtree?.children.map((children, i) => (
                <div>
                  <Row gutter={[16, 16]}>
                    <Col span={4}>
                      {children.employees.find(
                        (emp) =>
                          emp.employee === trainingprogress.employee._id &&
                          emp.is_success === 'SUCCESS'
                      ) && <CheckCircleOutlined style={{ color: 'green' }} />}
                      {children.employees.find(
                        (emp) =>
                          emp.employee === trainingprogress.employee._id &&
                          emp.is_success === 'FAILED'
                      ) && <CloseCircleOutlined style={{ color: 'red' }} />}
                      {!children.employees.find(
                        (emp) => emp.employee === trainingprogress.employee._id
                      ) && <Badge count={i + 1} size="small" color="grey" />}
                    </Col>
                    <Col span={12}>
                      <Typography>{children.title}</Typography>
                    </Col>
                    <Col span={4}>
                      {children.employees.map(
                        (emp) =>
                          emp.employee === trainingprogress.employee._id &&
                          emp.end_date && (
                            <Typography>
                              {moment(emp.end_date).format('DD/MM/YYYY')}
                            </Typography>
                          )
                      )}
                    </Col>
                    <Col span={4}>
                      <Dropdown
                        overlay={menu({
                          trainingtree,
                          children,
                          dispatchAPI,
                          trainingprogress,
                          setRefresh,
                          refresh,
                          message,
                          trainingTrees,
                          t
                        })}
                      >
                        <Button type="link">
                          <DashOutlined style={{ fontSize: 18 }} />
                        </Button>
                      </Dropdown>
                    </Col>
                  </Row>
                </div>
              ))}
            </Panel>
          ))}
        </Collapse>
      )}
    </div>
  );
};

export default ParcourTreeCard;

ParcourTreeCard.propTypes = {
  trainingprogress: PropTypes.shape({
    _id: PropTypes.string,
    employee: PropTypes.shape({ _id: PropTypes.string }),
    profiletree: PropTypes.shape({
      _id: PropTypes.string,
      children: PropTypes.arrayOf({})
    })
  }).isRequired,
  setRefresh: PropTypes.func,
  refresh: PropTypes.bool,
  setLastTrainingDate: PropTypes.func
};

ParcourTreeCard.defaultProps = {
  setRefresh: null,
  refresh: false,
  setLastTrainingDate: null
};
