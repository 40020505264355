/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Divider, Input, Typography, Form, Button, Select } from 'antd';
import { getIncomers } from 'react-flow-renderer';
import WFLInputForm from './Input/WFLInputForm';
import WFLTaskForm from './Task/WFLTaskForm';
import WFLDecisionForm from './Decision/WFLDecisionForm';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import { useAuthContext } from '../../../contexts/AuthContext';

const { Option } = Select;
const { TextArea } = Input;
const { Title } = Typography;

const WFLElementForm = ({
  selectedElement,
  elements,
  setElements,
  closeDrawer,
  workflow
}) => {
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [collections, setCollections] = useState([]);
  const [taskOperations, setTaskOperations] = useState(
    selectedElement.data.operations || []
  );
  const [decisionOperations, setDecisionOperations] = useState(
    selectedElement.data.operations || []
  );

  const [outputs, setOutputs] = useState(selectedElement.data.outputs || {});

  const [form] = Form.useForm();

  const getCollections = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/workflows/collections'
      });
      setCollections(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const useElementData = () => {
    const dataWithoutOutputs = JSON.parse(JSON.stringify(selectedElement.data));

    const newValue = {
      id: selectedElement.id,
      type: selectedElement.type,
      ...dataWithoutOutputs
    };
    return newValue;
  };

  const elementData = useElementData();

  const onFinish = (values) => {
    const newElements = elements;
    const newValues = values;

    const elementType = values.type;
    delete newValues.id;
    delete newValues.type;

    let tempOutputs = {};
    if (elementType === 'input' || elementType === 'decision') {
      const collectionName = newValues.collection;
      if (values?.trigger === 'PROCESS') {
        tempOutputs = outputs;
      } else {
        Object.keys(collections).map((coll, index) => {
          if (coll === collectionName) {
            tempOutputs = {
              _id: { type: 'ObjectId', required: true },
              ...Object.values(collections)[index],
              created_at: { type: 'Date', required: true },
              updated_at: { type: 'Date', required: true }
            };

            // If it's an input with method PATCH, we add beforePatchDocument - which is the document before the PATCH
            if (values?.trigger === 'PATCH') {
              tempOutputs = {
                ...tempOutputs,
                beforePatchDocument: {
                  type: 'Object',
                  required: true
                }
              };
            }
          }
        });
      }
    }

    elements.map((elm, index) => {
      if (elm.id === elementData.id) {
        newElements[index].type = elementType;
        newElements[index].data = {
          ...newElements[index].data,
          ...newValues
        };
        newElements[index].id = elementData.id;
        if (elementType === 'input' && Object.keys(tempOutputs).length > 0) {
          newElements[index].data.outputs = tempOutputs;
        }

        if (elementType === 'default') {
          newElements[index].data.outputs = outputs;
          newElements[index].data.operations = taskOperations;
        }
        if (elementType === 'decision') {
          const incomers = getIncomers(elm, elements);

          const decisionOutputs = incomers.reduce(
            (acc, curr) => ({ ...acc, ...curr.data.outputs }),
            {}
          );

          newElements[index].data.outputs = decisionOutputs;
          newElements[index].data.operations = decisionOperations;
        }

        if (elementType === 'log') {
          const incomers = getIncomers(elm, elements);

          const logOutputs = incomers.reduce(
            (acc, curr) => ({ ...acc, ...curr.data.outputs }),
            {}
          );

          newElements[index].data.outputs = logOutputs;
        }

        newElements[index].data.isConform = elementData.isConform;
      }
    });

    setElements(newElements);
    closeDrawer();
  };

  const onFinishFailed = () => {
    message('field_missing');
  };

  useEffect(() => {
    getCollections();
  }, []);

  return (
    <Form
      form={form}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
      name="basic"
      initialValues={elementData}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Title level={5}>Informations générales</Title>

      <Form.Item hidden name="id" rules={[{ required: true }]}>
        <Input />
      </Form.Item>

      <Form.Item label="Nom" name="label" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      {elementData.type !== 'smoothstep' && elementData.type !== 'log' && (
        <Form.Item label="Type" name="type" rules={[{ required: true }]}>
          <Select disabled={elementData.type === 'subprocess'}>
            <Option value="input">Début de process</Option>
            <Option value="default">Tâche</Option>
            <Option value="decision">Décision</Option>

            {elementData.type === 'subprocess' && (
              <Option value="subprocess">Sous-Processus</Option>
            )}
            <Option value="output">Fin de process</Option>
          </Select>
        </Form.Item>
      )}
      {elementData.type === 'log' && (
        <Form.Item label="Type" name="type" rules={[{ required: true }]}>
          <Select disabled>
            <Option value="log">Log</Option>
          </Select>
        </Form.Item>
      )}
      <Form.Item label="Description" name="description">
        <TextArea rows={5} />
      </Form.Item>

      <Divider />

      {elementData.type === 'input' && (
        <>
          <WFLInputForm
            title="Trigger"
            collections={collections}
            trigger={Form.useWatch('trigger', form)}
            workflow={workflow}
            setOutputs={setOutputs}
          />
          <Divider />
        </>
      )}

      {elementData.type === 'default' && (
        <>
          <WFLTaskForm
            selectedElement={selectedElement}
            elements={elements}
            operations={taskOperations}
            setOperations={setTaskOperations}
            outputs={outputs}
            setOutputs={setOutputs}
            collections={collections}
          />
          <Divider />
        </>
      )}

      {elementData.type === 'decision' && (
        <>
          <WFLDecisionForm
            selectedElement={selectedElement}
            elements={elements}
            operations={decisionOperations}
            setOperations={setDecisionOperations}
            outputs={outputs}
            setOutputs={setOutputs}
          />
          <Divider />
        </>
      )}

      <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
        <Button type="add" htmlType="submit" block>
          Enregistrer
        </Button>
      </Form.Item>
    </Form>
  );
};

WFLElementForm.propTypes = {
  selectedElement: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    data: PropTypes.shape({
      label: PropTypes.string,
      outputs: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
      operations: PropTypes.shape({
        stepInputs: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
        finalInputs: PropTypes.arrayOf(
          PropTypes.shape({
            key: PropTypes.string,
            outputName: PropTypes.string,
            formula: PropTypes.string,
            outputType: PropTypes.string
          })
        )
      }),
      decisionConditions: PropTypes.arrayOf(PropTypes.shape({}))
    })
  }),
  elements: PropTypes.arrayOf(PropTypes.shape({})),
  setElements: PropTypes.func.isRequired,
  closeDrawer: PropTypes.func.isRequired,
  workflow: PropTypes.shape({}).isRequired
};

WFLElementForm.defaultProps = {
  selectedElement: {},
  elements: []
};

export default WFLElementForm;
