import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Switch, Avatar, Skeleton, Row } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { ListResource } from '../../../components';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useFormatter } from '../../../utils/formatter';
import { permissionVerification } from '../../../utils/permissionVerification';

const ListEmployees = () => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const { formattedData } = useFormatter();

  const [listOfPositions, setListOfPositions] = useState([]);
  const [listOfPremises, setListOfPremises] = useState([]);
  const navigate = useNavigate();
  const { userPermissions } = useAuthContext();
  const location = useLocation();
  const { pathname } = location;
  const params = new URLSearchParams(location.search);
  const active = params.get('active');
  const [isActive, setActive] = useState(active === 'true');
  const [permit, setPermit] = useState(false);

  useEffect(() => {
    const permission = permissionVerification(
      userPermissions,
      '/employee-folder/employees/modification'
    );
    setPermit(permission);
  }, [userPermissions]);

  const onChange = () => {
    setActive(!isActive);
    navigate(`${pathname}?active=${!isActive}`);
  };

  const getListPosition = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/positions`
      });
      setListOfPositions(data);
    } catch (error) {
      message(error);
    }
  };

  const getListPremise = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/premise`
      });
      setListOfPremises(data);
    } catch (error) {
      message(error);
    }
  };

  useEffect(() => {
    (async () => {
      await getListPosition();
      await getListPremise();
    })();
  }, []);

  const headers = [
    {
      label: 'last_name',
      key: 'last_name'
    },
    {
      label: 'first_name',
      key: 'first_name'
    },
    {
      label: 'date_of_birth',
      key: 'date_of_birth'
    },
    {
      label: 'place_of_birth',
      key: 'place_of_birth'
    },
    {
      label: 'nationality',
      key: 'nationality'
    },
    {
      label: 'socialSecurityNumber',
      key: 'socialSecurityNumber'
    },
    {
      label: 'expiration_residence_permit',
      key: 'expiration_residence_permit'
    },
    {
      label: 'phone_number.country_code',
      key: 'phone_number.country_code'
    },
    {
      label: 'phone_number.number',
      key: 'phone_number.number'
    },
    {
      label: 'category',
      key: 'category'
    },
    {
      label: 'level',
      key: 'level'
    },
    {
      label: 'grade',
      key: 'grade'
    },
    {
      label: 'registration_number',
      key: 'registration_number'
    },
    {
      label: 'contract_type',
      key: 'contract_type'
    },
    {
      label: 'working_time',
      key: 'working_time'
    },
    {
      label: 'gross_monthly_salary',
      key: 'gross_monthly_salary'
    },
    {
      label: 'position',
      key: 'position.label',
      header_key: 'position'
    },
    {
      label: 'premise',
      key: 'premise.label',
      header_key: 'premise'
    },
    {
      label: 'start_date',
      key: 'start_date'
    }
  ];

  const columns = [
    {
      key: 'photo',
      dataIndex: 'photo',
      width: 60,
      render: (photo) => (
        <Skeleton title={0} paragraph={0} avatar={{ size: 35 }} active>
          <Avatar size={35} src={photo && photo} />
        </Skeleton>
      )
    },
    {
      title: t('employees.form.last_name'),
      key: 'last_name',
      dataIndex: 'last_name',
      sorter: true
    },
    {
      title: t('employees.form.first_name'),
      key: 'first_name',
      dataIndex: 'first_name',
      sorter: true
    },
    {
      title: t('employees.form.position'),
      key: 'position',
      dataIndex: 'position',
      render: (position) => position?.label,
      sorter: true,
      filters: listOfPositions.map((p) => ({
        text: p.label,
        value: p._id
      }))
    },
    {
      title: t('employees.form.premise'),
      key: 'premise',
      dataIndex: 'premise',
      render: (premise) => premise?.label,
      sorter: true,
      filters: listOfPremises.map((premise) => ({
        text: premise.label,
        value: premise._id
      }))
    },
    {
      title: t('employees.form.start_date'),
      key: 'start_date',
      dataIndex: 'start_date',
      render: (start_date) => moment(start_date).format('DD/MM/YYYY'),
      sorter: true
    },
    isActive && {
      title: t('employees.form.end_date'),
      key: 'end_date',
      dataIndex: 'end_date',
      render: (end_date) => moment(end_date).format('DD/MM/YYYY'),
      sorter: true
    },
    isActive && {
      title: t('employees.form.motif'),
      key: 'motif',
      dataIndex: 'motif',
      render: (motif, record) => !record.active && motif,
      sorter: true
    }
  ].filter(Boolean);

  return (
    <ListResource
      resourceName="employees"
      populate="premise,position"
      columns={columns}
      headers={headers}
      deleteAction={permit}
      editAction={permit}
      withCreateButton={permit}
      formatter={formattedData}
      templateType="employees"
      resourceModelName="Employee"
      extraButtons={
        <Row align="middle" className="list-switch-wrapper">
          <Switch checked={isActive} onChange={onChange} />
          <p>{t('employees.archived')}</p>
        </Row>
      }
      extraQuery={!isActive ? 'active=true' : 'active=false'}
    />
  );
};

export default ListEmployees;
