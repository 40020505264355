import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  Col,
  Divider,
  Input,
  Popconfirm,
  Row,
  Tag,
  Switch
} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  PlusOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Datatable } from '../../../../components/DataTable/Datatable';
import EditDrawer from './EditDrawer';
import { useApplicationsContext } from '../../../../contexts/ApplicationsContext';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { permissionVerification } from '../../../../utils/permissionVerification';

const { Search } = Input;
const iconSize = 18;

const ListApplications = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { userPermissions } = useAuthContext();
  const params = new URLSearchParams(location.search);
  const archived = params.get('archived');
  const { pathname } = useLocation();
  const [isArchived, setIsArchived] = useState(archived === 'true');
  const [permit, setPermit] = useState(false);

  useEffect(() => {
    const permission = permissionVerification(
      userPermissions,
      '/recruitments/in-progress/modification'
    );
    setPermit(permission);
  }, [userPermissions]);

  const {
    setVisible,
    searchValue,
    kanbanCols,
    dataRefresh,
    delApplication,
    searchResource,
    fetchApplicationById
  } = useApplicationsContext();

  const onChange = () => {
    setIsArchived(!isArchived);
    navigate(`${pathname}?archived=${!isArchived}`);
  };

  const columns = [
    {
      title: t('applications.form.candidate.first_name'),
      key: 'first_name',
      dataIndex: ['candidate', 'first_name'],
      render: (first_name) => first_name,
      sorter: (a, b) =>
        a.candidate.first_name.localeCompare(b.candidate.first_name)
    },
    {
      title: t('applications.form.candidate.last_name'),
      key: 'last_name',
      dataIndex: ['candidate', 'last_name'],
      render: (last_name) => last_name,
      sorter: (a, b) =>
        a.candidate.last_name.localeCompare(b.candidate.last_name)
    },
    {
      title: t('applications.form.created_at'),
      key: 'created_at',
      dataIndex: 'created_at',
      render: (created_at) => moment(created_at).format('ll'),
      sorter: true
    },
    {
      title: t('applications.form.premise'),
      key: 'offer',
      dataIndex: 'offer',
      render: (offer) => (offer ? `${offer.premise.label}` : 'N/A'),
      sorter: true
    },
    {
      title: t('applications.form.offer'),
      key: 'offer',
      dataIndex: 'offer',
      render: (offer) => (offer ? `${offer.name}` : 'N/A'),
      sorter: true
    },
    {
      title: t('applications.form.status'),
      key: 'status',
      dataIndex: 'status',
      render: (status) =>
        status && <Tag color={status.color}>{status.label}</Tag>,
      sorter: true,
      filters: kanbanCols.map((k) => ({ text: k.label, value: k._id }))
    },
    {
      key: 'action',
      fixed: 'right',
      width: '10%',
      render: (record) => (
        <span>
          <Link
            to={{
              pathname: `${pathname}/show/${record._id}`
            }}
          >
            <EyeOutlined style={{ fontSize: iconSize }} />
          </Link>
          <Divider type="vertical" />
          <Button
            type="link"
            onClick={async () => {
              await fetchApplicationById(record._id);
              setVisible(true);
            }}
            style={{ padding: '4px 0' }}
          >
            {permit && <EditOutlined style={{ fontSize: iconSize }} />}
          </Button>
          {permit && <Divider type="vertical" />}
          <Popconfirm
            title={t('datatable.column.action.delete.title')}
            okText={t('datatable.column.action.delete.ok')}
            cancelText={t('datatable.column.action.delete.cancel')}
            onConfirm={() => delApplication(record._id)}
            icon={<WarningOutlined />}
          >
            {permit && (
              <DeleteOutlined
                style={{ color: 'var(--errorColor)', fontSize: iconSize }}
                type="delete"
              />
            )}
          </Popconfirm>
        </span>
      )
    }
  ];

  return (
    <>
      <Row
        justify="space-between"
        gutter={[8, 16]}
        style={{ marginBottom: 16 }}
      >
        <Col>
          <Search
            allowClear
            placeholder={t('placeholder.search')}
            defaultValue={searchValue}
            onSearch={(value) => searchResource(value)}
          />
        </Col>
        <Row align="middle" className="list-switch-wrapper">
          <Switch checked={isArchived} onChange={onChange} />
          <p>{t('applications.archived')}</p>
        </Row>
        <Col>
          <Button
            type="add"
            onClick={() => setVisible(true)}
            disabled={!permit}
          >
            {t('buttons.create')}
            <PlusOutlined />
          </Button>
        </Col>
      </Row>
      <Datatable
        resourceName="applications"
        columns={columns}
        searchValue={searchValue}
        forceRefresh={dataRefresh}
        populate="candidate,offer,offer.premise,status"
        customActionColumn
        extraQuery={isArchived ? 'is_archived=true' : 'is_archived=false'}
      />
      <EditDrawer />
    </>
  );
};

export default ListApplications;
