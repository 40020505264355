const delActivity = async ({
  values,
  setListActivity,
  dispatchAPI,
  application,
  successMessage,
  errorMessage,
  setFormActivity,
  t
}) => {
  setListActivity(false);
  try {
    await dispatchAPI('PATCH', {
      url: `/applications/${application._id}`,
      body: {
        activities: application.activities.filter(
          (activity) => activity._id !== values._id
        )
      }
    });

    if (values.interview_id) {
      await dispatchAPI('DELETE', {
        url: `/interviews/${values.interview_id}?delInApplication=true`
      });
    }
    successMessage(t('applications.show.del_activity'));
  } catch (e) {
    if (e.response) errorMessage(e.response.status);
  }
  setListActivity(true);
  setFormActivity(false);
};

export default delActivity;
