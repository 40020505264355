import { calculateCompletionPercentage } from './calculateCompletionPercentage';

export const setCompletedTraining = async ({
  trainingtree,
  children,
  type,
  dispatchAPI,
  trainingprogress,
  setRefresh,
  refresh,
  message,
  trainingTrees
}) => {
  try {
    const { data } = await dispatchAPI('PATCH', {
      url: `/trainingtree/complete-training/${trainingtree._id}`,
      body: {
        employeeId: trainingprogress.employee,
        childrenId: children._id,
        type,
        title: children.title
      }
    });

    const index = trainingTrees.findIndex((obj) => obj._id === data._id);
    // eslint-disable-next-line no-param-reassign
    trainingTrees[index] = data;

    if (data) {
      await dispatchAPI('PATCH', {
        url: `/trainingprogress/${trainingprogress._id}`,
        body: {
          progress: calculateCompletionPercentage({
            trainingTrees,
            employeeId: trainingprogress.employee._id
          })
        }
      });
    }

    setRefresh(!refresh);
  } catch (e) {
    message(e);
  }
};
