import React, { useEffect, useState } from 'react';
import { Layout, Collapse, Spin, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import CreateTemplateEmail from './CreateTemplateEmail';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';

const { Content } = Layout;
const { Panel } = Collapse;

const ListTypeEmail = () => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [emails, setEmails] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const getEmails = async () => {
    try {
      setIsLoading(true);
      const { data } = await dispatchAPI('GET', { url: '/emails' });
      setEmails(data);
      setIsLoading(false);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getEmails();
    })();
  }, []);
  return (
    <Content
      className="site-layout-background"
      style={{
        marginTop: 2,
        padding: 24,
        backgroundColor: '#fff'
      }}
    >
      <Spin spinning={isLoading}>
        <Collapse>
          {emails &&
            emails.map((email, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Panel header={t(`settings.email.${email.type}`)} key={index}>
                <CreateTemplateEmail type={email.type} id={email._id} />

                <Button
                  type="dashed"
                  block
                  style={{ margin: 8, width: 'calc(100% - 16px)' }}
                >
                  {t('buttons.add')}
                </Button>
              </Panel>
            ))}
        </Collapse>
      </Spin>
    </Content>
  );
};

export default ListTypeEmail;
