import React, { useState, useEffect } from 'react';
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  WarningOutlined
} from '@ant-design/icons';
import moment from 'moment';
import { Divider, Popconfirm, Tag } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { permissionVerification } from '../../../utils/permissionVerification';
import { useAuthContext } from '../../../contexts/AuthContext';

export const useColumns = ({ entriesStatus, pathname, deleteResource }) => {
  const { t } = useTranslation();
  const { userPermissions } = useAuthContext();
  const [permit, setPermit] = useState(false);

  useEffect(() => {
    const permission = permissionVerification(
      userPermissions,
      '/recruitments/entries/modification'
    );
    setPermit(permission);
  }, [userPermissions]);

  const columns = [
    {
      title: t('entries.form.last_name'),
      key: 'last_name',
      dataIndex: ['employee', 'last_name'],
      sorter: (a, b) => a.employee.last_name.localeCompare(b.employee.last_name)
    },
    {
      title: t('entries.form.first_name'),
      key: 'first_name',
      dataIndex: ['employee', 'first_name'],
      sorter: (a, b) =>
        a.employee.first_name.localeCompare(b.employee.first_name)
    },
    {
      title: t('entries.form.position'),
      key: 'position.label',
      dataIndex: ['employee', 'position', 'label'],
      sorter: (a, b) =>
        a.employee.position.label.localeCompare(b.employee.position.label)
    },
    {
      title: t('entries.form.premise'),
      key: 'premise.label',
      dataIndex: ['employee', 'premise', 'label'],
      sorter: (a, b) =>
        a.employee.premise.label.localeCompare(b.employee.premise.label)
    },
    {
      title: t('entries.form.start_date'),
      key: 'start_date',
      dataIndex: ['employee', 'start_date'],
      render: (date) => (date ? moment(date).format('DD/MM/YYYY') : '-'),
      sorter: (a, b) =>
        a.employee.start_date.localeCompare(b.employee.start_date)
    },
    {
      title: t('entries.form.status'),
      key: 'status',
      dataIndex: 'status',
      render: (status) => (
        <Tag color={entriesStatus[status]}>{t(`entries.tags.${status}`)}</Tag>
      ),
      sorter: true,
      filters: Object.keys(entriesStatus).map((r) => ({
        text: t(`positions.tags.${r}`),
        value: r
      }))
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      width: 120,
      render: (record) => (
        <>
          <Link
            to={{
              pathname: `${pathname}/show/${record._id}`
            }}
          >
            <EyeOutlined style={{ fontSize: 18 }} />
          </Link>
          <Divider type="vertical" />
          <Link
            to={{
              pathname: `${pathname}/edit/${record._id}`
            }}
          >
            {permit && <EditOutlined style={{ fontSize: 18 }} />}
          </Link>
          {permit && <Divider type="vertical" />}
          <Popconfirm
            title={t('datatable.column.action.delete.title')}
            okText={t('datatable.column.action.delete.ok')}
            okButtonProps={{ type: 'danger' }}
            cancelText={t('datatable.column.action.delete.cancel')}
            onConfirm={() => deleteResource(record?._id, record?.employee._id)}
            icon={<WarningOutlined />}
          >
            {permit && (
              <DeleteOutlined style={{ fontSize: 18 }} type="delete" />
            )}
          </Popconfirm>
        </>
      )
    }
  ];

  return columns;
};
