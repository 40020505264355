import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ListTraining } from './ListTraining';
import CreateUpdateTraining from './ParcoursTree/CreateUpdateTraining';
import CreateUpdateProfile from './ProfileTree/CreateUpdateProfile';
import ShowTrainingPath from './ParcoursTree/ShowParcoursTree';

export const TrainingPathRouter = () => (
  <Routes>
    <Route
      path="/parcourstree/create"
      element={<CreateUpdateTraining purpose="create" />}
    />
    <Route
      path="/parcourstree/edit/:id"
      element={<CreateUpdateTraining purpose="edit" />}
    />
    <Route path="/parcourstree/show/:id" element={<ShowTrainingPath />} />
    <Route
      path="/profiletree/create"
      element={<CreateUpdateProfile purpose="create" />}
    />
    <Route
      path="/profiletree/edit/:id"
      element={<CreateUpdateProfile purpose="edit" />}
    />
    <Route path="/profiletree/show/:id" element={<ShowTrainingPath />} />
    <Route index element={<ListTraining />} />
  </Routes>
);
