export const calculateCompletionPercentage = ({
  trainingTrees,
  employeeId
}) => {
  let completedCount = 0;
  let totalCount = 0;

  trainingTrees.forEach((obj) => {
    obj.children.forEach((child) => {
      totalCount += 1;
      child.employees.forEach((emp) => {
        if (emp.employee === employeeId) {
          if (emp.is_completed) {
            completedCount += 1;
          }
        }
      });
    });
  });

  const percentage =
    totalCount > 0 ? Math.round((completedCount / totalCount) * 100) : 0;
  return percentage;
};
