import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import useFields from './fields';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainer/CreateUpdateContainer';

const CreateUpdateDocument = ({ purpose }) => {
  const [premises, setPremises] = useState([]);
  const [positions, setPositions] = useState([]);
  const [file, setFile] = useState(null);
  const [show, setShow] = useState(true);
  const { createFields, editFields } = useFields({
    premises,
    positions,
    setFile,
    setShow,
    show
  });
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const getPremises = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/premise' });

      const options = [];
      data.map((premise) =>
        options.push({ value: premise._id, label: premise.label })
      );
      setPremises(options);
    } catch (e) {
      message(e);
    }
  };

  const getPositions = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/position' });

      const options = [];
      data.map((position) =>
        options.push({ value: position._id, label: position.label })
      );
      setPositions(options);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getPremises();
      await getPositions();
    })();
  }, []);

  const setFieldsData = (data, fileId) => {
    const fileData = data.files.filter((f) => f._id === fileId);

    const fieldsData = {
      premise: data.label,
      ...fileData[0]
    };
    return fieldsData;
  };

  const config = {
    onCreateResource: {
      setBody: (data) => ({
        $push: {
          files: {
            _id: file._id,
            type: data.type,
            filename: data.filename,
            visibility: data.visibility,
            last_update: new Date(),
            show
          }
        }
      })
    },
    onGetResource: {
      setFields: (data, fileId) => setFieldsData(data, fileId)
    }
  };

  return (
    <CreateUpdateContainer
      fields={purpose === 'edit' ? editFields : createFields}
      baseUrl="premise"
      resource="documents"
      config={config}
      purpose={purpose}
      isPremise
    />
  );
};

export default CreateUpdateDocument;

CreateUpdateDocument.propTypes = {
  purpose: PropTypes.string.isRequired
};
