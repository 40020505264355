import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Popconfirm } from 'antd';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { permissionVerification } from '../../../../utils/permissionVerification';
import { useAuthContext } from '../../../../contexts/AuthContext';

const TrainingRow = ({ title, id, showAction = true, deleteTraining }) => {
  const { t } = useTranslation();
  const { userPermissions } = useAuthContext();
  const [permit, setPermit] = useState(false);

  useEffect(() => {
    const permission = permissionVerification(
      userPermissions,
      '/trainings/trainings-path/parcourstree/modification'
    );
    setPermit(permission);
  }, [userPermissions]);

  return (
    <Row align="middle" className="training-tree-row">
      <Col span={18}>{title}</Col>
      <Col span={6}>
        {showAction && (
          <Row justify="end" align="middle">
            <Button type="link">
              <Link to={`/trainings/trainings-path/parcourstree/show/${id}`}>
                <EyeOutlined />
              </Link>
            </Button>
            <Button type="link">
              <Link to={`/trainings/trainings-path/parcourstree/edit/${id}`}>
                {permit && <EditOutlined />}
              </Link>
            </Button>
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={() => deleteTraining(id)}
              icon={<WarningOutlined />}
            >
              <Button type="link" danger>
                {permit && <DeleteOutlined />}
              </Button>
            </Popconfirm>
          </Row>
        )}
      </Col>
    </Row>
  );
};

TrainingRow.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  showAction: PropTypes.bool,
  deleteTraining: PropTypes.func.isRequired
};

TrainingRow.defaultProps = {
  showAction: true
};

export default TrainingRow;
