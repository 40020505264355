import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Row, Alert } from 'antd';
import { useTranslation } from 'react-i18next';

const AlertCard = ({ employees }) => {
  const { t } = useTranslation();
  const today = moment();

  const alerts = employees.map((employee) => {
    if (today.isSame(moment(employee.start_date), 'day')) {
      return (
        <Alert
          style={{ width: '100%' }}
          key={`start_date_${employee.id}`}
          message={`${t('dashboard.alert.start_date')}${employee.last_name} ${
            employee.first_name
          }`}
          type="warning"
        />
      );
    }
    if (moment(employee.trial_period_renewal).isSame(today, 'day')) {
      return (
        <Alert
          style={{ width: '100%' }}
          key={`trial_period_renewal_${employee.id}`}
          message={`${t('dashboard.alert.trial_period_renewal')}${
            employee.last_name
          } ${employee.first_name}`}
          type="warning"
        />
      );
    }
    if (moment(employee.trial_period_end).isSame(today, 'day')) {
      return (
        <Alert
          style={{ width: '100%' }}
          key={`trial_period_end_${employee.id}`}
          message={`${t('dashboard.alert.trial_period_end')}${
            employee.last_name
          } ${employee.first_name}`}
          type="warning"
        />
      );
    }

    return null;
  });

  return <Row gutter={[8, 8]}>{alerts}</Row>;
};

export default AlertCard;

AlertCard.propTypes = {
  employees: PropTypes.arrayOf(
    PropTypes.shape({
      start_date: PropTypes.string,
      trial_period_renewal: PropTypes.string,
      trial_period_end: PropTypes.string
    })
  ).isRequired
};
