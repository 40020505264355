import React, { useState, useEffect } from 'react';
import { Upload } from 'antd';
import { useTranslation } from 'react-i18next';
import { UploadOutlined } from '@ant-design/icons/lib';
import { useErrorMessage } from '../../../../../utils/ErrorMessage';
import { useAuthContext } from '../../../../../contexts/AuthContext';

const { Dragger } = Upload;

const useFields = ({ setOtherAttachments, otherAttachments }) => {
  const { token } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const [tempAttachments, setTempAttachments] = useState([]);
  const [files, setFiles] = useState(null);

  useEffect(() => {
    if (tempAttachments.length > 0) {
      setOtherAttachments((prevAttachments) => [
        ...prevAttachments,
        ...tempAttachments
      ]);
      setTempAttachments([]);
    }
  }, [tempAttachments]);

  const draggerProps = {
    name: 'file',
    action: `${process.env.REACT_APP_API_URL}/files`,
    headers: { Authorization: `Bearer ${token}` },
    beforeUpload: (newFile) => {
      setFiles([newFile]);
    },
    onChange(info) {
      const { status, response } = info.file;

      if (status === 'done') {
        setTempAttachments((prevAttachments) => [...prevAttachments, response]);
      }

      if (status === 'error') {
        message(`${info.file.name} file upload failed.`);
      }
    },

    onRemove: (file) => {
      setFiles(files.filter((f) => f.uid !== file.uid));
      setOtherAttachments(
        otherAttachments.filter((f) => f.metadata.originalName !== file.name)
      );
    }
  };

  const fields = [
    {
      name: ['file'],
      noLabel: true,
      input: (
        <Dragger {...draggerProps}>
          <p className="ant-upload-drag-icon">
            <UploadOutlined style={{ color: 'var(--textColor)' }} />
          </p>
          <p className="ant-upload-text">{t('files.create.action')}</p>
        </Dragger>
      )
    }
  ];

  return {
    fields
  };
};

export default useFields;
