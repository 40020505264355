import React, { useState, useEffect } from 'react';
import RealOrSeriousCauseHeader from './RealOrSeriousCauseHeader';
import FaultWithAHeader from './FaultWithAHeader';
import FaultWithoutAHeader from './FaultWithoutAHeader';

const DismissalHeader = ({
  activeStep,
  nextStep,
  exit,
  setIsModalOpen,
  setRestitutionType,
  restitutionType,
  generateFile,
  isDocLoading,
  setAbsenceReasonType,
  absenceReasonType,
  updateStatus,
  t
}) => {
  const [typeOfDismissal, setTypeOfDismissal] = useState('');

  useEffect(() => {
    setTypeOfDismissal(exit?.type_of_dismissal);
  }, [exit]);

  const setHeader = () => {
    switch (typeOfDismissal) {
      case 'real_or_serious_cause':
        return (
          <RealOrSeriousCauseHeader
            activeStep={activeStep}
            nextStep={nextStep}
            setIsModalOpen={setIsModalOpen}
            setRestitutionType={setRestitutionType}
            restitutionType={restitutionType}
            exit={exit}
            generateFile={generateFile}
            isDocLoading={isDocLoading}
            t={t}
            updateStatus={updateStatus}
          />
        );
      case 'serious_fault_with_post_abandonment':
        return (
          <FaultWithAHeader
            activeStep={activeStep}
            nextStep={nextStep}
            setIsModalOpen={setIsModalOpen}
            setRestitutionType={setRestitutionType}
            restitutionType={restitutionType}
            exit={exit}
            generateFile={generateFile}
            isDocLoading={isDocLoading}
            setAbsenceReasonType={setAbsenceReasonType}
            absenceReasonType={absenceReasonType}
            t={t}
            updateStatus={updateStatus}
          />
        );
      case 'serious_fault_without_post_abandonment':
        return (
          <FaultWithoutAHeader
            activeStep={activeStep}
            nextStep={nextStep}
            setIsModalOpen={setIsModalOpen}
            setRestitutionType={setRestitutionType}
            restitutionType={restitutionType}
            exit={exit}
            generateFile={generateFile}
            isDocLoading={isDocLoading}
            t={t}
            updateStatus={updateStatus}
          />
        );
      default:
        return null;
    }
  };

  return setHeader();
};

export default DismissalHeader;
