import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Switch, Tag, Row } from 'antd';
import { ListResource } from '../../../components';
import { useFormatter } from '../../../utils/formatter';
import { permissionVerification } from '../../../utils/permissionVerification';
import { useAuthContext } from '../../../contexts/AuthContext';

const ListRecommandations = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const params = new URLSearchParams(location.search);
  const { userPermissions } = useAuthContext();
  const archived = params.get('archived');
  const { formattedData } = useFormatter();
  const [isArchived, setIsArchived] = useState(archived === 'true');
  const [permit, setPermit] = useState(false);

  useEffect(() => {
    const permission = permissionVerification(
      userPermissions,
      '/recruitments/recommendations/modification'
    );
    setPermit(permission);
  }, [userPermissions]);

  const onChange = () => {
    setIsArchived(!isArchived);
    navigate(`${pathname}?archived=${!isArchived}`);
  };

  const headers = [
    {
      label: 'employee',
      extra_label: 'employee_last_name',
      key: 'employee.last_name'
    },
    {
      label: 'employee',
      extra_label: 'employee_first_name',
      key: 'employee.first_name'
    },
    {
      label: 'candidate',
      extra_label: 'candidate_last_name',
      key: 'candidate.last_name'
    },
    {
      label: 'candidate',
      extra_label: 'candidate_first_name',
      key: 'candidate.first_name'
    },
    {
      label: 'how_known_candidate',
      key: 'how_known_candidate'
    },
    {
      label: 'why_recommanded',
      key: 'why_recommanded'
    }
  ];

  const columns = [
    {
      title: t('recommandation.form.candidate'),
      key: 'candidate',
      dataIndex: 'candidate',
      render: (candidate) => `${candidate?.first_name} ${candidate?.last_name}`,
      sorter: true
    },
    {
      title: t('recommandation.form.employee'),
      key: 'employee',
      dataIndex: 'employee',
      render: (employee) => `${employee?.first_name} ${employee?.last_name}`,
      sorter: true
    },
    {
      title: t('recommandation.form.application-status'),
      key: 'application',
      dataIndex: 'application',
      render: (application) => (
        <Tag color={application?.status?.color}>
          {application?.status?.label}
        </Tag>
      ),
      sorter: true
    },
    {
      title: t('recommandation.form.created_at'),
      dataIndex: 'created_at',
      render: (created_at) => moment(created_at).format('ll'),
      sorter: true
    }
  ];

  return (
    <ListResource
      resourceName="recommandations"
      populate="employee,candidate,application,application.status offer"
      columns={columns}
      headers={headers}
      deleteAction={permit}
      editAction={permit}
      withCreateButton={permit}
      formatter={formattedData}
      templateType="recommendations"
      resourceModelName="Recommandation"
      extraButtons={
        <Row align="middle" className="list-switch-wrapper">
          <Switch checked={isArchived} onChange={onChange} />
          <p>{t('recommandations.archived')}</p>
        </Row>
      }
      extraQuery={isArchived ? 'is_archived=true' : 'is_archived=false'}
    />
  );
};
export default ListRecommandations;
