import React from 'react';
import {
  DownloadOutlined,
  WarningOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import { Divider, Button, Popconfirm, message } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDownloadDocument } from '../../../../../utils/downloadDoc';
import { useAuthContext } from '../../../../../contexts/AuthContext';

export const setColumns = ({ data }) => {
  const { t } = useTranslation();
  const { downloadDocument } = useDownloadDocument();
  const { dispatchAPI } = useAuthContext();

  const iconSize = 18;

  const employeeId = data._id;

  const deleteFile = async (id) => {
    try {
      await dispatchAPI(`DELETE`, { url: `files/${id}` });
      await dispatchAPI('PATCH', {
        url: `employees/${employeeId}`,
        body: { $pull: { files: { _id: id } } }
      });
      window.location.reload();
    } catch (error) {
      message(error);
    }
  };

  const columns = [
    {
      title: t('employees.form.documents.name'),
      key: '_id',
      dataIndex: '_id',
      render: (id) => id?.metadata?.originalName
    },
    {
      title: t('employees.form.documents.date_of_upload'),
      key: '_id',
      dataIndex: '_id',
      render: (id) => moment(id?.metadata?.uploadDate).format('DD / MM / YYYY')
    },
    {
      key: 'action',
      align: 'right',
      render: (record) => (
        <>
          <Button
            onClick={() =>
              downloadDocument({
                _id: record._id._id,
                metadata: record._id.metadata,
                contentType: record._id.contentType
              })
            }
            type="link"
          >
            <DownloadOutlined style={{ fontSize: iconSize }} />
          </Button>
          <Divider type="vertical" />
          <Popconfirm
            title={t('employees.documents.delete.title')}
            okText={t('employees.documents.delete.ok')}
            okButtonProps={{ type: 'danger' }}
            cancelText={t('employees.documents.delete.cancel')}
            onConfirm={() => deleteFile(record._id._id)}
            icon={<WarningOutlined />}
          >
            <DeleteOutlined
              style={{ color: '#b51010', fontSize: iconSize }}
              type="delete"
            />
          </Popconfirm>
        </>
      )
    }
  ];
  return columns;
};
