import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col, Typography, Divider } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import moment from 'moment';
import PropTypes from 'prop-types';
import { routes, subRoutes } from '../../../../utils/constants/adminRoutes';
import { Datatable } from '../../../../components/DataTable/Datatable';

const { Title } = Typography;

const Recommandations = ({ application }) => {
  const { t } = useTranslation();

  const columns = [
    {
      title: t('recommandations.form.candidate.title'),
      key: 'candidate',
      dataIndex: 'candidate',
      render: (candidate) => `${candidate?.first_name} ${candidate?.last_name}`,
      sorter: true
    },
    {
      title: t('recommandations.form.employee'),
      key: 'employee',
      dataIndex: 'employee',
      render: (employee) => `${employee?.first_name} ${employee?.last_name}`,
      sorter: true
    },
    {
      title: t('recommandations.form.created_at'),
      dataIndex: 'created_at',
      render: (created_at) => moment(created_at).format('ll'),
      sorter: true
    },
    {
      key: 'action',
      fixed: 'right',
      width: '10%',
      dataIndex: '_id',
      render: (id) => (
        <span>
          <Link
            to={{
              pathname: `${routes.RECRUITMENTS}${subRoutes.RECRUITMENTS.RECOMMANDATIONS}/show/${id}`
            }}
          >
            <EyeOutlined style={{ fontSize: 18 }} />
          </Link>
        </span>
      )
    }
  ];

  return (
    <>
      <Divider orientation="left" plain>
        <Title level={3}>{t('applications.show.recommendations')}</Title>
      </Divider>

      <Col span={24}>
        {application && (
          <Datatable
            resourceName="recommandations"
            columns={columns}
            populate="employee,candidate"
            customActionColumn
            extraQuery={`offer=${application?.offer?._id}&is_archived=false`}
          />
        )}
      </Col>
    </>
  );
};

export default Recommandations;

Recommandations.propTypes = {
  application: PropTypes.shape({
    offer: PropTypes.shape({ _id: PropTypes.string })
  })
};

Recommandations.defaultProps = {
  application: {}
};
