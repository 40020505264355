import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Tag, Row, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ListResource } from '../../../components';
import { bonusStatus } from '../../../utils/constants/tagColors';
import { useFormatter } from '../../../utils/formatter';
import { useAuthContext } from '../../../contexts/AuthContext';
import { permissionVerification } from '../../../utils/permissionVerification';

const ListBonus = () => {
  const { t } = useTranslation();
  const { formattedData } = useFormatter();
  const navigate = useNavigate();
  const { userPermissions } = useAuthContext();
  const location = useLocation();
  const { pathname } = location;
  const params = new URLSearchParams(location.search);
  const archived = params.get('archived');
  const [isArchived, setIsArchived] = useState(archived === 'true');
  const [permit, setPermit] = useState(false);

  useEffect(() => {
    const permission = permissionVerification(
      userPermissions,
      '/employee-folder/bonus/modification'
    );
    setPermit(permission);
  }, [userPermissions]);

  const onChange = () => {
    setIsArchived(!isArchived);
    navigate(`${pathname}?archived=${!isArchived}`);
  };

  const bonusAmount = (share, salary) => {
    if (share && salary) {
      return `${salary * (share / 100)} €`;
    }
    return '-';
  };

  const headers = [
    {
      label: 'employee',
      extra_label: 'employee_last_name',
      key: 'employee.last_name'
    },
    {
      label: 'employee',
      extra_label: 'employee_first_name',
      key: 'employee.first_name'
    },
    {
      label: 'premise',
      key: 'employee.premise.label',
      header_key: 'premise'
    },
    {
      label: 'position',
      key: 'employee.position.label',
      header_key: 'position'
    },
    {
      label: 'status',
      key: 'status'
    },
    {
      label: 'share',
      key: 'share'
    },
    {
      label: 'date',
      key: 'date'
    }
  ];

  const columns = [
    {
      title: t('bonus.form.employee'),
      key: 'name',
      dataIndex: 'employee',
      render: (employee) => `${employee.first_name} ${employee.last_name}`,
      sorter: (a, b) =>
        a.employee.first_name.localeCompare(b.employee.first_name)
    },
    {
      title: t('bonus.form.premise'),
      key: 'premise.label',
      dataIndex: 'employee',
      render: (employee) =>
        employee.premise.label ? `${employee.premise.label}` : '-',
      sorter: (a, b) =>
        a.employee.premise.label.localeCompare(b.employee.premise.label)
    },
    {
      title: t('bonus.form.position'),
      key: 'position.label',
      dataIndex: 'employee',
      render: (employee) =>
        employee.position.label ? `${employee.position.label}` : '-',
      sorter: (a, b) =>
        a.employee.position.label.localeCompare(b.employee.position.label)
    },
    {
      title: t('bonus.form.share'),
      key: 'share',
      dataIndex: 'share',
      render: (share) => `${share} % salaire`,
      sorter: true
    },
    {
      title: t('bonus.form.amount'),
      key: 'employee.gross_monthly_salary',
      dataIndex: 'share',
      render: (share, bonus) =>
        bonusAmount(share, bonus?.employee.gross_monthly_salary),
      sorter: (a, b) =>
        a.share * a.employee.gross_monthly_salary -
        b.share * b.employee.gross_monthly_salary
    },
    {
      title: t('bonus.form.status'),
      key: 'status',
      dataIndex: 'status',
      render: (status) => (
        <Tag color={bonusStatus[status]}>{t(`bonus.tags.${status}`)}</Tag>
      ),
      sorter: true
    },
    {
      title: t('bonus.form.date'),
      key: 'date',
      dataIndex: 'date',
      render: (date) => `${moment(date).format('MM-YYYY')}`,
      sorter: true
    }
  ];
  return (
    <ListResource
      resourceName="bonus"
      columns={columns}
      headers={headers}
      formatter={formattedData}
      deleteAction={permit}
      templateType="bonus"
      resourceModelName="Bonus"
      populate="employee,employee.position premise"
      editAction={permit}
      withCreateButton={permit}
      extraButtons={
        <Row align="middle" className="list-switch-wrapper">
          <Switch checked={isArchived} onChange={onChange} />
          <p>{t('bonus.archived')}</p>
        </Row>
      }
      extraQuery={isArchived ? 'is_archived=true' : 'is_archived=false'}
    />
  );
};

export default ListBonus;
