import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';

const ValidFirstSignature = () => {
  const { dispatchAPI } = useAuthContext();
  const { signatureId, entrieId } = useParams();
  const { message } = useErrorMessage();

  const updateEntrieStep = async () => {
    try {
      await dispatchAPI('PATCH', {
        url: `/entries/${entrieId}`,
        body: { step: 'APPLICANT_SIGNED' }
      });
    } catch (e) {
      message(e);
    }
  };

  const uploadFirstSignature = async () => {
    try {
      await dispatchAPI('GET', {
        url: `signatures/upload_first_signature/${signatureId}`
      });
      updateEntrieStep();
    } catch (e) {
      message(e);
    }
  };

  useEffect(async () => {
    await uploadFirstSignature();
  }, []);

  return <h1>Première signature validé</h1>;
};

export default ValidFirstSignature;
