/* eslint-disable prettier/prettier */
export const formatPhoneNumber = (phoneNumber) =>
  phoneNumber?.length === 9
    ? phoneNumber.replace(
        /(\d{1})(\d{2})(\d{2})(\d{2})(\d{2})/,
        '$1 $2 $3 $4 $5'
      )
    : phoneNumber
        .slice(1)
        .replace(/(\d{1})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5');

export const getFormattedPhoneNumber = (countryCode, phoneNumber) =>
  phoneNumber ? `${countryCode} ${formatPhoneNumber(phoneNumber)}` : '-';

export const validatePhoneNumber = (rule, value, callback) => {
  const phoneRegex = /^0[0-9]{9}$|^[1-9][0-9]{8}$/;
  if (value && !phoneRegex.test(value)) {
    callback('Veuillez saisir un numéro de téléphone valide');
  } else {
    callback(undefined, {
      country_code: value.country_code,
      number: value.number
    });
  }
};
