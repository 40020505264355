import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import useFields from './fields';
import { useAuthContext } from '../../../contexts/AuthContext';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainer/CreateUpdateContainer';
import { getEmployees } from './utils/getEmployees';
import { getOffers } from './utils/getOffers';
import { getKanbans } from './utils/getKanbans';

const CreateUpdateRecommandation = ({ purpose }) => {
  const navigate = useNavigate();
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [employees, setEmployees] = useState([]);
  const [offers, setOffers] = useState([]);
  const [kanbanCols, setKanbanCols] = useState([]);
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const { fields } = useFields({
    employees,
    offers,
    setIsFieldsLoading,
    isFieldsLoading
  });

  const createRecommandation = async (body) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/offers/${body.offer}?populate=premise`
      });

      const candidateBody = {
        last_name: body.last_name,
        first_name: body.first_name,
        email: body.email,
        phone_number: body.phone_number,
        premise: data.premise._id,
        potential_positions: [data.position]
      };

      const createdCandidate = await dispatchAPI('POST', {
        url: '/candidate',
        body: candidateBody
      });

      const newCandidate = createdCandidate.data;

      const applicationBody = {
        candidate: newCandidate._id,
        status: kanbanCols[0]._id,
        offer: body.offer
      };

      const createdApplication = await dispatchAPI('POST', {
        url: '/application',
        body: applicationBody
      });
      const newApplication = createdApplication.data;

      const recommandationBody = {
        employee: body.employee,
        candidate: newCandidate._id,
        application: newApplication._id,
        offer: newApplication.offer,
        how_known_candidate: body.how_known_candidate,
        why_recommanded: body.why_recommanded
      };

      await dispatchAPI('POST', {
        url: '/recommandations',
        body: recommandationBody
      });

      message.success(t('recommandations.messages.success'));
      navigate(-1);
    } catch (e) {
      message.error(e.message);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEmployees({ dispatchAPI, setEmployees, message });
    await getOffers({ dispatchAPI, setOffers, message });
    await getKanbans({ dispatchAPI, setKanbanCols, message });
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return (
    <CreateUpdateContainer
      fields={fields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="recommandations"
      resource="recommandations"
      customSubmit={purpose === 'create' ? createRecommandation : null}
    />
  );
};

CreateUpdateRecommandation.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateRecommandation;
