export const userRoles = {
  GUEST: 'green',
  USER: 'blue',
  'SUPER-USER': 'geekblue',
  ADMIN: 'red',
  'SUPER-ADMIN': 'magenta'
};

export const bonusStatus = {
  TO_PAY: 'orange',
  PAID: 'green'
};

export const pendingTasksCat = {
  POST: 'green',
  PATCH: 'blue',
  DELETE: 'red'
};

export const offerStatus = {
  PENDING: 'orange',
  SAVED: 'blue',
  PUBLISHED: 'green',
  ARCHIVED: 'grey'
};

export const applicationStatus = {
  TODO: 'orange',
  FIRST_INTERVIEW: 'blue',
  SECOND_INTERVIEW: 'green',
  VALIDATED: 'grey',
  REJECTED: 'green'
};

export const entriesStatus = {
  ON_HOLD: 'grey',
  ONBOARDING_CLOSE: 'green',
  TO_CHECK: 'orange',
  RH_ACTION: 'dodgerBlue'
};

export const incidentsStatus = {
  TREAT: 'red',
  PROCESS: 'grey',
  RESOLVED: 'green'
};

export const memosStatus = {
  PUBLISHED: 'green',
  DRAFT: 'grey',
  PLANNIFIED: 'black'
};

export const usersPosition = {
  DIRECTOR: 'red',
  RUNNER: 'blue',
  RH: 'yellow',
  SERVER: 'green',
  ROOM_MANAGER: 'violet'
};

export const exitsStatus = {
  WAITING: 'grey',
  ACTION_RH: 'blue',
  CLOSE: 'green'
};

export const templateStatus = {
  'CREATING-PROCESS': '#FADB14',
  VALIDATED: '#2DE180'
};

export const WFLStatus = {
  INITIATED: 'grey',
  PENDING: 'orange',
  VALIDATED: 'green'
};
