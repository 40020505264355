import React from 'react';
import { Skeleton, Row, Card, Avatar, Col } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { employeePrincipalInfo, employeeContentInfo } from '../listContent';
import { DescriptionList } from '../../../../components';
import ListInterviews from './ListInterviews';
import ListEvents from './ListEvents';
import ListEquipments from './ListEquipments';
import CollapseProvideDocuments from './ListProvideDocuments/CollapseProvideDocuments';
import CollapseGeneratedDocuments from './ListGeneratedDocuments/CollapseGeneratedDocuments';
import ShowTrainingPath from './ShowTrainingPath';

const DetailsCards = ({
  entry,
  employee,
  isLoading,
  files,
  setFiles,
  handlePostDocuments,
  trainingprogress,
  setRefresh,
  refresh
}) => {
  const { t } = useTranslation();
  const provideDocuments = [];
  const generatedDocuments = [];
  const path = window.location.pathname;
  const type = path.split('/');

  if (Object.keys(employee).length) {
    Object.entries(employee?.documents).map((document) =>
      document[1] === true ? provideDocuments.push(document[0]) : null
    );
  }

  if (entry && entry?.files?.length) {
    entry?.files.map((file) => generatedDocuments.push(file));
  }

  return (
    <>
      <Row gutter={[24, 0]} align="stretch">
        <Col xs={24} xl={14}>
          <Card title={t('employees.show.personal-info')} className="form-card">
            <Row gutter={[0, 24]}>
              <Col xs={24} md={8} lg={6} xl={4} xxl={6}>
                <Skeleton
                  loading={isLoading}
                  title={0}
                  paragraph={0}
                  avatar={{ size: 120 }}
                  active
                >
                  <Avatar
                    size={120}
                    src={employee && employee?.photo ? employee?.photo : ``}
                  />
                </Skeleton>
              </Col>
              <Col xs={24} md={16} lg={18} xl={20} xxl={18}>
                <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
                  <DescriptionList
                    data={employeePrincipalInfo(employee || {}, t)}
                    translate
                  />
                </Skeleton>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={24} xl={10}>
          <Card title={t('employees.show.position')} className="form-card">
            <Row>
              <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
                <DescriptionList
                  data={employeeContentInfo(employee || {}, t)}
                  translate
                />
              </Skeleton>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row gutter={[24, 0]} align="stretch">
        {trainingprogress ? (
          <Col xs={24} xl={10}>
            <Card
              title={t('employees.show.trainings_parcours')}
              className="form-card"
            >
              <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
                <ShowTrainingPath
                  trainingprogress={trainingprogress}
                  setRefresh={setRefresh}
                  refresh={refresh}
                />
              </Skeleton>
            </Card>
          </Col>
        ) : null}
        <Col xs={24} xl={7}>
          <Card
            title={
              type[2] === 'employees'
                ? t('employees.show.documents')
                : t('entries.show.provide_doc')
            }
            className="form-card"
          >
            <CollapseProvideDocuments
              files={files}
              handlePostDocuments={handlePostDocuments}
              setFiles={setFiles}
              provideDocuments={provideDocuments}
              isLoading={isLoading}
              employee={employee}
            />
          </Card>
        </Col>
        {type[2] === 'entries' && (
          <Col xs={24} xl={7}>
            <Card title={t('entries.show.generated_doc')} className="form-card">
              <CollapseGeneratedDocuments
                generatedDocuments={generatedDocuments}
                entry={entry}
                isLoading={isLoading}
              />
            </Card>
          </Col>
        )}
      </Row>
      <Row gutter={[24, 0]} align="stretch">
        <Col xs={24} xl={7}>
          <Card
            title={
              type[2] === 'employees'
                ? t('employees.show.equipments')
                : t('entries.show.equipments')
            }
            className="form-card"
          >
            <Row>
              <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
                <ListEquipments employee={employee} />
              </Skeleton>
            </Row>
          </Card>
        </Col>
        <Col xs={24} xl={11}>
          <Card
            title={t('employees.show.interviews_history')}
            className="form-card"
          >
            <Row style={{ width: '100%' }}>
              <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
                <ListInterviews employee={employee} />
              </Skeleton>
            </Row>
          </Card>
        </Col>
        <Col xs={24} xl={6}>
          <Card
            title={t('employees.show.exchanges_history')}
            className="form-card"
          >
            <Row>
              <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
                <ListEvents employee={employee} />
              </Skeleton>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};

DetailsCards.propTypes = {
  employee: PropTypes.shape({
    _id: PropTypes.string,
    photo: PropTypes.string,
    documents: PropTypes.arrayOf({})
  }),
  entry: PropTypes.arrayOf({}),
  isLoading: PropTypes.bool.isRequired,
  files: PropTypes.arrayOf(PropTypes.shape({ _id: PropTypes.string })),
  setFiles: PropTypes.func.isRequired,
  handlePostDocuments: PropTypes.func.isRequired,
  trainingprogress: PropTypes.shape({
    _id: PropTypes.string,
    progress: PropTypes.string
  }),
  setRefresh: PropTypes.func,
  refresh: PropTypes.bool
};

DetailsCards.defaultProps = {
  employee: {},
  files: [],
  entry: [],
  setRefresh: null,
  refresh: false,
  trainingprogress: []
};

export default DetailsCards;
