import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Card } from 'antd';
import useFields from './fields/fields';
import { CreateUpdateEmployeeContainer } from './CreateUpdateEmployeeContainer';
import EquipementsField from './fields/EquipmentsField/EquipmentsField';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import { useAuthContext } from '../../../contexts/AuthContext';

const CreateUpdateEmployee = ({ purpose }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { formFields, isFieldsLoading, position } = useFields();
  const [equipmentTree, setEquipmentTree] = useState({});
  const [equipments, setEquipments] = useState([]);
  const [equipmentData, setEquipmentData] = useState([]);

  const getEmployeeEquipments = async () => {
    if (purpose === 'edit') {
      try {
        const { data } = await dispatchAPI('GET', {
          url: `employees/${id}?populate=equipments._id`
        });
        setEquipmentData(data.equipments);
      } catch (e) {
        message(e);
      }
    }
  };

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data,
        start_date: data.start_date && moment(data.start_date),
        end_date: data.end_date && moment(data.end_date)
      })
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        equipments: equipmentData
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        equipments: equipmentData
      })
    }
  };

  const getEquipments = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/equipment'
      });
      const options = [];
      data
        .filter((equipment) => equipment.show)
        .map((equipment) =>
          options.push({
            label: equipment.label,
            value: equipment._id,
            quantity: equipment.quantity,
            size: equipment.size
          })
        );
      setEquipments(options);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getEquipments();
      await getEmployeeEquipments();
    })();
  }, []);

  const setChildren = (value) => {
    const newEquipmentTree = JSON.parse(JSON.stringify(equipmentTree));
    newEquipmentTree.children = value;
    setEquipmentTree(newEquipmentTree);
  };

  return (
    <CreateUpdateEmployeeContainer
      formFields={formFields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="employees"
      resource="employees"
      config={config}
      populate="employee"
      position={position}
      formExtra={
        <Card
          className="form-card"
          title={t(`cardTitles.employees.equipmentCardTitle`)}
        >
          <EquipementsField
            list={equipmentTree.children || []}
            setList={setChildren}
            equipments={equipments}
            setEquipmentData={setEquipmentData}
            equipmentData={equipmentData}
            purpose={purpose}
          />
        </Card>
      }
    />
  );
};

CreateUpdateEmployee.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateEmployee;
