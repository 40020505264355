import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Switch, Row, Col, Select, Typography } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { StarFilled } from '@ant-design/icons';
import { ListResource } from '../../../components';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import { useFormatter } from '../../../utils/formatter';
import { useHandleResize } from '../../../utils/HandleResize';
import { permissionVerification } from '../../../utils/permissionVerification';

const { Option } = Select;

const ListCandidates = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const params = new URLSearchParams(location.search);
  const archived = params.get('archived');
  const { dispatchAPI, userPermissions } = useAuthContext();
  const { message } = useErrorMessage();
  const { formattedData } = useFormatter();
  const [extraQuery, setExtraQuery] = useState('');
  const [positions, setPositions] = useState([]);
  const [premises, setPremises] = useState([]);
  const [isArchived, setIsArchived] = useState(archived === 'true');
  const [premiseId, setPremiseId] = useState(null);
  const [positionId, setPositionId] = useState(null);
  const { width } = useHandleResize();
  const [permit, setPermit] = useState(false);

  useEffect(() => {
    const permission = permissionVerification(
      userPermissions,
      '/recruitments/candidates/modification'
    );
    setPermit(permission);
  }, [userPermissions]);

  const onChange = () => {
    setIsArchived(!isArchived);
    navigate(`${pathname}?archived=${!isArchived}`);
  };

  const headers = [
    {
      label: 'first_name',
      key: 'first_name'
    },
    {
      label: 'last_name',
      key: 'last_name'
    },
    {
      label: 'phone_number.country_code',
      key: 'phone_number.country_code'
    },
    {
      label: 'phone_number.number',
      key: 'phone_number.number'
    },
    {
      label: 'potential_positions',
      key: 'potential_positions',
      model_key: 'position',
      multiple_data_key: 'label'
    },
    {
      label: 'premise',
      key: 'premise'
    },
    {
      label: 'last_contact',
      key: 'last_contact'
    }
  ];

  const columns = [
    {
      title: t('candidates.form.first_name'),
      key: 'first_name',
      dataIndex: 'first_name',
      sorter: true
    },
    {
      title: t('candidates.form.last_name'),
      key: 'last_name',
      dataIndex: 'last_name',
      sorter: true
    },
    {
      title: t('candidates.form.potential_positions'),
      key: 'potential_positions',
      dataIndex: 'potential_positions',
      render: (potential_positions) =>
        (potential_positions &&
          potential_positions.map((position, index, array) => {
            if (index === array.length - 1) {
              return position?.label;
            }
            return `${position?.label}, `;
          })) ||
        '-',
      sorter: true,
      filters: positions.map((r) => ({
        text: r.label,
        value: r._id
      }))
    },
    {
      title: t('offers.form.premise'),
      key: 'premise',
      dataIndex: 'premise',
      render: (premise) => premise.label,
      sorter: true
    },
    {
      title: t('candidates.form.interesting'),
      key: 'interesting',
      dataIndex: 'interesting',
      render: (interesting) =>
        interesting ? (
          <StarFilled style={{ color: 'var(--secondaryColor)' }} />
        ) : (
          ''
        ),
      sorter: true,
      filters: [true, false].map((r) => ({
        text: r ? (
          <StarFilled style={{ color: 'var(--secondaryColor)' }} />
        ) : (
          '-'
        ),
        value: r
      }))
    },
    {
      title: t('candidates.form.last_contact'),
      key: 'last_contact',
      dataIndex: 'last_contact',
      render: (last_contact) => moment(last_contact).format('ll'),
      sorter: true
    }
  ];

  const getPositions = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/positions?show=true'
      });
      setPositions(data);
      return data;
    } catch (error) {
      message(error);
      return [];
    }
  };

  const getPremises = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/premise'
      });
      setPremises(data);
    } catch (e) {
      message(e);
    }
  };

  const setQuery = () => {
    let query = '';

    query = isArchived ? 'is_archived=true' : 'is_archived=false';

    if (premiseId) {
      query += `&premise=${premiseId}`;
    }

    if (positionId) {
      query += `&position=${positionId}`;
    }

    setExtraQuery(query);
  };

  useEffect(() => {
    getPositions();
    getPremises();
    setQuery();
  }, [isArchived, premiseId, positionId]);

  return (
    <ListResource
      resourceName="candidates"
      populate="premise,potential_positions"
      formatter={formattedData}
      templateType="candidates"
      editAction={permit}
      withCreateButton={permit}
      deleteAction={permit}
      columns={columns}
      headers={headers}
      resourceModelName="Candidate"
      extraButtons={
        <Row
          gutter={[8, 8]}
          align="middle"
          justify="space-between"
          style={{ flexWrap: width > 1700 ? 'nowrap' : 'wrap' }}
        >
          <Col>
            <Select
              placeholder={t('entries.premise_place_holder')}
              showSearch
              allowClear
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              style={{ width: 240, marginRight: 8 }}
              onChange={(value) => {
                setPremiseId(value);
              }}
            >
              {premises.map((premise) => (
                <Option value={premise._id} key={premise._id}>
                  {premise.label}
                </Option>
              ))}
            </Select>
            <Select
              placeholder={t('entries.position_place_holder')}
              showSearch
              allowClear
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              style={{ width: 240 }}
              onChange={(value) => {
                setPositionId(value);
              }}
            >
              {positions.map((offer) => (
                <Option value={offer._id} key={offer._id}>
                  {offer.label}
                </Option>
              ))}
            </Select>
          </Col>
          <Col>
            <Row
              align="middle"
              className="list-switch-wrapper"
              style={{ flexWrap: 'nowrap' }}
            >
              <Switch checked={isArchived} onChange={onChange} />
              <Typography>{t('candidates.archived')}</Typography>
            </Row>
          </Col>
        </Row>
      }
      extraQuery={extraQuery}
    />
  );
};

export default ListCandidates;
