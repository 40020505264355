const setRestitutionBody = ({ value, restitutionType }) => {
  switch (restitutionType) {
    case 'material': {
      return {
        equipments: Object.entries(value).map((v) => ({
          ...v[1],
          label: v[0]
        })),
        is_material: true
      };
    }
    case 'check_list': {
      const isValidated = Object.values(value.check_list).every(
        (v) => v === true
      );
      return {
        ...value,
        is_check_list_validated: isValidated
      };
    }
    default:
      return null;
  }
};

export const setBody = ({ value, activeStep, file, restitutionType }) => {
  switch (activeStep) {
    case 'SIGNED_BREAK':
      return {
        $push: {
          documents: {
            _id: file._id,
            type: activeStep,
            filename: file.metadata.originalName,
            contentType: file.contentType,
            metadata: { originalName: file.metadata.originalName }
          }
        }
      };
    case 'ACKNOWLEDGMENT_OF_RECEIPT':
      return {
        $push: {
          documents: {
            _id: file._id,
            type: activeStep,
            filename: file.metadata.originalName,
            contentType: file.contentType,
            metadata: { originalName: file.metadata.originalName }
          }
        },
        is_resignation_letter: true
      };
    case 'EMPLOYEE_BREAKUP_NOTIFICATION':
      return {
        $push: {
          documents: {
            _id: file._id,
            type: activeStep,
            filename: file.metadata.originalName,
            contentType: file.contentType,
            metadata: { originalName: file.metadata.originalName }
          }
        }
      };
    case 'RESTITUTION_VALIDATED':
      return setRestitutionBody({ value, restitutionType, file, activeStep });
    case 'ACCESS_DELETED':
      return { ...value, status: 'CLOSE' };
    default:
      return value;
  }
};
