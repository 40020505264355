import React from 'react';
import { Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../../../utils/ErrorMessage';

const { Dragger } = Upload;

const acknowledgmentContent = ({ setFile }) => {
  const { message } = useErrorMessage();
  const { token } = useAuthContext();

  const draggerProps = {
    name: 'file',
    multiple: true,
    action: `${process.env.REACT_APP_API_URL}/files`,
    headers: { Authorization: `Bearer ${token}` },
    onChange(info) {
      const { status, response } = info.file;

      setFile(response);

      if (status === 'error') {
        message(`${info.file.name} file upload failed.`);
      }
    }
  };

  const resignationLetterFields = [
    {
      name: ['documents'],
      rules: [{ required: true }],
      input: (
        <Dragger {...draggerProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Glisser / Déposer</p>
        </Dragger>
      )
    }
  ];

  return {
    resignationLetterFields
  };
};

export default acknowledgmentContent;
