import React, { useState } from 'react';
import { Button, DatePicker, Form, Input, Select, Typography } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import useFields from './InterviewListContent';
import { useGenerateFormItem } from '../../../../utils/GenerateFormItem';

const { Option } = Select;
const { TextArea } = Input;

const layout = {
  labelCol: { span: 4, offset: 1 },
  wrapperCol: { span: 16 }
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 }
};

const AddEditActivityForm = ({
  purpose,
  handleAddActivity,
  handleUpdateActivity,
  setFormActivity,
  setListActivity,
  itemSelected
}) => {
  const { t } = useTranslation();
  const [type, setType] = useState(itemSelected?.type || '');
  const generateFormItem = useGenerateFormItem();
  const { fields } = useFields();

  const initValues = () => {
    let values = {};

    if (purpose === 'edit') {
      values = { ...itemSelected };
      values.dateTime = moment(values.dateTime);
      values.date = moment(values.date);
      values.time_start = moment(values.time_start);
      values.time_end = moment(values.time_end);
    } else values.dateTime = moment();
    return values;
  };

  const finish = (values) => {
    if (purpose === 'create') handleAddActivity(values);
    else handleUpdateActivity(values);
  };

  return (
    <div style={{ marginTop: 20 }}>
      <Form
        style={{ color: 'white' }}
        {...layout}
        initialValues={initValues()}
        onFinish={finish}
      >
        <Form.Item
          name="type"
          label={
            <Typography style={{ color: 'white' }}>
              {t('applications.form.activity.category')}
            </Typography>
          }
          rules={[{ required: true }]}
        >
          <Select onChange={(event) => setType(event)}>
            <Option value="note">{t('applications.form.activity.note')}</Option>
            <Option value="call">{t('applications.form.activity.call')}</Option>
            <Option value="meeting">
              {t('applications.form.activity.rdv')}
            </Option>
          </Select>
        </Form.Item>
        {type !== 'meeting' && (
          <>
            <Form.Item
              name="title"
              label={
                <Typography style={{ color: 'white' }}>
                  {t('applications.form.activity.title')}
                </Typography>
              }
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="desc"
              label={
                <Typography style={{ color: 'white' }}>
                  {t('applications.form.activity.description')}
                </Typography>
              }
            >
              <TextArea rows={4} />
            </Form.Item>
          </>
        )}
        {type === 'meeting' &&
          fields &&
          fields.map((field) => generateFormItem('interviews', field))}
        {type !== 'meeting' && (
          <Form.Item
            name="dateTime"
            label={
              <Typography style={{ color: 'white' }}>
                {t('applications.form.activity.date')}
              </Typography>
            }
            rules={[{ required: true }]}
          >
            <DatePicker showTime format="DD-MM-YYYY" />
          </Form.Item>
        )}
        <Form.Item {...tailLayout}>
          <Button
            danger
            style={{ marginRight: 20 }}
            onClick={() => {
              setFormActivity(false);
              setListActivity(true);
            }}
          >
            {t('buttons.cancel')}
          </Button>
          <Button type="primary" htmlType="submit">
            {t('buttons.add')}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddEditActivityForm;

AddEditActivityForm.propTypes = {
  purpose: PropTypes.string.isRequired,
  handleAddActivity: PropTypes.func.isRequired,
  handleUpdateActivity: PropTypes.func.isRequired,
  setFormActivity: PropTypes.func.isRequired,
  setListActivity: PropTypes.func.isRequired,
  itemSelected: PropTypes.shape({ type: PropTypes.string }).isRequired
};
